import React from "react";

import { useElemInView } from "../../../../utils/customHooks/useElemInView";
import ListStyle from './comps/ListStyle'
import CardsStyle from './comps/CardsStyle'
import TableStyle from './comps/TableStyle'
import {UserProfilesStyle} from './comps/UserProfilesStyle'
import { PaddingTopBottom20 } from "oolib";
import EmptyStateListing from "./EmptyStateListing";
import { AnnotationCardStyle } from "../../../../components/AnnotationCardStyle"
import { TagWithAnnoationsStyle } from "../../../../components/TagWithAnnotationsStyle"

const ListingModule = ({
  status,
  data,
  listingStyle,
  listingConfig,
  fetchNextPage,
  isFetchingNextPage,
  totalCount,
  sectionTitle,
  sectionLink,
  activeSort,
  setActiveSort,
  hasNextPage,
  ListingWrapper,
  enableContainerQuery,
  emptyPageConfigSearch,
  contentTypes,
  inTCI,
  builderProps,
  getDataToBeExported,
  hasAccessToDowloadCsv,
  tagCategory,
  tag,
  searchSortBarRef

}) => {
  const { observerRef } = useElemInView({ callback: fetchNextPage });

  

const flattenPages = (data) => 
  data.pages.reduce((a,b) => [...a, ...b.data] ,[])
  
  
  


  const ListingStyles = {
    ListStyle,
    CardsStyle,
    TableStyle,    
    UserProfilesStyle,
    AnnotationCardStyle,
    TagWithAnnoationsStyle
  }


  const ThisListingStyle = ListingStyles[listingStyle];

  return (
    <PaddingTopBottom20>

    {  data && flattenPages(data).length===0?
        <EmptyStateListing listingStyle={listingStyle} contentTypes={contentTypes} emptyPageConfigSearch={emptyPageConfigSearch} />
      :<ThisListingStyle
        status={status} 
        data={data && flattenPages(data)} 
        observerRef={hasNextPage?observerRef:null} 
        isFetchingNextPage={isFetchingNextPage}      
        totalCount={totalCount}
        sectionTitle={sectionTitle}
        sectionLink={sectionLink}
        activeSort={activeSort}
        setActiveSort={setActiveSort}
        hasNextPage={hasNextPage}
        ListingWrapper={ListingWrapper}
        {...listingConfig} 
        enableContainerQuery={enableContainerQuery}
        inTCI={inTCI}
        builderProps={builderProps}
        getDataToBeExported={getDataToBeExported}
        hasAccessToDowloadCsv={hasAccessToDowloadCsv}
        tagCategory={tagCategory}
        tag={tag}
        searchSortBarRef={searchSortBarRef}
     
        />}
    </PaddingTopBottom20>
  );
};

export default ListingModule;
