import React, { useRef } from "react";
import {
  ButtonPrimaryV2,
  ButtonSecondaryV2,
  ModalConfirmAction,
  TextInput,
  UI_CAPTION,
  UI_CAPTION_BOLD,
  getBreakPoint,
  icons,
  useScreenWidth,
} from "oolib";
import {
  ButtonsStyled,
  NewLineStyled,
  SearchBarStyled,
  LoadingButtonStyled,
} from "./styled";
import { useState } from "react";
import styled from "styled-components";
import LexicalTextEditor from "../../../inputs/TextEditor/LexicalTextEditor";
import { set } from "lodash";
import ConfirmAction from "@/components/generalUI/ConfirmAction";
const { ArrowClockwise, PaperPlaneRight } = icons;

export const SearchBar = ({
  onSubmit,
  deleteChat,
  disableOnSubmit,
  showDeleteChatButton,
}) => {
  const [value, setValue] = useState({});
  const [editorKey, setEditorKey] = useState(false);
  const editorRef = useRef();

  const screenWidth = useScreenWidth();

  const noUserText = value.allText ? value.allText.trim().length === 0 : true;

  const disableOnClick = disableOnSubmit || noUserText;

  const isMobile = screenWidth < getBreakPoint("sm");

  const handleOnClick = () => {
    if (disableOnClick) return;
    const allTextValue = value.allText;
    onSubmit({ value: allTextValue });
    setValue({});
    setEditorKey((prev) => !prev);
  };

  const handleOnKeyPress = (e) => {
    if (isMobile) return;
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      setTimeout(handleOnClick, 0);
    }
  };

  return (
    <SearchBarStyled
      onClick={() => {
        editorRef.current.click();
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "1.2rem",
          minHeight: "5rem",
        }}
      >
        <div onKeyDown={handleOnKeyPress} style={{ flex: 1 }}>
          {" "}
          <div ref={editorRef}>
            <LexicalTextEditor
              key={editorKey}
              placeholderColor={"#00000080"}
              placeholder="Ask any question..."
              value={value}
              variant={"simple"}
              onChange={(id, value) => setValue({ ...value })}
            />
          </div>
        </div>

        <ButtonsStyled showButton={!noUserText || showDeleteChatButton}>
          <ConfirmAction
            onConfirmText={"Clear & Start New"}
            title="Your existing chat will permanently delete."
          >
            <ButtonSecondaryV2
              disabled={disableOnSubmit}
              onClick={deleteChat}
              icon="NotePencil"
            />
          </ConfirmAction>
          <LoadingButtonStyled loading={disableOnSubmit}>
            <ButtonPrimaryV2
              onClick={handleOnClick}
              icon={disableOnSubmit ? "CircleNotch" : "ArrowUp"}
              disabled={disableOnClick}
            />
          </LoadingButtonStyled>
        </ButtonsStyled>
      </div>
      <NewLineStyled>
        <UI_CAPTION_BOLD>shift + enter</UI_CAPTION_BOLD>{" "}
        <UI_CAPTION>for new line</UI_CAPTION>
      </NewLineStyled>
    </SearchBarStyled>
  );
};
