import { Container, Wrapper700, SkeletonLoader, PaddingTopBottom30, ListContent, makeArrayFromLength } from "oolib";
import React, { Fragment } from "react";
import { TotalCount } from "../../../TotalCount";
import { listingWrapperSelector } from "../../utils/listingWrapperSelector";
import DownloadDataButton from "../../DownloadDataButton";



const FallbackWrapper = ({children, enableContainerQuery}) => <div enableContainerQuery={enableContainerQuery}><Wrapper700 left>{children}</Wrapper700></div>


const ListStyle = ({ data, status, isFetchingNextPage, observerRef, totalCount,configs, onClick, ListingWrapper, enableContainerQuery, inTCI,getDataToBeExported, hasAccessToDowloadCsv }) => {


  const Wrapper = listingWrapperSelector({
    ListingWrapper,
    FallbackWrapper
  })


  const genSkeletonListElems = () =>
    makeArrayFromLength(6).map(() => <SkeletonLoader style={{ height: "13rem", margin: '1rem 0' }} />);

  const genListElems = (data) =>
    data?.map((d, i) => {
      const linkTo = !inTCI && `/published-page/${d.meta.kp_content_type}?id=${d._id}`;
      return (
        <Fragment key={d._id}>
         
        <div key={d._id} ref={data.length === i + 1 ? observerRef : null}>


           <ListContent
            id={d._id}
            data={d}
            to={!onClick && linkTo} //onClick overrides to, because we need to pass onClick from outside, while to is generated from within here itself.
            config={configs}
            onClick={onClick}
          />
          </div>
        </Fragment>
      );
    });


  return (
    <div>


        <Wrapper enableContainerQuery={enableContainerQuery}>
        <div
        style={{ display: "flex", marginRight: "1rem", paddingBottom: "1rem" }}
      >
          {totalCount !== undefined && <TotalCount style={{paddingBottom: '2rem'}} totalCount={totalCount} />}
          {hasAccessToDowloadCsv && (
          <>
            <DownloadDataButton getDataToBeExported={getDataToBeExported}/>
            
          </>
        )}

        </div>
       
          {/* <PaddingTopBottom30> */}
          {status === "loading" ? genSkeletonListElems() : genListElems(data)}
          {isFetchingNextPage && genSkeletonListElems()}
          {/* </PaddingTopBottom30> */}


 
        </Wrapper>
    </div>
  );
};

export default ListStyle;
