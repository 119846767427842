import React, { useMemo, useState } from "react"
import { Container, icons, colors2 as colors, UI_BODY_SEMIBOLD_SM, DropdownSingle, ButtonGhost, DropdownMulti } from "oolib"
import { Search } from "../../../../../pageTpls/ListingPage/newComps/ActionsPanel/comps/Search"
import { Sort } from "../../../../../pageTpls/ListingPage/newComps/ActionsPanel/comps/Sort"
import { StyledSortButton } from "../../../../../pageTpls/ListingPage/newComps/ActionsPanel/styled"

const { SortDescending } = icons;

export const SearchSortPanel = ({ disableColorCode, tplOptionsDropdownData, selectedTPLsArrayOptions, setSelectedTpls, selectedView, tplOptions,  setSelectedView, handleExternalButtonClick, setParentToggleState, setKeyword, keyword, defaultSortOptions, activeSort, setActiveSort, isMobile }) => {
    const [showSearchInputMobile, setShowSearchInputMobile] = useState(false);
    
    const activeSortValue = useMemo(() => defaultSortOptions.filter(item => item.value === activeSort), [activeSort])[0]

    return(
        <div style={{ position: 'sticky', top: 0, background: 'white', zIndex: 10, margin: isMobile && "0 -1.2rem" }}>
            <div style={{ 
                height: isMobile ?  "4.5rem" : '4rem',  
                backgroundColor: colors.white, 
                display: 'flex', 
                gap: '2rem', 
                borderBottom: `1px solid ${colors.grey10}`, 
                padding: '0 2rem',
                justifyContent: "space-between" 
            }}> 
                {isMobile && !showSearchInputMobile &&
                    <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
                    <DropdownMulti
                        S
                        genCustomSelectComp={() => 
                            <ButtonGhost
                                icon="File"
                                iconWeight="bold"
                                iconSize={20}               
                                style={{ width: "45px", height: "45px" }}
                            />
                        }
                        popOutOfOverflowHiddenParent={true}
                        options={tplOptionsDropdownData}
                        value={selectedTPLsArrayOptions}
                        onChange={(k, v) =>
                            setSelectedTpls(
                            v.map((d) =>
                                tplOptions.find(
                                (dd) => dd.kp_content_type === d.value
                                )
                            )
                            )
                        }
                    />
                    <ButtonGhost
                        icon="Funnel"
                        iconWeight="bold"
                        iconSize={20}
                        style={{ width: "45px", height: "45px" }}
                        onClick={() => {
                            // Always ensure LSB is true when selecting this view
                            if (selectedView.value !== "filters") {
                            setParentToggleState(prev => ({ ...prev, lsb: true }));
                            } else {
                            // Toggle only when already on this view
                            setParentToggleState(prev => ({ ...prev, lsb: !prev.lsb }));
                            }
                            setSelectedView({ value: "filters", icon: "Funnel", display: "Filters" });
                        }}
                        />

                    {!disableColorCode && <ButtonGhost
                            icon="PaintBucket"
                            iconWeight="bold"
                            iconSize={20}
                            style={{ width: "45px", height: "45px" }}
                            onClick={() => {
                                // Always ensure LSB is true when selecting this view
                                if (selectedView.value !== "breakdown") {
                                setParentToggleState(prev => ({ ...prev, lsb: true }));
                                } else {
                                // Toggle only when already on this view
                                setParentToggleState(prev => ({ ...prev, lsb: !prev.lsb }));
                                }
                                setSelectedView({ value: "breakdown", icon: "PaintBucket", display: "Color Code", message: "new" });
                            }}
                    />}
                    </div>}
                
                {(!showSearchInputMobile && isMobile) && <ButtonGhost
                    icon="MagnifyingGlass"
                    onClick={() => setShowSearchInputMobile(true)}
                    iconSize={'M'}
                    style={{ width: "45px", height: "45px" }}
                />}
                {(showSearchInputMobile || !isMobile) && (
                            <Search 
                                isMobile={isMobile}
                                style={{ borderRight: "none" }}
                                placeholder={"Search annotation"}
                                // onClose={() => setShowSearchInputMobile(false)}
                                searchTermInputText={keyword}
                                handleSearch={(k,v) => setKeyword(v)}
                                {...{ onClose : isMobile ? () => setShowSearchInputMobile(false) : undefined }}
                            />
                )}

                {!isMobile && <div style={{display: 'flex', alignItems: 'center', gap: '1rem', borderLeft: `1px solid ${colors.grey10}` }}>
                    <Sort
                        genCustomSelectComp={() => (
                            <StyledSortButton>
                                <SortDescending size={16} weight="bold" />
                                <UI_BODY_SEMIBOLD_SM>{`Sort by: ${activeSort?.display || activeSortValue?.display}`}</UI_BODY_SEMIBOLD_SM>
                            </StyledSortButton>
                        )}
                        saveValueAsString
                        sortConfig={defaultSortOptions}
                        activeSort={activeSort}
                        setActiveSort={setActiveSort}
                    />
                </div>}
            </div>
        </div>
    )
}