import { colors, colors2, ellipsis, getBreakPoint, UI_BODY_BOLD_SM_DF } from 'oolib';
import styled, { css } from 'styled-components';

const applyFreezeFirstColCss = ({row_header}) => ({freezeFirstCol, cellIdx, rowIdx}) => freezeFirstCol && cellIdx === 0 && css`
  position: sticky;
  left: 0;
  z-index:  ${row_header === 'header' ? 10 : 1};
  border-right-width: 4px;
  background-color: ${
    row_header === 'header'
    ? colors.white
    : rowIdx % 2 === 0
      ? colors.greyColor3
      : colors.white
  };
`
//header

export const StyledDataTableHeader = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors2.grey10};
  border-bottom: 1px solid ${colors2.grey10};
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
  display: flex;
  align-items: center;
`

export const StyledDataTableHeaderCell = styled.div`
  flex: 0 0 auto;
  border-right: 1px solid ${colors2.grey10};
  cursor: pointer;

  ${applyFreezeFirstColCss({row_header : 'header'})}
  
`

export const StyledDataTableHeaderCellText = styled(UI_BODY_BOLD_SM_DF)`
  ${ellipsis}
`

export const StyledHeaderCellContentWrapper = styled.div`
position: relative;
  display: flex; 
  align-items: center; 
  gap: 0.5rem; 
  min-width: 0;
  height: 100%;
  padding-top: 0.9rem;
  padding-bottom: 0.9rem
`

//rows

export const StyledDataTableRowsContainer = styled.div`
  border: 1px solid ${colors2.grey10};
  border-bottom-left-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
  border-top: none;
`


export const StyledRowCellContentWrapper = styled.div`
  padding-left: 1rem;
  padding-right: ${({cellComp}) => cellComp !== 'TableTextInput' ? '1rem' : 'unset' }; // cuz in textinput case we want the text to bleed off the right edge
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
`

export const StyledTableRowCell = styled.div`
   flex: 0 0 auto;
   height: 100%;
   border-right: 1px solid ${colors2.grey10};
   ${applyFreezeFirstColCss({row_header : 'row'})}
   ${({highlight}) => {
    const colorLookup = {
      error: colors2.error,
    };
    if(highlight){
      return css`
        border: 2px solid ${colorLookup[highlight]};
      `
    }
   }}
   p, h1, h2, h3, h4, h5, h6{
    ${ellipsis}
  }

`

export const StyledCheckboxContainer = styled.div`
  width: ${({ screenWidth }) => screenWidth >= getBreakPoint('md') ? "36px" : "42px"};
  min-height: ${({ screenWidth }) => screenWidth >= getBreakPoint('md') ? "36px" : "42px"};
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid ${colors2.grey10};
`;