

import {configs_gelabs_annoBase} from './gelabs'
import { configs_nkms_annoBase } from './nkms'
import { configs_demo_annoBase } from './demo'


export const annotationExplorerConfigs = {
    gelabs: configs_gelabs_annoBase,
    nkms: configs_nkms_annoBase,
    demo: configs_demo_annoBase
}