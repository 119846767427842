export const generateColorCodeOptions = (filterConfigs) => {
  if (!filterConfigs || !Array.isArray(filterConfigs)) {
    return [];
  }
  
  const colorCodeOptions = [];
  
  for (const config of filterConfigs) {

    if (config?.configs && Array.isArray(config.configs)) {

        for (const subConfig of config.configs) {
            
            if (subConfig?.sectionTitle && subConfig?.configs) {
              
              const validConfigs = subConfig.configs.filter(item =>
                item?.target?.filterType !== 'rollupValuePathType' && 
                item?.target?.filterType !== 'nestedRollupTagType'
              )

              if (validConfigs.length > 0) {
                colorCodeOptions.push({
                  title: subConfig.sectionTitle,
                  options: validConfigs
                });
              }

            }

        }
    }
  
  }
  
  return colorCodeOptions;
}
