import React from 'react'
import { ButtonPrimary, colors, transition, WrapperCardGrid } from 'oolib'
import { SANS_2, SERIF_5_6 } from 'oolib'
import styled from 'styled-components'

const StyledCardRadioWrapper = styled.div`
padding: 3rem 2rem;
  text-align: center;
  border: 2px solid;
  border-color: ${colors.greyColor5};
  background-color: ${colors.greyColor5};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* @include kp-multi-transition(background-color, border-color); */
  ${transition('background-color', 'border-color')}
  cursor: pointer;

  &:hover{
    background-color: ${colors.white};
  }

  &.-selected-{
    background-color: ${colors.white};
    border-color: ${({theme}) => theme.colors.primaryColor100};
  }
`

const CardRadioInput = (props) => {
	const { value, options, onChange, id, style } = props

	return (
		<WrapperCardGrid style={style} id={id}>
			{options.map((d) => {
				let display = d.display || d.title
				let desc = d.desc || d.subtitle
				return (
					<CardRadio
						key={d.value}
						selected={d.value === value?.value}
						display={display}
						desc={desc}
						onClick={() => onChange && onChange(id, d)}
					/>
				)
			})}
		</WrapperCardGrid>
	)
}

export const CardRadio = (props) => {
	//col settings must be the bootstrap codes

	const { display, desc, action, selected, onClick } = props

	return (
		<StyledCardRadioWrapper
			onClick={onClick}
			className={`${selected ? '-selected-' : ''}`}>
			<div>
				{display && <SERIF_5_6>{display}</SERIF_5_6>}
				{desc && <SANS_2 style={{ marginTop: '0.5rem' }}>{desc}</SANS_2>}
			</div>
			{action && (
				<div style={{ margin: '0 auto' }}>
					<ButtonPrimary
						style={{ marginTop: '1.5rem' }}
						children={action.text}
					/>
				</div>
			)}
		</StyledCardRadioWrapper>
	)
}

export default CardRadioInput
