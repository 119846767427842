
import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { getTenant } from '../../TENANT_CONFIGS'

const internalEmailDomain = "@ooloilabs.in"
const mhaiBockDomain=["arundatimd@gmail.com"]
const devGTag = 'G-LJN5509HP0'
const productionGTag = 'G-2JJXFN5E3Q'

export const useActivateGoogleAnalytics = ({ platformConfigsStatus, platformConfigs, userData }) => {

  useEffect(() => {
    if (platformConfigsStatus === 'success') {
      const { deployment: { _GoogleAnalytics } } = platformConfigs;

      //initialize the dev GA on 'dev ENV' (for testing purposes)
      if(
        process.env.NODE_ENV === "production" && 
        import.meta.env.VITE_ENV === 'dev' 
      ){
        initializeGA({userData, gTag: devGTag})
      }
      
      //initialize prod GA on 'prod ENV' of only those tenants that have _GoogleAnalytics enabled
      if (
        process.env.NODE_ENV === "production" && 
        import.meta.env.VITE_ENV === 'prod' && 
        _GoogleAnalytics?.enable
      ) {
        initializeGA({userData, gTag: productionGTag})
      }
    }
  }, [platformConfigsStatus, userData])
}

const initializeGA = ({userData, gTag}) => {
  let tenantId = getTenant();
  ReactGA.initialize(gTag);
  //this just used to filter the userId in the reports later but it record the userData later
  
  const email=userData?.user?.email
  if (email?.includes(internalEmailDomain) || (tenantId==="mhai" && mhaiBockDomain.includes(email) )){
   
    ReactGA.set({ traffic_type: "internal" });
  }
  // Send pageview with a custom path
  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
}
