
import { SANS_2, clampText, ellipsis, colors } from "oolib"
import styled,{css} from "styled-components"

const { yellowHighlight, greyColor10, white } = colors;

export const TagDisplayContainerStyled = styled.div`
  background: ${white};
position: relative;
  border: 1px solid ${greyColor10};
  padding: 1rem;
  padding-right: 3rem;
  max-width: 45rem;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);

  border-radius: 3px;
  z-index: 1000;

  /* position: absolute;
  ${({ top }) =>
    top &&
    css`
      top: ${top}px;
    `}
  ${({ left }) =>
    left &&
    css`
      left: ${left}px;
    `} */
`

export const TagsContainerStyled = styled.div`
flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 20rem;
  padding: 0.5rem;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    width: 0px; /* webkit */
  }
`

export const TagListStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.4rem;
  margin-top: 0.3rem;
  div {
    white-space: normal;
  }
`


export const StyledTextFragmentPreview = styled(SANS_2)`
  background-color: ${yellowHighlight}25;
  ${clampText(2)}
  width: fit-content;
`

export const StyledActionMenuWrapper = styled.div`
position: absolute;
right: 0;
top: 0;
`