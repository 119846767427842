import React from "react";

import { CardWrapperStyled, StyledButtonSecondaryCompact } from "./styled";
import { CONTENT_BODY_SM, UI_TAG, TagDisplay } from "oolib";

import { TagListStyled } from "../../../AnnoPlugin/AnnoLightbox/AnnoLightboxDisplay/styled";
import { groupTagsByCategory } from "../../utils";
import { __GetContentTypeConfigNew } from "../../../../../../../../utils/getters/gettersV2";

const TagCategoryAndTags = ({ data = [] }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      {data.map(({ tagCategory, tags }) => (
        <div>
          <UI_TAG>
            {__GetContentTypeConfigNew(tagCategory).general?.content?.title ||
              tagCategory}
          </UI_TAG>

          <TagListStyled>
            {tags.map((tag, i) => (

              <TagDisplay
                variant="secondary"
                XS
                display={`${tag.display}`}
              ></TagDisplay>

            ))}
          </TagListStyled>
        </div>
      ))}
    </div>
  );
};

export const Cards = ({
  _id,
  text,
  tags,
  cached,
  handleApply,
  handleRemove,
  applied,
}) => {
  const groupedTags = groupTagsByCategory(tags);
  // console.log({ _id, text, tags, cached })
  // const { handleSetAnnoData } = useAnnoContextLex();
  // const [editor] = useLexicalComposerContext();

  return (
    <CardWrapperStyled>
      <div style={{ display: "flex", gap: "1.6rem", flexDirection: "column" }}>
        <CONTENT_BODY_SM>{text}</CONTENT_BODY_SM>

        <TagCategoryAndTags data={groupedTags} />


        {cached ? <TagDisplay XS display="cached" /> : null}
        <div style={{ display: "flex", gap: "1rem" }}>
          {applied ? (
            <StyledButtonSecondaryCompact
              icon={"X"}
              S
              onClick={() => handleRemove({ _id })}
              remove={true}
            >
              Remove
            </StyledButtonSecondaryCompact>
          ) : (
            <StyledButtonSecondaryCompact
              icon={"Check"}
              S
              onClick={() => handleApply({ text, tagsData: groupedTags, _id })}
            >
              Apply
            </StyledButtonSecondaryCompact>
          )}

        </div>
      </div>
    </CardWrapperStyled>
  );
};
