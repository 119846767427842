

export const genfilterConfig = ({ contentTypes, allTpls }) => {
    return[
        {
        filterId: "contentType",
        display: "Content Type",
        source: {
            filterType: "staticType",
            options: allTpls
            .filter((d) => contentTypes.includes(d.kp_content_type))
            .map((d) => ({
                display: d.general.content.title,
                value: d.kp_content_type,
            })),
        },
        target: {},
        },
        {
            filterId: "dateFilter",
            display: "Published Date",
            source: {
              filterType: "dateRangeType",
            },
            target: {}
        },
        {
        filterId: "stakeholder",
        source: {
            filterType: "tagType",
            tagType: "stakeholder",
        },
        target: {},
        },
        {
        filterId: "subject",
        source: {
            filterType: "tagType",
            tagType: "subject",
        },
        target: {},
        },
        {
        filterId: "country",
        source:{
            filterType: "tagType",
            tagType: "country",
        },
        target: {},
        },
        {
        filterId: "states",
        source:{
            filterOptionsBy: { filterId: 'country' },
            filterType: "tagType",
            tagType: "states",
        },
        target: {},
        },
        {
        filterId: "districts",
        source:{
            filterOptionsBy: { filterId: 'states' },
            filterType: "tagType",
            tagType: "districts",
        },
        target: {},
        },
        {
            filterId: "facilityTypes",
            source:{
                filterType: "tagType",
                tagType: "facilityTypes",
            },
            target: {},
        },
        {
        filterId: "conditionAreas",
        source: {
            filterType: "tagType",
            tagType: "conditionAreas",
        },
        target: {},
        },
        {
        filterId: "teams",
        source: {
            filterType: "tagType",
            tagType: "teams",
        },
        target: {},
        },
        // {
        //   filterId: '3',
        //   source: {
        //     filterType: 'tagType',
        //     tagType: 'facilityType'
        //   },
        //   target: {}
        // },
  ]}