const traverseLexicalJSON = (node, context = {}) => {
  let result = [];
  
  if (!node) return result;

  switch (node.type) {
    case 'text':
      if (node.text?.trim()) {
        result.push(node.text);
      }
      break;

    case 'list':
      if (node.children) {
        node.children.forEach(child => {
          const itemContent = traverseLexicalJSON(child, {
            ...context,
            currentList: node // Pass the list node as context
          });
          result.push(...itemContent);
        });
      }
      break;

    case 'listitem':
      if (node.children) {
        const itemContent = node.children
          .map(child => traverseLexicalJSON(child, context).join(' '))
          .join(' ');
        if (itemContent.trim()) {
          const marker = context.currentList?.tag === 'ul' 
            ? '-'  // unordered list uses dash
            : `${node.value}.`; // ordered list uses the value property
          result.push(`${marker} ${itemContent}`);
        }
      }
      break;

    case 'paragraph':
      if (node.children) {
        const paragraphContent = node.children
          .map(child => traverseLexicalJSON(child).join(' '))
          .join(' ');
        if (paragraphContent.trim()) {
          result.push(paragraphContent);
        }
      }
      break;

    case 'heading':
      if (node.children) {
        const headingContent = node.children
          .map(child => traverseLexicalJSON(child).join(' '))
          .join(' ');
        if (headingContent.trim()) {
          const level = node.tag?.charAt(1) || '1';
          result.push(`${'#'.repeat(Number(level))} ${headingContent}`);
        }
      }
      break;

    case 'root':
      if (node.children) {
        node.children.forEach(child => {
          result.push(...traverseLexicalJSON(child));
        });
      }
      break;

    default:
      if (node.children) {
        node.children.forEach(child => {
          result.push(...traverseLexicalJSON(child));
        });
      }
      break;
  }
  return result;
};

export const convertLexicalJSONToTemplateLiteral = (lexicalValue) => {
  try {
    const editorStateJSON = lexicalValue?.editorState;
    if(!editorStateJSON) return '';
    const state = typeof editorStateJSON === 'string'
      ? JSON.parse(editorStateJSON)
      : editorStateJSON;
    const textContent = traverseLexicalJSON(state.root);
    return textContent.join('\n');
  } catch (error) {
    console.error('Error processing Lexical state:', error);
    return '';
  }
};