import styled from "styled-components";
export const AIMessageStyled = styled.div`
  border: 1px solid ${({ active }) => (active ? "#000000CC" : "#e6e6e6")};
  padding: 1.2rem;
  max-width: 55rem;
  width: fit-content;
  border-radius: 0.6rem;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px 0px #0000000f;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
