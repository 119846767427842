import { ButtonGhost, LoaderCircle } from "oolib";
import React, { Fragment, useState } from "react";
import {
  StyledActionsWrapper,
} from "../styled";
import { Search } from "./Search";
import { Sort } from "./Sort";
import { DataDownloadModal } from "../../DataDownloadModal";


export const MobilePanel = ({
  filtersExist,
  showSearchInput,
  showSortDropdown,
  activeSort,
  setActiveSort,
  sortConfig,
  showSideBarLeft,
  setShowSideBarLeft,
  showDownloadDataButton,
  getDataToBeExported,
  searchTermInputText,
  handleSearch,
}) => {
  const [showSearchInputMobile, setShowSearchInputMobile] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [showDatadownloadModal, setShowDataDownloadModal] = useState(false)
  return (
    <Fragment>
      {showDatadownloadModal && <DataDownloadModal setShowDataDownloadModal={setShowDataDownloadModal} getDataToBeExported={getDataToBeExported} setExporting={setExporting} />}
      <StyledActionsWrapper>
        {/* Search */}
        {showSearchInputMobile ? (
          <div style={{ width: "100%" }}>
            <Search 
              isMobile={true}
              onClose={() => setShowSearchInputMobile(false)}
              searchTermInputText={searchTermInputText}
              handleSearch={handleSearch}
            />
          </div>
        ) : (
        <Fragment>
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* Filter */}
            {filtersExist && (
              <ButtonGhost
                icon="Funnel"
                onClick={() => setShowSideBarLeft(!showSideBarLeft)}
                iconSize={'M'}
                style={{ width: "45px", height: "45px" }}
              />
            )}

            {/* Search Button */}
            {showSearchInput && (
              <ButtonGhost
                icon="MagnifyingGlass"
                onClick={() => setShowSearchInputMobile(true)}
                iconSize={'M'}
                style={{ width: "45px", height: "45px" }}
              />
            )}

            {/* Sort */}
            {showSortDropdown &&
              <Sort 
                {...{
                  sortConfig,
                  activeSort,
                  setActiveSort,
                  genCustomSelectComp : () => (
                    <ButtonGhost
                      icon="SortDescending"
                      iconSize={'M'}
                      style={{ width: "45px", height: "45px" }}
                    />
                  )
                }}
              />}
          </div>

            {/* Download */}
            {showDownloadDataButton && (
              exporting ? (
                <div style={{ width: "45px", height: "45px" }}>
                  <LoaderCircle S />
                </div>
              ) : (
                <ButtonGhost
                  icon="ArrowLineDown"
                  onClick={() => setShowDataDownloadModal(true)} 
                  iconSize={'M'}
                  style={{ width: "45px", height: "45px" }}
                />
              )
            )}

            {/* {showDownloadDataButton && (
              jsonExporting ? (
                <div style={{ padding: "0.5rem" }}>
                  <LoaderCircle S />
                </div>
              ) : (
                <ButtonGhost
                  icon="Download__nofill"
                  onClick={() => handleDataExport({ getDataToBeExported, setExporting, dataFormat: "json" })} 
                  iconSize={'M'}
                />
              )
            )} */}
        </Fragment>
      )}
    </StyledActionsWrapper>
    </Fragment>
    
  );
};

