import {
  Breadcrumbs,
  colors,
  Container,
  Loader,
  PaddingTopBottom15,
  getVal,
  getText
  } from "oolib";
import React, { Fragment, useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import CommentsModule from "../../components/comments/CommentsModule";
import RelatedContentModule from "../../components/discovery/RelatedContentModule";


import {
  __CheckAccessToContentType,
  __CheckAccessToModule,
  __IsAuthor
} from "../../utils/accessControl";
import { ViewBlockGenerator } from "../../utils/blockGenerators";
import { useGetBreadcrumbLinksData } from "../../utils/breadcrumbs";
import { getQueryParam } from "../../utils/general";

import {
  __GetContentTypeConfigNew,
} from "../../utils/getters/gettersV2";
import { useGetContribution } from "../../utils/react-query-hooks/contributions";
import { useGetQueryData } from "../../utils/react-query-hooks/general";
import { UpdatesModule } from "../UpdatesModule";
import { PublishedPageLayoutRenderer, ModuleContainer } from "./comps";
import { useGenUnderEditBanners } from "./utils";
import { useAppSettingsContext } from "../../contexts/appSettingsContext";
import ErrorStates from "../../components/ErrorStates";
import PluginModule from "../../components/plugins/pluginModule";
import { ReactionsContextProvider } from "../../utils/react-query-hooks/Reactions/context";
import { handleInjectSerialNumbersBasedOnTplConfig } from "../../utils/addSerialNumbersToTemplate";
import AiSummary from "@/components/AiSummary";

const { greyColor5 } = colors;

export default function PublishedPageV2(props) {
  const { onboardingProps, CustomHeader } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const { content_type } = useParams();
  const contentType = onboardingProps?.contentType || content_type;

  const contributionId = getQueryParam(location.search, "id");

  const userData = useGetQueryData('userData');
  const user = userData?.user;

  const { APP_SETTINGS } = useAppSettingsContext();

  const lang = APP_SETTINGS.lang;
  const isFirst = useRef(true);

    const { hasUpdateType, comments, segment } =
    __GetContentTypeConfigNew(contentType).general; // chat with abrar

  const { data: contributionQueryData, status: getContributionStatus,error:contributionError } =
    useGetContribution({
      contentType,
      contentId: onboardingProps?.contributionId || contributionId,
      mode: "view",
      firstLoad: isFirst,
    });

  useEffect(() => {
    isFirst.current = false;
  }, []);



  
  // useGenUnderEditBanners({
  //   contentData: contributionQueryData,
  //   contentStatus: getContributionStatus,
  //   segment
  // })

  /* begin breadcrumb creation */

  const breadCrumbLinksData = useGetBreadcrumbLinksData({
    content: contributionQueryData?.contribution,
    comp: "PublishedPage",
  });

  const genBreadcrumbs = () => {
    let linksData = breadCrumbLinksData;
    if (!linksData) return;
    //else
    return (
      <Container style={{ 
        padding: "0 2rem", 
        position: 'relative', 
        zIndex: 101, /** imp. so that it stay on top when mobile cover img blurs & translates on listing pages */ 
        background: colors.white 
      }}>
        {/* <Breadcrumbs links={linksData} /> */}
      </Container>
    );
  };

  /* end breadcrumb creation */

  /**
   * the child comp that the module container contains, has control over this.
   * the child sets this to false if no data is rendered within it
   */
  const [showModuleContainer, setShowModuleContainer] = useState({
    updates: true,
    comments: true,
    relatedContent: true
  });

  const genUpdateModuleHeaderActionProp = () =>
    (__CheckAccessToContentType(user, "CREATE_UPDATE", contentType) ||
      (__CheckAccessToContentType(
        user,
        "CREATE_UPDATE_IF_AUTHOR",
        contentType
      ) &&
        __IsAuthor(
          contributionQueryData.contribution.meta.kp_contributed_by?._id,
          userData?.user._id
        ))) && {
      text: "Share An Update",
      onClick: () => {
        const params = new URLSearchParams(location.search);
        params.set("createUpdate", "create");
        navigate({
          pathname: location.pathname,
          search: params.toString()
        })
      }
  };
  
  const genComp = () => {
    switch (getContributionStatus) {
      case "loading":
        return <Loader debug={'get contribution api loading'} S/>;
      case "error":
        return <ErrorStates errorResponseObject={contributionError}/>;
      case "success":
        return (

          <ReactionsContextProvider onboardingProps={onboardingProps} contributionQueryData={contributionQueryData}>


          <Fragment>
          { CustomHeader && (
              <CustomHeader
                title={getText(
                  getVal(contributionQueryData.contribution.main, [
                    "title",
                    "kp_title",
                  ])
                )}
                onSave={(onboardingCb) => onboardingCb()}
              />
            )}
          { !onboardingProps && genBreadcrumbs() /**generate breadcrumbs only if it isnt the onboarding flow*/ } 
          {/* { import.meta.env.VITE_ENV !== "prod"? <AiSummary documentId={contributionId} contentType={contentType} />:null}  */}
            <PublishedPageLayoutRenderer
              content={contributionQueryData.contribution}
              tpl={handleInjectSerialNumbersBasedOnTplConfig({tpl: contributionQueryData.tpl})}
              contentType={contentType}
              />

            {/* {_PblPageCTA && (
              <Container style={{ paddingBottom: "4rem" }}>
                <div>
                  <a href={_PblPageCTA.link} target="_blank">
                    <ButtonPrimary>{_PblPageCTA.text}</ButtonPrimary>
                  </a>
                </div>
              </Container>
            )}
             */}

            <div style={{ padding: "2rem 0", background: greyColor5 }}>
              { contributionQueryData.tpl?.kp_settings && 
                contributionQueryData.tpl.kp_settings[0]?.blocks?.length > 0 &&
                <ModuleContainer title={'Tags'}>
                  {
                    contributionQueryData.tpl.kp_settings[0]?.blocks?.map((block) => {
                      return (
                        <ViewBlockGenerator
                          block={block}
                          content={contributionQueryData.contribution}
                          Wrapper={PaddingTopBottom15}
                        />
                      );
                    })
                  }
                </ModuleContainer> }
             

              {showModuleContainer.updates && hasUpdateType && (
                <ModuleContainer
                  title={`Updates (${contributionQueryData.contribution.updates.length})`}
                  action={genUpdateModuleHeaderActionProp()}
                >
                  <UpdatesModule //this HAS to be rendered, even if no update is there, else 'Share An Update' button wont work
                    parentContentType={contentType}
                    parentContent={contributionQueryData.contribution}
                    userData={userData}
                    />
                </ModuleContainer>
              )}
              {showModuleContainer.relatedContent && (
                <ModuleContainer title={"Related"}>
                  <RelatedContentModule
                    content={contributionQueryData.contribution}
                    setShowModuleContainer={setShowModuleContainer}
                  />
                </ModuleContainer>
              )}
              <PluginModule content={contributionQueryData.contribution} tpl={contributionQueryData.tpl}  />
              { showModuleContainer.comments && 
                comments.enable && (
                <ModuleContainer title={"Comments"}>
                  <CommentsModule
                    comments={contributionQueryData.contribution.comments}
                    contentType={contentType}
                    contentId={getQueryParam(location.search, "id")}
                    contributedById={contributionQueryData.contribution.meta.kp_contributed_by?._id}
                  />
                </ModuleContainer>
              )}
            </div>
          </Fragment>
          </ReactionsContextProvider>
        );
      default:
        return;  
    }
  };


  return <div key={`${contentType}_${contributionId}_${lang?.value || ''}`}>{genComp()}</div>;


}
