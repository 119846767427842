export const configs_gelabs_annoBase = {
  contentTypes: {
    // whitelist: ["immersion1AFGDNigeria", "immersion1ATemplates", "immersion1BIDINigeria", "immersion1BFGDNigeria"]
},
  filterDefs: {
    whitelist: [
      // "annoTags_factors",
      // "annoTags_influences",
      // "annoTags_existingPrograms",
      // "annoTags_findings",
      "tags_participantsRegistrationTemplates_main_DOT_country_DOT_value",
      "ROLLUP_tags_participantsRegistrationTemplates_segmentationNigeria",
      "ROLLUP_tags_participantsRegistrationTemplates_segmentKenya",
      "ROLLUP_tags_participantsRegistrationTemplates_segmentationSenegal",
      "tags_participantsRegistrationTemplates_main_DOT_age_DOT_value",
      "tags_participantsRegistrationTemplates_main_DOT_maritalStatus_DOT_value",
      "tags_participantsRegistrationTemplates_main_DOT_educationLevel_DOT_value",
      "ROLLUP_tags_participantsRegistrationTemplates_vaginalInsertableProductsUsed",
      "ROLLUP_tags_participantsRegistrationTemplates_useCases",
      "ROLLUP_tags_participantsRegistrationTemplates_accessToMobilePhones",
      "tags_participantsRegistrationTemplates_main_DOT_numberOfChildren_DOT_value",
    ],
  },
  tempDisableBarGraph: true,
  disableTotalCount: true,
  disableColorCode: true,
  cardConfigs: {  
    metaBlock: [
      { 
        prefix: 'Pathway Segment: ',
        key: 'text',
        valuePath: ['tags.segmentKenya.data.0.display', 'tags.segmentationNigeria.data.0.display', 'tags.segmentationSenegal.data.0.display']
      }
    ],
    tags: {
        whitelist: [
            // 'documentStatus'
        ]
    },
    annoTags: {
        whitelist: []
    }
  },
};
