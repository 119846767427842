import { ButtonGhostV2, colors2 as colors, mediaQuery, transition } from "oolib";
import styled, { css } from "styled-components";

const expandedStyles = css`
  &::after {
    border: 1.5px solid ${colors.grey80};
    box-shadow: 0px 4px 10px 0px #0000001F;
  }
`;


const hasValueStyles = css`
  &::after {
    /* border: 1px solid ${colors.grey10}; */
  }
`;

export const StyledFilterAccordionHeader = styled.div`
  padding: 0.6rem 0.8rem;
  border-radius: 0.6rem;
  position: relative;
  cursor: pointer;
  background: ${({ value, expand }) => value.length || expand ? colors.grey5 : "unset"};

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    pointer-events: none;
    /* border: 1px solid transparent;  // prevents layout shift by always having a transparent border */
  }
  
  ${({ expand, value }) => {
    if (expand) return expandedStyles;
    if (value?.length) return hasValueStyles;
    return '';
  }}

  &:hover{
    background-color: ${colors.grey5} !important;
  }

`;


export const StyledFilterAccordionTitleWrapper = styled.div`
  display: flex;
  
  justify-content: space-between;
  align-items: center;
  
  
  
`;

export const StyledSelectedValuesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.3rem;
  padding-top: 0.6rem;
`

const optionShellCSS = css`
    padding: 0.7rem 1rem;
    width: 100%;

    ${mediaQuery('sm')}{
      padding: 1rem 1.4rem;
    }
`

export const StyledOptionsLoaderWrapper = styled.div`
${optionShellCSS}
`

export const StyledOptionWrapper = styled.div`
  
  cursor: pointer;
  

  @media (hover:hover) {
    &:hover {
      background-color: ${colors.grey5};
    }
  }
  border-radius: 0.4rem;
  
  .filterOptionsLightbox__checkboxInput{
    ${optionShellCSS};
  }
  /* padding: 0.7rem 1rem;

  ${mediaQuery('sm')}{
    padding: 1rem 1.4rem;
  } */
`;

export const StyledOptionsLightboxWrapper = styled.div`
  position: fixed;
  
  
  ${({ isMobile, optionsLightboxLeft }) => 
    isMobile ? 
      css`
          top: 0;
          left: 100dvw;
          transform: translateX(-100%); //need to this this instead of right: 0 cuz some other code in the app is messing with that..
          height: 100dvh;
          ` 
      : 
      css`
          top: ${({ optionsLightboxTop }) => optionsLightboxTop}px;
          left:  ${optionsLightboxLeft}px;
          max-height: ${({ height}) => height };
        `   
  }
  width: ${({ width }) => width};
  background-color: ${colors.white};
  z-index: 100000;
  box-shadow: 0px 4px 20px 0px #00000026;
  ${transition("top 0.1s ease-out")}
  border: 2px solid white;
  border-radius: 1.6rem 0 0 1.6rem; //rounded only on left side
  
  ${mediaQuery('sm')}{
    border-radius: 0.8rem;
  }

`;

export const StyledFiltersWrapper = styled.div`
  
  height: inherit;
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  // Style the scrollbar itself
  &::-webkit-scrollbar {
    width: 0.6rem;  // adjust width as needed
    background: transparent;
  }

  // Style the scrollbar handle/thumb
  &::-webkit-scrollbar-thumb {
    background: ${colors.grey10};  // adjust color as needed
    min-height: 3.6rem;
    border-radius: 0.3rem;
  }

  ${({lightboxConfig}) => {
    if(lightboxConfig.comp === 'DateRangeType'){
      return css`
        padding-top: 14px;
        padding-bottom: 14px;
        display: flex;
        justify-content: center;
      `
    }else{
      return css`
        padding-bottom: 2rem;
        /* margin: 14px 14px 0px 0px;

        ${mediaQuery('sm')} {
          margin: 10px 10px 0px 0px;
        }     */
      `
    }
  }}

`

export const StyledActionsArea = styled.div`
  position: sticky;
  top: 0;
  background-color: ${colors.white};
  z-index: 1;
  border-bottom: 1px solid ${colors.grey10};
`
export const StyledSelectClearButtonsWrapper = styled.div`
  position: sticky;
  top: 0;
  background-color: ${colors.white};
  z-index: 1;
  padding: 0 2px;
`;

export const StyledSelectClearInnerWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 2px 0;
  /* border-bottom: 1px solid ${colors.grey10}; */
`;

export const StyledLightBoxActionButton = styled(ButtonGhostV2)`
  padding-left: ${({ isMobile }) => isMobile ? "1.2rem" : "0.7rem"};
  padding-right: 1rem;
  padding-top: ${({ isMobile }) => isMobile ? "1.2rem" : "0.9rem"};
  padding-bottom: ${({ isMobile }) => isMobile ? "1.2rem" : "0.9rem"};
`