import { debounce, throttle } from 'lodash'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'


// import { getPublishedListing } from '../actions/listings'
import { testJSON } from 'oolib'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAppSettingsContext } from '../../contexts/appSettingsContext'
import { getQueryParam } from '../general'
import { __GetAllContentTypeConfig } from '../getters/gettersV2'
import { useGetQueryData } from '../react-query-hooks/general'

export const useComponentWillMount = (func) => {
  const willMount = useRef(true)

  if (willMount.current) func()

  willMount.current = false
}

export const useParseContentBlockValue = (valFromParent, sanitizeVal) => {
  const [_value, _setValue] = useState(sanitizeVal(valFromParent)) //sanitize _value has to be declared by each component. here you have the logic that deals with settings a 'empty _value data structure', should the valFromParent be falsy
  useEffect(() => {
    _setValue(sanitizeVal(valFromParent))
  }, [valFromParent])

  return [_value, _setValue]
}

export const useScroll = (scrollFn, options = {}) => {
  useLayoutEffect(() => {
    scrollFn() //run the scroll fn once before any scroll to set initial states

    let throttledScrollFn = throttle(scrollFn, 100)
    let fnToUse = options.noThrottle ? scrollFn : throttledScrollFn
    window.addEventListener('scroll', fnToUse)
    return () => window.removeEventListener('scroll', fnToUse)
  }, [options.loading])
}




export function useEmailValidation(value) {
  const [invalidEmail, setInValidEmail] = useState(true)

  useEffect(() => {
    value &&
      setInValidEmail(
        !/(^[a-zA-Z0-9\s]+(\.?[a-zA-Z0-9]+)*@[a-zA-Z]+\.[a-zA-Z]{2,}$)|(^\s{0,}$)/.test(
          value
        )
      )
  }, [value])

  return { invalidEmail }
}



// to deprecate now that we have useSearchParamsState
export const useActiveTabFromQueryParam = (
  loading,
  tabBarOptions, //tabBarOptions,
  options = {}
) => {
  let location = useLocation()
  const {tabBarOptionsFn,
  queryParamKey = 'activeTab',
accessControlFn,

} = options;

  let [activeTab, setActiveTab] = useState(
    (tabBarOptions && tabBarOptions[0]) || { display: '', value: '' }
  )



  // useEffect(()=>{
  //   setActiveTab(tabBarOptions[0])
  // },[tabBarOptions])
let isFirstMount = useRef(true)
  

  useEffect(() => {
    if (!loading) {
      let tabOps = tabBarOptionsFn
        ? tabBarOptionsFn()
        : tabBarOptions

      if(tabOps.length === 0){ 
      
        setActiveTab(p => p);
        
      }else{

        
        let newActiveTabValue =
          (location.search && getQueryParam(location.search, queryParamKey)) ||
          (isFirstMount.current && tabOps[0].value)
        newActiveTabValue = accessControlFn
          ? accessControlFn(newActiveTabValue)
          : newActiveTabValue

        let newActiveTab = tabOps.find(
          (op) => op.value.toString() === newActiveTabValue.toString()
        )

        //if new active tab is not found, then we should unset the prev value?
        //or let the previous value linger?
        //cuz right now the previous value lingers.
        if (newActiveTab) setActiveTab(newActiveTab)

      }

      
    }
  }, [location.search, loading])

  //cuz we want default to op[0] (iin above use effect) to run only once.
  useEffect(() => { isFirstMount.current = false; }, [])

  return activeTab
}

//NOTE:
/**
 * the ref cannot be on the same element that you are setting the height
 * else it wont work. Ideally you want a structure like this:
 *
 * <div style={{height: <setHeightHere>}}>
 *  <div ref={<ref>}></div>
 * </div>
 *
 */
export const useSetElHeight = (ref) => {
  const [elHeight, setElHeight] = useState(undefined)

  const handleSetElHeight = (el) => {
    if (el && el.current) {
      let h = el.current.getBoundingClientRect().height
      if (h !== elHeight) {
        setElHeight(h)
      }
    }
  }

  useEffect(() => {
    handleSetElHeight(ref)
  })

  return elHeight
}

export const useModal = (initState) => {
  const [modalState, setModalState] = useState(initState || undefined)

  return [modalState, setModalState]
}

export const usePrevLocations = () => {
  const navigate = useNavigate()
  let { APP_SETTINGS } = useAppSettingsContext()

  return {
    getPrevLocation: () => APP_SETTINGS.prevLoc && APP_SETTINGS.prevLoc[0],
    goBack: () => {
      if (APP_SETTINGS.prevLoc) navigate(APP_SETTINGS.prevLoc[0])
      else navigate('/')
    },
    quitContributeConfig: (activeIdx) => {
      let prevLocs = APP_SETTINGS.prevLoc
      if (!prevLocs || prevLocs.length === 0) {
        navigate('/')
        return
      }
      if (prevLocs[0]?.pathname === '/choose-template') {
        navigate(prevLocs[1] || '/') // if 2 routes prev exists then go there, else go home
      } else {
        // else prevLocs[0] exists, but is not /choose-template. so go back 1 route.
        navigate(prevLocs[0])
      }
    },
  }
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
  }

  export const useStretchDivToRemainingHeight = (options = {}) => {
    const { 
      rerunArray = [],
      propertyToSet = 'minHeight', // alt : 'height'
      refName = 'defaultRef',
      enabled = true
    } = options
    /**
     * we need the ability to pass a refName in case
     * we are using this function for two separate divs in a comp
     * if we dont use separate names for the ref, we will have a clash and error
     * 
     * Hence the containerRef[refName] approach below
     * to accomodate for refName
     */
    let containerRef = {
      [refName]: useRef(null)
    };
    useLayoutEffect(() => {
      if(enabled){
        
        let top = containerRef[refName].current?.getBoundingClientRect().top
        let heightToSet = window.innerHeight - top;
        
        if(containerRef[refName].current){
          containerRef[refName].current.style[propertyToSet] = `${heightToSet}px`    
        }
        
      }
      
    },rerunArray)
    return containerRef[refName]
  }


export const useDebounce = (options = {}) => {
  const {interval = 1000} = options;
  const fn = functionToDebounce => functionToDebounce()
  return useRef(debounce(fn, interval)).current
}

export const useGetTagCategory = (tagType) => {
    const { _ProfileTypes } = useGetQueryData('platformConfigs');
    const allContentTypes = __GetAllContentTypeConfig();
    let profileConf = _ProfileTypes.find((p) => p.id === tagType)
    let contentConf = allContentTypes.find((c) => c.kp_content_type === tagType)
    if(profileConf){
      return ({category: 'userTags'})
    }else if(contentConf){
      return ({category: 'contentTags', segment: contentConf?.general.segment})
    }else{
      return ({category: 'tags'})
    }
    
  }




  
   /**
   * test cases:
   * - if url has a predefined value then state on mount should be that value
   * - if url does not, then state on mount should be the
   *   initState value that is passed
   * - if initstate value is not passed, and nothing in url either init state should be null
   * - if after having a state, through some event the state is set to undefined, it should stay at undefined,
   * not reset to initState
   * - state should be properly serialized for the url
   * - url param should be properly parsed for use in js : test string, num, bool, Obj, ary
   * 
   * @limitation 2 useSearchParamState cannot be used on the same comp. causes bugs in updating the location.search
   */
    export const useSearchParamsState = ({key, value : initValue}) => {
    
      const location = useLocation();
      const navigate = useNavigate();
      let params = new URLSearchParams(location.search);
  
      const firstMountDone = useRef(false);
      
      const stringifyNonPrimitives = value => {
        return ['string', 'number', 'boolean'].indexOf(typeof value) === -1
          ? JSON.stringify(value)
          : value
      }
      
      // only on first mount we set to init value if query param isnt there
      // this way on first render itself, we have some truthy value returned by state var
      let state = params.get(key) || ( !firstMountDone.current ? stringifyNonPrimitives(initValue) : undefined);
  
      useEffect(() => {
        // if no _value already exists in loc.search, then set initVal
       if(initValue && !params.get(key)){
         params.set(key, stringifyNonPrimitives(initValue) )
         navigate({search: params.toString()},{replace: true})
       }
       firstMountDone.current = true;
     },[params.get(key)])
      
      //this condition is needed cuz undefined gets saved in url as string
      //and JSON.parse fails on this particular string.
      if(state === 'undefined') state = undefined;
      if(state && testJSON(state)) state = JSON.parse(state);
  
      const setState = value => {
        params.set(key, stringifyNonPrimitives(value) )
        navigate({search: params.toString()})
      }
  
      return [ state, setState ]
    }