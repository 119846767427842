import { cloneDeep } from "lodash";
import { getAnnoExplorerConfigsForThisTenant } from "./getAnnoExplorerConfigsForThisTenant";

export const applyTagsWhitelistConfig = ({annoDoc, cardConfigs}) => {
  const clonedDoc = cloneDeep(annoDoc);

  if (!cardConfigs) return clonedDoc;
  const tagsWhitelist = cardConfigs.tags?.whitelist;
  const annoTagsWhitelist = cardConfigs.annoTags?.whitelist;
  let filteredTags;
  let filteredAnnoTags;

  const filterTagsDataAsPerWhitelist = ({ tagsData, whitelist }) => {
    return Object.keys(tagsData).reduce((a, key) => {
      if (whitelist.includes(key)) {
        return { ...a, [key]: tagsData[key] };
      } else {
        return a;
      }
    }, {});
  };
  if (!tagsWhitelist) {
    filteredTags = clonedDoc.tags;
  } else {
    filteredTags = filterTagsDataAsPerWhitelist({
      tagsData: clonedDoc.tags,
      whitelist: tagsWhitelist,
    });
  }

  if (!annoTagsWhitelist) {
    filteredAnnoTags = clonedDoc.annoTags;
  } else {
    filteredAnnoTags = filterTagsDataAsPerWhitelist({
      tagsData: clonedDoc.annoTags,
      whitelist: annoTagsWhitelist,
    });
  }

  return {
    ...clonedDoc,
    tags: filteredTags,
    annoTags: filteredAnnoTags,
  };
};
