import { motion } from "framer-motion";
import { colors, mediaQuery } from "oolib";
import styled from "styled-components";

export const SidebarContainerStyled = styled(motion.div)`
  position: fixed;
  top: 0;
  background-color: white;
  padding:1.4rem;
  bottom: 0;
  z-index: 100000;
  overflow: hidden;



  ${mediaQuery("sm")} {
    width: 35rem;
    position: sticky;
    height: calc(100vh - ${({ topOffset }) => topOffset}rem);
    top: ${({ topOffset }) => topOffset || 0}rem;
  }

  background-color: white;
  border-left: 1px solid #0000001A
`;

export const SidebarWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overscroll-behavior: contain;
  overflow-y: scroll;
  height: 100%;
  scrollbar-width: none;
  margin-bottom: 6.5rem;
`;
