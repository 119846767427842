import React, { useEffect, useRef } from "react";
import {
  ButtonGhostCompact,
  CONTENT_BODY_POSTIT,
  Divider,
  UI_BODY_SM,
  UI_CAPTION,
  UI_TAG,
  typoV2_CSS
} from "oolib";
import { AIMessageStyled } from "./styled";
import Markdown from "react-markdown";
import { Copy } from "../../UserActions/Copy";
import { Feedback } from "../../UserActions/Feedback";
import { __GetContentTypeConfigNew } from "../../../../../utils/getters/gettersV2";
import { SummaryWrapperStyled } from "../../../../Synthesis/comps/AnnotationCard/styled";
import styled from "styled-components";
import { useSidebarContext } from "../../../contexts/sidebarContext";

const { UI_BODY_SM_DF, UI_BODY_BOLD_SM_DF, UI_BODY_DF, UI_BODY_BOLD_DF, UI_DISPLAY, UI_DISPLAY_SM, UI_HEADLINE, UI_HEADLINE_SM, UI_TITLE, UI_TITLE_SM} = typoV2_CSS

export const MarkdownStyled = styled(Markdown)`
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;

  ol {
    margin-top: 0; //god knows where it comes from but we have to cancel it
    li {list-style: decimal;}
  }

  ul {
    li {
      list-style: disc;

      li {
        list-style: circle;
      }
    }
  }

  p,
  li {
    ${UI_BODY_SM_DF} // UI_BODY_DF

    strong {
      ${UI_BODY_BOLD_SM_DF};
    }
  }

  /*  h1, h2, h3, and blockquote */
  h1,
  h2,
  h3,
  blockquote {
    margin: 0.9rem 0;
  }

  /* for first child h1, h2, h3, and blockquote */
  h1:first-child,
  h2:first-child,
  h3:first-child,
  blockquote:first-child {
    margin-top: 0;
    margin-bottom: 0.9rem;
  }

  /* for h1, h2, h3, and blockquote followed by a p, ul, or ol */
h1 + p,
h2 + p,
h3 + p,
blockquote + p,
h1 + ul,
h2 + ul,
h3 + ul,
blockquote + ul,
h1 + ol,
h2 + ol,
h3 + ol,
blockquote + ol{
    margin-top: -0.65rem; /* 28px - 20px = 8px */
  }

  li {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
  }
  p {
    margin-bottom: 0.4rem;
  }


  h1 {
    ${UI_TITLE_SM}
  }
  h2 {
    ${UI_TITLE_SM}
  }
  h3 {
    ${UI_TITLE_SM}
  }
  h4 {
    ${UI_TITLE_SM}
  }
  h5 {
    ${UI_TITLE_SM}
  }
  h6 {
    ${UI_TITLE_SM}
  }
`;

export const AIMessage = ({ message, streaming = false }) => {
  const { setShowSidebar, setContent, content } = useSidebarContext();
  const markdownRef = useRef();
  const handleViewAll = () => {
    setShowSidebar(true);
    setContent(message);
  };

  const handleCopy = async () => {
    if (!markdownRef.current) return;

    const htmlContent = markdownRef.current.innerHTML; // Rendered HTML
    const plainText = markdownRef.current.textContent; // Plain text

    // Create clipboard data with all formats
    const clipboardItem = new ClipboardItem({
      "text/html": new Blob([htmlContent], { type: "text/html" }),
      "text/plain": new Blob([plainText], { type: "text/plain" }),
    });

    await navigator.clipboard.write([clipboardItem]);
  };

  return (
    <AIMessageStyled
      active={content ? message._id === content._id : false}
      key={message._id}
    >
      <UI_BODY_SM>
        <div ref={markdownRef}>
          <MarkdownStyled>
            {streaming ? message : message.content.value}
          </MarkdownStyled>
        </div>
      </UI_BODY_SM>

      {message.vectorSearchInfo?.chunks?.length > 0 ? (
        <>
          <Divider />
          <Chunks
            handleViewAll={handleViewAll}
            chunks={message.vectorSearchInfo?.chunks.slice(0, 3)}
          />
          <Divider />
          <Actionables handleCopy={handleCopy} message={message} />
        </>
      ) : null}
    </AIMessageStyled>
  );
};

const Actionables = ({ message, handleCopy }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Copy onClick={handleCopy} color={"#808080"} />
      {/* <LineVertical size={30} /> */}
      <Feedback
        reaction={message.userFeedback?.reaction}
        comments={message.userFeedback?.comments}
        messageId={message._id}
      />
    </div>
  );
};

const Chunks = ({ chunks, handleViewAll }) => {
  return (
    <>
      {/* <div style={{ padding: "1rem" }}> */}
      <div
        onClick={handleViewAll}
        style={{
          display: "flex",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
      >
        <UI_TAG>TOP REFERENCES</UI_TAG>{" "}
          <ButtonGhostCompact
            style={{ marginTop: "-7px" }}
            icon="ArrowLineUpRight"
          >View all references</ButtonGhostCompact>
      </div>
      <div style={{ display: "flex", gap: "1rem" }}>
        {chunks.map((chunk) => (
          <div
            style={{
              backgroundColor: "#F3F3F3",
              borderRadius: "6px",
              padding: "1rem",
              flex: 1,
              cursor: "pointer",
            }}
            onClick={handleViewAll}
          >
            <SummaryWrapperStyled noOfLines={3}>
              <CONTENT_BODY_POSTIT>{chunk.text}</CONTENT_BODY_POSTIT>
            </SummaryWrapperStyled>
          </div>
        ))}
      </div>
      {/* </div> */}
    </>
  );
};
