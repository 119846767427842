import { getBreakPoint, useScreenWidth, useScroll} from "oolib";
import React, { useState } from "react";
import { StyledShellContainer } from "./newComps/Shell/styled";
import { CoverSection } from "./newComps/CoverSection";
import { useSectionHeightTracker } from "./newUtils/useSectionHeightTracker";
import { CustomCompRenderer } from "../ContributeTpl/layouts/GroupsStyle1/comps/CustomCompRenderer";
import { FormRenderer } from "./FormRenderer";
import { ListingRenderer } from "./ListingRenderer";
import { TabsPanel } from "./newComps/TabsPanel";
import { MobileBGCoverWrapper } from "./newComps/CoverSection/comps/MobileBGCoverWrapper";
import { useScrollEffectsManager } from "./newComps/CoverSection/comps/MobileBGCoverWrapper/useScrollEffectsManager";


const ListingPage = (props) => {
  
  const screenWidth = useScreenWidth();
  const isMobile = screenWidth < getBreakPoint("md");

  const { actionRef, actionBarHeight, tabsRef, tabsPanelHeight, stickyTitleHeight, mobileTitleRef } =
    useSectionHeightTracker({ screenWidth, isMobile, activeTab: props.tabBarConfig?.value });

  const [showSideBarLeft, setShowSideBarLeft] = useState(isMobile ? false : true); // by default filters are open if they exist on desktop
  /**
   * BACKWARDS COMPAT
   * if new format of configs (i.e props.bodyConfig), then the only place we are
   * going to be using this right now is GroupStyle1, so render those configs as is
   * else, it is the typical ListingPage scenario, so we can simply, copy paste old listingpage into this,
   * do backward compat reshaping and write the code as we see fit.
   */

  const isOldVersionProps = !props.bodyConfig;

    /*this is what actually has to be followed!*/
  const coverConfig_og = isOldVersionProps
    ? props?.configs?.coverConfig
    : props.coverConfig;

  const coverConfig = coverConfig_og;

  // /*this is temp to fake cover config*/
  // const coverConfig = {
  //   coverExists: true,
  //   title: "This is a title",
  //   subtitle: "This is a subtitle",
  //   desc: "This is a description",
  //   btn: coverConfig_og.btn,
  //   content: { metaPrimary: 'meta primary', test: ['Apples', 'Pomegranate']},
  //   // coverImage: [{ publicUrl: "https://pbs.twimg.com/media/F7kU9AUWYAAcxdt?format=jpg&name=medium"}],
  //   metaPrimary: {valuePath: 'metaPrimary'},
  //   tagsIntro: { valuePath: 'test'}
  // }

  const bodyConfig = isOldVersionProps
    ? [
        {
          value: "listingRenderer", //this is only for populating the react key
          comp: "ListingRenderer",
          props,
        },
      ]
    : props.bodyConfig;

  const tabBarConfig = isOldVersionProps
    ? undefined //cuz old version props will only be used whereever the old listing page was meant to be used, and in those cases, we dont need tabbar
    : props.tabBarConfig;

  const renderBody = ({ bodyConfig, tabBarConfig }) => {
    const activeBodyConfig = tabBarConfig?.value
      ? bodyConfig.find((d) => d.value === tabBarConfig?.value)
      : bodyConfig[0];

    switch (activeBodyConfig.comp) {
      case "ListingRenderer":
        return (
          <ListingRenderer
            key={activeBodyConfig.value + (activeBodyConfig.props?.contentTypes || []).join('+')}
            {...activeBodyConfig.props}
            tabsPanelHeight={tabsPanelHeight}
            actionBarHeight={actionBarHeight}
            actionRef={actionRef} //temp later will go inside
            isMobile={isMobile}
            setShowSideBarLeft={setShowSideBarLeft} //temp later will go inside
            showSideBarLeft={showSideBarLeft} //temp later will go inside
            collectionId={props.collectionId}
            mobileTitleRef={mobileTitleRef }
            stickyTitleHeight={stickyTitleHeight}
            coverConfig={coverConfig}
          />
        );
      case "FormRenderer":
        return (
          <FormRenderer
            key={activeBodyConfig.value}
            {...activeBodyConfig.props}
          />
        );
      case "CustomCompRenderer":
        return (
          <CustomCompRenderer
            key={activeBodyConfig.value}
            tabsPanelHeight={tabsPanelHeight}
            actionBarHeight={actionBarHeight}
            {...activeBodyConfig.props}
          />
        );
    }
  };

  

  const {
    coverBlur,
    coverShift,
    mobileInfoSectionOpacity,

    //[Sticky Title related]
    coverSectionBottom,
    coverSectionRef

  } = useScrollEffectsManager({ tabsPanelHeight, actionBarHeight });

  return (
    <StyledShellContainer>
      {isMobile && 
      <MobileBGCoverWrapper 
        style={{zIndex: 100}} //v.v.imp to have bg-image cover the listingmodule items on scroll
        coverConfig={coverConfig}
        coverBlur={coverBlur}
        coverShift={coverShift}
        /> }
      {coverConfig && (
        <CoverSection
          coverExists={coverConfig.coverExists}
          title={coverConfig.title}
          desc={coverConfig.desc}
          btn={coverConfig.btn}
          content={coverConfig.content}
          coverImage={coverConfig.coverImage}
          coverVideo={coverConfig.coverVideo}
          metaPrimary={coverConfig.metaPrimary}
          tagsIntro={coverConfig.tagsIntro}
          mobileTitleRef ={mobileTitleRef }
          stickyTitleHeight={stickyTitleHeight}
          isMobile={isMobile}
          showSideBarLeft={showSideBarLeft}
          mobileInfoSectionOpacity={mobileInfoSectionOpacity}
          coverSectionBottom={coverSectionBottom}
          coverSectionRef={coverSectionRef}
        />
      )}
      {tabBarConfig && (
        <TabsPanel
          style={{zIndex: 102}} // v.v.imp to have bg-image cover the listingmodule items on scroll
          tabsRef={tabsRef}
          options={tabBarConfig.options}
          onChange={tabBarConfig.onChange}
          value={tabBarConfig.value}
          isMobile={isMobile}
          showSideBarLeft={showSideBarLeft}
          stickyTitleHeight={stickyTitleHeight}
        />
      )}
      {renderBody({ bodyConfig, tabBarConfig })}
    </StyledShellContainer>
  );
};

export default ListingPage;
