import {
  colors,
  SANS_2,
  PaddingTopBottom60,
  Container,
  LABEL,
  getBreakPoint,
  Wrapper1120,
  useScreenWidth,
} from "oolib";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import okflogo from "../../../../images/okf_logo.svg";
import { _Locale } from "../../../locale/Locale";
import { footerRouteBlacklist } from "../../config";
import { genLinksConfigAsOptions } from "../../Header/HamburgerMenuV2/utils/genLinksConfigAsOptions";
import { FooterLink } from "./comps/FooterLink";
import {
  StyledAboutAndLinksContainer,
  StyledAboutSectionWrapper,
  StyledFooterSectionLabel,
  StyledInnerGrid,
  StyledInnerGridCol,
  StyledInnerGridColContentsWrapper,
  StyledLinksGroupWrapper,
  StyledLinksSectionContentsWrapper,
  StyledLinksSectionWrapper,
  StyledOuterGrid,
  StyledOuterLeftCol,
  StyledOuterRightCol,
} from "./styled";
import { FooterLogos } from "./FooterLogos";
import { cloneDeep } from "lodash";
import RichTextEditor from "../../../inputs/TextEditor/RichTextEditor";
import { useAppSettingsContext } from "../../../../contexts/appSettingsContext";


const { greyColor100 } = colors;

const FooterV2 = ({ config, builderProps }) => {
  const { _Nav, deployment } = config;

  const { APP_SETTINGS } = useAppSettingsContext();

  const [renderFooter, setRenderFooter] = useState(true);

  const location = useLocation();

  const screenWidth = useScreenWidth();

  useEffect(() => {
    if (
      !footerRouteBlacklist.some((substr) =>
        window.location.pathname.includes(substr)
      ) ||
      builderProps
    ) {
      setRenderFooter(true);
    } else {
      setRenderFooter(false);
    }
  }, [location]);

  if (!renderFooter) return null;

  const socialIconsMap = {
    facebook: "FacebookLogo",
    twitter: "TwitterLogo",
    whatsapp: "WhatsappLogo",
    linkedin: "LinkedinLogo",
    youtube: "YoutubeLogo",
    instagram: "InstagramLogo",
  };

  const genLinks = (conf) =>
    conf.map((item) => {
      if (!item.hideInFooter) {
        if (item.folder || item.menu) {
          //menu is the deprecated term
          let thisFolder = item.folder || item.menu;
          return (
            (thisFolder.length > 0 || !!builderProps) && (
              <StyledLinksGroupWrapper key={item.value || item.display}>
                {item.display && (
                  <StyledFooterSectionLabel>
                    {item.display}
                  </StyledFooterSectionLabel>
                )}

                {thisFolder.map((d) => {
                  if(d.hideInFooter) return null;
                  return (
                  <FooterLink
                    thisNavConfig={d}
                    icon={d.type === "social" && socialIconsMap[d.value]}
                    key={d.to}
                    to={d.to}
                    display={d.display}
                  />
                )})}
              </StyledLinksGroupWrapper>
            )
          );
        } else {
          return (
            <StyledLinksGroupWrapper key={item.value || item.display}>
              <FooterLink
                thisNavConfig={item}
                icon={item.type === "social" && socialIconsMap[item.value]}
                to={item.to}
                display={item.display}
              />
            </StyledLinksGroupWrapper>
          );
        }
      }
    });



    // Footer about text translation
    const aboutContent = deployment._FooterText.blocks
      ? deployment._FooterText
      : deployment._FooterText[APP_SETTINGS.lang.value];

  return (
    <div style={{ backgroundColor: greyColor100 }}>
      <Container className="OKE-Footer">
        <Wrapper1120 left>
          <PaddingTopBottom60>
            <div className="OKE-Footer-logos">
              {" "}
              {/* Important for reference on platform builder */}
              <FooterLogos config={config} />
            </div>
            <StyledAboutAndLinksContainer>
              <StyledOuterGrid>
                <StyledOuterLeftCol>
                  <StyledAboutSectionWrapper>
                    <LABEL
                      className="OKE-Footer__sectionTitle"
                      style={{ padding: "0 0 1rem 0" }}
                    >
                      {_Locale("About")}
                    </LABEL>
                    <div className="OKE-Footer__aboutText-wrapper">
                      {deployment._FooterText && (
                        <RichTextEditor
                          // textAlign={screenWidth < getBreakPoint('sm') && 'center'}
                          typo={"SANS_2"}
                          invert={true}
                          value={cloneDeep(aboutContent)} //tricky one this was. important to do this because otherwise,when the RTE mounts, it shoves entityMap:{} into the value, which ends up modifying the 'deployment' config object in react-query
                          readOnly={true}
                        />
                      )}

                      <div style={{ paddingTop: "2rem" }}>
                        <SANS_2 invert={true}>
                          {"POWERED BY :"}
                        </SANS_2>
                      </div>
                      <a
                        style={{ marginTop: "1rem" }}
                        href="https://www.openknowledgeframework.in"
                        target="_blank"
                      >
                        <img
                          src={okflogo}
                          alt="Open Knowledge Framework"
                          id={"okf_logo"} /** Id is v. important for coachmark to work */
                          style={{ paddingTop: "1rem", width: "25rem" }}
                        />
                      </a>
                    </div>
                  </StyledAboutSectionWrapper>
                </StyledOuterLeftCol>
                <StyledOuterRightCol>
                  <StyledLinksSectionWrapper>
                    <StyledLinksSectionContentsWrapper>
                      <StyledInnerGrid
                        style={{
                          gridTemplateColumns:
                            screenWidth >= getBreakPoint("sm")
                              ? "repeat(3, 1fr)"
                              : "repeat(4, 1fr)",
                        }}
                      >
                        <StyledInnerGridCol
                          style={{
                            gridColumn:
                              screenWidth >= getBreakPoint("sm")
                                ? "1/2"
                                : "1/3",
                          }}
                        >
                          <StyledInnerGridColContentsWrapper>
                            {genLinks(
                              genLinksConfigAsOptions(_Nav).filter(
                                (d) =>
                                  !d.footerCol || parseInt(d.footerCol) === 0
                              )
                            )}
                            {_Nav.footer?.col0 &&
                              genLinks(
                                genLinksConfigAsOptions({
                                  //recursive
                                  primary: _Nav.footer.col0,
                                })
                              )}
                          </StyledInnerGridColContentsWrapper>
                        </StyledInnerGridCol>
                        <StyledInnerGridCol
                          style={{
                            gridColumn:
                              //if screen width is between 'sm' & 'md' or greater than 'lg'
                              (screenWidth >= getBreakPoint("sm") &&
                                screenWidth < getBreakPoint("md")) ||
                              screenWidth >= getBreakPoint("lg")
                                ? "2/3"
                                : "3/5",
                          }}
                        >
                          <StyledInnerGridColContentsWrapper>
                            {genLinks(
                              genLinksConfigAsOptions(_Nav).filter(
                                (d) => parseInt(d.footerCol) === 1
                              )
                            )}
                            {_Nav.footer?.col1 &&
                              genLinks(
                                genLinksConfigAsOptions({
                                  //recursive
                                  primary: _Nav.footer.col1,
                                })
                              )}
                            {
                              //if screen width is less than 'sm' or between 'md' and 'lg'
                              (screenWidth < getBreakPoint("sm") ||
                                (screenWidth >= getBreakPoint("md") &&
                                  screenWidth < getBreakPoint("lg"))) && (
                                <>
                                  {genLinks(
                                    genLinksConfigAsOptions(_Nav).filter(
                                      (d) => parseInt(d.footerCol) === 2
                                    )
                                  )}
                                  {_Nav.footer?.col2 &&
                                    genLinks(
                                      genLinksConfigAsOptions({
                                        //recursive
                                        primary: _Nav.footer.col2,
                                      })
                                    )}
                                </>
                              )
                            }
                          </StyledInnerGridColContentsWrapper>
                        </StyledInnerGridCol>
                        {
                          //if screen width is between 'sm' & 'md' or greater than 'lg'
                          ((screenWidth >= getBreakPoint("sm") &&
                            screenWidth < getBreakPoint("md")) ||
                            screenWidth >= getBreakPoint("lg")) && (
                            <StyledInnerGridCol style={{ gridColumn: "3/4" }}>
                              <StyledInnerGridColContentsWrapper>
                                {genLinks(
                                  genLinksConfigAsOptions(_Nav).filter(
                                    (d) => parseInt(d.footerCol) === 2
                                  )
                                )}
                                {_Nav.footer?.col2 &&
                                  genLinks(
                                    genLinksConfigAsOptions({
                                      //recursive
                                      primary: _Nav.footer.col2,
                                    })
                                  )}
                              </StyledInnerGridColContentsWrapper>
                            </StyledInnerGridCol>
                          )
                        }
                      </StyledInnerGrid>
                    </StyledLinksSectionContentsWrapper>
                  </StyledLinksSectionWrapper>
                </StyledOuterRightCol>
              </StyledOuterGrid>
            </StyledAboutAndLinksContainer>
          </PaddingTopBottom60>
        </Wrapper1120>
      </Container>
    </div>
  );
};

export default FooterV2;
