import React, { Fragment, useEffect, useState } from "react";
import { useFormValidationContext } from "../../../contexts/formValidationContext";
import { useHandleContentChange } from "../../../utils/contributionFlowUtils";
import { formValidationCheckV2 } from "../../../utils/validation/formValidation";
import {ButtonPrimary, ButtonSecondary, ButtonGhost} from 'oolib'
import { FormConfigsRenderer } from "../layouts/GroupsStyle1/comps/FormConfigsRenderer";
import { cloneDeep } from "lodash";


export const useOutOfTheBoxForm = ({
    onSubmit,
    content: _content,
    setContent: _setContent,
    configs,
    buttonConfig = {},
    FormWrapper,
    blockWrapper,
    makeFormValidationVisibleMsg,
    CustomCallToActionComp // in case a single submit button isnt enough, and you need to setup multiple call to actions
  }) => {
    buttonConfig.comp = buttonConfig.comp || "ButtonPrimary";
    buttonConfig.props = buttonConfig.props || { children: "Done" };
  
    const {
      setFormValidation,
      formValidation,
      resetFormValidation,
      makeFormValidationVisible,
    } = useFormValidationContext();
  
    useEffect(() => {
      const { failedBlocks } = formValidationCheckV2({
        blocks: configs,
        content,
      });
      setFormValidation((p) => ({ ...p, failedBlocks }));
  
      return () => resetFormValidation();
    }, [JSON.stringify(configs)]);
  
    const [internalContent, setInternalContent] = useState(
      _content ? cloneDeep(_content) : {}
    );

    //basically if a content setter is passed from the parent,
    //respect that content handling state rather than this
    //internal one
    const content = _setContent ? _content : internalContent;
    const setContent = _setContent || setInternalContent
  
    const { handleContentChange } = useHandleContentChange();
  
    const handleOnChange = (block, value, options = {}) =>
      handleContentChange({
        block,
        value,
        setContent,
        setFormValidation,
        blocksToValidate: configs,
        ...options,
      });
  
    const buttonComps = {
      ButtonPrimary,
      ButtonSecondary,
      ButtonGhost,
    };
    let ButtonComp = buttonComps[buttonConfig.comp];
  
    return {
      FORM: (
        <Fragment>
          <FormConfigsRenderer
            FormWrapper={FormWrapper}
            blockWrapper={blockWrapper}
            configs={configs}
            handleOnChange={handleOnChange}
            content={content}
            formValidation={formValidation}
            setFormValidation={setFormValidation}
          />
          {CustomCallToActionComp && (
            <CustomCallToActionComp
              content={content}
              disabled={
                formValidation.failedBlocks.length > 0 ||
                formValidation.invalidInputBlocks.length > 0
              }
              onDisabledButtonClick={() => {
                if (
                  formValidation.failedBlocks.length > 0 ||
                  formValidation.invalidInputBlocks.length > 0
                )
                  makeFormValidationVisible(makeFormValidationVisibleMsg);
              }}
            />
          )}
        </Fragment>
      ),
      SUBMIT_BUTTON: (
        <div
          onClick={() => {
            if (
              formValidation.failedBlocks.length > 0 ||
              formValidation.invalidInputBlocks.length > 0
            )
              makeFormValidationVisible(makeFormValidationVisibleMsg);
          }}
        >
          <ButtonComp
            {...buttonConfig.props}
            onClick={() => onSubmit(content)}
            disabled={
              formValidation.failedBlocks.length > 0 ||
              formValidation.invalidInputBlocks.length > 0
            }
          />
        </div>
      ),
    };
  };
  