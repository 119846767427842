import React from "react";
import NCTWGData from '../../../../../../components/dataviz/NCTWGData';
import WQNDataDb from '../../../../../../components/dataviz/WQNDataDb';
import  AiChat  from "../../../../../../components/AiChat";
import { GELABS_findings } from "./customComps/GELABS_findings";
import { GELABS_findingsAnnoTab } from "./customComps/GELABS_findingsAnnoTab";


export const CustomCompRenderer = ({
  configs,
  content,
  tabsPanelHeight,
  actionBarHeight
}) => {

  if(!configs?.comp) return null

  const comps = {
    NCTWGData,
    WQNDataDb,
    AiChat,
    GELABS_findings,
    GELABS_findingsAnnoTab
  }
  const CompToRender = comps[configs.comp];

  return CompToRender
    ? <CompToRender {...{content, tabsPanelHeight, actionBarHeight}}/> 
    : null;
 
};
