import { getTplTitle } from "../../../../utils/general";
import { extractAllBlocksFromTpl } from "../../../../utils/templating/extractAllBlocksFromTpl";
import { uiContent } from "../uiContent";


// Reusable helper for comparing filter elements
const compareFilterElements = (item1, item2) => {
  return item1.filterType === item2.filterType && 
    (
      (item1.filterType === 'tagType' && item1.tagType === item2.tagType) ||
      (item1.filterType === 'valuePathType' && item1.valuePath === item2.valuePath) ||
      (item1.filterType === 'dateRangeType' && item1.valuePath === item2.valuePath) ||
      (item1.filterType === 'nestedRollupTagType' && 
        JSON.stringify(item1.rollupPath) === JSON.stringify(item2.rollupPath)) ||
      (item1.filterType === 'rollupValuePathType' && 
        item1.tagTypeCollectionToRollup === item2.tagTypeCollectionToRollup &&
        item1.valuePathInRolledUpCollection === item2.valuePathInRolledUpCollection)
    );
};

// Helper to check if element exists in array using our comparison logic
const elementExistsInArray = (element, array) => {
  return array.some(arrItem => compareFilterElements(element, arrItem));
};

function getArraysIntersection(...arrays) {
  if (arrays.length === 0) return [];

  return arrays.reduce((acc, array) =>
    acc.filter((item) => elementExistsInArray(item, array))
  );
}

const getCombinations = (array) => {
  const combinations = [];

  // Get combinations of size k from the array
  const combine = (start, k, temp) => {
    if (temp.length === k) {
      combinations.push([...temp]);
      return;
    }

    for (let i = start; i < array.length; i++) {
      temp.push(array[i]);
      combine(i + 1, k, temp);
      temp.pop();
    }
  };

  // Get all possible sizes from 1 to array.length
  for (let k = 1; k <= array.length; k++) {
    combine(0, k, []);
  }

  return combinations;
};

const findCommonGroups = (blocksPerTpl) => {
  const tplCombinations = getCombinations(blocksPerTpl);

  return tplCombinations
    .map((combination) => {
      const commonElements = getArraysIntersection(
        ...combination.map((comb) => comb.blocks || [])
      );

      const largerCombinations = tplCombinations.filter(
        (otherComb) =>
          otherComb.length > combination.length &&
          combination.every((comb) => 
            otherComb.some(otherTpl => otherTpl.contentType === comb.contentType)
          )
      );

      const uniqueElements = commonElements.filter(
        (element) =>
          !largerCombinations.some((largerComb) => {
            const largerCommonElements = getArraysIntersection(
              ...largerComb.map((comb) => comb.blocks || [])
            );
            return elementExistsInArray(element, largerCommonElements);
          })
      );

      return {
        contentTypes: combination.map((comb) => comb.contentType),
        elements: uniqueElements,
      };
    })
    .filter((group) => group.elements.length > 0);
};

const getRollupPossibilities = ({ tagType, allTpls }) => {
  const thisTagTpl = allTpls.find((d) => d.kp_content_type === tagType);
  const allBlocks = extractAllBlocksFromTpl({ tpl: thisTagTpl });

  // Helper function to find tag relationships
  const findTagChains = (startTagType, visited = []) => {
    // Prevent infinite loops
    if (visited.includes(startTagType)) return [];

    const tagTpl = allTpls.find((d) => d.kp_content_type === startTagType);
    if (!tagTpl) return [];

    const tagBlocks = extractAllBlocksFromTpl({ tpl: tagTpl }).filter((block) =>
      block.valuePath?.startsWith("tags.")
    );

    const chains = [];

    for (const block of tagBlocks) {
      const nextTagType = block.props?.tagType;
      if (nextTagType) {
        // Add the direct chain
        chains.push({
          tagTypeCollectionToRollup: tagType,
          rollupPath: [...visited, startTagType, nextTagType],
        });

        // Find chains continuing from nextTagType
        const nextChains = findTagChains(nextTagType, [
          ...visited,
          startTagType,
        ]);
        chains.push(...nextChains);
      }
    }

    return chains;
  };



  const singleLevelValuePathTypeRollupPossibilities = allBlocks
    .map((block) => {
      if (block.props?.options?.length > 0) {
        return {
          tagTypeCollectionToRollup: tagType,
          valuePathInRolledUpCollection: block.props?.saveValueAsString
            ? block.valuePath
            : `${block.valuePath}.value`,
          filterSourceValuePath: block.valuePath,
          filterDisplay:
            block.props?.label || block.props?.shortLabel || block.valuePath,
        };
      }
      return undefined;
    })
    .filter(Boolean);

  // Find nested rollup possibilities
  const nestedPossibilities = allBlocks
    .filter((block) => block.valuePath?.startsWith("tags."))
    .flatMap((block) => {
      // Add direct 2-level chain
      const directChain = {
        tagTypeCollectionToRollup: tagType,
        rollupPath: [tagType, block.props.tagType]
      };
      
      // Get deeper chains
      const deeperChains = findTagChains(block.props?.tagType, [tagType]);
      
      return [directChain, ...deeperChains];
    })
  

  const allPossibilities = [...singleLevelValuePathTypeRollupPossibilities, ...nestedPossibilities];
  // console.log('All rollup possibilities:', {
  //   regular: singleLevelValuePathTypeRollupPossibilities,
  //   nested: nestedPossibilities,
  //   combined: allPossibilities
  // });
  return allPossibilities;
};

const createFilterSection = ({
  group,
  type,
  totalTpls,
  isRollup = false,
  allTpls,
}) => {
  const isCommon = group.contentTypes.length === totalTpls;
  const baseTitle =
    type === "tags" 
      ? uiContent.tagsFilterTitle 
      : type === 'annoTags'
        ? uiContent.annoTagsTitle
        : '';

  return {
    id: `group_${group.contentTypes.join("_")}_${type}`,
    filterScope: type,
    title: isCommon
      ? `Common ${isRollup ? "Rollup " : ""}${baseTitle}`
      : `${isRollup ? "Rollup " : ""}${baseTitle}`,
    subtitle: `${group.contentTypes
      .map((c) => {
        let thisTpl = allTpls.find((tpl) => tpl.kp_content_type === c);
        return (
          thisTpl?.general?.content?.title || thisTpl?.kp_content_type || ""
        );
      })
      .join(", ")}`,
    contentTypesCount: group.contentTypes.length,
    elements: group.elements,
  };
};

export const autoGenFilterConfigsFromTpl = ({
  selectedTpls,
  _ProfileTypes,
  allTpls,
  filterScopes = ["doc", "anno"],
}) => {
  

  // Get all profile templates
  const allProfileTpls = allTpls.filter((d) =>
    _ProfileTypes.some((p) => p.id === d.kp_content_type)
  );

  // Get author tag filters
  const authorTagGroups = allProfileTpls
    .flatMap((tpl) => {
      const blocks = tpl.kp_templates.myProfileConfig
        .reduce((acc, config) => [...acc, ...config.blocks], [])
        .filter((block) => !!block.props?.tagType)
        .map((block) => ({
          ...block,
          tplContentType: tpl.kp_content_type,
        }));
      return blocks;
    })
    // Group by tagType to combine same tags from different profile types
    .reduce((acc, block) => {
      const existing = acc.find((b) => b.props.tagType === block.props.tagType);
      if (existing) {
        existing.tplContentTypes = [
          ...existing.tplContentTypes,
          block.tplContentType,
        ];
      } else {
        acc.push({
          ...block,
          tplContentTypes: [block.tplContentType],
        });
      }
      return acc;
    }, []);

  const authorTagFilterConfigs = authorTagGroups.map((block) => ({
    filterId: `authorTagFilter_${block.props.tagType}`,
    display: `Author Tag: ${block.props.tagType}`,
    value: block.tplContentTypes,
    source: {
      filterType: "tagType",
      tagType: block.props.tagType,
      scope: "tags",
    },
    target: {
      filterType: "rollupRelationshipType",
      tagType: block.props.tagType,
      rollupResourceTypes: block.tplContentTypes,
      relationshipValuePath: "meta.kp_contributed_by",
    },
  }));

  const otherCommonDocumentFilters = {
    sectionId: "commonDocumentFilters",
    sectionTitle: "Common Document Filters",
    configs: [
      {
        filterId: "authorFilter",
        display: 'Author',
        value: "author",
        source: {
          filterType: "authorsType",
          profileTypes: allTpls
            .filter((d) =>
              _ProfileTypes.some((p) => p.id === d.kp_content_type)
            )
            .map((p) => p.kp_content_type),
          scope: "tags",
        },
        target: {
          filterType: "valuePathType",
          valuePath: `meta.kp_contributed_by`,
        },
      },
      ...authorTagFilterConfigs,
      {
        filterId: "publishedDateFilter",
        display: "Published Date",
        value: "published_date",
        source: {
          filterType: "dateRangeType",
        },
        target: {
          filterType: "dateRangeType",
          valuePath: "kp_date_published",
        },
      }
    ],
  };

  

  const annoTagBlocksPerTpl = selectedTpls.map((tpl) => {
    const allTagTypes = extractAllBlocksFromTpl({ tpl })
      .filter(block => block.props?.annotation?.enable)
      .flatMap(block => block.props.annotation.tagTypesConfig?.map(d => d.tagType) || []);
      
    // Get unique tagTypes in this tpl
    const uniqueTagTypes = [...new Set(allTagTypes)];
    
    return {
      contentType: tpl.kp_content_type,
      blocks: uniqueTagTypes.map(tagType => ({
        tagType,
        filterType: 'tagType'
      }))
    };
  });

  const docTagBlocksPerTpl = selectedTpls.map((tpl) => ({
    contentType: tpl.kp_content_type,
    blocks: extractAllBlocksFromTpl({ tpl })
      .filter(block => block.valuePath.startsWith("tags."))
      .map(block => ({
        tagType: block.props.tagType,
        filterType: 'tagType'
      }))
  }));

  const rollupPossibilitiesPerTpl = selectedTpls.map((tpl) => ({
    contentType: tpl.kp_content_type,
    blocks: extractAllBlocksFromTpl({ tpl })
      .filter(block => block.valuePath.startsWith("tags."))
      .flatMap(block => {
        const possibilities = getRollupPossibilities({ 
          tagType: block.props.tagType, 
          allTpls 
        });
        return possibilities.map(p => ({
          ...p,
          filterType: p.rollupPath ? 'nestedRollupTagType' : 'rollupValuePathType'
        }));
      })
  }));
  
  const valuePathBlocksPerTpl = selectedTpls.map((tpl) => ({
    contentType: tpl.kp_content_type,
    blocks: extractAllBlocksFromTpl({ tpl })
      .filter(block => block.props?.options?.length > 0)
      .map(block => ({
        valuePath: block.valuePath,
        display: block.props?.label || block.props?.shortLabel || block.valuePath,
        value: block.props?.value || block.props?.shortLabel || block.valuePath,
        saveValueAsString: block.props?.saveValueAsString,
        contentType: tpl.kp_content_type,
        filterType: 'valuePathType'
      }))
  }));
  
  const dateTypeBlocksPerTpl = selectedTpls.map((tpl) => ({
    contentType: tpl.kp_content_type,
    blocks: extractAllBlocksFromTpl({ tpl })
      .filter(block => ["DatePicker", "DateRangePicker", "DateTimePicker", "DateTimeRangePicker"].includes(block.comp))
      .map(block => ({
        valuePath: block.valuePath,
        display: block.props?.value || block.props?.shortLabel || block.valuePath,
        value: block.props?.label || block.props?.shortLabel || block.valuePath,
        contentType: tpl.kp_content_type,
        filterType: 'dateRangeType'
      }))
  }));

  const combinedBlocksPerTpl = selectedTpls.map((tpl) => {
    // Get each type of blocks for this template
    const docTagBlocks = docTagBlocksPerTpl.find(t => t.contentType === tpl.kp_content_type)?.blocks || [];
    const valuePathBlocks = valuePathBlocksPerTpl.find(t => t.contentType === tpl.kp_content_type)?.blocks || [];
    const dateTypeBlocks = dateTypeBlocksPerTpl.find(t => t.contentType === tpl.kp_content_type)?.blocks || [];
    const rollupBlocks = rollupPossibilitiesPerTpl.find(t => t.contentType === tpl.kp_content_type)?.blocks || [];
  
    return {
      contentType: tpl.kp_content_type,
      blocks: [
        ...docTagBlocks,
        ...valuePathBlocks,
        ...dateTypeBlocks,
        ...rollupBlocks
      ]
    };
  });
  
  // Now we can find common groups across all types
  const combinedGroups = findCommonGroups(combinedBlocksPerTpl);

  

  
  const annoTagGroups = filterScopes.includes("anno") ? findCommonGroups(annoTagBlocksPerTpl) : [];
  

  const annotationTagsFilterSections = [
    // Common and Template-specific annoTags
    ...(filterScopes.includes("anno")
    ? annoTagGroups
        .sort((a, b) => b.contentTypes.length - a.contentTypes.length)
        .map((group) =>
          createFilterSection({
            group,
            type: "annoTags",
            totalTpls: selectedTpls.length,
            allTpls,
          })
        )
    : []),
  ]

  // Create all filter sections with proper metadata
  const documentTagsFilterSections = filterScopes.includes("doc") 
  ? combinedGroups
      .sort((a, b) => b.contentTypes.length - a.contentTypes.length)
      .map((group) =>
        createFilterSection({
          group,
          type: "tags",  
          totalTpls: selectedTpls.length,
          allTpls,
        })
      )
  : [];

  const final_annotationTagsFilterConfigs = {
    sectionId: 'annotationTagsSection',
    sectionTitle: 'Annotation Filters',
    configs: annotationTagsFilterSections.map(section => ({
      sectionId: section.id,
      sectionTitle: section.title,
      configs: section.elements.map((dd) => ({
        filterId: `annoTags_${dd.tagType}`,
        display: dd?.tagType,
        value: dd?.tagType, 
        source: _ProfileTypes.some((p) => p.id === dd.tagType)
          ? {
              filterType: "profileTagType",
              profileTypes: [dd.tagType],
              scope: 'annoTags',
            }
          : {
              filterType: "tagType",
              tagType: dd.tagType,
              scope: 'annoTags',
            },
        target: {
          filterType: "tagType",
          tagType: dd.tagType,
        },
      })),
    })),
  }

  const documentTagsActualFilterConfigs = documentTagsFilterSections.map((d) => ({
    sectionId: d.id,
    sectionTitle: d.title,
    sectionSubtitle: d.subtitle,
    configs: d.elements.map((dd) => {
      // Use the filterType we added to determine which case to handle
      switch(dd.filterType) {
        case 'valuePathType':
          return {
            filterId: `valuePathFilter_${dd.contentType}_${dd.valuePath.replaceAll(".", "_DOT_")}`,
            display: dd.display,
            value: dd.value || dd.contentType || dd.valuePath,
            source: {
              filterType: "tplType",
              contentType: dd.contentType,
              valuePath: dd.valuePath,
              scope: "tags",
            },
            target: {
              filterType: "valuePathType",
              valuePath: dd.saveValueAsString ? dd.valuePath : `${dd.valuePath}.value`,
            },
          };
        
        case 'dateRangeType':
          return {
            filterId: `dateFilter_${dd.contentType}_${dd.valuePath.replaceAll(".", "_DOT_")}`,
            display: dd.display,
            value: dd.valuePath,
            source: {
              filterType: "dateRangeType"
            },
            target: {
              filterType: "dateRangeType",
              valuePath: dd.valuePath
            }
          };
        
        case 'nestedRollupTagType':
          const getDisplay = rollupPath => {
            const lastTag = rollupPath[rollupPath.length-1];
            const lastTagTpl = allTpls.find(tpl => tpl.kp_content_type === lastTag);
            return lastTagTpl?.general?.content?.title || lastTagTpl?.kp_content_type
          }
          const getTooltip = rollupPath => {
            const displays = [...rollupPath].reverse().map(tagType => { //have to spread else it reverses for the following operations as well
              const tpl = allTpls.find(tpl => tpl.kp_content_type === tagType);
              return tpl?.general?.content?.title || tpl?.kp_content_type
            })
            const toReturn = `Indirect filter: ${displays.join(' > ')}`
            
            return toReturn
          }

          return {
            filterId: `ROLLUP_${d.filterScope}_${dd.rollupPath.join("_")}`,
            display: getDisplay(dd.rollupPath),
            value: dd.rollupPath,
            tooltip: getTooltip(dd.rollupPath),
            source: {
              filterType: "tagType",
              tagType: dd.rollupPath[dd.rollupPath.length - 1],
              scope: d.filterScope,
            },
            target: {
              filterType: "nestedRollupTagType",
              rollupPath: dd.rollupPath,
            },
          };
        
        case 'rollupValuePathType':
          return {
            filterId: `${d.filterScope}_${dd.tagTypeCollectionToRollup}_${dd.valuePathInRolledUpCollection.replaceAll(".", "_DOT_")}`,
            display: dd.filterDisplay,
            value: dd.filterDisplay,
            tooltip: `Indirect filter: ${dd.filterDisplay} > ${getTplTitle(dd.tagTypeCollectionToRollup) || dd.tagTypeCollectionToRollup}`,
            source: {
              filterType: "tplType",
              contentType: dd.tagTypeCollectionToRollup,
              valuePath: dd.filterSourceValuePath,
              scope: d.filterScope,
            },
            target: {
              filterType: "rollupValuePathType",
              tagTypeCollectionToRollup: dd.tagTypeCollectionToRollup,
              valuePathInRolledUpCollection: dd.valuePathInRolledUpCollection,
            },
          };
        
        case 'tagType':
        default:
          return {
            filterId: `tags_${dd.tagType}`,
            display: dd.tagType,
            value: dd.tagType,
            // filterId: `docTags_${dd.tagType}`,
            source: _ProfileTypes.some((p) => p.id === dd.tagType)
              ? {
                  filterType: "profileTagType",
                  profileTypes: [dd.tagType],
                  scope: 'tags',
                }
              : {
                  filterType: "tagType",
                  tagType: dd.tagType,
                  scope: 'tags',
                },
            target: {
              filterType: "tagType",
              tagType: dd.tagType,
            },
          };
      }
    }),
  }))

  const final_documentTagsFilterConfigs = {
    sectionId: 'documentTagsSection',
    sectionTitle: 'Document Filters',
    configs: [
      otherCommonDocumentFilters,
      ...documentTagsActualFilterConfigs,
    ]
  }

  // console.log({final_documentTagsFilterConfigs})

  return [
    final_annotationTagsFilterConfigs,
    final_documentTagsFilterConfigs,
  ];
};
