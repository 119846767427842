import { useGetQueryData } from "./react-query-hooks/general";

export const useGetTagUnsetQueryObjForMultilingual = () => {
   const {
      deployment: { _AutoTranslate, _AppLanguageOptions },
   } = useGetQueryData("platformConfigs");

   const getUnsetQuery = (tag) => {
      let unsetQueryObj = { [`tags.${tag}`]: "" };

      if(_AutoTranslate.enable === true && _AppLanguageOptions.length) {
         unsetQueryObj = _AppLanguageOptions.reduce(
            (prevObj, languageOption) => {
               return {
                  ...prevObj,
                  [`translations.${languageOption.id}.tags.${tag}`]: "",
               };
            },
            { ...unsetQueryObj }
         );
      }
      
      return {
         $unset: unsetQueryObj,
      };
   }

   return getUnsetQuery;
};