import React, { createContext, useState, useContext } from "react";

const SidebarContext = createContext(null);

export function SidebarProvider({ children }) {
  const [showSidebar, setShowSidebar] = useState(false);
  const [content, setContent] = useState();
  

  return (
    <SidebarContext.Provider
      value={{ showSidebar, setShowSidebar, content, setContent }}
    >
      {children}
    </SidebarContext.Provider>
  );
}

// Custom hook for cleaner usage
export function useSidebarContext() {
  return useContext(SidebarContext);
}
