import React from 'react'
import LexicalTextEditor from './TextEditor/LexicalTextEditor';
import { useAppSettingsContext } from '../../contexts/appSettingsContext';
import { BlockLabel } from 'oolib';

export const StaticRichText = ({ label, sublabel, richContent: _richContent }) => {
  const { APP_SETTINGS } = useAppSettingsContext();

  const richContent = _richContent?.isLexical
    ? _richContent
    : _richContent && _richContent[APP_SETTINGS?.lang?.value]; //else we have agreement in multiple languages

  return (
    <>
      <BlockLabel label={label} sublabel={sublabel}/>
      <LexicalTextEditor
          value={richContent}
          readOnly={true}
      />
    </>
  )
}

