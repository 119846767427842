
import { colors } from "oolib";
import React, { useEffect, useRef, useState } from "react";
import { StyledGridContainer, StyledLSBSection, StyledLSBToggleButton, StyledRSBSection, StyledRSBToggleButton } from "./styled";

/**
 * a little note about the props lsbAllowCollapse & rsbAllowCollapse:
 * the only purpose of this prop is to show the button that 
 * allows user to toggle the open/close state of the sidebar. 
 * 
 * But if you intent to control this manually from outside 
 * the comp, then you dont need to enable this prop
 */

export const SidebarLayoutV2 = ({
  gridHeightCSS = 'calc(100vh - 10rem)',
  toggleState : toggleStateFromParent,
  setToggleState : setToggleStateFromParent,
  toggleAnimationStyle = 'slide', //alt = mask
  
  lsbId,
  lsbWidth : _lsbWidth = 250,
  lsbClosedWidth = 0,
  lsbAllowCollapse = false, 
  lsbAllowResize = false,
  lsbComp,

  rsbId,
  rsbWidth : _rsbWidth = 250,
  rsbClosedWidth = 0,
  rsbAllowCollapse = false,
  rsbAllowResize = false,
  rsbComp,

  children
}) => {

  const [lsbWidth, setLsbWidth ] = useState(_lsbWidth)
  const [rsbWidth, setRsbWidth ] = useState(_rsbWidth)

  //--- sidebar toggle handling ---- //
  //if comes from parent, then it controls it
  const [toggleState, setToggleState] = useState(
    toggleStateFromParent ||
    { lsb: true, rsb: true }
  )

  const handleSetToggleState = value => {
    let fireStateSetter = setToggleStateFromParent || setToggleState
    fireStateSetter(value)
  }
  
  const isFirstMount = useRef(true)
  
  useEffect(() => {
    console.log({toggleStateFromParent})
    if(!isFirstMount.current) setToggleState(toggleStateFromParent)
  },[toggleStateFromParent])

  useEffect(() => isFirstMount.current = false,[])
  //--- end sidebar toggle handling ---- //

  const initResizeSidebarIsActiveState = {
    isActive: false,
    lsb_rsb: undefined,
    initClientX: undefined
  }
  const [resizeSidebarIsActive, setResizeSidebarIsActive] = useState(initResizeSidebarIsActiveState);

  const attachEdgeHighlightHandlers = (lsb_rsb) => {
    const thresholdCondition = e => lsb_rsb === 'lsb'
      ? (
        e.clientX >= e.currentTarget.getBoundingClientRect().right - 10 &&
        e.clientX <= e.currentTarget.getBoundingClientRect().right //need this only because the toggle button is sticking out to the right of the sidebar
      )
      : (
        e.clientX <= e.currentTarget.getBoundingClientRect().left + 10 &&
        e.clientX >= e.currentTarget.getBoundingClientRect().left
      )
    const borderLeft_Right = lsb_rsb === 'lsb'
        ? 'Right'
        : 'Left'
    return ({
    onMouseMove: e => {
      if(thresholdCondition(e)){
        e.currentTarget.style[`border${borderLeft_Right}`] = `2px solid ${colors.blue}`;
        e.currentTarget.style.cursor = 'ew-resize';
      }else{
        e.currentTarget.style[`border${borderLeft_Right}`] = `1px solid ${colors.greyColor10}`;
        e.currentTarget.style.cursor = 'unset';
      }
    },
    onMouseOut: e => {
      e.currentTarget.style[`border${borderLeft_Right}`] = `1px solid ${colors.greyColor10}`;
      e.currentTarget.style.cursor = 'unset';
    },
    onMouseDown: e => {
      if(thresholdCondition(e)){
        setResizeSidebarIsActive({
          isActive: true,
          lsb_rsb,
          initClientX: e.clientX,
          initWidth: lsb_rsb === 'lsb' ? lsbWidth : rsbWidth
        })

      }
    }
  })}

  return (
    <StyledGridContainer
      {...{
        gridHeightCSS,
        toggleState,
        lsbComp,
        rsbComp,
        lsbWidth,
        lsbClosedWidth,
        rsbWidth,
        rsbClosedWidth,
        resizeSidebarIsActive,
      }}
      onMouseUp={(e) => {
        if (resizeSidebarIsActive.isActive) {
          setResizeSidebarIsActive(initResizeSidebarIsActiveState);
        }
      }}
      onMouseMove={(e) => {
        if (resizeSidebarIsActive.isActive) {
          const { lsb_rsb, initClientX, initWidth } = resizeSidebarIsActive;
          if (lsb_rsb === "lsb") {
            setLsbWidth(initWidth + (e.clientX - initClientX));
          } else {
            setRsbWidth(initWidth - (e.clientX - initClientX));
          }
        }
      }}
    >
      {lsbComp && (
        <StyledLSBSection
          {...(lsbAllowResize ? attachEdgeHighlightHandlers("lsb") : {})}
          {...{ lsbWidth, toggleAnimationStyle }}
        >
          {lsbAllowCollapse && <StyledLSBToggleButton
          lsbWidth={lsbWidth}
            icon={toggleState.lsb ? 'CaretLeft': 'CaretRight'}
            onClick={() => handleSetToggleState(prev => ({...prev, lsb: prev.lsb ? false : true}))}
            />}
          <div style={{direction: 'ltr', height: '100%'}}>{lsbComp}</div>
        </StyledLSBSection>
      )}
      <section style={{ background: colors.white, overflow: 'auto' }}>
        <div style={{minWidth: '50vw', height: '100%'}}>
          {children}
        </div>
      </section>
      {rsbComp && (
        <StyledRSBSection
          {...(rsbAllowResize ? attachEdgeHighlightHandlers("rsb") : {})}
          {...{ rsbWidth, toggleAnimationStyle }}
        >
          {rsbAllowCollapse && <StyledRSBToggleButton
            icon={toggleState.rsb ? 'CaretRight': 'CaretLeft'}
            onClick={() => handleSetToggleState(prev => ({...prev, rsb: prev.rsb ? false : true}))}
            />}
          {rsbComp}
        </StyledRSBSection>
      )}
    </StyledGridContainer>
  );
};
