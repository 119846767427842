import styled from "styled-components";

export const FeedbackStyled = styled.div`
  display: flex;
  gap: 0.5rem;
  width: 100%;
  align-items: center;
`;

export const ThumbsUpStyled = styled.div`
  background-color: ${({ active }) => (active ? "#5DA922" : "")};
  border-radius: 0.4rem;
  cursor: pointer;
`;

export const ThumbsDownStyled = styled.div`
  background-color: ${({ active }) => (active ? "#C41717" : "")};
  border-radius: 0.4rem;
  cursor: pointer;
`;
