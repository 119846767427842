import React from "react";
import { SANS_3, SANS_2, UI_BODY_SM_DF } from "oolib";

function calculateAge(dob) {
  const birthDate = new Date(dob);
  const currentDate = new Date();

  let age = currentDate.getFullYear() - birthDate.getFullYear();

  // Adjust age based on birth month and day
  if (
    currentDate.getMonth() < birthDate.getMonth() ||
    (currentDate.getMonth() === birthDate.getMonth() &&
      currentDate.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
}

const CalcTimePeriodDisplay = ({ value, textComp = "UI_BODY_SM_DF", ...textCompProps }) => {

  const TextComps = {
    SANS_2,
    SANS_3,
    UI_BODY_SM_DF
  };

  const TextComp = TextComps[textComp];

  return <TextComp {...textCompProps}>{calculateAge(value)}</TextComp>;
};

export default CalcTimePeriodDisplay;
