
import { useGetDataQuery } from "../../../../../utils/react-query-hooks/getData";
import { useGetTagsV3 } from "../../../../../utils/react-query-hooks/tags";
import { useGetTpl } from "../../../../../utils/react-query-hooks/tpls";
import { useGetUsers } from "../../../../../utils/react-query-hooks/users";
import { extractAllBlocksFromTpl } from "../../../../../utils/templating/extractAllBlocksFromTpl";
import { generateOptions, getText } from "oolib";
import { convertFilterOptionsByConfIntoFindQuery } from "../utils/convertFilterOptionsByConfIntoFindQuery";
import { prepQueryToFetchOnlyUsedTags } from "../utils/prepQueryToFetchOnlyUsedTags";
import { getTagTypeTitle} from '../utils/getTagTypeTitle'


export const fetchFilterOptionsConfigs = {
  contentTagType: {
    hook: useGetDataQuery,
    getArgs: ({
      filterConfig,
      enableDataFetch,
      contentTypes,
      activeFilters,
      
    }) => [
      {
        contentTypes: [filterConfig.source.tagType],
        findQuery: {
         ...( {kp_published_status:"published"}),
          ...(filterConfig.source.findQuery || {}),
          ...prepQueryToFetchOnlyUsedTags({
            contentTypes,
            filterConfig,
          }),
          
          ...(filterConfig.source.filterOptionsBy //read how this works inside the function definition
            ? convertFilterOptionsByConfIntoFindQuery({
                activeFilters,
                thisFilterConfig: filterConfig,
                filterOptionsBy: filterConfig.source.filterOptionsBy,
              })
            : {}),
        },
        queryOptions: {
          enabled: enableDataFetch === true,
        },
      },
    ],
    convertToOptions: ({ data }) =>
      data.data.map((d) => ({
        display: getText(d.main.title),
        value: d._id,
      })),
    getFilterDisplay: ({filterConfig}) => {
      return filterConfig.display || getTagTypeTitle({tagType: filterConfig.source.tagType})
    }
  },
  tagType: {
    //to deprecate
    hook: useGetTagsV3,
    getArgs: ({ filterConfig, enableDataFetch, contentTypes }) => [
      // to deprecate
      filterConfig.source.tagType,
      {
        tagCategory: "tags",
        filters: {
          ...(filterConfig.source.findQuery || {}),         
          ...prepQueryToFetchOnlyUsedTags({
            contentTypes,
            filterConfig,
          }),
        },
        enabled: enableDataFetch === true,
      },
    ],
    convertToOptions: ({ data }) =>
      data.map((d) => ({
        display: d.display,
        value: d.tagId,
      })),
    getFilterDisplay: ({filterConfig}) => {
      return filterConfig.display || getTagTypeTitle({tagType: filterConfig.source.tagType})
    }
  },
  usersType: {
    hook: useGetUsers,
    getArgs: ({ filterConfig, enableDataFetch }) => [
      {
        // tplId: filterConfig.source.contentType,
        findQuery:filterConfig.source.profileTypes?{profileType:{$in:filterConfig.source.profileTypes}}:{},
        enabled: enableDataFetch === true,
      },
    ],
    convertToOptions: ({ data }) =>
      data.map((d) => ({
        display: d.name,
        value: d._id,
      })),
    getFilterDisplay: ({filterConfig}) => {
      return filterConfig.display || (filterConfig.source.profileTypes?.length > 1 
        ? 'Users'
        : getTagTypeTitle({tagType: filterConfig.source.profileTypes[0]}))
    }
  },
  authorsType: {
    hook: useGetDataQuery,
    getArgs: ({ filterConfig, enableDataFetch, contentTypes }) => [
      {
        contentTypes, // [stories]
        projection: {"meta.kp_contributed_by": 1},
        findQuery: {kp_published_status:"published"},
        population: [
          {
            path: "meta.kp_contributed_by",
            model: "user",
            select: ["name"],
          },
        ],
        // tplId: filterConfig.source.contentType,
        // findQuery:filterConfig.source.profileTypes?{profileType:{$in:filterConfig.source.profileTypes}}:{},
        enabled: enableDataFetch === true,
      },
    ],
    convertToOptions: ({ data }) => {
      const ids = new Set();
      return data.data.filter(author => {
        const authorId = author?.meta?.kp_contributed_by?._id;
        if(!authorId || ids.has(authorId)) return false;
        
        ids.add(authorId);
        return true;
      }).map((d) => ({
        display: d.meta.kp_contributed_by.name,
        value: d.meta.kp_contributed_by._id,
      }))
    },
    getFilterDisplay: ({filterConfig}) => {
      return filterConfig.display || (filterConfig.source.profileTypes?.length > 1 
        ? 'Users'
        : getTagTypeTitle({tagType: filterConfig.source.profileTypes[0]}))
    }
  },
  tplType: {
    hook: useGetTpl,
    getArgs: ({ filterConfig, enableDataFetch }) => [
      filterConfig.source.contentType,
      {       
        enabled: enableDataFetch === true
       },
    ],
    convertToOptions: ({ data, filterConfig }) => {
      const allBlocks = extractAllBlocksFromTpl({ tpl: data });

      const block = allBlocks.find(
        (block) => block.valuePath === filterConfig.source.valuePath
      );

      if(!block) return []

    
      // For the block where injectOtherProp is set to true ( ex- checkboxList), ensure that we explicitly include the other option in the filter options for this block.
      if (block.props.injectOtherOption) {
        return [...block.props.options, { display: "Other", value: "other" }];
      } else {
        return block.props.options;
      }
      
    },
    getFilterDisplay: ({ filterConfig }) => filterConfig.display
  },
  staticType: {
    hook: (filterConfig, theme) => {
      const options = filterConfig.source.options;
      const optionsFn = filterConfig.source.optionsFn;
      return {
        status: "success",
        data: generateOptions({ options, optionsFn,theme }),
      };
    },
    getArgs: ({ filterConfig, theme }) => [filterConfig,theme],
    convertToOptions: ({ data }) => {
      return data;
    },
    getFilterDisplay: ({ filterConfig }) => filterConfig.display
  },
  dateRangeType: {
    lightboxConfig: {
      comp: 'DateRangeType',
      props: {}
    },
    getFilterDisplay: ({ filterConfig }) => filterConfig.display
  },

    /**
     * IM NOT SURE THIS WORKS.
     */
  profileTagTypeInverse: {
    // hook: useGetProfiles, //this has to be replaced by use get users
    getArgs: ({ filterConfig, enableDataFetch }) => [
      {
        profileType: filterConfig.source.profileType,
        enabled: enableDataFetch === true,
      },
    ],
    convertToOptions: ({ data }) =>
      data.map((d) => ({
        display: d.userName,
        value: d._id,
      })),
      getFilterDisplay: ({ filterConfig }) => filterConfig.display
  },
};
