import { OKELink, SANS_3 } from "oolib";
import React, { Fragment, useState } from "react";
import { DataDownloadModal } from "../../newComps/DataDownloadModal";

const DownloadDataButton = ({ getDataToBeExported }) => {
  const [exporting, setExporting] = useState(false);
  const [showDatadownloadModal, setShowDataDownloadModal] = useState(false);
  return (
    <Fragment>
      {showDatadownloadModal && (
        <DataDownloadModal
          setExporting={setExporting}
          getDataToBeExported={getDataToBeExported}
          setShowDataDownloadModal={setShowDataDownloadModal}
        />
      )}
      <SANS_3 style={{ marginLeft: "1rem" }}>
        <OKELink
          onClick={() => {
            setShowDataDownloadModal(true);
          }}
          color={"black"}
        >
          {exporting ? "Downloading..." : "Download Data"}
        </OKELink>
      </SANS_3>
    </Fragment>
  );
};

export default DownloadDataButton;
