import {
  __checkIsSWValid,
  __isLocalhost,
  __isPublicUrlMismatch,
  // __registerSWOnLocalhost,
} from "./function";

export function register(config) {
  if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
    console.log("REG_1", process.env);

    if (__isPublicUrlMismatch()) return;

    const windowLoadCallback = () => {
      const swUrl = `${import.meta.env.PUBLIC_URL}/service-worker.js`;

      if (__isLocalhost()) {
        console.log("register sw on localhost url");
        __checkIsSWValid(swUrl, config, registerValidSW);
      } else {
        console.log("register sw on non-localhost url");
        registerValidSW(swUrl, config);
      }
    };

    if (document.readyState === "complete") {
      windowLoadCallback();
    } else {
      window.addEventListener("load", windowLoadCallback);
    }
  }
}

function registerValidSW(swUrl, config) {
  console.log("i");
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      console.log("reg done", registration);
      console.log(
        "naivgator.serviceworker.controller",
        navigator.serviceWorker.controller
      );

      let waitingWorker = registration.waiting;
      if (waitingWorker) {
        console.log("TEST WAITING worker EXISTS");
        /**
         * should broadcast update available only if waiting worker 
         * is in 'installed' state. cuz if it is in activating or activated
         * state, it means the update has already been installed.
         */
        if(waitingWorker.state === 'installed'){
          config.broadcastUpdateAvailable(registration);
        }

        waitingWorker.onstatechange = () => {
          console.log(
            "TEST WAITING worker state changed to: ",
            waitingWorker.state
          );
          config.broadcastSecondSWStatusMsg(waitingWorker.state);
        };
      }

      /** purely for logging purposes, doesnt affect app in anyway. */
      let activeWorker = registration.active;
      if (activeWorker) {
        console.log("TEST ACTIVE worker EXISTS");
        activeWorker.onstatechange = () => {
          console.log(
            "TEST ACTIVE worker state changed to: ",
            activeWorker.state
          );
        };
      }

      registration.onupdatefound = () => {
        console.log("update found", registration);

        let installingWorker = registration.installing;
        if (installingWorker) {
          if (activeWorker) {
            // console.log('TEST INSTALLING worker state is', installingWorker.state)
            installingWorker.state === "installing" &&
              config.broadcastSecondSWStatusMsg("installing");
            installingWorker.onstatechange = () => {
              console.log(
                "TEST INSTALLING worker 2nd state changed to: ",
                installingWorker.state
              );
              installingWorker.state === "installed" &&
                config.broadcastUpdateAvailable(registration);
            };
          } else if (!activeWorker) {
            installingWorker.state === "installing" &&
              config.broadcastFirstSWStatusMsg("installing");
            installingWorker.onstatechange = () => {
              console.log(
                "TEST INSTALLING worker state changed to: ",
                installingWorker.state
              );
              config.broadcastFirstSWStatusMsg(installingWorker.state);
            };
          }
        }
      };

      const messageChannel = new MessageChannel();

      navigator.serviceWorker.ready.then((_registration) => {
        console.log(
          "This web app is being served cache-first by a service " +
            "worker. To learn more, visit https://cra.link/PWA"
        );

        _registration.active.postMessage({ type: "PORT_INITIALIZATION" }, [
          messageChannel.port2,
        ]);

        // worker.active.postMessage({type: 'REPLAY_BG_SYNC_REQUESTS'}, [
        //   messageChannel.port2,
        // ]);

        //Listen to messages
        messageChannel.port1.onmessage = (event) => {
          // Process message
          console.log("messagechannel", event);

          switch (event.data.type) {
            case "SW_ACTIVATED":
              console.log("NEW SW ACTIVATED");
              config.handleNewSWActivated(event.data.payload.wasAWaitingWorker);
              break;

            // case 'DATA_SAVED_LOCALLY' :
            // console.log('fire on local save');
            // config && config.onLocalSave && config.onLocalSave();
            // break;

            case "DATA_SAVED_TO_DB":
              config &&
                config.onSavedToDb &&
                config.onSavedToDb(event.data.payload);
              break;

            default:
              break;
          }
        };
      });
      
    })
    .catch((error) => {
      console.error("Error during service worker registration:", error);
    });
}

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}
