import { $getRoot, $createRangeSelection, $getNodeByKey } from "lexical";

export const findRange = (editor, targetText) => {
  let result = {
    startNodeKey: "",
    endNodeKey: "",
    startOffset: 0,
    endOffset: 0,
  };

  // TODO-> We can use the chunk's start and end range to limit the search space from the entire text to just the chunk text, optimizing the search.
  editor.getEditorState().read(() => {
    const root = $getRoot();

    const nodes = root.getAllTextNodes();

    // Build a continuous text representation with node mappings
    let combinedText = "";
    const nodeMap = [];

    for (const node of nodes) {
      const textContent = node.getTextContent();
      const startIndex = combinedText.length;
      combinedText += textContent;

      nodeMap.push({
        node,
        start: startIndex,
        end: combinedText.length,
      });
    }

    const regexPattern = targetText
      .replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
      .replace(/\s+/g, "\\s+");

    const regex = new RegExp(regexPattern);
    const match = regex.exec(combinedText);

    if (!match) {
      throw new Error("suggetion not found!");
    }

    const targetEnd = match.index + match[0].length;

    // Find start node and offset
    for (const mapping of nodeMap) {
      if (match.index >= mapping.start && match.index < mapping.end) {
        result.startNodeKey = mapping.node.getKey();
        result.startOffset = match.index - mapping.start;
        break;
      }
    }

    // Find end node and offset
    for (const mapping of nodeMap) {
      if (targetEnd > mapping.start && targetEnd <= mapping.end) {
        result.endNodeKey = mapping.node.getKey();
        result.endOffset = targetEnd - mapping.start;
        break;
      }
    }
  });

  return result;
};

export const getSelectionRange = ({
  startNodeKey,
  endNodeKey,
  startOffset,
  endOffset,
  type = "text",
}) => {
  const selection = $createRangeSelection();

  selection.anchor.set(startNodeKey, startOffset, type);
  selection.focus.set(endNodeKey, endOffset, type);

  // Ensure the selection is normalized
  selection.dirty = true;
  selection._cachedNodes = null;
  selection.isBackward = false;
  return selection;
};

export const getAllText = (editor) => {
  let allText = "";
  editor.getEditorState().read(() => {
    const rootNode = $getNodeByKey("root");
    allText = rootNode.getTextContent();
  });

  return allText;
};

export const groupTagsByCategory = (input) => {
  const categoryMap = {};

  // Group tags by their category
  input.forEach((tag) => {
    const category = tag.tagCategory;
    if (!categoryMap[category]) {
      categoryMap[category] = [];
    }
    categoryMap[category].push(tag);
  });

  // Convert the grouped object into an array
  return Object.keys(categoryMap).map((category) => ({
    tagCategory: category,
    tags: categoryMap[category],
  }));
};
