import { colors2, transition } from "oolib";
import styled from "styled-components";

export const CONTAINER_PADDING = 10;
const barColor = colors2.secondaryContainer; // temp color
const { grey5 } = colors2

export const StyledBarGraphContainer = styled.div`
    padding: ${CONTAINER_PADDING}px;
    padding-bottom: 0.4rem; // UI scroll bar bottom space 
    overflow-x: auto;
    background-color: white;
    border-radius: 8px;
`

export const StyledBarGraphCollectionWrapper = styled.div`
    display: flex;
`

export const StyledBarGraphWrapper = styled.div`
    display: flex;
    position: relative;
  `
export const StyledSingleBarWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: flex-end;
    position: relative;
    cursor: pointer;
    border-radius: 2px;

    &:hover {
       background-color: ${grey5};
    }
`

export const StyledBar = styled.div`
    border-radius: 2px;
    background-color: ${barColor};
    color: ${colors2.onSecondary};
    position: relative;
    ${transition("width")};
`

export const StyledLabelWrapper = styled.div`
    position: absolute;
    transform: rotate(-90deg);
    transform-origin: left top;
    bottom: 0;
    left: calc(50% - 8px); // 10px for font
    text-wrap: nowrap;
    display: flex;
`
