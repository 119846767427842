import { getVal, toArray } from "oolib";
import { queryClient } from "../../../..";

/**
 * const filterConfig = {
  filterId: "tags_districts_states_country",
  source: {
    filterType: "tagType",
    tagType: "country",
    scope: "tags"
  },
  target: {
    filterType: "nestedRollupTagType",
    rollupPath: ["districts", "states", "country"]  // from bottom to top of hierarchy
  }
} 
 */

export const filterAsPerActiveFilters = ({
  separatedData,
  activeFilters,
  tagTypeCollectionToRollupDocs,
  tagTypeInRolledUpCollectionDocs
}) => {
  //first filter down to only the selected tpls
  
  return separatedData.filter((d) => {
    if (
      activeFilters.every((a) => {
        if (a.target.filterType === "nestedRollupTagType") {
          // Get all docs of the final target type (e.g., countries)
          // const allQueryMatches = queryClient.getQueriesData([
          //   "contentListing",
          //   [a.target.rollupPath[a.target.rollupPath.length - 1]], // 'country'
          // ]);

          // console.log({allQueryMatches})
          
          // const allDocsInFinalCollection = allQueryMatches.find(
          //   ([key, _data]) => key[2]?.queryOptions?.enabled
          // )?.[1]?.data || [];
        
          // Filter to only selected values
          let currentDocs = tagTypeCollectionToRollupDocs.data.filter(
            (doc) => {
              return (
                doc.meta.kp_content_type === a.target.rollupPath[a.target.rollupPath.length - 1] &&
                a.values.some((v) => v.value === doc._id) 
              )
            }
          );
          
        
          // Traverse down the path in reverse, getting tagged resources at each step
          for (let i = a.target.rollupPath.length - 2; i >= 0; i--) {
            const currentTagType = a.target.rollupPath[i]; // e.g., 'state', then 'districts'

            
            
            // Get IDs from tagged resources
            const taggedIds = Array.from(
              new Set(
                currentDocs.flatMap(
                  doc => (doc.taggedResources?.[currentTagType]?.data || []).map(tr => tr._id)
                )
              )
            );
        
            // Get actual docs for these IDs from our prefetched collection
            currentDocs = tagTypeCollectionToRollupDocs.data.filter(
              doc => doc.meta.kp_content_type === currentTagType && taggedIds.includes(doc._id)
            );

          }
        
          // Finally, check if the content is tagged with any of the final docs (districts)
          return d[a.source.scope]?.[a.target.rollupPath[0]]?.data.some(
            dd => currentDocs.some(doc => doc._id === dd._id)
          );
        } else if(a.target.filterType === "rollupValuePathType"){
          const relevantDocIds = tagTypeCollectionToRollupDocs.data.filter(rD => {
            return a.values.some(aa =>{
              let valueAtPath = getVal(rD, a.target.valuePathInRolledUpCollection);
              if(valueAtPath){
                /**
                 * this will give us 'option values', because valuePathInRolledUpCollection would be 
                 * '<some_block_value_path>.value'. 
                 * this takes care of single select as well as multi select as well as saveValueAsString scenarios
                 */
                valueAtPath = toArray(valueAtPath); 
              } 
              return valueAtPath?.includes(aa.value)
            })
          }).map(rD => rD._id)
          return d[a.source.scope]?.[a.target.tagTypeCollectionToRollup]?.data?.some(dd => relevantDocIds.includes(dd._id));
        } else if(a.target.filterType === "rollupRelationshipType"){

          /**
           * get all the tag docs in a.values
           * get all user ids inside taggedResources.<rollupresourcetypes(basically profiletypes)>.data
           * finally getVal at relationshipValuePath should be in the array of all user ids fetched
           */
          // console.log({tagTypeCollectionToRollupDocs, applied: a.values})
          const tagDocsOfSelectedFilterValues = tagTypeCollectionToRollupDocs.data.filter(rD => {
            return a.values.some(aa => aa.value === rD._id)
          })
          const userIdsTaggedWithTheseTags = tagDocsOfSelectedFilterValues.flatMap(doc => {
            return a.target.rollupResourceTypes.flatMap(profileType => {
              return doc.taggedResources?.[profileType]?.data?.map(pD => pD._id) || []
            })
          })
          let userIdAtRelationshipPath = getVal(d, a.target.relationshipValuePath);
          //this is a temp hack cuz of kp_contributed_by population
          if(userIdAtRelationshipPath._id) userIdAtRelationshipPath = userIdAtRelationshipPath._id
          // console.log({tagDocsOfSelectedFilterValues, userIdsTaggedWithTheseTags, userIdAtRelationshipPath})
          return userIdsTaggedWithTheseTags.includes(userIdAtRelationshipPath)
          
          
        } else if(a.target.filterType === "valuePathType") {
          // Handle the new valuePathType filter
          const valueAtPath = getVal(d, a.target.valuePath);
          
          // If no value exists at path, return false
          if (!valueAtPath) {
            return false;
          }
          
          // Convert to array to handle both single and multiple values
          const values = toArray(valueAtPath);
          
          // Check if any of the filter values match the values at the path
          return a.values.some(filterValue => values.includes(filterValue.value));
        } else if (a.target.filterType === "dateRangeType") {
          const [fromDate, toDate] = a.values;
          const targetValue = getVal(d, a.target.valuePath);
        
          if (!targetValue) return false;
        
          // Case 1: Simple date string
          if (typeof targetValue === 'string') {
            const date = new Date(targetValue);
            return date >= new Date(fromDate) && date <= new Date(toDate);
          }
          
          // Case 2: Array of dates [fromDate, toDate]
          if (Array.isArray(targetValue)) {
            const startDate = new Date(targetValue[0]);
            // If endDate is null (ongoing), only check start date
            const endDate = targetValue[1] ? new Date(targetValue[1]) : null;
            return startDate >= new Date(fromDate) && 
                   startDate <= new Date(toDate) &&
                   (!endDate || (endDate >= new Date(fromDate) && endDate <= new Date(toDate)));
          }
        
          // Case 3: Object with date field (DateTimePicker & DateTimeRangePicker)
          if (targetValue.date) {
            const date = new Date(targetValue.date);
            return date >= new Date(fromDate) && date <= new Date(toDate);
          }
        
          return false;
        }

        // Default case for simple tag type filtering
        return a.values.some((v) => {
          return d[a.source.scope]?.[
            a.target.tagType || a.source.tagType || a.source.profileTypes?.[0] //i dont know why we are depending on source for tagtype and for scope... should clean this up
          ]?.data.some((dd) => dd._id === v.value);
        });
      })
    ) {
      return true;
    } else {
      return false;
    }
  });
};
