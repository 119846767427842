import {
  $createMarkNode,
  $getMarkIDs,
  $isMarkNode,
  $unwrapMarkNode,
  MarkNode,
} from "@lexical/mark";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister, registerNestedElementResolver } from "@lexical/utils";
import {
  $getNodeByKey,
  $getSelection,
  $isRangeSelection,
  $isTextNode,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
} from "lexical";
import React, { useEffect, useMemo, useState } from "react";

import { cloneDeep } from "lodash";
import AnnoLightbox from "./AnnoLightbox";
import { useAnnoContextLex } from "./context";
import { handleCreateAnnotation } from "./handlers/handleCreateAnnotation";
import { applyClassesForAnnoColor } from "./utils/applyClassesForAnnoColor";
import { constructAndInjectLexicalValueForEachAnnoDataId } from "./utils/constructAndInjectLexicalValueForEachAnnoDataId";
import { getAnnoLightboxPosition } from "./utils/getAnnoLightboxPosition";
import { useHighlightAnnoOnEvent } from "./utils/useHighlightAnnoOnEvent";

export const SHOW_ANNO_LIGHTBOX_COMMAND = createCommand(
  "SHOW_ANNO_LIGHTBOX_COMMAND"
);

/*

moved this markNodeMap outside the component so that it can be imported in the AnnoSuggestionsPlugin.

 best to context and share the state?.

*/

const editorMap = new WeakMap(); // to avoid memory leaks

export const getMarkNodeMap = (editor) => {
  if (!editorMap.has(editor)) {
    editorMap.set(editor, new Map());
  }

  return editorMap.get(editor);
};

export const AnnoPlugin = ({ annotation, readOnly }) => {
  const [editor] = useLexicalComposerContext();
  const { handleSetAnnoData, annoData, handleUpdateFragmentsInAnnoData, latestAnnoDataRef } =
    useAnnoContextLex();
  const [showAnnoLightbox, setShowAnnoLightbox] = useState(undefined);

  const markNodeMap = getMarkNodeMap(editor);

  const { setActiveAnnoDataIDs, resetActiveAnnoDataIDs } =
    useHighlightAnnoOnEvent({
      hover_select: "select",
      classToApply: "-selected-",
      markNodeMap,
      annoData,
      editor,
      eventBasedCallback: {
        select: ({ editor, activeData_with_relatedNodeKeys }) => {
          setShowAnnoLightbox({
            activeData_with_relatedNodeKeys,
            mode: "display",
            position: getAnnoLightboxPosition({ editor }),
          });
        },
      },
    });

  const {
    setActiveAnnoDataIDs: setHoveredAnnoDataIDs,
    resetActiveAnnoDataIDs: resetHoveredAnnoDataIDs,
  } = useHighlightAnnoOnEvent({
    hover_select: "hover",
    classToApply: "-hovered-",
    markNodeMap,
    annoData,
    editor,
  });

  useEffect(() => {
    const markNodeKeysToIDs = new Map();
    return mergeRegister(
      /**
       * by default lexical stores overlapping MarkNodes
       * data in a very shitty way. This utility resolves that,
       * making the data structure a nice and clean:
       * [
       *   {ids: [A], ...},
       *   {ids: [A, B], ...},
       *   {ids: [B], ...},
       * ]
       */
      registerNestedElementResolver(
        editor,
        MarkNode,
        (from) => {
          return $createMarkNode(from.getIDs());
        },
        (from, to) => {
          // Merge the IDs
          const ids = from.getIDs();
          ids.forEach((id) => {
            to.addID(id);
          });
        }
      ),
      /**
       * just creates a map of MarkNode ids to all the nodekeys that have this id
       * this is a sense is a better representation of 'which are all the nodes
       * that have the same data attached to it. This way, in the case of overlaps, we can
       * keep track of which pieces belong to one complete anno string (in the case of overlaps)
       * This will also help with our 'select anno on click' feature
       * looks something like:
       * {
       *  A: [key1, key2]
       *  B: [key3, key3]
       * }
       */
      editor.registerMutationListener(MarkNode, (mutations) => {
        const registeredElements = new WeakSet();
        editor.getEditorState().read(() => {
          for (const [key, mutation] of mutations) {
            const node = $getNodeByKey(key);
            let ids = [];

            if (mutation === "destroyed") {
              ids = markNodeKeysToIDs.get(key) || [];
            } else if ($isMarkNode(node)) {
              ids = node.getIDs();
            }

            for (let i = 0; i < ids.length; i++) {
              const id = ids[i];
              let markNodeKeys = markNodeMap.get(id);
              markNodeKeysToIDs.set(key, ids);

              if (mutation === "destroyed") {
                if (markNodeKeys !== undefined) {
                  markNodeKeys.delete(key);
                  if (markNodeKeys.size === 0) {
                    markNodeMap.delete(id);
                  }
                }
              } else {
                if (markNodeKeys === undefined) {
                  markNodeKeys = new Set();
                  markNodeMap.set(id, markNodeKeys);
                }
                if (!markNodeKeys.has(key)) {
                  markNodeKeys.add(key);
                }
              }
            }

            const element = editor.getElementByKey(key);

            applyClassesForAnnoColor({
              element,
              latestAnnoDataRef,
              ids,
              node
            })

            if (
              // Updated might be a move, so that might mean a new DOM element
              // is created. In this case, we need to add an event listener too.
              (mutation === "created" || mutation === "updated") &&
              $isMarkNode(node) &&
              element !== null &&
              !registeredElements.has(element)
            ) {
              registeredElements.add(element);
                
              for (let i = 0; i < ids.length; i++){
                element.id = `${ids[i]}`;
              }

              element.addEventListener("mouseover", (e) => {
                if (ids?.length > 0) {
                  setHoveredAnnoDataIDs(ids);
                }
              });
              element.addEventListener("mouseout", (e) =>
                resetHoveredAnnoDataIDs((prev) =>
                  prev.length === 0 ? prev : []
                )
              );
            }
          }

          //set the annoData state which in turn
          //triggers the parentOnChange of LexicalTextEditor
          handleUpdateFragmentsInAnnoData(
            constructAndInjectLexicalValueForEachAnnoDataId(
              Object.fromEntries(markNodeMap), // convert marknodemap into serialized editorstate jsons and save to annodata
            )
          );
        });
      }), // END registerMutationListener on MarkNode
      /**
       * depending on where the cursor currently is,
       * it figures out what is the MarkNode under it,
       * and what markIDs does it have. With this info,
       * we can now highlight all the MarkNods that have
       * these markIDs.
       */
      editor.registerUpdateListener(({ editorState, tags }) => {
        editorState.read(() => {
          const selection = $getSelection();
          let hasActiveIds = false;
          //   let hasAnchorKey = false;

          if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode();

            if ($isTextNode(anchorNode)) {
              const annoDataIDs = $getMarkIDs(
                anchorNode,
                selection.anchor.offset
              );
              if (annoDataIDs !== null) {
                setActiveAnnoDataIDs(annoDataIDs);
                hasActiveIds = true;
              }
              //   if (!selection.isCollapsed()) {
              //     setActiveAnchorKey(anchorNode.getKey());
              //     hasAnchorKey = true;
              //   }
            }
          }
          if (!hasActiveIds) {
            resetActiveAnnoDataIDs();
          }
          //   if (!hasAnchorKey) {
          //     setActiveAnchorKey(null);
          //   }
        });
      }),
      //below command is dispatched by the Anno button in the toolbar : ../ToolbarPlugin/AnnoTool/index.js
      editor.registerCommand(
        SHOW_ANNO_LIGHTBOX_COMMAND,
        () => {
          setShowAnnoLightbox({
            position: getAnnoLightboxPosition({ editor }),
          });

          return true;
        },
        COMMAND_PRIORITY_EDITOR
      )
    );
  }, [editor, markNodeMap]);

  return (
    <>
      {showAnnoLightbox && (
        <AnnoLightbox
          data={showAnnoLightbox.data}
          setData={({ tagType, value }) => {
            setShowAnnoLightbox((prev) => {
              const newData = prev.data ? cloneDeep(prev.data) : {};
              if (value?.data.length) newData[tagType] = value;
              else delete newData[tagType];
              return {
                ...prev,
                data: newData,
              };
            });
          }}
          nodeKeys={showAnnoLightbox.nodeKeys}
          dataId={showAnnoLightbox.dataId}
          mode={showAnnoLightbox.mode}
          activeData_with_relatedNodeKeys={
            showAnnoLightbox.activeData_with_relatedNodeKeys
          } //only required if mode = display
          setEditMode={({ data, dataId, nodeKeys }) => {
            setShowAnnoLightbox((prev) => ({
              ...prev,
              mode: "edit",
              data,
              dataId,
              nodeKeys,
            }));
          }}
          position={showAnnoLightbox.position}
          handleClickOutside={() => setShowAnnoLightbox(undefined)}
          annoConfig={annotation}
          readOnly={readOnly}
          handleCancel={() => setShowAnnoLightbox(undefined)}
          handleConfirmEdit={({ data, dataId }) => {
            handleSetAnnoData({ type: "tags", data, dataId });
            setShowAnnoLightbox(undefined);
          }}
          handleRemove={({ nodeKeys, dataId: targetDataId }) => {
            editor.update(() => {
              /*
                these are all the mark nodes that need to be changed. by change we mean:
                1// either remove it completely IF this mark node has a single dataId behind it that is equal to the targetDataId
                2// else if this node has multiple dataIds behind it, then remove this dataId from the array of dataIds behind this node
                */
              const markNodesToChange = nodeKeys.map((key) => {
                const node = $getNodeByKey(key);
                return { nodeKey: key, node, dataIds: $getMarkIDs(node) };
              });
              const markNodesToUnwrap = markNodesToChange.filter(
                (d) => d.dataIds.length === 1 && d.dataIds[0] === targetDataId
              );
              const markNodesToEdit = markNodesToChange.filter(
                (d) => d.dataIds.length > 1 && d.dataIds.includes(targetDataId)
              );

              // console.log({markNodesToUnwrap, markNodesToEdit})

              markNodesToUnwrap.forEach(({ node }) => {
                $unwrapMarkNode(node);
              });

              markNodesToEdit.forEach(({ node, nodeKey }) => {
                node.deleteID(targetDataId);
                /**
                 * .deleteId somehow does not trigger a related
                 * 'updated' listen inside the mutationListener above.
                 *
                 * Hence here only we remove this dataId from the
                 * markNodeMap. (typically this work is done in the mutation listener above)
                 * Else strange bugs will occur
                 */
                markNodeMap.delete(targetDataId);
              });
              setShowAnnoLightbox(undefined);
              //   //this should be enough, since this will instantly trigger handleUpdateFragmentsInAnnoData, and that
              //   //will mark the annoData related to the nodes we just deleted as 'IS_UNUSED'
            });
          }}
          handleConfirmCreate={
            // read what targetSelection is, and why its needed inside AnnoLightbox file
            ({ data, targetSelection }) => {
              handleCreateAnnotation({
                data,
                targetSelection,
                editor,
                handleSetAnnoData,
                callback: () => setShowAnnoLightbox(undefined),
              });
            }

            // insertAnno(
            //   {
            //     value: { tags: data.data },
            //     editorState,
            //     onEditorChange: (k, v) => handleChange(v),
            //   },
            //   () => setShowAnnoLightbox(undefined)
            // )
          }
        />
      )}
    </>
  );
};
