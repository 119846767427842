import React , { useState } from "react";
import { colors2, UI_CAPTION_SEMIBOLD, icons } from "oolib";
import { StyledButton, StyledIconWrapper } from "../styled";
import { TagList } from "./TagList";
import { valueNotFalsyOrEmptyAryObj } from "@/utils/validation/validatorFns";

const { CaretUp } = icons;

export const TagSectionOnCard = ({ annoTags, tags, annoTagsTitle, tagsTitle }) => {

    const [show, setShow] = useState(false)

    const annoTagsExist = valueNotFalsyOrEmptyAryObj(annoTags);
    const tagsExist = valueNotFalsyOrEmptyAryObj(tags);
    // this means that if both annotags and tags are either falsy or empty obj then render nothing
    if(!tagsExist && !annoTagsExist) return null;

    return (
    <div style={{ borderTop: `1px solid ${colors2.grey10}`, paddingTop: '0.2rem', width: 'inherit' }}>
      <div style={{ paddingLeft: '0.2rem', paddingRight: '0.2rem' }}>
          <StyledButton onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setShow(prev => !prev)
              }}>
                    <UI_CAPTION_SEMIBOLD style={{ color: colors2.grey80 }}>
                        Show Tags
                    </UI_CAPTION_SEMIBOLD>

                    <StyledIconWrapper show={show} >
                        <CaretUp color={colors2.grey40} size={16}/>
                    </StyledIconWrapper>
          </StyledButton>
      </div>
      <div
        style={{
          paddingTop: show ? '1rem' : '0.2rem',
          paddingBottom: show ? '1rem' : 0,
          height: show ? 'auto' : 0,
          overflow: 'hidden',
          display: "grid",
          width: "inherit",
          gridTemplateColumns: "minmax(0, 1fr) minmax(0, 1fr)",
          gap: "1rem",
          paddingLeft: "1.6rem",
          paddingRight: "1.6rem"
        }}
      > 
        {annoTagsExist && <TagList tags={annoTags} title={annoTagsTitle} />}
        {tagsExist && <TagList tags={tags} title={tagsTitle} />}
      </div>
    </div>
  )};