import { colors2, Container, PaddingTopBottom20, SkeletonLoader, UI_BODY_SEMIBOLD_SM } from "oolib";
import React from "react";
import { MasonryCardGridShell } from "../MasonryCardGridShell";

import { CardAnnoExplorer } from "../CardAnnoExplorer";
import { getAnnoExplorerConfigsForThisTenant } from "../../utils/getAnnoExplorerConfigsForThisTenant";
import { BarGraphCollection } from "../BarGraphCollection";
import { useExtractAnnoTagStats } from "../../utils/extractAnnoTagStats";
import { applyTagsWhitelistConfig } from "../../utils/applyTagsWhitelistConfig";
import { ColorCodeDisplay, StyledStatusWrapper, StyledWrapper } from "../ColorCodeDisplay";

export const ListingModuleAnnotationExplorer = ({
  data,
  dataFetchStatus,
  dataFetchError,
  observerRef,
  totalCount,
  breakDown, 
  setBreakDown,
  appliedColorConfig,
  isMobile,
  activeColorFilter
}) => {
  const annoExplorerConfigs = getAnnoExplorerConfigsForThisTenant()
  const reshapedDataForBarGraph = useExtractAnnoTagStats(data)

  return (
    <Container
      style={{ background: colors2.grey2, padding: "2rem" }}
    >
        {
          (!data && !annoExplorerConfigs?.tempDisableBarGraph) 
          ? 
          <SkeletonLoader style={{ width: "100%", height: "250px", borderRadius: "0.8rem", marginBottom: "1rem" }} />
          : 
          (!annoExplorerConfigs?.tempDisableBarGraph) && ( <BarGraphCollection data={reshapedDataForBarGraph} />)
        }

        {dataFetchStatus !== "loading" 
          && 
          <ColorCodeDisplay
            isMobile={isMobile} 
            breakDown={breakDown} 
            setBreakDown={setBreakDown}
            appliedColorConfig={appliedColorConfig}
            totalCount={totalCount}
            activeColorFilter={activeColorFilter} 
          />
        } 

      <div>
        { (!data && dataFetchStatus === "loading" && !annoExplorerConfigs?.disableTotalCount) ? 
          <SkeletonLoader style={{ width: "100%", height: "50px", borderRadius: "0.8rem", marginBottom: "1rem" }} /> 
          :
          (data && !annoExplorerConfigs?.disableTotalCount && !breakDown?.activeColorConfig?.tags?.length) && (
          <StyledWrapper isMobile={isMobile}>
            <StyledStatusWrapper>
              <UI_BODY_SEMIBOLD_SM>{`Annotations Showing: ${totalCount}`}</UI_BODY_SEMIBOLD_SM> 
            </StyledStatusWrapper>
          </StyledWrapper>
        )}

        <MasonryCardGridShell
          dataFetchStatus={dataFetchStatus} //meaning, if the core data has already successfully loaded, then regard the fetchFilteredDataStatus as the dataFetchStatus
          dataFetchError={dataFetchError}
          data={data}
          renderCard={(cols, colI, singleAnno, singleAnnoI, thisAry) => (
            <CardAnnoExplorer
              cardConfigs={annoExplorerConfigs?.cardConfigs}
              key={singleAnno.annoKey}
              singleAnno={singleAnno}
              lastCardObserverRef={
                singleAnnoI === thisAry.length - 1 && colI === cols - 1
                  ? observerRef
                  : null
              }
            />
          )}
        />
      </div>
    </Container>
  );
};
