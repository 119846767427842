import axios from "axios"
import { __GetContentTypeConfigNew } from "../utils/getters/gettersV2";




export const validationPlugin = async ({value, passValidationErrorToFormValidation, content}) => {
    try{
        const contentType = content.meta.kp_content_type;
        const contentTypesWithDuplicationCheck = ["newsReports", "proceedings"];
        if(!contentTypesWithDuplicationCheck.includes(contentType)) return null;

        const excludeIds = [content._id];

        /** below two lines is to ignore this 'no duplicate' rule between clones of the same document. */
        const ogDocId = content.kp_pre_mod_doc || content.kp_og_published_doc; //if this exists, it suggests this doc is either an under mod clone or an edit pbl clone
        if(ogDocId) excludeIds.push(ogDocId);
        
        const sanitizeUrl = (url) => {
            return url
                .replace(/^https?:\/\//, "") // Remove http/https
                .replace(/\/$/, "") // Remove trailing slash if any
                .replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // escape special regex characters
        };
        
        const urlPattern = `^(?:https?:\/\/)?${sanitizeUrl(value)}\\/?$`; // to match with or without protocol
        const configs = {
            findQuery: {
                'main.link.url': {
                    // has a document with link that matches the link being shared in THIS document
                    $regex: urlPattern,
                    $options: 'i'
                },
                kp_published_status: 'published', //should be published
                _id: { $nin: excludeIds } //shouldnt be THIS doc and its clones
            },
            projection: {
                _id: 1,
            },
            limit: 1,
            contentTypes: [contentType]
        }
        const res = await axios.get(`/api/discovery/getData`, {
            params: { configs: JSON.stringify(configs) }
        })


        let foundDuplicates = res.data?.data || [];
        const contentTypeDisplay = __GetContentTypeConfigNew(contentType)?.general?.content?.singular || "";
        if(foundDuplicates?.length > 0){
            let errorObj = {
                type: 'error', 
                msg: `This ${contentTypeDisplay} report has been published before.`, 
                link: { 
                    text: 'View Duplicate', 
                    to: `${process.env.NODE_ENV === 'production'
                        ? `${window.location.protocol}//${window.location.hostname}`
                        : 'http://localhost:3000'}/published-page/${contentType}?id=${foundDuplicates[0]._id}`
                }
            }
            if(passValidationErrorToFormValidation) passValidationErrorToFormValidation(errorObj.type)
            return errorObj
        }
        //else
        return undefined
        // return res;
    }catch(err){
        console.log({err})
    }
}