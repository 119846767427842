import { colors2, icons, UI_BODY_SEMIBOLD, UI_BODY_SEMIBOLD_SM, UI_BODY_SM, UI_TAG } from 'oolib';
import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';

const { grey5, secondaryContainer, grey40, grey80, grey60, grey30 } = colors2;
const { CaretDown, File } = icons;

const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
`;

const DropdownButton = styled.button`
  width: 100%;
  display: flex;
  height: 36px;
  padding: 0.9rem 1rem;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #E7E7E7;
  background: #FFF;
  cursor: pointer;
  &:hover {
    background-color: ${grey5};
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  z-index: 2000;
  width: 100%;
  margin-top: 0.25rem;
  background-color: white;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 20rem;
  overflow-y: auto;
  padding: 1rem;
  margin-bottom: 2rem;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transform: ${({ isOpen }) => (isOpen ? 'translateY(0)' : 'translateY(-10px)')};
  transition: opacity 0.3s ease, transform 0.3s ease;
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')}; /* Prevent clicks when closed */
`;



const OptionButton = styled.div`
  width: 100%;
  text-align: left;
  padding: 0.75rem 1rem;
  background-color: ${({ isSelected, theme : { colors } }) => (isSelected ? secondaryContainer : 'white')};
  color: ${({ isSelected }) => (isSelected ? grey80 : grey60 )};

  cursor: pointer;
  &:hover {
    background-color: ${secondaryContainer};
  }
`;

export const DropdownGroup = ({ id, options, onChange, value }) => {
  console.log({ options, value })
  const [ isOpen, setIsOpen] = useState(false);
  const [ selectedOption, setSelectedOption] = useState(value);  
  const handleToggle = () => setIsOpen(!isOpen);

  const theme = useTheme()
  const handleSelect = (option) => {
    setSelectedOption(option);
    onChange(id, option);
    setIsOpen(false);
  };

  return (
    <DropdownContainer>
      <DropdownButton onClick={handleToggle} aria-haspopup="true" aria-expanded={isOpen}>
        <div style={{ display: "flex", gap: "1rem" }}>
          {/* <File size={20} /> */}
          <UI_BODY_SM capitalize style={{ color: value ? colors2.black : colors2.grey60 }}>{selectedOption?.display || "Select Filter"}</UI_BODY_SM>
        </div>
          <CaretDown size={20} style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)', transition: "transform 0.2s" }}/>
      </DropdownButton>

      <DropdownMenu isOpen={isOpen}>
        {options.map((group, groupIndex) => (
          <div key={groupIndex}>
            <UI_TAG style={{ color: grey30, padding: "0.5rem" }}>{group.title}</UI_TAG>
            {group.options?.map((option, optionIndex) => (
              <OptionButton
                key={options.filterId}
                id={options.filterId}
                theme={theme}
                isSelected={option?.value === selectedOption?.value}
                onClick={() => handleSelect(option)}
              >
                <UI_BODY_SEMIBOLD_SM>{option.display}</UI_BODY_SEMIBOLD_SM>
              </OptionButton>
            ))}
          </div>
        ))}
      </DropdownMenu>
    </DropdownContainer>
  );
};
