import React, { useEffect, useState } from "react";

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { createCommand, COMMAND_PRIORITY_EDITOR, $getNodeByKey } from "lexical";
import {
  icons,
  SkeletonLoader,
  UI_BODY_SEMIBOLD,
  useBannerContext,
} from "oolib";

import { findRange, getAllText, getSelectionRange } from "./utils";
import {
  $wrapSelectionInMarkNode,
  $unwrapMarkNode,
  $getMarkIDs,
} from "@lexical/mark";
import { useAnnoContextLex } from "../AnnoPlugin/context";
import { useGetSuggestions } from "./hooks";
import { SidebarContainerStyled, SidebarWrapperStyled } from "./styled";

// import data from "./data.json";
import { Cards } from "./components/card";

import { ButtonGhost } from "oolib/dist/v2/components/Buttons";
import { getMarkNodeMap } from "../AnnoPlugin";


const { Stars } = icons;

export const SHOW_ANNO_SUGGESTIONS = createCommand("SHOW_ANNO_SUGGESTIONS");

export const AnnoSuggestionsPlugin = ({
  annotation,
  fieldValuePath,
  _id,
  contentType,
}) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [editor] = useLexicalComposerContext();
  const { handleSetAnnoData } = useAnnoContextLex();

  const markNodeMap = getMarkNodeMap(editor);

  const { SET_ALERT_BANNER } = useBannerContext();

  const tagCategory = annotation.tagTypesConfig.map((d) => d.tagType);

  const { data: suggestionsData, status } = useGetSuggestions({
    text: getAllText(editor),
    tagCategory,
    fieldValuePath,
    _id,
    contentType,
    enabled: showSidebar,
  });

  useEffect(
    () =>
      editor.registerCommand(
        SHOW_ANNO_SUGGESTIONS,
        () => {
          setShowSidebar(true);
        },
        COMMAND_PRIORITY_EDITOR
      ),
    []
  );

  useEffect(() => {
    if (status === "success" && suggestionsData) {
      setSuggestions(suggestionsData);
    }
  }, [status]);

  //TODO: optimization
  const handleApply = ({ text, tagsData, _id }) => {
    try {
      const { startNodeKey, endNodeKey, startOffset, endOffset } = findRange(
        editor,
        text
      );

      editor.update(() => {
        const selection = getSelectionRange({
          startNodeKey,
          endNodeKey,
          startOffset,
          endOffset,
        });

        const data = {};

        tagsData.forEach(({ tagCategory, tags }) => {
          data[tagCategory] = { collectionId: tagCategory, data: tags };
        });

        $wrapSelectionInMarkNode(selection, selection.isBackward, _id);
        handleSetAnnoData({
          type: "tags",
          data,
          dataId: _id,
          disableParentOnChange: true,
        });
      });
    } catch (error) {
      SET_ALERT_BANNER(error.message, "red", 3000);
    }
  };

  const handleRemove = ({ _id }) => {
    try {
      editor.update(() => {
        const nodeKeys = markNodeMap.get(_id);

        if (!nodeKeys) throw new Error("node found in the mark node map");

        const markNodesToChange = [];

        nodeKeys.forEach((key) => {
          const node = $getNodeByKey(key);
          markNodesToChange.push({
            nodeKey: key,
            node,
            dataIds: $getMarkIDs(node),
          });
        });

        const markNodesToUnwrap = markNodesToChange.filter(
          (d) => d.dataIds.length === 1 && d.dataIds[0] === _id
        );
        const markNodesToEdit = markNodesToChange.filter(
          (d) => d.dataIds.length > 1 && d.dataIds.includes(_id)
        );

        markNodesToUnwrap.forEach(({ node }) => {
          $unwrapMarkNode(node);
        });
        markNodesToEdit.forEach(({ node, nodeKey }) => {
          node.deleteID(_id);

          markNodeMap.delete(_id);
        });
      });
    } catch (error) {
      SET_ALERT_BANNER("failed to remove!", "red", 3000);
    }
  };

  return showSidebar ? (
    <SidebarContainerStyled
      initial={{ width: 0, opacity: 1, height: "100%" }}
      animate={{ width: 350 }}
      transition={{ type: "tween" }}
      exit={{ width: 0, opacity: 0 }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "0.6rem",
            alignItems: "center",
            padding: "0.2rem 0rem",
          }}
        >
          <Stars size="16" />

          <UI_BODY_SEMIBOLD>
            {" "}
            Annotation Suggestions{" "}
            {`${suggestions.length ? `(${suggestions.length})` : ""}`}
          </UI_BODY_SEMIBOLD>
        </div>

        <ButtonGhost
          S
          iconSize={"S"}
          icon="X"
          onClick={() => setShowSidebar(false)}
        />

      </div>

      <SidebarWrapperStyled>
        {status === "loading" ? (
          <>
            <SkeletonLoader
              style={{ width: "100%", height: "25rem", borderRadius: "0.8rem" }}
            />
            <SkeletonLoader
              style={{ width: "100%", height: "25rem", borderRadius: "0.8rem" }}
            />
            <SkeletonLoader
              style={{ width: "100%", height: "25rem", borderRadius: "0.8rem" }}
            />
            <SkeletonLoader
              style={{ width: "100%", height: "25rem", borderRadius: "0.8rem" }}
            />
          </>
        ) : (
          <div
            style={{
              marginBottom: "5rem",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            {suggestions.map((d) => (
              <Cards
                {...d}
                key={d._id}
                applied={markNodeMap.get(d._id)}
                handleApply={handleApply}
                handleRemove={handleRemove}
              />
            ))}
          </div>
        )}
      </SidebarWrapperStyled>
    </SidebarContainerStyled>
  ) : null;
};
