import { ButtonGhost, colors, colors2, SANS_3 } from "oolib";
import React, { Fragment, useEffect, useRef, useState } from "react";

const useFreezePageBehind = (innerHeight) => {
  useEffect(() => {
    // Store original values
    const originalStyle = window.getComputedStyle(document.getElementById('root'));
    const originalOverflow = originalStyle.overflow;
    const originalPosition = originalStyle.position;
    const originalWidth = originalStyle.width;
    const originalHeight = originalStyle.height;
    // const originalTop = document.body.style.top;

    // Apply multiple techniques to prevent scrolling
    
    // document.body.style.width = "100%";
    document.documentElement.style.overflow = "hidden";
    document.documentElement.style.position = "fixed";
    document.documentElement.style.height = innerHeight+'px';
    document.getElementById('root').style.overflow = "hidden";
    document.getElementById('root').style.position = "fixed";
    document.getElementById('root').style.height = innerHeight+'px';
    document.body.style.overflow = "hidden";
    document.body.style.position = "fixed";
    document.body.style.height = innerHeight+'px';
    // document.body.style.top = `-${scrollY}px`;

    // Touch events backup for iOS
    //   const preventDefault = (e) => e.preventDefault();
    //   document.addEventListener('touchmove', preventDefault, { passive: false });

    return () => {
      // this ensure that when the dropdown is closed,
      // the 'scrollto' (later on in the code), instantly takes the user to their scroll position
      // rather than having a scroll animation which is disorienting
      document.documentElement.style.scrollBehavior = "auto";
      document.body.style.scrollBehavior = "auto";

      // Restore original styles
      document.documentElement.style.overflow = 'unset';
      document.documentElement.style.position = 'unset';
      document.documentElement.style.height = 'unset';
      document.getElementById('root').style.overflow = 'unset';
      document.getElementById('root').style.position = 'unset';
      document.getElementById('root').style.height = 'unset';
      document.body.style.overflow = 'unset';
      document.body.style.position = 'unset';
      document.body.style.height = 'unset';
      // document.body.style.width = originalWidth;
      
      // document.body.style.top = originalTop;

     

      // Remove touch event listener
      // document.removeEventListener('touchmove', preventDefault);
    };
  }, [innerHeight]);
};

export const useTouchInnerHeight = () => {
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleTouch = () => {
      setTimeout(() => {
        setInnerHeight(window.visualViewport.height);
      }, 1000); // Delay to ensure viewport resize is completed
    };

    window.addEventListener("touchstart", handleTouch);
    window.addEventListener("touchmove", handleTouch);
    window.addEventListener("touchend", handleTouch);
    window.addEventListener("focusin", handleTouch);
    window.addEventListener("focusout", handleTouch);
    //   window.visualViewport?.addEventListener("resize", handleTouch);

    return () => {
      window.removeEventListener("touchstart", handleTouch);
      window.removeEventListener("touchmove", handleTouch);
      window.removeEventListener("touchend", handleTouch);
      window.removeEventListener("focusin", handleTouch);
      window.removeEventListener("focusout", handleTouch);
      // window.visualViewport?.removeEventListener("resize", handleTouch);
    };
  }, []);

  return innerHeight;
};

const useShutLightboxOnBrowserBack = (options = {}) => {
  useEffect(() => {
    window.history.pushState(null, '', window.location.href);
    
    const handlePopState = () => {
      options.onClose();
      window.history.pushState(null, '', window.location.href);
    };
    
    window.addEventListener('popstate', handlePopState);
    
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);
}

export const MobileShell = ({ children, onClose, title }) => {
  
  const scrollRef = useRef(0);

  /**
   * we capture current scroll pos before freezepagebehind because that
   * reduces pages height and destroys the current page scroll
   */
  useEffect(() => {
    // Get current scroll position
    scrollRef.current = window.scrollY;
  },[])

  const innerHeight = useTouchInnerHeight();

  useFreezePageBehind(innerHeight);

  /**
   * we restore pagescroll after freezepagebehind unloads because, that would
   * restore the original length of page, and therefore the scrollto will
   * work properly.
   */
  useEffect(() => {
    
    return () => {
      // Restore scroll position
      window.scrollTo({left:0, top: scrollRef.current, behavior: 'instant'});
    }
  },[])


  useShutLightboxOnBrowserBack({onClose})

  //  useEffect(() => {
  //     document.body.style.overflow = "hidden";
  //     return () => { document.body.style.overflow = "unset" };
  //   }, []);

  return (
    <Fragment>
      {/* <div style={{position:'fixed', top: '0', left: 0, fontSize: '20px', color: 'red', zIndex:100000000000000}}>
           <SANS_3>{innerHeight}</SANS_3>
        </div> */}
      <div
        style={{
          top: 0,
          left: 0,
          height: innerHeight + "px",
          width: "100dvw",
          position: "fixed",
          zIndex: "100000000",
          background: "rgba(0,0,0,0.9)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <div
          style={{
            // maxHeight: (0.9 * innerHeight) + "px", // ---> dynamic. we maybe wanna get to this eventually, but causes a lot of jumpyness when search bar is on top
            height: (0.9 * innerHeight) + "px", //temp but dependable.
            background: "white",
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <div
            style={{
              background: colors.greyColor5,
              zIndex: 10,
              position: "sticky",
              top: 0,
              padding: "0.5rem 2rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SANS_3 semibold>{title}</SANS_3>
            <ButtonGhost icon="X" onClick={onClose} />
          </div>
          <div
          style={{
            overflow: "auto",
            flexGrow: 1
          }}
          >
          {children}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
