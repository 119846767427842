import React, { useEffect, useRef, useState } from "react";

import { colors2, getBreakPoint, toArray, useScreenWidth } from "oolib";
import FilterModule from "../../components/discovery/FilterModule";

import { useGetDataLazy } from "../../utils/react-query-hooks/getData";
import { useGetAllUsersLazy } from '../../utils/react-query-hooks/users/useGetAllUsersLazy';

import { useDebounce } from "../../utils/customHooks";
import ListingModule from "./comps/ListingModule";


import { useAppSettingsContext } from "../../contexts/appSettingsContext";
import { trackEventGA } from "../../trackers/GA/trackEventGA";

import ErrorStates from "../../components/ErrorStates";

import { buildListingPageQueryForKS } from "../../utils/okf-ks-utils/buildListingPageQueryForKS";
import { valueNotFalsyOrEmptyAryObj } from "../../utils/validation/validatorFns";
import { useExtractInitBackendSortFromTableConfig } from "./utils/useExtractInitBackendSortFromTableConfig";
import { defaultListingConfigForCardOrList } from "../PublishedListing/config/defaultListingConfigForCardOrList";
import { genBaseTableConfig } from "../PublishedListing/config/genBaseTableConfig";

import { checkAccessToDownloadCsv } from "./utils/checkAccessToDownloadCsv";
import { queryClient } from "../..";
import { useGetExportToCsvContent } from "../../utils/react-query-hooks/exportToCsv";
import { __GetContentTypeConfigNew } from "../../utils/getters/gettersV2";
import { ActionsPanel } from "./newComps/ActionsPanel";
import { DESKTOP_SIDEBAR_WIDTH, MOBILE_SIDEBAR_WIDTH, Shell } from "./newComps/Shell";
import { mergeCsvExportArraysFromContentTypes } from "../../utils/general";
import { FilterModuleHeader } from "./newComps/Shell/comps/FiltersSidebarShell/comps/FilterModuleHeader";
import { getSessionStorageDataForListingPage, getSessionStorageKeyForListingPage } from "./newUtils/sessionStorageUtils";



export const ListingRenderer = ({
  contentTypes,
  configs = {},
  presetActiveFilters = [],
  /*
  collectionId is IMPORTANT. its passed down from groupsStyle1 layout. it is used to add a unique identifier 
  to querykey for data fetching, and session storage key for remembering filters. 
  this way, we dont have random bugs like same filters getting applied to different collection pages
  */
  collectionId, 
  showCount = true,
  broadcastTotalCount, // fn that captures the totalcount for parent. used in linkormebedmodallex
  showSearchInput : _showSearchInput = true, //sometimes, we want to handle the search input UI externally and trigger keyword search config from there. In such cases, we want to disable the native SearchInput design. eg LinkOrEmbedModalLex
  searchTermInputValue: searchTermInputValueFromParent, // in some cases we allow the parent to define its own searchinput ui, and control the searchterminputvalue from there. eg LinkOrEmbedModalLex
  sectionTitle = undefined, //sometimes if we are not showing the cover, we might want a section title instead
  sectionLink = undefined,
  enableLazyLoad = true,
  ListingWrapper,
  enableContainerQuery,
  inTCI,
  builderProps,
  listingExport,

  actionBarHeight,tabsPanelHeight,actionRef,isMobile,setShowSideBarLeft,showSideBarLeft,
  stickyTitleHeight, setStickyTitleHeight,
  coverConfig
}) => {
  const {
    sortConfig,
    getDataQuery = 'useGetDataLazy',
    getDataConfig = {},
    filterConfig: {
      filters,
      filterModuleOrientation
    } = {},
    listingStyle = "CardsStyle",
    listingConfig: _listingConfig,
    keywordSearchConfig,
    tagCategory,
    tag
  } = configs;

  let listingConfig =
    _listingConfig ||
    (
      listingStyle === "TableStyle" 
      ? genBaseTableConfig(contentTypes[0])
      : ['CardsStyle', 'ListStyle'].includes(listingStyle)
        && defaultListingConfigForCardOrList
    ) ||
    {};

  const { APP_SETTINGS } = useAppSettingsContext();

  const lang = APP_SETTINGS.lang;
  // const newListingConfigs = cloneDeep(listingConfig);

  // if (lang.value !== "en") {
  //   newListingConfigs.configs.title = [`translations.${lang.value}.main.title`];
  //   newListingConfigs.configs.subtitle = [
  //     `translations.${lang.value}.main.subtitle`,
  //   ];
  // }

  // listingConfig = newListingConfigs;

  /**
   * we are defaulting find query to fetch only published pieces, but
   * it can be overwritten by the findQuery prop, if one chooses to.
   */
  const findQuery = {
    kp_published_status: "published",
    ...(getDataConfig.findQuery || {}),
  };
  let { data : userData } = queryClient.getQueryState('userData')

  //session storage
  const sessionStorageKey = getSessionStorageKeyForListingPage({contentTypes, collectionId});
  const sessionData = getSessionStorageDataForListingPage(sessionStorageKey);

  //local states
  const [activeFilters, setActiveFilters] = useState(
    sessionData?.activeFilters || []
  );

  const [searchTermInputText, setSearchTermInputText] = useState(
    sessionData?.searchTerm || ""
  );
  const [_searchTermInputValue, setSearchTermInputValue] = useState(
    sessionData?.searchTerm || ""
  );
  // in some cases we allow the parent to define its own searchinput ui, and control the searchterminputvalue from there. eg LinkOrEmbedModalLex
  const searchTermInputValue = searchTermInputValueFromParent || _searchTermInputValue;

  const [searchInUse, setSearchInUse] = useState(false);

  const [activeSort, setActiveSort] = useExtractInitBackendSortFromTableConfig({
    tableConfig: listingConfig?.tableConfig,
    enabled: listingStyle === "TableStyle",
    fallbackIfDisabled: sortConfig && sortConfig[0],
  });

  /*
  reset active filters, if the content types being rendered by this listing page changes
  (happens when you shift from one page using ListingPage component to another page using ListingPage component)
  */
  useEffect(() => {
    setActiveFilters(sessionData?.activeFilters || [])
    setActiveSort(sortConfig?.[0] || activeSort);
  },[contentTypes])

  //update session storage
  useEffect(() => {
    const toSet = {
      searchTerm: searchTermInputValue || "",
      activeFilters: (activeFilters?.length > 0 && activeFilters) || [],
    };
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(toSet));
  }, [activeFilters, searchTermInputValue]);

  //refs
  const searchSortBarRef = useRef(null);
  const totalWidthRef = useRef(null);

  //custom hooks
  const debounce = useDebounce();
  const screenWidth = useScreenWidth();
  const isDesktop = screenWidth >= getBreakPoint("md");

  const getDataQueryLookup = {
    useGetDataLazy,
    useGetAllUsersLazy
  }

  const {
    error:contentError,
    data: contentData,
    status: contentStatus,
    fetchNextPage: contentFetchNextPage,
    hasNextPage,
    isFetchingNextPage: contentIsFetchingNextPage,
  } = getDataQueryLookup[getDataQuery](
    {
      activeFilters: [...presetActiveFilters, ...activeFilters], //presetActiveFilters are injected here, so that, even if filterModule 'clears' activeFilters, the presetFilters aren't cleared
      contentTypes,
      searchTerm: searchTermInputValue,
      activeSort: activeSort && activeSort.sort,
      ...getDataConfig,
      findQuery,
      collectionId,
      // enabled: keywordSearchConfig ? false : true,
      ksConfig:
        searchTermInputValue &&
        buildListingPageQueryForKS({
          keyword: searchTermInputValue,
          config: { fields: keywordSearchConfig.fields },
          activeSort: activeSort?.sort,
        }),
      /**
       * collectionId is only relevant in order to give a unique query key (react query)
       * to the related content that might be fetched against a given collection page
       */
    },
    {
      onSuccess: () => {},
      onError: (err) => {},
    }
  );


  //data export hooks --------

  const commonDataExportArgs = {
    activeFilters: [...presetActiveFilters, ...activeFilters], //presetActiveFilters are injected here, so that, even if filterModule 'clears' activeFilters, the presetFilters aren't cleared
    resourceType:contentTypes,
    ...getDataConfig,
    findQuery: searchTermInputValue ? {} : findQuery,
    populateTagsArray: mergeCsvExportArraysFromContentTypes({contentTypes,propertyPath: 'populateTagsArray'}),
    fieldsToSortAtEnd: mergeCsvExportArraysFromContentTypes({contentTypes, propertyPath:'fieldsToSortAtEnd'}),  
    enableUpdateExport: contentTypes?.some(type => __GetContentTypeConfigNew(type)?.general.csvExport?.enableUpdateExport) || false
  }
  
  const {
    refetch:csvRefetch
  } = useGetExportToCsvContent({...commonDataExportArgs});

  const {
    refetch:jsonRefetch
  } = useGetExportToCsvContent(
    {
      ...commonDataExportArgs,
      dataFormat: "json",  
    }
  );

  const getDataToBeExported = async ({dataFormat}) => {
    if(dataFormat === 'json'){
      const response = await jsonRefetch();
      return response
    }else if(dataFormat === 'csv'){
      const response = await csvRefetch();
      return response
    }
  }

  
  const totalCount = contentData?.pages[0]?.count
  useEffect(() => {
    if(totalCount !== undefined && broadcastTotalCount){
      broadcastTotalCount(totalCount)
    }
  },[totalCount])

  const handleSearch = (id, value) => {
    setSearchTermInputText(value);
    debounce(() => {
      trackEventGA("Search Flow","Listing Searched")
      setSearchTermInputValue(value)
    });
  };

  const showSortDropdown = sortConfig && listingStyle !== "TableStyle";
  const showSearchInput = _showSearchInput && !!keywordSearchConfig === true && !keywordSearchConfig.disabled
  const showFilters = valueNotFalsyOrEmptyAryObj(filters);
  
  const allMajorModules = {
    filter:  showFilters && (
      <FilterModule
        totalResult={totalCount}
        setActiveFilters={setActiveFilters}
        activeFilters={activeFilters}
        presetActiveFilters={presetActiveFilters}
        filterConfig={filters}
        contentTypes={contentTypes}
        usedIn={"ListingPage"}
        isMobile={isMobile}
        // actions={<div style={{height: '40rem', background: 'grey'}}></div>}
        topPanelHeight={tabsPanelHeight + actionBarHeight}
        mobileHeader={isMobile && <FilterModuleHeader
          style={{
            padding: '1.2rem 1.6rem',
            // height: '4rem',
            borderBottom: `1px solid ${colors2.grey10}`
          }}
          isMobile={isMobile}
          showSideBarLeft={showSideBarLeft}
          onClose={() => setShowSideBarLeft(false)}
          setActiveFilters={setActiveFilters}
          />}
        />
    ),
    contentConfig: {
      layout: listingStyle === "TableStyle" ? 'fullWidth' : "contained",
      content: contentError ? <ErrorStates errorResponseObject={contentError} /> : (
        <ListingModule
          status={contentStatus}
          data={contentData}
          emptyPageConfigSearch={searchTermInputValue && searchTermInputValue !== "" ? { // Check if searchTermInputValue is truthy
            emptyStatePreset: "searchListing",
            contentTypes: contentTypes,
            searchTerm: _searchTermInputValue,
            onClick: () => {
              setSearchTermInputText("");
              debounce(() => setSearchTermInputValue(""));
            },
          } : undefined } // Pass undefined when searchTermInputValue is falsy
          sectionTitle={sectionTitle}
          sectionLink={sectionLink}
          totalCount={showCount ? totalCount : undefined}
          listingStyle={listingStyle}
          listingConfig={listingConfig}
          fetchNextPage={enableLazyLoad ? contentFetchNextPage : () => {}} //fetchNextPage could be undefined if lazy load is disabled in listing page. so we make it a empty function as a fallback to avoid errors
          isFetchingNextPage={contentIsFetchingNextPage}
          activeSort={activeSort}
          setActiveSort={setActiveSort}
          hasNextPage={hasNextPage}
          ListingWrapper={ListingWrapper}
          enableContainerQuery={enableContainerQuery}
          contentTypes={contentTypes}
          inTCI={inTCI}
          builderProps={builderProps}
          getDataToBeExported={getDataToBeExported}
          hasAccessToDowloadCsv={(listingExport && !isMobile) && checkAccessToDownloadCsv({ resourceType: contentTypes[0] })}
          tagCategory={tagCategory}
          tag={tag}
          searchSortBarRef={searchSortBarRef} 
        />
      )
    }
  }

  const { filter, contentConfig} = allMajorModules;

  return (
    <>
     {(!!filter || !!showSortDropdown || !!showSearchInput) && (
          <ActionsPanel
            style={{
              zIndex: 101, // v.v.imp to have bg-image cover the listingmodule items on scroll
              ...(!coverConfig ? { backgroundColor: colors2.white} : {}) //because cover is what provides a bg to actionsPanel. in the absence of it we need to apply an explicit white bg
            }}
            tabsPanelHeight={tabsPanelHeight}
            actionRef={actionRef}
            filtersExist={!!filter}
            isMobile={isMobile}
            setShowSideBarLeft={setShowSideBarLeft}
            showSideBarLeft={showSideBarLeft}
            totalWidthRef={totalWidthRef}
            Wrapper={!!filter && "Wrapper1500"}
            stickyTitleHeight={stickyTitleHeight}
            setStickyTitleHeight={setStickyTitleHeight}
            showDownloadDataButton={listingExport && checkAccessToDownloadCsv({resourceType: contentTypes[0]})}
            getDataToBeExported={getDataToBeExported}
            
            //sort related
            showSortDropdown={showSortDropdown}
            activeSort={activeSort}
            setActiveSort={setActiveSort}
            sortConfig={sortConfig}

            //search related
            showSearchInput={showSearchInput}
            searchTermInputText={searchTermInputText}
            handleSearch={handleSearch}

            
          />
        )}
      <Shell
        {...{
          contentConfig,
          filter,
          isMobile,
          tabsPanelHeight,
          actionBarHeight,
          showSideBarLeft,
          setShowSideBarLeft,
        }}
      />
    </>
  );
};

