import React, { Fragment, useState } from "react";
import { useQueryClient } from 'react-query';

//actions

//sass //utils

//config
import { __GetAllContentTypeConfig, __GetContentTypeConfigNew, __GetProfileTypeConfig } from '../../../../../utils/getters/gettersV2';
import { useGetQueryData } from "../../../../../utils/react-query-hooks/general";
import { useGetContentListings } from "../../../../../utils/react-query-hooks/listings";
import { useGetParallelProfiles } from "../../../../../utils/react-query-hooks/profile";
//cards
import { ButtonPrimary, CardProfile, colors, ListContent, Loader, TabBarStyle1, useBannerContext } from 'oolib';




import { defaultProfileCardConfig } from "../../../../../pageTpls/ProfilesListing/configs/defaultProfileCardConfig";


const { primaryColor10, white } = colors; 
 
const KPInternalResSelect = ({
  onConfirm : parentOnConfirm,
  selectedAry : parentSelectedAry,
  onCloseModal,
  contentTypeEmbedOptions : prop_contentTypeEmbedOptions,
  profileTypeEmbedOptions : prop_profileTypeEmbedOptions,
  maxSelectLimit,
}) => { //col settings must be the bootstrap codes

  //--api call / fetch from store as per config
  //searchbar to search through list
  //dropdown to select specific content type or all
  //prop to set max limit of select
  //selected preview

  //--parent comp that allow to trigger this modal
  //--parent 'value' state var, which gets updated when selections here are confirmed
  //--appropriate cards rendered as per 'value'

  const {  _ProfileTypes } = useGetQueryData('platformConfigs');

  const {SET_INFO_BANNER} = useBannerContext();
  
  let queryClient = useQueryClient();

  const [selectedAry, setSelectedAry] = useState(parentSelectedAry || [])

  let toggleOps = [];

  let contentTypeEmbedOptions = prop_contentTypeEmbedOptions || __GetAllContentTypeConfig().map(contentType => contentType.kp_content_type).filter(id => id !== 'staticPages')
  let profileTypeEmbedOptions = _ProfileTypes.map(p => p.id).filter(d => d.category === 'userProfiles');
  // the filter above is temp, since only userprofile card embed work properly currently.
  // to find out why look at the comment in org profile card comp.

  const profileQueries = useGetParallelProfiles(profileTypeEmbedOptions)
  const contentQueries = useGetContentListings(
    contentTypeEmbedOptions.map(contentType => ({contentType, enabled: true}))
  )
  

  contentTypeEmbedOptions.map(contentType => {
    let { kp_content_type: contentTypeId, general : { content:{title}} } = __GetContentTypeConfigNew(contentType);
    toggleOps.push({value: contentTypeId, name: title})
  })
  //1  profiles embed
  if(profileTypeEmbedOptions){
    profileTypeEmbedOptions.map(profileType => {
      let { id : profileTypeId, content : {title} } = __GetProfileTypeConfig(profileType);
      toggleOps.push({value: profileTypeId, name: title})
    }) 
  }

  // toggleOps.push({value: 'userProfiles', name: 'User Profiles'})

  const [activeTab, setActiveTab] = useState(contentTypeEmbedOptions[0]);

  const onToggleBarChange = (value) => setActiveTab(value)

  const genToggleBar = (toggleOps) => (
    <TabBarStyle1
      className='kp-profile__content-types-toggle'
      options={ toggleOps }
      onChange={ (key, value) => onToggleBarChange(value) }
      />
  )

  const handleSetSelectedAry = (d) => {
    let newSelectedAry = [];
    if(selectedAry.some(datum => datum._id === d._id)){
      newSelectedAry = selectedAry.filter(datum => datum._id !== d._id)
    }else{
      if(selectedAry.length === (maxSelectLimit) ){
        
      SET_INFO_BANNER({color:"grey",msg:`you have already hit the maximum of ${maxSelectLimit} resources that you can embed.`,timeOut:3000})

        newSelectedAry = selectedAry;
      }else{
        newSelectedAry = [...selectedAry, d];
      }

    }
    setSelectedAry(newSelectedAry);
  }

  const handleConfirm = () => {
    parentOnConfirm({type: 'internal', selectedAry}); //unfortunately we have to call this key selectedAry, because thats the key being used in cards display and that is saved to db in the case of richeditor card embeds.
    onCloseModal()
  }
  


  const genContentList = () => {

    return (
      <Fragment>
      { toggleOps.length > 1 && genToggleBar(toggleOps) }
      { contentTypeEmbedOptions.map(contentType => {
        let { kp_content_type : contentTypeId } = __GetContentTypeConfigNew(contentType);
        if(contentTypeId === activeTab){
          let data = queryClient.getQueryData(['contentListing', contentTypeId]); 
          return (
          <Fragment>
            {data.map((d, i) =>  {
              
              let selectStyle = selectedAry.some(datum => datum._id === d._id) ? primaryColor10 : white
              return(
                <div style={{backgroundColor:selectStyle}}>
                <ListContent
                  onClick={() => handleSetSelectedAry(d)}
                  data={d}
                  config={{
                    title: 'main.title'
                  }}
                  />
                  </div>
              )
            })}
          </Fragment> )
        }

      })}
      
      { //3 profiles emb3d : LAST
      profileTypeEmbedOptions && profileTypeEmbedOptions.map(profileType => {
        let {  id : profileTypeId } = __GetProfileTypeConfig(profileType);
        if(profileTypeId === activeTab){
          return (
          <Fragment>
          { queryClient.getQueryData(['Profile', profileTypeId]).map(d => {
              return(

                <CardProfile
                  key={d._id}
                  data={d}
                  config={defaultProfileCardConfig}
                  />
              )
            }) }
          </Fragment> )
        }

      })}
      </Fragment>
    )
  }

  return (
      <Fragment>
        <div className='kp-internal-resource-select'>
         
        { contentQueries.some(q => q.status !== 'success') || profileQueries.some(q => q.status !== 'success')
        ? <Loader />
      : <Fragment>
          <div className='kp-internal-resource-select__resource-list' style={{padding : '6rem 0' /*to account for the ModalButtonGroup*/ }}>
          { genContentList() }
          
          </div>
          <div style={{position: 'sticky', bottom: 0, padding: '1rem 0', display: 'flex', justifyContent: 'center', backgroundColor: white}}>
            <ButtonPrimary 
              onClick = {() => handleConfirm()}
              children="Insert Link"

              />
            </div>
          
          {/*<div className='kp-internal-resource-select__insert-btn-wrapper'>
            <ButtonSecodary
              className='kp-internal-resource-select__insert-btn'
              disabled={selectedAry.length === 0}
              onClick={() => handleConfirm() }
              >
              Insert Link
            </ButtonSecodary>
          </div>*/}
        </Fragment> }
        </div>

      </Fragment>
  )
}

export default KPInternalResSelect
