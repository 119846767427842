import React, { useMemo } from "react";
import { colors2, UI_CAPTION_SEMIBOLD, UI_TAG, TagLinkV2 } from "oolib";
import { getProperTitleFromContentTypeId } from "../../../utils/getProperTitleFromContentTypeId";


export const TagList = ({ tags, title }) => {

  return (
    <div>
      <UI_TAG style={{ color: colors2.grey40 }}>{title}</UI_TAG>
      <div style={{ display: "flex", flexDirection: 'column', gap: '1.2rem', marginTop: '0.8rem' }}>
      {tags && Object.values(tags).map((d, index) => {
        if (!d?.data?.length) return null;
        
        return (
          <div key={`${d.collectionId}-${index}`}>
            <UI_CAPTION_SEMIBOLD 
              style={{ 
                paddingBottom: "0.2rem", 
                color: colors2.grey50 
              }}
            >
              {getProperTitleFromContentTypeId(d.collectionId)}
            </UI_CAPTION_SEMIBOLD>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "0.5rem",
              }}
            >
              {d.data.map((dd, idx) => {
                return (
                <div
                  style={{maxWidth: '100%'}}
                  key={`${dd.display}-${idx}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                > 
                <div className="tagwrapper">
                  <TagLinkV2
                    target={"_blank"}
                    size={'XS'}
                    display={dd.display}
                    to={`/published-page/${d.collectionId}?id=${dd._id}`}
                  />
                  {/* <UI_CAPTION_SEMIBOLD style={{ color: colors2.grey80 }}>{dd.display}</UI_CAPTION_SEMIBOLD> */}
                </div>
                </div>
              )})}
            </div>
          </div>
        );
      })}
      </div>
    </div>
  );
};
