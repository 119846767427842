import { useState, useMemo, useEffect } from "react";
import { useGetDataQuery } from "../../../utils/react-query-hooks/getData";
import { useGetQueryData } from "../../../utils/react-query-hooks/general";
import { useElemInView } from "../../../utils/customHooks/useElemInView";
import { autoGenFilterConfigsFromTpl } from "./utils/autoGenFilterConfigsFromTpl";
import { applyFilterConfigsAsPerTenant } from "./utils/applyFilterConfigsAsPerTenant";
import { applyKeywordSearch } from "./utils/applyKeywordSearch";
import { applyActiveSort } from "./utils/applyActiveSort";
import { filterAsPerActiveFilters } from "./utils/filterAsPerActiveFilters";
import { separateEachAnnoIntoIndependentDoc } from "./utils/separateEachAnnoIntoIndependentDoc";
import { extractAllBlocksFromTpl } from "../../../utils/templating/extractAllBlocksFromTpl";
import { getAnnoExplorerConfigsForThisTenant } from "./utils/getAnnoExplorerConfigsForThisTenant";

const getTplsWithAnnosEnabled = ({ allTpls }) => {
  return allTpls.filter((tpl) => {
    const allBlocks = extractAllBlocksFromTpl({ tpl });
    return (
      allBlocks.filter((d) => d.props?.annotation?.enable === true)?.length > 0
    );
  });
};


const useFilterAsPerActiveFilters = ({
  separatedData, 
  totalFilters, 
  selectedTpls
}) => {
  const tagTypesToFetch = totalFilters.flatMap(filter => {
    if (filter.target.filterType === "nestedRollupTagType") {
      /**
       * earlier we used to fetch all the tags in the rollupPath except the last one, because we assumed
       * the last one will open and show in the dropdown of the filter and hence the options would be 
       * cached. However, in the case of presetActiveFilters, that assumption doesnt work. Therefore,
       * at the cost of being slightly less performant, we simply fetch docs for all the tags in the rollupPath
       */
      return filter.target.rollupPath 
    } else if(filter.target.filterType === 'rollupRelationshipType'){
      return [filter.target.tagType]
    } else if (filter.target.valuePathInRolledUpCollection) {
      return [filter.target.tagTypeCollectionToRollup];
    }
    return [];
  }).filter(Boolean);

  const { status: tagTypeStatus, data: tagTypeCollectionToRollupDocs, error: fetchTagTypeError, } = useGetDataQuery({
    contentTypes: tagTypesToFetch,
    findQuery: { kp_published_status: 'published' },
    queryOptions: {
      enabled: tagTypesToFetch.length > 0,
    },
  });

  const filteredData = useMemo(() => {
    if (separatedData && tagTypeStatus !== 'loading') {
      return filterAsPerActiveFilters({
        separatedData,
        activeFilters: totalFilters,
        selectedTpls,
        tagTypeCollectionToRollupDocs
      });
    }
  }, [separatedData, totalFilters, selectedTpls, tagTypeStatus, tagTypeCollectionToRollupDocs]);

  return {
    filteredData,
    fetchFilteredDataStatus: tagTypeStatus,
    fetchTagTypeError
  };
};

const DEFAULT_SORT_OPTIONS = [
  {display: 'Anno Tags - Highest to Lowest', value: 'annoTagsCountDesc'},
  {display: 'Alphabetical - A to Z', value: 'alphabeticalAsc'},
  {display: 'Latest', value: "latest"},
  {display: "Oldest", value: "oldest"}
];

export const useGetAnnotationExplorerData = (config = {}) => {
  const {
    presetActiveFilters = [],
    contentTypesWhitelist,
    initialCardsLimit = 20,
    filterDefs : _filterDefs //remember this is v2 configs, ie the whitelist pattern
  } = config;

  // Get all templates and profile types
  const allTpls = useGetQueryData("allTpls");
  const { _ProfileTypes } = useGetQueryData("platformConfigs");

  // Determine template options with whitelist and annotations enabled
  const tplOptions = useMemo(() => {
    // First, filter by whitelist if exists
    const filteredTpls =
      contentTypesWhitelist?.length > 0
        ? allTpls.filter((d) =>
            contentTypesWhitelist.includes(d.kp_content_type)
          )
        : allTpls;

    // Then filter to only include templates with annotations
    return getTplsWithAnnosEnabled({ allTpls: filteredTpls });
  }, [allTpls, contentTypesWhitelist]);

  // State management
  const [selectedTpls, setSelectedTpls] = useState(tplOptions);
  const [activeFilters, setActiveFilters] = useState([]);
  const totalFilters = [...presetActiveFilters, ...activeFilters];
  
  const [activeSort, setActiveSort] = useState(DEFAULT_SORT_OPTIONS[0].value);
  const [keyword, setKeyword] = useState("");

  // Lazy loading management
  const [cardsLimit, setCardsLimit] = useState(initialCardsLimit);
  const { observerRef } = useElemInView({
    callback: () => {
      setCardsLimit((prev) => prev + initialCardsLimit);
    },
  });

  // Reset cards limit when filters or selected templates change
  useEffect(() => {
    setCardsLimit(initialCardsLimit);
  }, [JSON.stringify(totalFilters), JSON.stringify(selectedTpls), initialCardsLimit]);

  // Determine fields to project from templates
   const annoFieldsToProject = tplOptions
      .map((tpl) => {
        const allBlocks = extractAllBlocksFromTpl({ tpl });
  
        return allBlocks.filter((bl) => bl.props.annotation?.enable === true);
      })
      .reduce((a, b) => [...a, ...b], []);
  
  
    const mainFieldsToProject = tplOptions
      .map((tpl) => {
        const allBlocks = extractAllBlocksFromTpl({ tpl });
  
        return allBlocks.filter((bl) => ['RadioList', 'CheckboxList', 'DropdownSingle', 'DropdownMulti'].includes(bl.comp));
      })
      .reduce((a, b) => [...a, ...b], []);
    
  

  // Data fetching
  const { data, status, error } = useGetDataQuery({
    contentTypes: selectedTpls.map((d) => d.kp_content_type),
    findQuery: { kp_published_status: "published" },
    population : [
      {
        path: "meta.kp_contributed_by",
        model: "user",
        select: ["name", "_id"],
      },
    ],
    translationConfig: {
      translateComp: [
        "TitleInput",
        "LexicalTextEditor",
        "TagsInputSingle",
        "TagsInputMulti",
      ],
    },
    projection: {
      // we project anno fields here only because we need to separate them out. we really dont need them in the final data
      ...annoFieldsToProject.reduce(
        (a, b) => ({ ...a, [`${b.valuePath}.annoData`]: 1 }),
        {}
      ),
      ...mainFieldsToProject.reduce(
        (a, b) => ({ ...a, [b.valuePath]: 1 }),
        {}
      ),
      // main: 1,
      tags: 1,
      "main.title": 1,
      "meta.kp_content_type": 1,
      "kp_date_published": 1,
      "meta.kp_contributed_by": 1,
      translations: 1,
    },
  });

  // Separate annotations into independent documents
  const separatedData = useMemo(() => {
    if (status === "success") {
      return separateEachAnnoIntoIndependentDoc({
        data,
        selectedTpls,
      });
    }
  }, [status, data, selectedTpls]);


  const {fetchFilteredDataStatus, filteredData: _filteredData, fetchTagTypeError} = useFilterAsPerActiveFilters({
    separatedData, 
    totalFilters, 
    selectedTpls
  })

  
  // Sort data
  const sortedData = useMemo(
    () => applyActiveSort({data: _filteredData, activeSort}),
    [_filteredData, activeSort]
  );

  // Apply keyword search
  const data_finalResults = useMemo(
    () => applyKeywordSearch({ data: sortedData, keyword }),
    [sortedData, keyword]
  );

  // Generate filter configurations
  const filterConfigs = useMemo(() => {
    const autoGenFilters = autoGenFilterConfigsFromTpl({
      selectedTpls,
      _ProfileTypes,
      allTpls,
    });


    const filterDefs = _filterDefs || getAnnoExplorerConfigsForThisTenant()?.filterDefs || {}
    
    return applyFilterConfigsAsPerTenant({autoGenFilters, filterDefs});
  }, [selectedTpls, _ProfileTypes]);

  // Paginated results
  const paginatedResults = useMemo(
    () =>
      data_finalResults ? [...data_finalResults].slice(0, cardsLimit) : undefined,
    [data_finalResults, cardsLimit]
  );

  return {
    // Template options
    tplOptions,
    tplOptionsDropdownData: tplOptions.map((d) => ({
      display: d.general?.content?.title || d.kp_content_type,
      value: d.kp_content_type,
    })),

    // Data states
    data: paginatedResults,
    totalData: data_finalResults,
    totalCount: data_finalResults?.length || 0,
    error: status === "success" ? fetchTagTypeError : error,

    // Filtering and sorting states
    selectedTpls,
    setSelectedTpls,
    activeFilters,
    setActiveFilters,
    filterConfigs,

    // Sorting states
    DEFAULT_SORT_OPTIONS,
    activeSort,
    setActiveSort,

    // Search states
    keyword,
    setKeyword,

    // Lazy loading
    observerRef,
    cardsLimit,
    setCardsLimit,

    // Fetch statuses
    dataFetchStatus: status === "success" ? fetchFilteredDataStatus : status,
  };
};