import React from "react";
import { ActionMenu, getBreakPoint, useScreenWidth, ButtonSecondaryV2 } from "oolib";
import { trackEventGA } from "../../../../trackers/GA/trackEventGA";
import { scrollModuleInView } from "../../../../utils/scrollModuleInView";
import { ButtonContentEngagement } from "../../../../pageTpls/PublishedPage/comps/ContentEngagementButtonPanel/Comps/ButtonContentEngagement";
import { _Locale } from "../../../locale/Locale";
import { UIContent } from "../../../../UIContent";

export const TPLActionMenu = ({
  genShareUrl,
  contentType,
  kebabStyle,
  invert,
  popOutOfOverflowHiddenParent,
  ShowShareButton,
  ShowModButton,
  ShowEditButton,
  ShowCommentsButton,
  ShowDeleteButton,
  handleShareButtonClick,
  handleModeratePublished,
  handleEditPublished,
  setShowDeleteModal,
  currentStatus,
  canUserDelete,
  canUserEdit,
  canModDelete,
  canModReview,
  tplActionsIcon,
  buttonSecondary = false,
}) => {
  const screenWidth = useScreenWidth();
  return (
    <div className="kebabMenu">
      <ActionMenu
        invert={invert}
        align="right"
        iconSize="20"
        CustomSelectComp={({ active, onClick }) => {
          return (
            buttonSecondary 
            ? 
            <ButtonSecondaryV2 icon="DotsThree" onClick={onClick} active={active}  /> 
            : 
            <ButtonContentEngagement
              invert={invert}
              icon={tplActionsIcon || "DotsThree"}
              onClick={onClick}
              active={active}
            />
          );
        }}
        popOutOfOverflowHiddenParent={popOutOfOverflowHiddenParent}
        actions={[
          ...(ShowCommentsButton &&
          (screenWidth < getBreakPoint("md") || kebabStyle)
            ? [
                {
                  display: "Comments",
                  icon: "ChatText",
                  onClick: (e) => {
                    scrollModuleInView("commentsModule");
                  },
                },
              ]
            : []),
          ...(ShowShareButton &&
          (screenWidth < getBreakPoint("md") || kebabStyle)
            ? [
                {
                  display: genShareUrl.isLoading ? "Creating Url..." : "Share",
                  icon: "ShareNetwork",
                  onClick: () => handleShareButtonClick(),
                },
              ]
            : []),

          canUserEdit
            ? {
                display: "Edit",
                icon: "PencilSimple",
                onClick: () =>
                  ShowEditButton ? handleEditPublished() : () => {},
                disabled: !ShowEditButton,
                infoTooltip: ShowEditButton
                  ? undefined
                  : {
                      text: `${_Locale(
                        UIContent.disableEditInfoTooltip[currentStatus]
                      )}`,
                    },
              }
            : undefined,

          canModReview
            ? {
                display: "Review",
                icon: "UserCirclePlus",
                disabled: !ShowModButton,
                onClick: () =>
                  ShowModButton ? handleModeratePublished() : () => {},
                infoTooltip: ShowModButton
                  ? undefined
                  : {
                      text: `${_Locale(
                        UIContent.disableReviewInfoTooltip[currentStatus]
                      )}`,
                    },
              }
            : undefined,

          canModDelete
            ? {
                display: "Delete",
                icon: "Trash",
                disabled: !ShowDeleteButton,
                onClick: ShowDeleteButton
                  ? (e) => {
                      trackEventGA(
                        "Published Content",
                        `Clicked Delete On Published Content`,
                        `${contentType}`
                      );
                      setShowDeleteModal(true);
                    }
                  : () => {},
                infoTooltip: ShowDeleteButton
                  ? undefined
                  : {
                      text: `${_Locale(
                        UIContent.disableDeleteForModInfoTooltip
                      )}`, // editPublished, underModeration, sentBack
                    },
              }
            : undefined,

          canUserDelete
            ? {
                display: "Delete",
                icon: "Trash",
                disabled: !ShowDeleteButton,
                onClick: (e) => {
                  trackEventGA(
                    "Published Content",
                    `Clicked Delete On Published Content`,
                    `${contentType}`
                  );
                  setShowDeleteModal(true);
                },
                infoTooltip: ShowDeleteButton
                  ? undefined
                  : {
                      text: `${_Locale(
                        UIContent.disableDeleteInfoTooltip[currentStatus]
                      )}`, // editPublished, underModeration, sentBack
                    },
              }
            : undefined,
          ,
        ].filter(d=>d)}
      />
    </div>
  );
};
