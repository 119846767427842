import styled from "styled-components";

import { transition } from "oolib";



export const StyledContentSection = styled.div`
  height: inherit;
  display: flex;
  flex-grow: 1;  
  position: relative;


  width: ${({ sidebar, sidebarWidth }) => sidebar ? `calc(100% - ${sidebarWidth}px)` : '100%'};
`;


export const StyledSection = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  align-items: ${({ sidebar }) => sidebar ? 'start' : 'center'}; 
  min-width: 0;
  ${transition("all 0.5s ease-in-out")};
  margin-top: -2rem; // temp padding for top PaddingTopBottom20 in 
`
