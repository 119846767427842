import {
  ButtonGhost,
  ButtonPrimary,
  ButtonSecondary,
  Container,
  SANS_2,
  Section,
  Wrapper1000,
  Wrapper700,
  colors,
  getBreakPoint,
  useScreenWidth,
} from "oolib";
import React, { useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { useLocation } from "react-router-dom";
import { useFormValidationContext } from "../../../../contexts/formValidationContext";
import { extractAllBlocksFromTpl } from "../../../../utils/templating/extractAllBlocksFromTpl";
import { FormConfigsRenderer } from "../../layouts/GroupsStyle1/comps/FormConfigsRenderer";
import { useCreateIndexList } from "../../../../utils/templateIndex/useCreateIndexList";
import IndexBar from "../../../../components/generalUI/IndexBar";
import { usePlatformBuilderContext } from "../../../../contexts/platformBuilderContext";
import TplActions from "../../../../components/generalUI/TplActions";
import { CoverConfigsRenderer } from "../GroupsStyle1/comps/CoverConfigsRenderer";
import { TableOfContent } from "../../../../components/generalUI/TableOfContent";

const Layout = ({ tpl, handleOnChange, content, readOnly, memo, publishBtnTextAndOnClickAndDisabled,showIndexbar,indexType, activePageIdx=0, setActivePageIdx, stepValidation, setStepValidation }) => {
  const { formValidation, makeFormValidationVisible, setFormValidation } =
    useFormValidationContext();
  // const [stepValidation, setStepValidation] = useState({});
  const location = useLocation();
  // const bodyRef = useStretchDivToRemainingHeight();


  const {
    indexList,
    activeIndexOp,
    setActiveIndexOp
  } = useCreateIndexList({
    tpl ,
    content,
    readOnly
  })


  const pageCoverRef = useRef(null);

  const renderers = {
    FormBuilder: FormConfigsRenderer,
  };

  const TCIIsActive = sessionStorage.getItem("TCIIsActive") === "true";
  const subSpaces = tpl.kp_templates.body.subSpaces.filter(ss => ss.subSpaceHiddenInTCI !== true)
  const bodySubSpaces = readOnly
    ? subSpaces.filter((s) => s.inputOnly !== true)
    : subSpaces.filter((s) => s.displayOnly !== true);

  // const [activePageIdx, setActivePageIdx] = useState(0);
  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
  },[activePageIdx])

  const totalPagesLength = bodySubSpaces.length;

  const genStepIndicator = () => (
    <div style={{display: "flex"}}>
      <SANS_2>{`Step `}</SANS_2>
      &nbsp;
      <SANS_2>{` ${activePageIdx + 1}/${totalPagesLength}`}</SANS_2>
    </div>
  );

 



const screenWidth = useScreenWidth()
  

  useEffect(() => {
    /**
     * Okay so this is the logic.
     * We basically run extractAllBlocksFromTpl, BUT, we trim the tpl down
     * to ONLY the currently active subspace configs.
     *
     * Now once we have this subspace's blocks, we simply filter
     * down the 'total failed blocks' from formValidation state, to only
     * those blocks that are on the currently active subspace.
     *
     * This will give us the failed blocks of this subspace.
     *
     * We do the same for invalid input blocks as well.
     *
     * Why we should do it this way? Because if we dont depend on the formValidation state
     * in context, we wont be able to track then invalidInputBlocks, because that stuff is set
     * at the BlockGenerator level.
     */
    const thisSubSpaceBlocks = extractAllBlocksFromTpl({
      tpl: {
        category: "applicationFormsStyle1",
        kp_templates: {
          body: {
            subSpaces: [bodySubSpaces[activePageIdx]],
          },
        },
      },
    });
    const {
      failedBlocks: totFailedBlocks,
      invalidInputBlocks: totInvalidInputBlocks,
    } = formValidation;

    const thisPageFailedBlocks = totFailedBlocks.filter((block) =>
      thisSubSpaceBlocks.some((bl) => bl.valuePath === block.valuePath)
    )
    const thisPageInvalidInputBlocks = totInvalidInputBlocks.filter((block) =>
      thisSubSpaceBlocks.some((bl) => bl.valuePath === block.valuePath)
    )

    setStepValidation && setStepValidation((p) => ({
      ...p,
      failedBlocks: thisPageFailedBlocks,
      invalidInputBlocks: thisPageInvalidInputBlocks,
    }));

    return () => setFormValidation((p) => ({ ...p, makeVisible: false }));
  }, [
    activePageIdx,
    formValidation.failedBlocks.length,
    formValidation.invalidInputBlocks.length,
  ]);

  //full hack. to shove this into onboarding customheader
  useEffect(() => {
    if(location.pathname === '/onboarding'){
      let elem = document.querySelector('#OnboardingSaveAndProgressButton')
      if(elem) elem.style.display = 'none'
    }
  },[])
  useEffect(() => {
    if (location.pathname === "/onboarding") {
      const StepIndicatorReactNode = genStepIndicator();
      const staticHtml = ReactDOMServer.renderToStaticMarkup(
        StepIndicatorReactNode
      );
      let whereToInsert = document.querySelector(
        "#OnboardingCustomHeader #CustomLeftComp"
      );
      whereToInsert.innerHTML = staticHtml;
    }
  }, [activePageIdx]);


  const {platformBuilderMode} = usePlatformBuilderContext()



const genIndexBar = () => showIndexbar ? (
  <TableOfContent
    type={'anchorLinks'}
    indexType={indexType}
    options={indexList}
    value={activeIndexOp}
    onChange={(k, v) => setActiveIndexOp(v)}
    failedBlocks={
      !readOnly &&
      formValidation.makeVisible &&
      formValidation.failedBlocks
    }
  />
) : null

// ignore indexBar element while pdf dowload
  return (
    <>
      
      <div>
      { /** basically in edit mode, we give the option to hide the cover on some pages. usually its hidden on the first page where we sometimes choose to have a simple 'instructions page' like GTDC & GSL */}
      {(bodySubSpaces[activePageIdx]?.hideCover !== true || readOnly) && <CoverConfigsRenderer
          {...{
            readOnly,
            content,
            tpl,
            memo,
            handleOnChange,
            coverComp: 'KnowledgeResources2Intro1',
            align: 'left'
          }}
        />}
      { readOnly && !platformBuilderMode && !tpl.kp_templates.intro && //the .intro bit is for backwards compatibility. because for older applicationFormsStyle tpls intro section didnt exist so we had to show tpl actions separately. Now it does.
      <Wrapper700>
          <TplActions
          {...{
            content,
          }}

        />
        </Wrapper700>}
        <div ref={pageCoverRef}>
        
      </div>
      <div style={{position:'relative'}}>
        {screenWidth >= getBreakPoint('xl') && genIndexBar()}
        {bodySubSpaces
        .filter((sub, idx) => (readOnly ? true : idx === activePageIdx))
        .map((sub) => (
          <div style={!readOnly ? {paddingBottom: '6rem'} : {}}> { /** adds a bit of extra space that is eaten up by the fixed bottom bar below */}
          <FormConfigsRenderer
            {...{
              configs: sub.configs,
              wrapperConfig: sub.wrapper,
              readOnly,
              content,
              memo,
              handleOnChange,
            }}
          />
          </div>
        ))}
        {screenWidth < getBreakPoint('xl') && genIndexBar() } 
        </div>
      </div>
      

      {/* {!readOnly ? (
        <div
          style={{
            position: "fixed",
            width: '100%',
            bottom: 0,
            background: colors.white,
            zIndex: 100
          }} /*ref={pageCoverRef}* /
        >
          <Section borderTop>
            <Container>
              <Wrapper1000>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "1rem 0",
                  }}
                >
                  <div>
                    {location.pathname !== "/onboarding" && genStepIndicator()}
                  </div>
                  <div style={{ display: "flex", gap: "1rem" }}>
                    <ButtonGhost
                      children={"Back"}
                      disabled={activePageIdx === 0}
                      onClick={() => setActivePageIdx((prev) => prev - 1)}
                    />
                    <div>
                      <div
                        onClick={(e) => {
                          if (
                            stepValidation?.failedBlocks?.length > 0 ||
                            stepValidation?.invalidInputBlocks?.length > 0
                          )
                            publishBtnTextAndOnClickAndDisabled.wrapperOnClick(e);
                        }}
                      >
                        <ButtonPrimary
                          id={location.pathname !== "/onboarding" ? "stepNextButton" : "OnboardingSaveAndProgressButton"}
                          children={activePageIdx === totalPagesLength - 1
                              ? location.pathname === '/onboarding'
                                ? (document.querySelector('#OnboardingSaveAndProgressButton')?.innerText || 'Next')
                                : publishBtnTextAndOnClickAndDisabled?.text
                              : "Next"}
                          disabled={

                            readOnly
                              ? activePageIdx === totalPagesLength - 1
                              //if last page then, check publish button disabled info.
                                : (activePageIdx === totalPagesLength - 1 && publishBtnTextAndOnClickAndDisabled?.disabled) ||
                                stepValidation?.failedBlocks?.length > 0 ||
                                stepValidation?.invalidInputBlocks?.length > 0
                          }
                          onClick={() => {
                            activePageIdx === totalPagesLength - 1
                              ? location.pathname === '/onboarding'
                                ? document.querySelector('#OnboardingSaveAndProgressButton').click()
                                : publishBtnTextAndOnClickAndDisabled ? publishBtnTextAndOnClickAndDisabled.onClick() : (() => {})()
                              : setActivePageIdx((prev) => prev + 1)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Wrapper1000>
            </Container>
          </Section>
          {/* {!bodySubSpaces[activePageIdx].hideIntroSpace && (
          <CoverConfigsRenderer
            {...{
              readOnly,
              content,
              tpl,
              memo,
              handleOnChange,
              formValidation,
            }}
          />
        )} * /}
        </div>
      ) : null} */}
    </>
  );
};

export default Layout;
