import { colors2, icons, transition, UI_BODY_SEMIBOLD_SM, UI_BODY_SEMIBOLD, ButtonTertiaryCompact, ButtonSecondaryCompact } from "oolib";
import React from "react";
import styled, { css } from "styled-components";
import { createHoverEffect } from "../../../../../../utils/createHoverEffect";

const { Funnel } = icons;

export const StyledFilterButton = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  border-right: 1px solid ${colors2.grey10};
  background: none;
  flex: 0 0 auto;
  ${transition('width')};
  position: relative;
  ${({ showSideBarLeft }) => !showSideBarLeft && css`
    margin-left: -20px;
    padding-left: 20px;
    ${createHoverEffect()}
  `}
`

export const FilterModuleHeader = ({ onOpen, onClose, width, showSideBarLeft, style={}, setActiveFilters, isMobile }) => {

  const TitleTypo = isMobile ? UI_BODY_SEMIBOLD : UI_BODY_SEMIBOLD_SM;

  return (
    <StyledFilterButton
      style={{
        ...style,
        width,
        cursor: !showSideBarLeft && "pointer",
      }}
      onClick={() => onOpen && onOpen()}
      showSideBarLeft={showSideBarLeft}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "0.4rem" }}>
        <Funnel size={isMobile ? 20 : 16} weight="bold" color={colors2.grey80} />
        <TitleTypo style={{ color: colors2.grey80, paddingTop: "0.1rem" }}>
          Filters
        </TitleTypo>
      </div>
      <div>
        {showSideBarLeft && (
           <div style={{ display: 'flex', gap: '0.6rem' }}>
            { isMobile 
            ? 
            <>
            <ButtonTertiaryCompact responsive={true} value={"Clear All"} icon="X" onClick={() => setActiveFilters([])}/> 
            <ButtonSecondaryCompact responsive={true} value={"Close"} onClick={() => onClose && onClose()}/> 
            </>
            : 
            <ButtonTertiaryCompact responsive={true} value={"Close"} onClick={() => onClose && onClose()} /> 
            }
          </div>
        )}
      </div>
    </StyledFilterButton>
  );
};