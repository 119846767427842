export const domainToTenantMap = {
  
  "gsl-dev.ooloilabs.in":"gsl",
  "demo-dev.ooloilabs.in":"demo",
  "okft-dev.ooloilabs.in": "okft",
  "olir-dev.ooloilabs.in": "olir",
  "cata-dev.ooloilabs.in": "cata",
  "ved-dev.ooloilabs.in": "ved",
  "wqn-dev.ooloilabs.in": "wqn",
  "mhai-dev.ooloilabs.in": "mhai",
  "bosh-dev.ooloilabs.in":"bosh",
  "nkms-dev.ooloilabs.in":"nkms",
  "nanka-dev.ooloilabs.in":"nanka",
  "gtdc-dev.ooloilabs.in":"gtdc",
  "gelabs-dev.ooloilabs.in":"gelabs",
  "okfdocs-dev.ooloilabs.in":"okfdocs",
  "mad-dev.ooloilabs.in":"mad",


  
  "olir-staging.ooloilabs.in": "olir",
  "cata-staging.ooloilabs.in": "cata",
  "ved-staging.ooloilabs.in": "ved",
  "wqn-staging.ooloilabs.in": "wqn",
  "mhai-staging.ooloilabs.in": "mhai",
  "demo-staging.ooloilabs.in":"demo",
  "gsl-staging.ooloilabs.in":"gsl",
  "bosh-staging.ooloilabs.in":"bosh",
  "nkms-staging.ooloilabs.in":"nkms",
  "nanka-staging.ooloilabs.in":"nanka",
  "gtdc-staging.ooloilabs.in":"gtdc",
  "gelabs-staging.ooloilabs.in":"gelabs",
  "okfdocs-staging.ooloilabs.in":"okfdocs",
  "mad-staging.ooloilabs.in":"mad",


  "praman.org.in": "nct",
  "sandwatch.envmonitoring.in": "ved",//
  "makingitrealfp.org": "cata",//
  "research.ooloilabs.in": "olir",//
  "waterquality.network": "wqn",//
  "knowledge.menstrualhealthaction.org": "mhai",//
  "demo.ooloilabs.in":"demo",//
  "principalsspeak.globalschoolleaders.org": "gsl",//
  "scalescholarships.in":"bosh",//
  "knowledge.noorahealth.org":"nkms",//
  "pqrs.sportsstar.in":"nanka",//
  "okfdocs.ooloilabs.in":"okfdocs",//
  "udarta.in":"gtdc",//
  "genderdesignlabs.org":"gelabs",//
  "stories.makeadiff.in":"mad"//
  
};

export const getTenant = () => {
  if (
    process.env.NODE_ENV === "production" 
    && !window.location.origin.includes('-dot-okf-fe-dev-dot-ok-framework.el.r.appspot.com')
    // && import.meta.env.VITE_ENV !== 'dev' //activate when we wanna make dev a single url
  ) {
    return domainToTenantMap[window.location.hostname];
  } else {
    //locahost
    return (
      localStorage.getItem("localhost_tenantId") ||
      Object.values(domainToTenantMap)[0]
    );
  }
};
