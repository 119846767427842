import React from "react";
import { useState } from "react";
import { FeedbackStyled, ThumbsDownStyled, ThumbsUpStyled } from "./styled";
import {
  ButtonSecondaryV2,
  TextInput,
  UI_BODY_SM,
  ButtonGhostCompact,
  ButtonTertiaryCompact,
} from "oolib";
import { useSendFeedback } from "../../../hooks";

export const Feedback = ({ reaction = "", comments = "", messageId }) => {
  const [userReaction, setUserReaction] = useState(reaction);
  const [userComments, setUserComments] = useState(comments);

  const { mutate: feedback, status } = useSendFeedback();

  return (
    <FeedbackStyled
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
      }}
    >
      <ButtonGhostCompact
        icon="ThumbsUp"
        onClick={() => {
          setUserReaction("positive");
          feedback({ messageId, reaction: "positive" });
        }}
        style={userReaction === "positive" ? { background: "#5DA922" } : {}}
        iconSize={"M"}
        iconColor={userReaction === "positive" ? "white" : "#808080"}
      />

      <ButtonGhostCompact
        onClick={() => {
          setUserReaction("negative");
          feedback({ messageId, reaction: "negative" });
        }}
        icon="ThumbsDown"
        iconSize={"M"}
        iconColor={userReaction === "negative" ? "white" : "#808080"}
        style={userReaction === "negative" ? { background: "#C41717" } : {}}
      />

      {/* {userReaction === "negative" ? (
        <ButtonTertiaryCompact>Tell us what went wrong</ButtonTertiaryCompact>
      ) : null} */}

      {userReaction === "positive" || userReaction==="negative" ? (
        <UI_BODY_SM>Thanks for your feedback!</UI_BODY_SM>
      ) : null}
    </FeedbackStyled>
  );
};
