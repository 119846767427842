import { mediaQuery } from "oolib";
import styled from "styled-components";

export const LandingCompContainerStyled = styled.div`
  position: relative;
  bottom: 0;

  ${mediaQuery("sm")} {
    position: absolute;
    bottom: 4rem;
  }
`;

export const BetaStyledWrapper = styled.div`
  padding: 0 0.4rem;
  border-radius: 0.4rem;
  background: linear-gradient(
    118.55deg,
    #9dffea -18.66%,
    #bdd4ff 52.78%,
    #ffc9f0 122.8%
  );
`;
