import { colors2, icons, UI_BODY_BOLD_SM, UI_BODY_SEMIBOLD, UI_BODY_SM } from "oolib";
import React from "react";
import styled from "styled-components";

const { CaretDown } = icons;
const { grey5 } = colors2;



const StyledWrapper = styled.div`
  display: flex;
  height: 36px;
  padding: 10px 12px 10px 10px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid #E7E7E7;
  background: #FFF;
  cursor: pointer;
  &:hover{
    background-color: ${grey5};
  }
`;

export const CustomSelectComp = ({ display, placeholder, icon}) => {
  
  const Icon = icons[icon]
  return(
    <StyledWrapper>
      <div style={{ display: "flex", gap: "1rem"}}>
        {icon && <Icon size={20}/>}
        <UI_BODY_SM capitalize style={{ color: display ? colors2.black : colors2.grey60 }}>{display || placeholder}</UI_BODY_SM>
      </div>
      <CaretDown size={20}/>
    </StyledWrapper>
  )
}


