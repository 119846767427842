import React, { useState } from "react";
import { getVal, useBannerContext } from "oolib";
import { useBulkQuickUpdateContent } from "../../../../pageTpls/ContributeTpl/queryHooks/useBulkQuickUpdateContent";
import { useActivateSelfServeSurveyModal } from "../../../../pageTpls/PlatformBuilder/subModules/ContentTypesManager/comps/ContentTypeLanding/PublishTypeLanding/useActivateSelfServeSurveyModal";

export const useGetBulkActionsConfig = ({ contentType, tableConfig }) => {
   const { mutate: bulkQuickUpdateContents, status: bulkQuickUpdateStatus } = useBulkQuickUpdateContent();
   const { SET_INFO_BANNER } = useBannerContext();

   const [bulkActions_selectedRowIds, bulkActions_setSelectedRowIds] = useState([]);

   const { ActivateSelfServeSurveyModal, activateSelfServeSurvey } = useActivateSelfServeSurveyModal({
      contentType: contentType,
      onConfirmFunction: "bulkActivateSelfServeSurvey",
   });
   
   const handleShortlistUnshortlist = ({ data, shortlist = true }) => {
      if(bulkQuickUpdateStatus === "loading") return;

      const contentType = data?.[0]?.meta.kp_content_type;
      const filteredContentIds = bulkActions_selectedRowIds.filter((contentId) => {
         const matchingDataObj = data.find((obj) => obj._id === contentId);
         const isShortlisted = matchingDataObj.main.shortlistForSurvey2;
         return shortlist ? !isShortlisted : isShortlisted;
      });

      bulkQuickUpdateContents({
         contentType: contentType,
         body: {
            contentIds: filteredContentIds,
            queryObj: {
               $set: {
                  "main.shortlistForSurvey2": shortlist,
               },
            },
         }
      }, {
         onSuccess: () => {
            SET_INFO_BANNER(
               shortlist ? "Shortlisted successfully" : "Unshortlisted successfully",
               "green",
               3000
            );
            bulkActions_setSelectedRowIds([]);
         },
      });
   };

   const handleBulkActivateSelfServeSurvey = ({ data }) => {
      // getting userIds from selected data objects(filtering only selected contents first)
      let userIds = data
         .filter((obj) => bulkActions_selectedRowIds.includes(getVal(obj, tableConfig.rowIdPath)))
         .map((selectedObj) => selectedObj.meta.kp_contributed_by?._id)
         .filter(Boolean);

      userIds = [...(new Set(userIds))];
      activateSelfServeSurvey(null, { userIds, bulkActions_setSelectedRowIds });
   }
 
   const bulkActionsConfig = {
      shortlist: ({ data }) => {
         handleShortlistUnshortlist({ data, shortlist: true });
      },
      unshortlist: ({ data }) => {
         handleShortlistUnshortlist({ data, shortlist: false });
      },
      activateSelfServeSurvey: ({ data }) => {
         handleBulkActivateSelfServeSurvey({ data });
      },
   };

   return {
      bulkActions_selectedRowIds,
      bulkActions_setSelectedRowIds,
      bulkActionsConfig,
      ActivateSelfServeSurveyModal,
   };
}