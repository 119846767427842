import {
  SkeletonLoader
} from "oolib";
import React, { useMemo } from "react";
import { ListingModuleAnnotationExplorer } from "../../../../../../../../components/analysisModules/AnnotationExplorer/comps/ListingModuleAnnotationExplorer";
import { useGetAnnotationExplorerData } from "../../../../../../../../components/analysisModules/AnnotationExplorer/useGetAnnotationExplorerData";
import { getAnnoExplorerConfigsForThisTenant } from "../../../../../../../../components/analysisModules/AnnotationExplorer/utils/getAnnoExplorerConfigsForThisTenant";
import FilterModule from "../../../../../../../../components/discovery/FilterModule";
import { parseSpecialConfigSyntax } from "../../../ListingConfigsRenderer/utils/parseSpecialConfigSyntax";
import { FilterPlusBodyShellForCollectionPages } from "./FilterPlusBodyShellForCollectionPages";

export const GELABS_findingsAnnoTab = ({
  content,
  tabsPanelHeight,
  actionBarHeight,
}) => {
  const annoExplorerConfigs = getAnnoExplorerConfigsForThisTenant();

  const presetActiveFilters = useMemo(() => {
    const filters = [
      // Original hypothesis filter
      {
        target: {
          filterType: "tagType",
          /**
           * this plugin is used on the hypo collection page & the insights collection page
           * both of these are used as tags on findings. and findings is used an an annotation tag
           * on the content. so the below dynamic stuff on rolluppath works
           */
          tagType: "findings",
        },
        source: {
          scope: "annoTags",
        },
        values: [{ value: "$valuePath(_id)" }],
      },
    ];

    return parseSpecialConfigSyntax({
      config: filters,
      content,
    });
  }, [content]);

  // Use the new hook with tenant-specific configuration
  const {
    tplOptions,
    tplOptionsDropdownData,
    data,
    totalData,
    totalCount,
    selectedTpls,
    setSelectedTpls,
    activeFilters,
    setActiveFilters,
    filterConfigs,
    sortOptions,
    activeSort,
    setActiveSort,
    keyword,
    setKeyword,
    observerRef,
    dataFetchStatus,
    error,
  } = useGetAnnotationExplorerData({
    presetActiveFilters,
    // Use whitelist from annoExplorerConfigs
    contentTypesWhitelist: annoExplorerConfigs?.contentTypes?.whitelist,
    initialCardsLimit: 20,
  });

  const sidebarWidth = 300;

  const filterStickyTop = (actionBarHeight || 0) + (tabsPanelHeight || 0);

  return (
    <FilterPlusBodyShellForCollectionPages
      filterStickyTop={filterStickyTop}
      leftComp={
        filterConfigs && (
          <FilterModule
            filterConfig={filterConfigs}
            activeFilters={activeFilters}
            setActiveFilters={setActiveFilters}
            presetActiveFilters={presetActiveFilters}
            orientation="sidebar"
            expandedWidth={sidebarWidth}
          />
        )
      }
    >
      {dataFetchStatus === "loading" ? (
        [1, 2, 3].map((d) => <SkeletonLoader style={{ height: "300px" }} />)
      ) : (
        <ListingModuleAnnotationExplorer
          data={data}
          totalCount={totalCount}
          dataFetchStatus={dataFetchStatus}
          dataFetchError={error}
          observerRef={observerRef}
        />
      )}
    </FilterPlusBodyShellForCollectionPages>
  );
};
