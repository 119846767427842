import React, { useEffect, useMemo, useRef, useState } from "react"
import styled from "styled-components"
import { createPortal } from "react-dom"

import { getFilterType } from "@/components/inputs/DynamicTagsInputs/FilterInput/utils/getFilterType"
import { fetchFilterOptionsConfigs } from "@/components/inputs/DynamicTagsInputs/FilterInput/configs"
import { ButtonTertiaryCompact, CheckboxInput, colors2, Divider, getText, icons, makeArrayFromLength, TextLoader, UI_BODY_SEMIBOLD_SM, UI_BODY_SM, useHandleClickOutside } from "oolib"
import { ColorCircle, StyledHeaderWrapper, StyledLightBoxContainer, StyledOptionsWrapper, StyledOptionWrapper, StyledSelectHeader, StyledTextLoaderWrapper } from "./styled"
import { getTenant } from "@/TENANT_CONFIGS"
import { annoStorageKey } from "../.."
import { useGetOptionsByFilterType } from "@/components/inputs/DynamicTagsInputs/FilterInput/utils/useGetOptionsByFilterType"

const { CaretDown, CaretRight } = icons;

const colors = [ // temporary colors
  "#89D2E8",
  "#FFA4EB",
  "#83E292",
  "#EDB86F",
  "#A5BBFF",
  "#AFCE6C",
  "#F3A4A4",
  "#88DECD",
  "#D2A2FF",
  "#E1D561"
];

const convertToColorConfig = (config, activeColorFilter) => {
  const options = config.props.options;
  
  return {
    tagType: getTagType(activeColorFilter?.filterId), // Assuming "tags" as the default tagType 
    tagId: activeColorFilter.value,
    display: activeColorFilter.display,
    tags: options.map((option, index) => ({
      display: option.display,
      value: option.value,
      fill: colors[index % colors.length]
    }))
  };
}

const getTagType = (tagType) => tagType.startsWith("annoTags_") ? "annoTags" : "tags"

export const ColorCodeLightBox = ({ activeColorFilter, disabled, contentTypes, activeFilters, theme, onChange, value: value_ }) => {
  const [lightBoxOpen, setLightBoxOpen] = useState(false);
  const selectOptionRef = useRef();
  const lightboxRef = useRef();
  const lightBoxHeaderRef = useRef();

  const [value, setValue] = useState(value_?.tags || []);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const {
    setEnableDataFetch,
    lightboxConfig,
    display,
    status
  } = useGetOptionsByFilterType({
    initEnableDataFetch: true,
    filterConfig: activeColorFilter,
    contentTypes,
    activeFilters
  });

  const getTagType = (tagType) => tagType?.startsWith("annoTags_") ? "annoTags" : "tags";

  const position = selectOptionRef?.current?.getBoundingClientRect();

  const prevActiveColorFilterRef = useRef(activeColorFilter);
  
  useEffect(() => {
    // This effect will run whenever value_ changes
    setValue(value_?.tags || []);
    setSelectedOptions(value_?.tags || []);
  }, [value_]);

  useEffect(() => {
    if (prevActiveColorFilterRef.current !== activeColorFilter && prevActiveColorFilterRef.current) {
      setValue([]);
      setSelectedOptions([]);
      onChange({
        tagType: getTagType(activeColorFilter?.filterId),
        tagId: activeColorFilter.value,
        display: activeColorFilter.display,
        tags: []
      });
    }
    
    prevActiveColorFilterRef.current = activeColorFilter;
  }, [activeColorFilter, onChange]);

  const handleOptionClick = (option) => {
    let newValue = [...value];
    let isSelected = newValue.some((d) => d.value === option.value);
    
    if (!isSelected && newValue.length < 10) {
      // Add color to the option when selected
      const colorIndex = newValue.length % colors.length;
      const optionWithColor = {
        ...option,
        fill: colors[colorIndex]
      };
      
      newValue.push(optionWithColor);
      setSelectedOptions([...selectedOptions, optionWithColor]);
    } else if (isSelected) {
      // Remove the selected option
      newValue = newValue.filter((d) => d.value !== option.value);
      
      // Recalculate colors for all remaining items to maintain color sequence
      const updatedNewValue = newValue.map((item, index) => ({
        ...item,
        fill: colors[index % colors.length]
      }));
      
      newValue = updatedNewValue;
      setSelectedOptions(updatedNewValue);
    }
    
    setValue(newValue);
    
    // Update parent component
    onChange({ 
      tagType: getTagType(activeColorFilter?.filterId),
      tagId: activeColorFilter.value,
      display: activeColorFilter.display,
      tags: newValue
    });
  };

  useHandleClickOutside(lightboxRef, () => {
    setLightBoxOpen(false);
  });

  const maxHeightLightbox = useMemo(() => {
    if (!lightBoxHeaderRef.current) return 'calc(400px - 24px - 8px)';
    
    const headerHeight = lightBoxHeaderRef.current.getBoundingClientRect().height;
    return `calc(400px - 24px - 8px - ${headerHeight}px)`;
  }, [lightBoxOpen, lightBoxHeaderRef.current]);

  return(
    <>
      {/* <StyledSelectHeader disabled={disabled} onClick={() => !disabled && setLightBoxOpen(true)} ref={selectOptionRef}>
        <UI_BODY_SM>{status === "loading" ? "Loading breakdown options..." : "Configure"}</UI_BODY_SM> 
        <CaretRight size={20}/> 
      </StyledSelectHeader>  */}

      {/* {lightBoxOpen && createPortal( */}
       
          <StyledOptionsWrapper>
            <StyledHeaderWrapper ref={lightBoxHeaderRef}>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0.6rem 1rem 0.8rem 1rem", borderBottom: `1px solid ${colors2.grey10}`, background: "white" }}>
                
                <UI_BODY_SEMIBOLD_SM>Data Series</UI_BODY_SEMIBOLD_SM>
                <ButtonTertiaryCompact 
                  disabled={status==="loading"}
                  onClick={() => {
                    setValue([]);
                    setSelectedOptions([]);
                    onChange({
                      tagType: getTagType(activeColorFilter?.filterId),
                      tagId: activeColorFilter.value,
                      display: activeColorFilter.display,
                      tags: []
                    });
                  }
                }>Clear all</ButtonTertiaryCompact>
              </div>

              <div style={{ background: value.length === 10 ?  colors2.redBG : colors2.white, padding: "0.4rem 1rem", borderRadius: "0.4rem" }}> 
                <UI_BODY_SM style={{ color: value.length === 10 ?  colors2.red : colors2.grey60 }}>
                    Selected : {value.length}/10
                </UI_BODY_SM>
              </div>
              
            </StyledHeaderWrapper>

            {/* <Divider /> */}
            { 
              status === "loading"
              ? 
                <StyledTextLoaderWrapper>
                  {makeArrayFromLength(8).map((item, idx) => (
                    <TextLoader key={idx} style={{ width: "100%", height: "3rem"}}/>
                  ))}
                </StyledTextLoaderWrapper>
              :
              <div style={{ 
                // maxHeight: maxHeightLightbox, overflowY: "auto"
                 }}>
                {lightboxConfig?.props?.options?.map((option, index) => {
                  const isSelected = value.some(item => item.value === option.value);
                  return (
                    <StyledOptionWrapper 
                      key={option.value}
                      onClick={() => {
                        if (!isSelected && value.length >= 10) {
                          // Don't allow more selections when limit reached
                          return;
                        }
                        handleOptionClick(option);
                      }}
                      style={{
                        opacity: (!isSelected && value.length >= 10) ? 0.5 : 1,
                        cursor: (!isSelected && value.length >= 10) ? 'not-allowed' : 'pointer',
                        pointerEvents: (!isSelected && value.length >= 10) ? 'none' : 'unset',
                      }}
                    >
                      <CheckboxInput 
                        option={option} 
                        value={value} 
                        disabled={!isSelected && value.length >= 10} 
                      />
                      {isSelected && (
                        <ColorCircle 
                          fill={value.find(item => item.value === option.value)?.fill || colors[index % colors.length]} 
                        />
                      )}
                    </StyledOptionWrapper>
                  );
                })}
                <div style={{ padding: "1rem" }}/>
              </div>
            }
          </StyledOptionsWrapper>
        {/* document.getElementById("optionsLightbox-root") */}
      {/* )} */}
    </>
  );
}