import styled from "styled-components"

import { colors2 } from "oolib"

export const  { grey5 } = colors2;


export const StyledSelectHeader = styled.div`
  width: 100%;
  display: flex;
  height: 36px;
  padding: 10px 12px 10px 10px;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #E7E7E7;
  background: #FFF;
  cursor: pointer;
  &:hover {
    background-color: ${grey5};
  }
`

export const  StyledOptionWrapper = styled.div`
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  padding: 1rem;
  border-radius: 0.6rem;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background: ${grey5};
  }
`

export const  StyledOptionsWrapper = styled.div`
  border: 1px solid ${grey5}; 
  padding: 0.4rem;
  border-radius: 6px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  background-color: white;
  height: max-content;
  position: relative;
`

export const  StyledLightBoxContainer = styled.div`
  /* position: absolute; */
  /* top: ${props => `${props.top}px`}; */
  /* left: ${props => `${props.left}px`}; */
  /* width: 100%; */
  /* width: 300px; */
  /* max-height: 400px; */
  height: auto;
  z-index: 100;
  border-radius: 8px;
  /* padding: 1.2rem; */
  background: white;
  /* box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15); */
  overflow-y: hidden;
  position: relative;
`

export const  ColorCircle = styled.div`
  background: ${props => props.fill};
  min-width: 18px;
  height: 18px;
  border-radius: 50%;
`

export const StyledTextLoaderWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  width: 100%;
  margin-top: 0.4rem;
`

export const StyledHeaderWrapper = styled.div`
  z-index: 100;
  position: sticky;
  top: 4rem;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  background: white;
  border-bottom: 1px solid ${colors2.grey10};
  padding-bottom: 0.4rem;
`
