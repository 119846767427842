import React, { Fragment } from 'react'
import TableElem from "../../../../../../components/cards/TableElem"
import { TotalCount } from '../../../TotalCount'
import DownloadDataButton from '../../DownloadDataButton'

const DefaultTableWrapper = ({children}) => (
  <div style={{padding: '0 2rem'}}>{children}</div>
)

const TableStyle = ({
  tableConfig : _tableConfig,
  builderProps,
  data,
  status,
  isFetchingNextPage,
  observerRef,
  totalCount,
  activeSort,
  setActiveSort,
  enableContainerQuery,
  inTCI,
  getDataToBeExported,
  hasAccessToDowloadCsv,
  actionsConfig,
  conditionsConfig

}) => {

  

  const tableConfig = {
    ..._tableConfig,
    link: !inTCI ? _tableConfig.link : undefined
  }

  return (
    <Fragment>
      {totalCount !== undefined && 

      <div enableContainerQuery={enableContainerQuery} >
          <div
        style={{ display: "flex", marginRight: "1rem", paddingBottom: "1rem", paddingLeft: '2rem' }}
      >

        <TotalCount totalCount = {totalCount} />
        {
         hasAccessToDowloadCsv && (
          <>
            <DownloadDataButton getDataToBeExported={getDataToBeExported}/>
            
          </>
         )
        }
        </div>
      </div>}
   { totalCount!==0 && <TableElem
      TableWrapper={DefaultTableWrapper}
      actionsConfig={actionsConfig}
      conditionsConfig={conditionsConfig}
      tableConfig={tableConfig}
      data={data}
      status={status}
      lazyLoad={{
        isFetchingNextPage,
        observerRef,
        backendActiveSort: activeSort,
        setBackendActiveSort: setActiveSort
      }}
      builderProps={builderProps?.TableStyle}
      enableContainerQuery={enableContainerQuery}
      />
    }
    </Fragment>
    
  )
}
  
  export default TableStyle