import { getTenant } from "../../../../TENANT_CONFIGS";
class PDFEmbed {
    constructor() {
      this.readyPromise = new Promise((resolve) => {
        if (window.AdobeDC) {
          resolve();
        } else {
          /* Wait for Adobe Document Services PDF Embed API to be ready */
          document.addEventListener("adobe_dc_view_sdk.ready", () => {
            resolve();
          });
        }
      });
      this.adobeDCView = undefined;
    }
  
    ready() {
      return this.readyPromise;
    }
  
    previewFile(divId, viewerConfig, fileInfo) {
      
      const config = {
        /* Pass your registered client id */
        clientId: window.location.href.includes('localhost') 
          ? 'd12aa56c7f9a4b57af2cd65bb4250760' /** creds created for localhost on https://www.adobe.io/apis/documentcloud/dcsdk/gettingstarted.html# */ 
          : import.meta.env.VITE_ENV === 'prod'
            ? import.meta.env[`VITE_ADOBE_EMBED_API_KEY__${getTenant()}`]
            : import.meta.env.VITE_ADOBE_EMBED_API_KEY //staging & dev
      };
      
      if (divId) {
        /* Optional only for Light Box embed mode */
        /* Pass the div id in which PDF should be rendered */
        config.divId = divId;
      }
      /* Initialize the AdobeDC View object */
      this.adobeDCView = new window.AdobeDC.View(config);
  
      /* Invoke the file preview API on Adobe DC View object */
      const previewFilePromise = () => this.adobeDCView.previewFile(
        {
          /* Pass information on how to access the file */
          content: {
            /* Location of file where it is hosted */
            location: {
              url: fileInfo.path,
              /*
                      If the file URL requires some additional headers, then it can be passed as follows:-
                      headers: [
                          {
                              key: "<HEADER_KEY>",
                              value: "<HEADER_VALUE>",
                          }
                      ]
                      */
            },
          },
          /* Pass meta data of file */
          metaData: {
            /* file name */
            fileName: fileInfo.name,
            /* file ID */
          //   id: "6d07d124-ac85-43b3-a867-36930f502ac6",
          },
        },
        viewerConfig
      );
      
      return fileInfo.path && previewFilePromise();
    }
  }
  
  export default PDFEmbed;
  