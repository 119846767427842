import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  colors2,
  Container,
  SANS_4,
  Section,
  Wrapper1000,
  Wrapper650,
} from "oolib";
import { AIMessage } from "./comps/Messages/AI/index.js";
import { SearchBar } from "./comps/SearchBar/index.js";
import { useGetQueryData } from "../../utils/react-query-hooks/general.js";
import { ChatbotInfo } from "./comps/ChatbotInfo/index.js";
import { ErrorMessage } from "./comps/Messages/Error/index.js";
import { ChatHistory } from "./comps/ChatHistory/index.js";
import { useDeleteChat, useLoadChat } from "./hooks/index.js";
import SidebarContainer from "./comps/Sidebar/index.js";
import {
  SidebarProvider,
  useSidebarContext,
} from "./contexts/sidebarContext.js";
import { CardAnnoExplorer } from "../analysisModules/AnnotationExplorer/comps/CardAnnoExplorer/index.js";
import { __GetContentTypeConfigNew } from "../../utils/getters/gettersV2.js";
import { LandingComp } from "./comps/LandingComp/index.js";
import { TopFadeOverlay, BottomOverlay, SearchAreaOverlay } from "./styled.js";
// import data from "./data.json";

const getResponse = async (query) => {
  // if (Math.random() < 0.8) { // 80% chance to fail
  //   const mockError = new Error("Simulated API error");
  //   mockError.config = {
  //     url: "/api/aiChat/getResponse",
  //     method: "get",
  //     params: { query },
  //     headers: {},
  //     data: null
  //   };
  //   throw mockError;
  // }
  return axios.get("/api/aiChat/getResponse", { params: { query } });
};

const Sidebar = () => {
  const { showSidebar, setShowSidebar, content, setContent } =
    useSidebarContext();

  const handleClose = () => {
    setShowSidebar(false);
    setContent(null);
  };

  return showSidebar ? (
    <SidebarContainer
      onClose={handleClose}
      title={"All References"}
      topOffset={0}
      count={content?.vectorSearchInfo?.chunks?.length}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        {content?.vectorSearchInfo?.chunks?.map((chunk) => (
          <CardAnnoExplorer
            cardColor={colors2.grey2}
            singleAnno={{
              fragment: { blocks: [{ text: chunk.text }] },
              _id: chunk.meta.documentId,
              main: { title: chunk.main.title || "no title" },
              kp_date_published: chunk.meta.datePublished || "",
              meta: {
                kp_content_type: chunk.meta.kp_content_type,
                documentId:chunk.meta.documentId,
                contentTypeTitle: __GetContentTypeConfigNew(
                  chunk.meta.kp_content_type
                ).general.content.title,
              },
            }}
            showTagSection={false}
          />
        ))}
      </div>
    </SidebarContainer>
  ) : null;
};

const Main = ({
  chatHistory,
  chatLoadStatus,
  deleteChat,
  deleteChatStatus,
  handleSendMessage,
  setChatHistory,
  getResponseStatus,
  scrollIntoView,
  handleRetryRequest,
}) => {
  return (
    <div style={{ flex: 1, position: "relative" }}>
      <Container>
        <Wrapper650>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              minHeight: "calc(100vh - 6rem)",
              gap: "2rem",
            }}
          >
            <div style={{ flexGrow: 1, position: "relative" }}>
              <Section
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2rem",
                  padding: "2rem 0",
                }}
              >
                {chatLoadStatus === "loading" ? (
                  <SANS_4>Loading...</SANS_4>
                ) : chatLoadStatus === "error" ? (
                  <SANS_4>Error...</SANS_4>
                ) : chatHistory?.length > 0 ? (
                  <ChatHistory chatHistory={chatHistory} />
                ) : (
                  <LandingComp />
                )}

                {getResponseStatus === "loading" ||
                getResponseStatus === "error" ? (
                  <div ref={scrollIntoView}>
                    {getResponseStatus === "loading" ? (
                      <AIMessage
                        key="loading..."
                        message={"Loading..."}
                        streaming={true}
                      />
                    ) : (
                      <ErrorMessage onRetry={handleRetryRequest} />
                    )}
                  </div>
                ) : null}
              </Section>
            </div>

            <div
              style={{
                position: "sticky",
                bottom: "0rem",
                zIndex: "100",
                // backgroundImage: "radial-gradient(circle at bottom, #e3efff 5%, #ffffff 70%)"
              }}
            >
              <SearchBar
                showDeleteChatButton={chatHistory?.length !== 0}
                deleteChat={() =>
                  deleteChat(undefined, {
                    onSuccess: () => setChatHistory([]),
                  })
                }
                disableOnSubmit={getResponseStatus === "loading"}
                deleteChatStatus={
                  deleteChatStatus === "loading" || deleteChatStatus === "error"
                    ? deleteChatStatus
                    : undefined
                }
                onSubmit={handleSendMessage}
              />
              <div style={{ marginTop: "1.2rem" }}>
                <ChatbotInfo variant="modal" />
              </div>
            </div>
          </div>
        </Wrapper650>
      </Container>
    </div>
  );
};

const AiChat = () => {
  const userData = useGetQueryData("userData");
  const { data: chat, status: chatLoadStatus } = useLoadChat(userData.user._id);

  const { mutate: deleteChat, status: deleteChatStatus } = useDeleteChat();

  const [chatHistory, setChatHistory] = useState([]);
  const [getResponseStatus, setGetResponseStatus] = useState("idel");
  const [retryRequestConfig, setRetryRequestConfig] = useState(); // state to store the request config in case of a failure so that it can retried on a click.

  useEffect(() => {
    if (chatLoadStatus === "success" && chat) {
      setChatHistory(chat.messages);
    }
  }, [chatLoadStatus]);

  const handleRetryRequest = async () => {
    setGetResponseStatus("loading");

    if (!retryRequestConfig) {
      throw new Error("retry request config missing");
    }

    try {
      const response = await axios(retryRequestConfig);
      setGetResponseStatus("idel");
      setChatHistory((prev) => [...prev, response.data]);
    } catch (error) {
      setGetResponseStatus("error");
    }
  };

  const handleSendMessage = async ({ value, callback }) => {
    setChatHistory((prev) => [
      ...prev,
      { author: "user", content: { type: "text", value } },
    ]);

    setGetResponseStatus("loading");

    let response;

    try {
      response = await getResponse(value);
      setGetResponseStatus("idel");
      setChatHistory((prev) => [...prev, response.data]);
      callback && callback();
    } catch (error) {
      setRetryRequestConfig(error.config);
      setGetResponseStatus("error");
    }
  };

  const scrollIntoView = (node) => {
    if (node) {
      node.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <BottomOverlay />
      <TopFadeOverlay />
      <div style={{ display: "flex" }}>
        <SidebarProvider>
          <Main
            chatHistory={chatHistory}
            chatLoadStatus={chatLoadStatus}
            deleteChat={deleteChat}
            deleteChatStatus={deleteChatStatus}
            handleSendMessage={handleSendMessage}
            setChatHistory={setChatHistory}
            getResponseStatus={getResponseStatus}
            scrollIntoView={scrollIntoView}
            handleRetryRequest={handleRetryRequest}
          />{" "}
          <Sidebar />
        </SidebarProvider>
      </div>
    </div>
  );
};
export default AiChat;