import React, { useEffect } from 'react'
import {ThemeProvider} from 'styled-components'
import {useAppSettingsContext} from '../contexts/appSettingsContext'
import {useGetQueryData} from '../utils/react-query-hooks/general'
import {TypoClassesProvider, TypoV2ClassesProvider} from 'oolib'
import { _Locale } from '../components/locale/Locale'
import { useUploadMedia } from '../utils/react-query-hooks/media'
import { useGetDataLazy } from '../utils/react-query-hooks/getData'
import { additionalOptionsFns } from './themeConfigs/additionalOptionsFns'
import { parseSpecialSyntax } from '../utils/parseSpecialSyntax'
import RichTextEditor from '../components/inputs/TextEditor/RichTextEditor'
import { richTextHasValue, lexicalTextEditorHasValue } from '../utils/validation/validatorFns'
import { convertToRichText } from "../utils/getters/gettersV2";
import LexicalTextEditor from '../components/inputs/TextEditor/LexicalTextEditor'
import { getTenant }  from "../TENANT_CONFIGS"
import { pluginConfigs } from '../Plugins/_EXPORTS'
import { useQuickUpdateContent } from '../pageTpls/ContributeTpl/queryHooks/useQuickUpdateContent'
import { MobileShell } from './_tempDdMobileShell'

const OolibThemeProvider = props => {

    /**
     * here we create the theme object that will be supplied to the styled-components themeProvider.
     * all the variables made accessible here, to the ThemeProvider
     * can be accessed by all styled-components in this code base
     * using props.themes...
     * these theme variables ARE ALSO ACCESSIBLE to
     * the oolib component library.
     * All components in the oolib library can also access these
     * theme variables using props.themes...
     */

    const {APP_SETTINGS} = useAppSettingsContext();
    // const { _Theme : {colors}, _Roles, _Branch } = useGetQueryData('platformConfigs')
    const { _Theme : {colors}, _Roles } = useGetQueryData('platformConfigs')


    /**
     * backwards compatibility:
     * for older components and modules that still use scss, we need to make
     * primaryColor100, 10, 40 & text accessible in sass via css variables
     */
    useEffect(() => {   
        let root = document.documentElement
        root.style.setProperty('--primaryColor100', colors.primaryColor100);
        root.style.setProperty('--primaryColor40', colors.primaryColor40);
        root.style.setProperty('--primaryColor10', colors.primaryColor10);
        root.style.setProperty('--primaryColorText', colors.primaryColorText);
    },[])

    const {mutate: _quickUpdateContent, status: _quickUpdateContentStatus} = useQuickUpdateContent()

    const getAdobeApiKey = () => {
        return import.meta.env.VITE_ENV === 'prod'
          ? import.meta.env[`VITE_ADOBE_EMBED_API_KEY__${getTenant()}`]
          : import.meta.env.VITE_ADOBE_EMBED_API_KEY; // Staging & Dev
      };

      return (
        <ThemeProvider theme={{
            adobeKey: getAdobeApiKey(),
            _Roles,
            colors,
            lang: APP_SETTINGS.lang.value,
            localize: _Locale,
            useUploadMedia,
            useGetDataLazy,
            useGetQueryData,
            getTenant,
            /** needed by CardContent. not sure what to do about this in the future. so for now we pass it thru */
            parseSpecialSyntax,

            /** extends the optionsFns object inside oolib, since these additional functions 
             * use queryhooks that are very okf-fe specific. Hence it prob doesnt make sense
             * to put these in oolib, which we are trying to keep as generic as possible */
            optionsFns: additionalOptionsFns, 

            /**for image input & simpletable */
            RichTextEditor,
            LexicalTextEditor,
            richTextHasValue,
            lexicalTextEditorHasValue,
            convertToRichText, //this one is only for SimpleTable
            pluginConfigs: pluginConfigs,

            tableElem_handleCellChangeFns: {
                quickUpdateContent: (tableRowData, colConfig, v, setShowHandleCellChangeLoader) => {
                    if(_quickUpdateContentStatus !== "loading") {
                        setShowHandleCellChangeLoader({loaderText: 'Updating...'})
                        _quickUpdateContent({
                            contentType: tableRowData.meta.kp_content_type,
                            contentId: tableRowData._id,
                            body: {
                                $set: {
                                    [
                                        Array.isArray(colConfig.valuePath) 
                                        ? colConfig.valuePath[0] 
                                        : colConfig.valuePath
                                    ] : v
                                },
                            },
                            //so once quickUpdateContent finishes successfully, we invalidate the related 
                            //query data so that screen refreshes. these below functions allow us to 
                            //fire an action once that completes successfully/errorfully
                            onInvalidateQueriesError: () => setShowHandleCellChangeLoader(undefined),
                            onInvalidateQueriesSuccess: () => setShowHandleCellChangeLoader(undefined),
                        },{
                            onError: () => {
                                setShowHandleCellChangeLoader(undefined)
                            }
                        })
                    }
                }
            },

            MobileShell
            
        }}>
            <TypoV2ClassesProvider>
                <TypoClassesProvider>
                    {props.children}
                </TypoClassesProvider>
            </TypoV2ClassesProvider>
        </ThemeProvider>
    )
}

export default OolibThemeProvider;
