import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useGenErrorMsgBanners } from "../../useGenErrorMsgBanners";

export const useUpdateSelfServeSurvey = () => {
    const queryClient = useQueryClient();
    const { genErrorMsgBanners } = useGenErrorMsgBanners();
    
    return useMutation(
        ({ contentType, active, userId }) =>
            axios.patch(
                `/api/users/updateSelfServeSurvey`,
                { contentType, userId, active },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            ),
        {
            onSuccess: (
                res,
                { contentTypeToInvalidate }
            ) => {
                queryClient.invalidateQueries(["contentListing", "lazy", [contentTypeToInvalidate]]);
            },
            onError: (err) => {
                genErrorMsgBanners({ err });
            },
        }
    );
 };

export const useBulkUpdateSelfServeSurvey = () => {
    const queryClient = useQueryClient();
    const { genErrorMsgBanners } = useGenErrorMsgBanners();
    
    return useMutation(
        ({ contentType, active, userIds }) =>
            axios.patch(
                `/api/users/bulkUpdateSelfServeSurvey`,
                { contentType, userIds, active },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            ),
        {
            onSuccess: (
                res,
                { contentTypeToInvalidate }
            ) => {
                queryClient.invalidateQueries(["contentListing", "lazy", [contentTypeToInvalidate]]);
            },
            onError: (err) => {
                genErrorMsgBanners({ err });
            },
        }
    );
}