export const configs_demo_annoBase = {
  contentTypes: {
    whitelist: ["gigWorkerSurveys", "secondaryResearchGigWorkersResearchStudies"]
},
  filterDefs: {
    // whitelist: [
    //   "annoTags_factors",
    //   "annoTags_influences",
    //   "annoTags_existingPrograms",
    //   "annoTags_findings",

    //   "tags_participantsRegistrationTemplates_main_DOT_country_DOT_value",
    //   "ROLLUP_tags_participantsRegistrationTemplates_segmentationNigeria",
    //   "tags_participantsRegistrationTemplates_main_DOT_age_DOT_value",
    //   "tags_participantsRegistrationTemplates_main_DOT_maritalStatus_DOT_value",
    //   "tags_participantsRegistrationTemplates_main_DOT_educationLevel_DOT_value",
    //   "ROLLUP_tags_participantsRegistrationTemplates_vaginalInsertableProductsUsed",
    //   "ROLLUP_tags_participantsRegistrationTemplates_useCases",
    //   "ROLLUP_tags_participantsRegistrationTemplates_accessToMobilePhones",
    //   "tags_participantsRegistrationTemplates_main_DOT_numberOfChildren_DOT_value",
    // ],
  },
  disableColorCode: false,
  
  
  cardConfigs: {  
    // tags: {
    //     whitelist: [
    //         'documentStatus'
    //     ]
    // },
    // annoTags: {
    //     whitelist: []
    // }
  },
};
