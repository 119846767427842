import { setVal } from "oolib";
import { extractAllBlocksFromTpl } from "./templating/extractAllBlocksFromTpl";

const EXCLUDED_VALUEPATHS = [
  'main.title',
  'main.subtitle',
  'main.coverImage',
  'main.coverVideo'
];

const isHiddenBlock = (block) => {
  return block.inputOnly === true || block.displayOnly === true;
};

const isDependentBlock = (block) => {
  return block.displayConditions !== undefined;
};

const shouldExcludeBlock = (block) => {
  return EXCLUDED_VALUEPATHS.includes(block.valuePath) || block.comp === 'StaticRichText';
};

function convertNumberToLetters(num) {
  let result = "";

  while(num > 0) {
    num--; // Adjust num for 0-based indexing of charCode
    const remainder = num % 26;
    result = String.fromCharCode(97 + remainder) + result;
    num = Math.floor(num / 26);
  }

  return result;
}

const getSerialNumber = ({ 
  block, 
  regularBlockCount, 
  lastRegularNumber, 
  dependentCount = 0 
 }) => {
  // For hidden or dependent blocks, use previous number or 0 if first
  if (isHiddenBlock(block)) {
    return `${lastRegularNumber || '0'}.H`;
  }
  if (isDependentBlock(block)) {
    return `${lastRegularNumber || '0'}.${convertNumberToLetters(dependentCount + 1)}`;
  }
  // Regular block gets sequential number from regularBlockCount
  return `${regularBlockCount}`;
 };

 const processRepeaterBlocks = ({ 
  blocks, 
  parentSerialNum = '', 
  blockPathPrefix,
  lastRegularNumber = '0'
 }) => {
  const processedBlocks = [];
  let regularBlockCount = 1; // Track regular blocks separately from array index
  let dependentCount = 0;
  
  blocks.forEach((block) => {
    // Skip StaticRichText blocks entirely in repeaters
    if (block.comp === 'StaticRichText') {
      processedBlocks.push(block);
      return;
    }
 
    // Reset dependent count when encountering non-dependent block
    if (!isDependentBlock(block)) {
      dependentCount = 0;
    } else {
      dependentCount++;
    }
 
    // Determine the serial number based on block properties
    const baseNumber = getSerialNumber({ 
      block, 
      regularBlockCount,
      lastRegularNumber,
      dependentCount: dependentCount - 1
    });
    
    // Update lastRegularNumber and increment counter if this is a regular block
    if (!isHiddenBlock(block) && !isDependentBlock(block)) {
      lastRegularNumber = `${regularBlockCount}`;
      regularBlockCount++; // Only increment for regular blocks
    }
    
    // Current level serial number
    const currentSerialNum = parentSerialNum ? 
      `${parentSerialNum}.${baseNumber}` : 
      baseNumber;
    
    // Process current block
    const currentBlock = {
      ...block,
      blockPath: `${blockPathPrefix}.${blocks.indexOf(block)}`,
      serialNum: currentSerialNum
    };
    
    // If this is a Repeater block with nested blocks
    if (block.comp === 'Repeater' && block.props?.blocks) {
      const nestedBlocks = processRepeaterBlocks({
        blocks: block.props.blocks,
        parentSerialNum: currentSerialNum,
        blockPathPrefix: `${blockPathPrefix}.${blocks.indexOf(block)}.props.blocks`,
        lastRegularNumber: '0' // Reset for new repeater context
      });
      
      // Add processed nested blocks
      currentBlock.nestedBlocks = nestedBlocks;
    }
    
    processedBlocks.push(currentBlock);
  });
  
  return processedBlocks;
 };

 const addSerialNumbersToTemplate = ({ tpl, mode }) => {
  // Get all blocks with their paths and filter out excluded ones immediately
  const extractedBlocks = extractAllBlocksFromTpl({ tpl })
    .filter(block => !shouldExcludeBlock(block));
  
  // Make a deep copy of template
  let newTemplate = JSON.parse(JSON.stringify(tpl));
  
  // Keep track of Repeater blocks we've seen to avoid duplicate processing
  const processedRepeaterPaths = new Set();
  
  // Track the last regular number and regular block count separately
  let lastRegularNumber = '0';
  let regularBlockCount = 1;
  let dependentCount = 0;
  
  // Process blocks with correct indexing since excluded blocks are already filtered
  extractedBlocks.forEach((block) => {
    if (!block.blockPath) return;
    
    // Reset dependent count when encountering non-dependent block
    if (!isDependentBlock(block)) {
      dependentCount = 0;
    } else {
      dependentCount++;
    }
 
    // Determine serial number based on block properties
    const serialNumber = getSerialNumber({ 
      block, 
      regularBlockCount,
      lastRegularNumber,
      dependentCount: dependentCount - 1
    });
    
    // Update lastRegularNumber and increment counter if this is a regular block
    if (!isHiddenBlock(block) && !isDependentBlock(block)) {
      lastRegularNumber = `${regularBlockCount}`;
      regularBlockCount++; // Only increment for regular blocks
    }
    
    // If this is a Repeater, process its nested blocks
    if (block.comp === 'Repeater' && block.props?.blocks) {
      const repeaterPath = block.blockPath;
      
      // Skip if we've already processed this Repeater
      if (processedRepeaterPaths.has(repeaterPath)) return;
      processedRepeaterPaths.add(repeaterPath);
      
      // Process the Repeater's blocks with hierarchical numbering
      const processedBlocks = processRepeaterBlocks({
        blocks: block.props.blocks,
        parentSerialNum: `${serialNumber}`,
        blockPathPrefix: `${repeaterPath}.props.blocks`,
        lastRegularNumber: '0' // Reset for new repeater context
      });
      
      // Update the template with processed blocks
      newTemplate = setVal(
        newTemplate,
        `${repeaterPath}.props.blocks`,
        processedBlocks
      );
    }
    
    // Update the label for all blocks (including Repeaters)
    if (block.props?.label) {
      const currentLabel = block.props.label;
      if (!/^\d+(\.\d+)*(\.[dh])?\./.test(currentLabel)) { // Updated regex to handle new suffixes
        const labelSerialNum = block.comp === 'Repeater' ? 
          `${serialNumber}.` : 
          `${serialNumber}. `;
          
        newTemplate = setVal(
          newTemplate,
          `${block.blockPath}.props.label`,
          `${labelSerialNum}${currentLabel}`
        );
      }
    }
  });
  
  return newTemplate;
 };

export const handleInjectSerialNumbersBasedOnTplConfig = ({tpl}) => {
  const shouldInjectSerialNumbers = tpl?.general?.formFieldNumbering?.enable;
  return shouldInjectSerialNumbers ? addSerialNumbersToTemplate({tpl}) : tpl;
};