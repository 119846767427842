import React from "react";
import { SidebarContainerStyled, SidebarWrapperStyled } from "./styled";
import { ButtonGhostCompact, icons, UI_BODY_SEMIBOLD } from "oolib";
import { AnimatePresence } from "framer-motion";

const { Stars } = icons;

export default function SidebarContainer({
  icon,
  title,
  topOffset,
  onClose,
  children,
  count,
}) {
  return (
    <SidebarContainerStyled
      initial={{ transform: "translateX(100%)", opacity: 0 }}
      animate={{ transform: "translateX(0%)", opacity: 1 }}
      transition={{ type: "spring", stiffness: 300, damping: 30 }}
      exit={{ transform: "translateX(100%)", opacity: 0 }}
      topOffset={topOffset}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1.4rem",
        }}
      >
        {" "}
        <div
          style={{
            display: "flex",
            gap: "0.6rem",
            alignItems: "center",
            padding: "0.2rem 0rem",
          }}
        >
          {icon ? <Stars size="16" /> : null}

          <UI_BODY_SEMIBOLD>
            {title} {`${count ? `(${count})` : ""}`}
          </UI_BODY_SEMIBOLD>
        </div>
        <ButtonGhostCompact icon="X" onClick={() => onClose(false)} />
      </div>

      <SidebarWrapperStyled>{children}</SidebarWrapperStyled>
    </SidebarContainerStyled>
  );
}
