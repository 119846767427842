import { getDataQueryFn } from "../../../utils/react-query-hooks/getData";
import { extractAllBlocksFromTpl } from "../../../utils/templating/extractAllBlocksFromTpl";


export const parseQuickLink = (url) => {
   
  if (!url || !url.includes('self-serve-quick-link')) {
   
    return null;
  }

  try {
   
    const urlObj = new URL(url);
   
    const pathParts = urlObj.pathname.split('/');
    const contentType = pathParts[pathParts.length - 1];
    
    const searchParams = new URLSearchParams(urlObj.search);
    
    const presetValues = [];
    searchParams.forEach((value, valuePath) => {
      presetValues.push({
        valuePath,
        value
      });
    });

    return {
      contentType,
      presetValues
    };
  } catch (error) {
    console.log('Error parsing quicklink URL:', error);
    return null;
  }
};

export const handleQuickLink = async ({
  quickLinkData,
  userData,
  queryClient,
  generateFn,
  generateTplIsLoading,
  setSurveyData
}) => {
  if (!quickLinkData) return;
  
  const { contentType, presetValues } = quickLinkData;

  const existingSurvey = userData?.user?.selfServeSurveys?.find(
    (us) => us.contentType === contentType && !!us.documentId === true
  );

  if (existingSurvey) {
    setSurveyData({
      contentType: contentType,
      documentId: existingSurvey.documentId,
      mode: existingSurvey.status === "published" ? "view" : "edit",
    });
    return;
  }

  const tpl = queryClient.getQueryData(["tpl", contentType]);
  if (!tpl) return;

  const allBlocks = extractAllBlocksFromTpl({ tpl });
  
  const presetValuesWithBlockConfig = presetValues.map((presetV) => {
    const targetFieldBlock = allBlocks.find(block => block.valuePath === presetV.valuePath);
    return ({
      ...presetV,
      blockConfig: targetFieldBlock
    })
  })

  const getPresetValuesForTagBlocks = presetValuesWithBlockConfig => {
    return presetValuesWithBlockConfig.filter(presetV => {
      return ['TagsInputSingle', 'TagsInputMulti'].includes(presetV?.blockConfig?.comp)
    })
  }
  const relatedTagDocs = await getDataQueryFn({
    contentTypes: getPresetValuesForTagBlocks(presetValuesWithBlockConfig)
      .map(presetV => presetV?.blockConfig?.props?.tagType),
    projection: {
      'main.title': 1,
      _id: 1,
      tagId: 1
    },
    findQuery: {
      tagId: { $in: getPresetValuesForTagBlocks(presetValuesWithBlockConfig)
        .map(presetV => presetV.value.split('__')[0] /** this basically gives us the 'tagId' of the tag */) 
      }
    }
  })
  

  const processedPresetValues = presetValuesWithBlockConfig.map(({ valuePath, value, blockConfig }) => {
    
    let processedValue;

    if (blockConfig?.comp === "TagsInputSingle") {
        /**
         * this is a bit of a hack, but for TagsInputSingle, 
         * the value has to be in the format of <tagId>__<_id>
         * this hack is technically not needed anymore, since we have sorted it out
         * by actually fetching the tag docs from DB.
         * 
         * its still backwards compat, but now value can simply be the tagId
         */
      const valuePathSplit = valuePath.split(".");

      const tagIdFromUrl = value.split('__')[0];

      const relatedTagDoc = relatedTagDocs?.data?.find(d => d.tagId === tagIdFromUrl)

      const tagDocId = relatedTagDoc?._id || value.split('__')[1]
      const tagDisplay = relatedTagDoc?.main?.title || tagIdFromUrl

      processedValue = {
        collectionId: valuePathSplit[1],
        data: [{
          _id: tagDocId,
          display: tagDisplay,
          tagId: tagIdFromUrl,
        }],
      };

    } else if (blockConfig?.comp === "RadioList") {
      processedValue = blockConfig.props?.options?.find(option => option.value === value)?.value;
    } else {
      processedValue = value;
    }

    return {
      valuePath,
      value: processedValue
    };
  });

  

  if (!generateTplIsLoading) {
    generateFn(
      {
        kp_content_type: contentType,
        kp_settings: [],
      },
      {
        onSuccess: async (res) => {
          await queryClient.invalidateQueries("userData");
          setSurveyData({
            contentType: contentType,
            documentId: res.data.content._id,
            mode: "edit",
          });
        },
        updateSelfServeSurvey: true,
      },
      processedPresetValues,
    );
  }
};