import {
  Accordion,
  ButtonPrimaryCompact,
  CheckboxInput,
  Divider,
  DropdownSingle,
  SwitchSingle,
  TabBarV2,
  TagDisplayV2,
  UI_BODY,
  UI_BODY_BOLD_SM,
  UI_BODY_SEMIBOLD,
  UI_BODY_SEMIBOLD_SM,
  UI_BODY_SM,
  UI_CAPTION,
  UI_DISPLAY,
  UI_TAG,
  getText,
  icons,
  transition,
  useHandleClickOutside,
  DropdownMulti,
  useScreenWidth,
  getBreakPoint,
  ButtonSecondaryCompact
} from "oolib";
import { Tag, TagClear } from "oolib/dist/v2/components/Tags";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import FilterModule from "../../discovery/FilterModule";
import { SidebarLayoutV2 } from "../../layout/SidebarLayoutV2";
import { ListingModuleAnnotationExplorer } from "./comps/ListingModuleAnnotationExplorer";
import { SearchSortPanel } from "./comps/SearchSortPanel";
import { useGetAnnotationExplorerData } from "./useGetAnnotationExplorerData";
import { getAnnoExplorerConfigsForThisTenant } from "./utils/getAnnoExplorerConfigsForThisTenant";
import { useGetDataQuery } from "../../../utils/react-query-hooks/getData";
import { wrap } from "lodash";
import { DropdownGroup } from "./comps/DropdownGroup";
import { getFilterType } from "@/components/inputs/DynamicTagsInputs/FilterInput/utils/getFilterType";
import { fetchFilterOptionsConfigs } from "@/components/inputs/DynamicTagsInputs/FilterInput/configs";
import { createPortal } from "react-dom";
import { ColorCodeLightBox } from "./comps/ColorCodeLightBox";
import { addFillColorsToEachAnno } from "./utils/addFillColorsToEachAnno";
import { CustomSelectComp } from "./comps/CustomSelectComp";
import { getTenant } from "@/TENANT_CONFIGS";
import { generateColorCodeOptions } from "./utils/generateColorCodeOptions";
export const annoStorageKey = `${getTenant()}_annoExplorer`

const { File, PaintBucket, Funnel } = icons;

export const StyledGradientContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 1.2rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  ${transition("height")}
  
  background: linear-gradient(
      135deg,
    #D9E6F2 0%,    /* Left blue shade */
    #D8E3F3 20%,   /* Middle-left blue shade */
    #ECE0F1 60%,   /* Middle-right purple shade */
    #F3DCF1 100%   /* Right pink/purple shade */
  );
`;


export const AnnotationExplorer = () => {
  

  const screenWidth = useScreenWidth()  
  const isMobile = screenWidth < getBreakPoint("md");
  
  const [parentToggleState, setParentToggleState] = useState({ lsb: isMobile ? false : true, rsb: false });

  const annoExplorerConfigs = getAnnoExplorerConfigsForThisTenant()

  const {
    tplOptions,
    tplOptionsDropdownData,
    selectedTpls,
    setSelectedTpls,
    activeFilters,
    setActiveFilters,
    activeSort,
    setActiveSort,
    keyword,
    setKeyword,
    data: data_,
    filterConfigs,
    DEFAULT_SORT_OPTIONS,
    dataFetchStatus,
    error,
    totalCount,
    observerRef
  } = useGetAnnotationExplorerData({
    contentTypesWhitelist: annoExplorerConfigs?.contentTypes?.whitelist,
    initialCardsLimit: 20
  })

  const contentTypeSelectRef = useRef(null);

  const selectedTPLsArrayOptions = useMemo(() => {
    return selectedTpls.map((d) => ({
      display: d.general?.content?.title || d.kp_content_type,
      value: d.kp_content_type,
    }))
  }, [ selectedTpls, activeSort ])

  const handleTagClearClick = (value) => {
    setSelectedTpls((prevSelectedTpls) =>
      prevSelectedTpls.filter((tpl) => tpl.kp_content_type !== value)
    );
  };

  const tabOptions = [
    { value: "filters", icon: "Funnel", display: isMobile ?  "TagFilter" : "Filters" },
    !annoExplorerConfigs?.disableColorCode ? { value: "breakdown", icon: "PaintBucket", display: "Color Code", message: "new" } : null
  ].filter(Boolean);
  
  const colorCodeOptions = generateColorCodeOptions(filterConfigs)
  
  const [ selectedView, setSelectedView ] = useState(tabOptions[0])

  const [ breakDown, setBreakDown ] = useState(() => {
    if (typeof window !== 'undefined') {
      try {
        const savedValue = sessionStorage.getItem(annoStorageKey);
        if (savedValue) {
          return JSON.parse(savedValue);
        }
      } catch (error) {
        console.error('Error parsing saved color code state', error);
      }
    }
    return {
      activeColorFilter: colorCodeOptions[0]?.options[0],
      activeColorConfig: undefined
    };
  });

  const { activeColorFilter, activeColorConfig } =  useMemo(() => breakDown, [ breakDown ]) ;
  const [ colorCodeBanner, setColorCodeBanner ] = useState(true)
  // const [ enableColorCode, setEnableColorCode ] = useState( !!activeColorFilter )

  const { data, colorsConfig: appliedColorConfig } = activeColorConfig ? addFillColorsToEachAnno({ data: data_, colorsConfig: breakDown?.activeColorConfig }) : { data: data_ }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      sessionStorage.setItem(annoStorageKey, JSON.stringify(breakDown));
    }
  }, [ breakDown , annoStorageKey ]);


  const changeColorFilter = (value) => {
    setBreakDown(prevState => {
      return {
        activeColorFilter: value,
        activeColorConfig: undefined 
      };
    });
    
  };

  const updateColorConfig = (value) => {
    setBreakDown(prevState => {
      return {
        ...prevState,
        activeColorConfig: value
      };
    });
  };
  
  
  useEffect(() => {
    const handleBeforeUnload = () => {
      if (typeof window !== 'undefined') {
        sessionStorage.setItem(annoStorageKey, JSON.stringify(breakDown));
      }
    };
  
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [ breakDown, annoStorageKey ]);


  // const enableColorCodeFn = (k, v) => {
  //   if (!v) {
  //     setBreakDown({
  //       activeColorFilter: undefined,
  //       activeColorConfig: undefined
  //     });
  //   }
  //   setEnableColorCode(v);
  // };

  const generateSidebarUI = (value) => {
      switch(value){
        case "filters" :
            return(<>
            {!isMobile &&
              <div>
                <div style={{ padding: "1.4rem", position: "sticky", top: 0, paddingBottom: "0rem", display: "flex", alignItems: "center", gap: "0.3rem" }}>
                  <File size={16} weight="bold"/> <UI_BODY_SEMIBOLD_SM>{"Choose Template"}</UI_BODY_SEMIBOLD_SM>
                </div>
                <div style={{ padding: "1.4rem", display: "flex", gap: "0.4rem", flexDirection: "column" }}>
                  <DropdownMulti
                    S
                    genCustomSelectComp={() => 
                      <CustomSelectComp
                        placeholder={"Select Content Types"}
                        display={`${selectedTPLsArrayOptions?.length} Selected`}
                      />
                    }
                    popOutOfOverflowHiddenParent={true}
                    options={tplOptionsDropdownData}
                    value={selectedTPLsArrayOptions}
                    onChange={(k, v) =>
                      setSelectedTpls(
                        v.map((d) =>
                          tplOptions.find(
                            (dd) => dd.kp_content_type === d.value
                          )
                        )
                      )
                    }
                  />
                  {selectedTPLsArrayOptions?.length > 0 &&
                    <div style={{ display: "flex", gap: "0.4rem", width: '100%', flexWrap: "wrap" }}>
                      {selectedTPLsArrayOptions.map(v => (
                        <TagClear
                          key={v.value}
                          grey
                          id={v.value}
                          display={v.display}
                          size={'XS'}
                          onClick={() => handleTagClearClick(v.value)}
                        />
                      ))}
                    </div>
                  }
                </div>
              <Divider />
              </div> }
            <div style={{ height: `calc(100dvh - ${contentTypeSelectRef.current?.getBoundingClientRect()?.bottom}px)`}}>
              <FilterModule
                key={filterConfigs.map(conf => conf.sectionId).join('__')}
                filterConfig={filterConfigs}
                {...{ activeFilters, setActiveFilters }}
              />
            </div>
            </>
          )
          
        case "breakdown":
          return(
            <div style={{ padding: "1.4rem", position: "relative", display: "flex", gap: "1.4rem", flexDirection: "column" }}>
                    {colorCodeBanner  &&
                    <StyledGradientContainer>
                      <div>
                        <UI_BODY_SM>
                          <UI_BODY_BOLD_SM style={{ display: "inline-block", marginRight: "0.5rem" }}>Colorcode </UI_BODY_BOLD_SM> 
                          lets you breakdown your annotations and view them with different colors using tags from one tag category.</UI_BODY_SM>
                      </div>
                      <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <ButtonPrimaryCompact onClick={() => setColorCodeBanner(false)}>Got it</ButtonPrimaryCompact>
                      </div>
                    </StyledGradientContainer>
                    }
                    <div style={{ display: "flex", gap: "0.4rem", flexDirection: "column", width: "100%" }}>
                        {/* <PaintBucket size="16" weight="bold"/> */}
                      <UI_BODY_SM>Tag Category</UI_BODY_SM>
                        {/* <SwitchSingle 
                          option={{display: '', value: true}}
                          value={enableColorCode}
                          saveValueAsString
                          onChange={enableColorCodeFn}
                          /> */}
                      {(colorCodeOptions.length > 0 ) ? 
                        <DropdownGroup 
                          options={colorCodeOptions} 
                          onChange={(k, v) => {
                            changeColorFilter(v)
                          }}
                          value={activeColorFilter}
                        />
                        : <UI_BODY_SEMIBOLD_SM>Not Supported for Indirect / ROLLUP filters</UI_BODY_SEMIBOLD_SM>
                      }
                    </div>
                {(activeColorFilter && activeColorFilter?.source?.filterType) &&
                <div style={{ display: "flex", gap: "0.4rem", flexDirection: "column", width: "100%" }}>
                  <UI_BODY_SM>Select the tags to view breakdown</UI_BODY_SM>
                    <ColorCodeLightBox 
                      activeColorFilter={activeColorFilter} 
                      contentTypes={annoExplorerConfigs?.contentTypes?.whitelist}
                      activeFilters={activeFilters}
                      
                      value={activeColorConfig}
                      onChange={(value) => updateColorConfig(value)}
                      />
                </div>
                  }
              <Divider/>
            </div>
          )
      }
  } 

  return (
    <div>
      <SidebarLayoutV2
        gridHeightCSS="calc(100vh - 6rem)"
        lsbWidth={isMobile ? screenWidth + 10 : 300}
        toggleState={parentToggleState}
        setToggleState={setParentToggleState}
        lsbAllowCollapse={true}
        toggleAnimationStyle="mask"
        lsbComp={
          <div style={{ position: "relative" }}>
            {!isMobile && <div style={{ position: "sticky", top: 0, zIndex: 1000 }}>
              <TabBarV2 
                  options={tabOptions} 
                  value={selectedView}
                  onChange={(id, v) => setSelectedView(v)}
                  style={{ padding: "0.45rem 1rem", maxHeight: "4rem" }}
                  />
              <Divider/>
            </div> }
            {isMobile &&
            <div style={{ position: "Sticky", top: 0, zIndex: 99, background: "white" }}> 
              <div style={{ display: "flex", height: "4.4rem", alignItems: "center", justifyContent: 'space-between', padding: "0.45rem 1.4rem" }}>
                <div style={{ display: "flex", gap: "0.4rem" }}>
                    <UI_BODY_BOLD_SM>{selectedView.display}</UI_BODY_BOLD_SM>
                </div>  
                <ButtonSecondaryCompact onClick={() => setParentToggleState(prev => ({ ...prev, lsb: !prev.lsb }))}>Close</ButtonSecondaryCompact>
              </div>
              <Divider />
            </div>  }
            {generateSidebarUI(selectedView?.value)}
          </div>
        }
      >
      <div style={{ position: "relative" }}>

        <SearchSortPanel 
          {...{ 
            tplOptionsDropdownData, 
            selectedTPLsArrayOptions, 
            setSelectedTpls, 
            selectedView, 
            tplOptions, 
            selectedView, 
            setSelectedView, 
            setParentToggleState, 
            isMobile, 
            setKeyword, 
            keyword, 
            defaultSortOptions: DEFAULT_SORT_OPTIONS, 
            activeSort, 
            setActiveSort,
            disableColorCode: annoExplorerConfigs?.disableColorCode
          }}/>

        <ListingModuleAnnotationExplorer
          observerRef={observerRef}
          dataFetchStatus={dataFetchStatus}
          dataFetchError={error}
          data={data}
          totalCount={totalCount}
          breakDown={breakDown} 
          setBreakDown={setBreakDown}
          appliedColorConfig={appliedColorConfig}
          activeColorFilter={activeColorFilter}
          isMobile={isMobile} 
          />
      </div>
      </SidebarLayoutV2>
    </div>
  );
};

