import { ButtonSecondary, Modal } from "oolib";
import React from "react";
import { handleDataExport } from "../../../../utils/general";


export const DataDownloadModal = ({setExporting,getDataToBeExported, setShowDataDownloadModal}) => {
  
  const handleClick = ({dataFormat}) => {
    handleDataExport({getDataToBeExported, setExporting, dataFormat })
    setShowDataDownloadModal(false)
  }

  return (
    <Modal
      fitToContentHeight={true}
      desktopWidth="500px"
      onClose={() => setShowDataDownloadModal(false)} title="Choose a format"
    >
      <div style={{ display: "flex", gap: "1rem", padding: "2rem" }}>
        <ButtonSecondary onClick={() => handleClick({dataFormat: 'csv'})}>CSV</ButtonSecondary>
        <ButtonSecondary onClick={() => handleClick({dataFormat: 'json'})}>JSON</ButtonSecondary>
      </div>
    </Modal>
  )
}
