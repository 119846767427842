import React, { Fragment, useRef, useState } from "react";
import { createPortal } from "react-dom";
import {
    CheckboxInput,
    ButtonSecondaryCompact,
    TextLoader,
    UI_BODY_SEMIBOLD,
    UI_BODY_SEMIBOLD_SM,
    colors2,
    useScreenWidth,
    useScroll,
    DateRangePicker,
    ButtonGhostV2
} from "oolib";
import { FilterActionsButton } from "../../../discovery/FilterModule/comps/FilterActionsButton";
import {
    StyledActionsArea,
    StyledFiltersWrapper,
    StyledLightBoxActionButton,
    StyledOptionsLightboxWrapper,
    StyledOptionsLoaderWrapper,
    StyledOptionWrapper,
    StyledSelectClearButtonsWrapper,
    StyledSelectClearInnerWrapper,
} from "../styled";

const LightboxBreakpointPX = 700;

const getLightBoxHeight = (asideFilter) => {
  const bounds = asideFilter.current?.getBoundingClientRect();

  const topOfSidebarToBottomOfScreen = window.innerHeight - bounds.top - 50;
  const topOfSiderbarToBottomOfSidebar = bounds.bottom - bounds.top - 50;

  return Math.min(topOfSidebarToBottomOfScreen, topOfSiderbarToBottomOfSidebar);
}

const MultiSelectFilterTypes = ({id, options, onChange, value}) => {

  const handleOptionClick = (option) => {
    if(option.loading) return

    let newValue = [...value];
    let isSelected = newValue.some((d) => d.value === option.value);
    if (!isSelected) {
      newValue.push(option);
    } else {
      newValue = newValue.filter((d) => d.value !== option.value);
    }

    onChange(id, newValue);
};

  return (
    <Fragment>
      {options?.map((d) => (
          <StyledOptionWrapper
            key={d.value}
            onClick={() => handleOptionClick(d)}
          >
            {d.loading ? (
              <StyledOptionsLoaderWrapper>
                <TextLoader style={{ width: "8rem", height: "1.5rem" }} />
              </StyledOptionsLoaderWrapper>
            ) : (
              <CheckboxInput className={'filterOptionsLightbox__checkboxInput'} option={d} value={value} />
            )}
          </StyledOptionWrapper>
        ))}
    </Fragment>
  )
}

const DateRangeType = ({id, onChange, value}) => {
    const [val, setVal] = useState(value);

    const handleChange = (k, v) => {
      setVal(v)
      if(Array.isArray(v) && v.length === 2 && v.every(vv => !!vv === true)){
        onChange(id, v)
      }
    }

  return (
    <DateRangePicker
      onChange={handleChange}
      value={val}
      id={id}
      />
  )
}

const LightboxComp = ({id, comp, props, onChange, value}) => {

  const compsLookup = {
    DateRangeType,
    MultiSelectFilterTypes
  }

  const Comp = compsLookup[comp]

  return (
    <Comp
      id={id}
      onChange={onChange}
      value={value}
      {...props}
      />
  )
}

export const OptionsLightbox = ({
    ref_optionsLightboxWrapper,
    ref_sideFilterHeader,
    ref_asideFilter,
    lightboxConfig,
    onChange,
    value,
    title,
    id,
    setExpand
  }) => {

    const screenWidth = useScreenWidth();
    const actionsAreaRef = useRef();

    // const [ search, setSearch ] = useState("");



  const getBoundsOfSideFilterHeader = () => ref_sideFilterHeader?.current?.getBoundingClientRect();

  const [ optionsLightboxTop, setOptionsLightboxTop ] = useState(getBoundsOfSideFilterHeader()?.bottom);

  useScroll(() => {
    setOptionsLightboxTop(getBoundsOfSideFilterHeader()?.bottom);
  });

  const isMobile = screenWidth < LightboxBreakpointPX;
  const lightBoxHeight = getLightBoxHeight(ref_asideFilter);

  const desktopFiltersWrapperHeight = lightBoxHeight - (actionsAreaRef.current?.getBoundingClientRect().height || 0);
  const optionsLightboxWidth = isMobile ? 300 : lightboxConfig.comp === 'DateRangeType' ? 300 : 220;
  
  const overflowSetting = {
    StyledOptionsLightboxWrapper: lightboxConfig.comp === 'DateRangeType' ? 'visible' : 'hidden',
    StyledFiltersWrapper: lightboxConfig.comp === 'DateRangeType' ? 'visible' : 'auto'
  }

  return createPortal(
    
     
      
      <StyledOptionsLightboxWrapper
      style={{overflow: overflowSetting.StyledOptionsLightboxWrapper}}
      ref={ref_optionsLightboxWrapper}
      width={optionsLightboxWidth + 'px'}
      isMobile={isMobile}
      height={lightBoxHeight + 'px'}
      optionsLightboxTop={isMobile ? 0 : optionsLightboxTop}
      optionsLightboxLeft={isMobile ? 0 : ref_asideFilter.current.offsetWidth + 4}
    >
      {/* Title area mobile */}
      {isMobile &&
        <div style={{borderBottom: `1px solid ${colors2.grey10}`,}}>
          <div style={{ padding: '1.2rem 1.4rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '0.5rem' }}>
            <UI_BODY_SEMIBOLD>{title}</UI_BODY_SEMIBOLD>
            <ButtonSecondaryCompact onClick={() => setExpand(false)} value={"Close"}/>
          </div>
        </div>
      }

      {/* Actions area, Search, sort, clear, select all */}
      <StyledActionsArea ref={actionsAreaRef}>
        {!isMobile && <UI_BODY_SEMIBOLD_SM
          style={{
            color: colors2.grey80,
            padding: "0.9rem 1rem",
            borderBottom: `1px solid ${colors2.grey10}`,
          }}
        >
          {title}
        </UI_BODY_SEMIBOLD_SM>}
        {/* <TextInput 
          value={search}
          onChange={(id, v) => setSearch(v)}
          placeholder={"Search"}
          icon="MagnifyingGlass"
          /> */}
        {lightboxConfig?.comp === 'MultiSelectFilterTypes' && <StyledSelectClearButtonsWrapper>
          <StyledSelectClearInnerWrapper>
            <StyledLightBoxActionButton
              disabled={lightboxConfig.props?.options?.some(i => i.loading) || lightboxConfig.props?.options?.length === value.length}
              icon="CheckCircle"
              iconSize={16}
              iconWeight="bold"
              iconColor={colors2.grey80}
              onClick={() => onChange(id, lightboxConfig.props?.options)}
              responsive={true}
              isMobile={isMobile}
            >
              Select All
            </StyledLightBoxActionButton>
            <StyledLightBoxActionButton
              disabled={value.length === 0}
              icon="XCircle"
              iconSize={16}
              iconWeight="bold"
              iconColor={colors2.grey80}
              onClick={() => onChange(id, [])}
              responsive={true}
              isMobile={isMobile}
            >
              Clear All
            </StyledLightBoxActionButton>
          </StyledSelectClearInnerWrapper>
        </StyledSelectClearButtonsWrapper>}
      </StyledActionsArea>


      {/* Filter Options area */}
      <StyledFiltersWrapper 
        lightboxConfig={lightboxConfig}
        style={{ 
        maxHeight: isMobile ? '100svh' : desktopFiltersWrapperHeight,
        overflow: overflowSetting.StyledFiltersWrapper
        }}>
        <LightboxComp
          id={id}
          onChange={onChange}
          value={value}
          comp={lightboxConfig.comp}
          props={lightboxConfig.props}
          />
      </StyledFiltersWrapper>

    </StyledOptionsLightboxWrapper>,
    document.getElementById("optionsLightbox-root")
  );
};
