import React from "react";
import { render } from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from "./App";
import { QueryClient, QueryClientProvider } from "react-query";
import { SocketContextProvider } from "./contexts/socketContext";
import { ResearchNotesV1ContextProvider } from "./contexts/researchNotesV1Context";
import { FormValidationContextProvider } from "./contexts/formValidationContext";
import { setBackendUrlInProduction,setTenantRequestHeader } from "./axiosDefaults";
import { PlatformBuilderContextProvider } from "./contexts/platformBuilderContext";
import { getTenant } from "./TENANT_CONFIGS";
import { BannerProvider, HintsProvider } from "oolib";

// export async function init() {
//   window.miro.board.ui.on('icon:click', async () => {
//     await window.miro.board.ui.openPanel({url: 'app.html'});
//   });
//   // miro.board.ui.on('icon:click', addSticky);
// }

// init();


// Sentry.init({
//   dsn: "https://eaa302b58e7c4a0f9d65304471abe67a@o1375186.ingest.sentry.io/6728387",
//   integrations: [new BrowserTracing()],
//   environment: process.env.NODE_ENV === 'production'
//     ? import.meta.env.VITE_ENV
//     : 'local',
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });


Sentry.init({
  dsn: "https://998523deaa2c2e12b192096f637f8621@o4508481528135680.ingest.us.sentry.io/4508481532133376",
  environment: process.env.NODE_ENV === 'production'
    ? import.meta.env.VITE_ENV
    : 'local',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/okf-be-prod-dot-ok-framework.el.r.appspot.com/, /^https:\/\/okf-be-staging-dot-ok-framework.el.r.appspot.com/, /^https:\/\/okf-be-dev-dot-ok-framework.el.r.appspot.com/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

Sentry.setTag("tenant", getTenant());

// new query client
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

/**
 * not the most optimized but important because:
 * If a tab with OKF open is left idle for 5mins (default cacheTime), then
 * this cache is destroyed and after that if a user trys to navigate it throws
 * an error cuz platform configs are required at all times for this app to
 * function properly. Another place where this is done is
 */
queryClient.setQueryDefaults(['tpl'], {
  cacheTime: Infinity
})
queryClient.setQueryDefaults(['platformConfigs'], {
  cacheTime: Infinity
})
queryClient.setQueryDefaults(['allTpls'], {
  cacheTime: Infinity
})

//this is an axios default we are setting.
setBackendUrlInProduction()

// set tenant to req.headers
setTenantRequestHeader()

render(
  <QueryClientProvider client={queryClient}>
    <SocketContextProvider>
      <BannerProvider>
        <FormValidationContextProvider>
          <ResearchNotesV1ContextProvider>
              <PlatformBuilderContextProvider>
                <HintsProvider>
                  <App />
                </HintsProvider>
              </PlatformBuilderContextProvider>
          </ResearchNotesV1ContextProvider>
        </FormValidationContextProvider>
      </BannerProvider>
    </SocketContextProvider>
  </QueryClientProvider>,
  document.getElementById("root")
);
