export const __isLocalhost = () =>
  Boolean(
    window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.0/8 are considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );

/**
 * Our service worker won't work if PUBLIC_URL is on a different origin
   from what our page is served on. This might happen if a CDN is used to
   serve assets; see https://github.com/facebook/create-react-app/issues/2374

   We dont mess with PUBLIC_URL in OKE. so this abort process is unlikely to ever fire.
 */
export const __isPublicUrlMismatch = () => {
  // The URL constructor is available in all browsers that support SW.
  const publicUrl = new URL(import.meta.env.PUBLIC_URL, window.location.href);
  if (publicUrl.origin !== window.location.origin) {
    console.log("publicurl.origin diff from window.location.origin");
    console.log("publicUrl.origin", publicUrl.origin);
    console.log("window.location.origin", window.location.origin);

    return true;
  }
};


export const __checkIsSWValid = (swUrl, config, registerCallback) => {
    // Check if the service worker can be found. If it can't reload the page.
    fetch(swUrl, {
      headers: { 'Service-Worker': 'script' },
    })
      .then((response) => {
        // Ensure service worker exists, and that we really are getting a JS file.
        const contentType = response.headers.get('content-type');
        if (
          response.status === 404 ||
          (contentType != null && contentType.indexOf('javascript') === -1)
        ) {
          // No service worker found. Probably a different app. Reload the page.
          navigator.serviceWorker.ready.then((registration) => {
            registration.unregister().then(() => {
              window.location.reload();
            });
          });
        } else {
          // Service worker found. Proceed as normal.
          registerCallback(swUrl, config);
        }
      })
      .catch(() => {
        console.log('No internet connection found. App is running in offline mode.');
      });
  }
