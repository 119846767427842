import React, { useState } from "react";
import { SidebarLayoutV2 } from "../../layout/SidebarLayoutV2";

import {
  colors,
  colors2,
  Container,
  DataVizAccordion,
  DropdownSingle,
  formatCamelCaseToSentenceCase,
  getVal,
  makeArrayFromLength,
  MetaBlock,
  MetaBlockV2,
  PaddingTopBottom20,
  PaddingTopBottom40,
  SANS_2,
  SANS_4_5,
  SERIF_4_5,
  SkeletonLoader,
  TabBarV2,
  transition,
  UI_BODY_BOLD_SM_DF,
  UI_BODY_SM_DF,
  UI_TITLE,
  Wrapper700,
} from "oolib";
import styled from "styled-components";
import { __GetAllContentTypeConfig } from "../../../utils/getters/gettersV2";
import { useDynamicLayoutHeight } from "../InsightMatrix/utils/useDynamicLayoutHeight";
import ErrorStates from "../../ErrorStates";
import { tplBlocks } from "../../../importGroups/tplBlocks";
import { blockIsPopulatedConditions } from "../../../utils/validation/blockIsPopulatedConditions";

import { useElemInView } from "../../../utils/customHooks/useElemInView";
import FilterModule from "../../discovery/FilterModule";
import { getValidFieldsForQuantViz } from "../InsightMatrix/utils/getValidFieldsForQuantViz";

const StyledButton = styled.button`
  background-color: ${({active}) => active ? colors2.secondaryContainer : colors.white};
  border: 0;
  border-radius: 0.4rem;
  padding: 1rem 2rem;
  text-align: left;
  &:hover {
    background-color: ${({active}) => active ? colors2.secondaryContainer :  colors2.grey5 }
  }
  ${transition('background-color')}
  display: block;
  width: 100%;
  cursor: pointer;
`;

export const CompRendererDataPivot = ({
  pivotableBlocks,
  setActiveBlock,
  activeBlock,
  setSubActiveBlock,
  subActiveBlock,
  data,
  dataFetchStatus,
  dataFetchError,
  dataVizAccordion,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
  filterConfig,
  activeFilters,
  setActiveFilters
}) => {
  const { parentWrapperRef, gridHeightCSS } = useDynamicLayoutHeight();

  const BlockComp = activeBlock && tplBlocks[activeBlock.comp];
  const blockProps = {
    readOnly: true,
    ...(activeBlock?.props || {}),
    label: undefined, //removing label at the block level cuz we are showing it as the page header
    sublabel: undefined,
  };

  const getAppropriateBlockLabel = bl => bl.props?.shortLabel || bl.props?.label || formatCamelCaseToSentenceCase(bl.valuePath.replace('main.', ''))
  const { observerRef } = useElemInView({ callback: fetchNextPage });
  const renderBlocks = () => {
    const flattenedData =
      data?.pages.reduce((a, b) => [...a, ...b.data], []) || [];

    return (
      <div style={{backgroundColor: colors.greyColor3}}>
      <Wrapper700 left style={{padding: '1rem 1.6rem 1rem 1.6rem' }}>
        
          <UI_TITLE>
            {getAppropriateBlockLabel(activeBlock)}
          </UI_TITLE>
        
        </Wrapper700>
      <div style={{display: 'grid', gridTemplateColumns: '700px 1fr' }}>
       
        
      
        
        <div style={{backgroundColor: colors2.white, borderRadius: '8px', border: `1px solid ${colors2.grey10}`, marginLeft: '1.6rem', boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.06)`}}>
          <div style={{
            padding: '0 1.6rem',
          }}>
            <div style={{
            padding: '1.2rem 0',
            borderBottom: `1px solid ${colors2.grey10}`
          }}>
          <UI_BODY_BOLD_SM_DF>{`All Responses (${data?.pages[0]?.count})`}</UI_BODY_BOLD_SM_DF>
            </div>
          </div>
        {flattenedData.map((d, index) => {
          const value = getVal(d, activeBlock.valuePath);
          const shouldDisplayBlock = blockIsPopulatedConditions(
            activeBlock,
            value
          );
          
          if (!shouldDisplayBlock){
            if(flattenedData.length === index + 1){
              return <div ref={(hasNextPage && flattenedData.length === index + 1) ? observerRef : null}/> 
            }else{
              return null
            }
          } 
          //else
          return (
            <div key={d._id} style={{padding: '0 1.6rem'}} ref={hasNextPage && flattenedData.length === index + 1 ? observerRef : null}>
              <div style={{padding: '1.6rem 0', borderBottom: `1px solid ${colors2.grey10}`}}>
              <BlockComp {...blockProps} value={value} />
              <div style={{marginTop: '0.8rem'}}>
                <MetaBlockV2
                  data={d}
                  config={[
                    {key: 'name', valuePath: 'meta.kp_contributed_by.name'},
                    {key: 'date', valuePath: 'kp_date_published'}
                  ]}
                  />
                  </div>
              </div>
            </div>
          );
        })}
        </div>
        
      
       
        <div>
        {
          dataVizAccordion && 
          <div style={{padding: '0 1.6rem'}}>
            <DataVizAccordion
              key={subActiveBlock + JSON.stringify(activeFilters)}
              {...dataVizAccordion}
              />
              </div>
        
          }
        </div>
      
      
      </div>
      </div>
    );
  };
const sidebarTabOps = [
  { icon: 'ListDashes', value: 'formFields'},
  { icon: 'Funnel', value: 'filters'},
  { icon: "CirclesFour", value: "breakDown" }
]
  const [activeSidebarTab, setActiveSidebarTab] = useState(sidebarTabOps[0].value)

  return (
    <div ref={parentWrapperRef}>
      <div style={{display:'grid', gridTemplateColumns: '280px 1fr'}}>
      <div style={{height: '100%'}}>

        
            <div style={{height: '100vh', borderRight: `1px solid ${colors.greyColor10}`, overflow:'auto', position: 'sticky', top: 0, padding: '0.4rem', paddingTop: 0}}>
              
              <div style={{padding: '0.6rem 1.6rem'}}>
              <TabBarV2
                options={sidebarTabOps}
                value={activeSidebarTab}
                onChange={(k,v) => setActiveSidebarTab(v)}
                saveValueAsString
                />
                </div>
                {activeSidebarTab === 'formFields' ? pivotableBlocks?.map((bl) => (
                
                <StyledButton active={bl.valuePath === activeBlock?.valuePath} onClick={() => setActiveBlock(bl)}>
                  <UI_BODY_SM_DF>{getAppropriateBlockLabel(bl)}</UI_BODY_SM_DF>  
                </StyledButton>
              )) : activeSidebarTab === "breakDown" ? (
                getValidFieldsForQuantViz(pivotableBlocks)
                  .filter((d) => d.valuePath !== activeBlock.valuePath)
                  .map((bl) => (
                    <StyledButton
                      active={bl.valuePath === subActiveBlock?.valuePath}
                      onClick={() => setSubActiveBlock(bl)}
                    >
                      <UI_BODY_SM_DF>
                        {getAppropriateBlockLabel(bl)}
                      </UI_BODY_SM_DF>
                    </StyledButton>
                  ))
              ): <FilterModule
          filterConfig={filterConfig}
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
          />}
              
            </div>
          </div>
          {dataFetchStatus === "loading" ? (
          makeArrayFromLength(6).map(() => (
            <SkeletonLoader style={{ height: "13rem", margin: "1rem 0" }} />
          ))
        ) : dataFetchStatus === "error" ? (
          <ErrorStates
            errorResponseObject={dataFetchError}
            enableLinkToHomepage={false}
          />
        ) : (
          dataFetchStatus === "success" && renderBlocks()
        )}
      </div>
    </div>
  );
};
