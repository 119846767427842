import React, { useState } from "react";

import { ButtonPrimary , colors, injectHttps, Modal, TabBarStyle1, TextInput} from 'oolib'
//actions

//sass
//utils

import {  __GetContentTypeConfigNew } from '../../../../../utils/getters/gettersV2'

import { useGetContentListing } from "../../../../../utils/react-query-hooks/listings";

const { primaryColor10, white } = colors; 
 
const KPLinkInput = ({ id, onConfirm, onClose, textInputProps, linkType }) => {
	//col settings must be the bootstrap codes

	
	

	const [linkInputOption, setLinkInputOption] = useState('internal')
	const [selectedLink, setSelectedLink] = useState({
		value: '',
		type: null,
	})

	const handleSetSelectedLink = (v, type) => {
		setSelectedLink({
			value:
				type === 'internal'
					? `${window.location.protocol}//${window.location.host}/published-page/${v}`
					: v,
			type,
		})
	}

	const handleToggle = (k, v) => {
		setLinkInputOption(v)
	}

	const {data: listings, status : listingsStatus} = useGetContentListing({contentType: 'events', enabled: !!__GetContentTypeConfigNew('events')}) // chat with abrar


	const [displayText, setDisplayText] = useState('')

	return (
		<Modal title='Insert Link' onClose={onClose}>
			{linkType === 'button' && (
				<TextInput
					placeholder='Write Button Text Here'
					value={displayText}
					onChange={(k, v) => setDisplayText(v)}
				/>
			)}
			<TabBarStyle1
				id='kp_link_input_toggle'
				options={[
					{ value: 'internal', name: 'Internal Resource' },
					{ value: 'external', name: 'External Resource' },
				]}
				onChange={(k, v) => handleToggle(k, v)}
			/>
			<div className='kp-link-input__body'>
				{linkInputOption === 'internal' ? (
					<div className='kp-link-input__internal-link-options'>
						{listingsStatus === 'loading' ? (
							<div>loading...</div>
						) : (
							listings && listings.map((d) => (
								<div
									onClick={() =>
										handleSetSelectedLink(
											`${d.meta.kp_content_type}?id=${d._id}`,
											'internal',
										)
									}
									style={{
										cursor: 'pointer',
										borderBottom: '1px solid grey',
										padding: '0.5rem',
										backgroundColor: selectedLink.value.includes(d._id)
											? primaryColor10
											: white,
									}}>
									<h4 className='h4 bold'>
										{d.main.kp_title && d.main.kp_title.blocks[0].text}
									</h4>
									<h4 className='h4'>
										{d.main.kp_subtitle && d.main.kp_subtitle.blocks[0].text}
									</h4>
								</div>
							))
						)}
					</div>
				) : (
					<TextInput
						{...textInputProps}
						value={selectedLink.value}
						onChange={(k, v) => handleSetSelectedLink(v, 'external')}
					/>
				)}
			</div>
			<ButtonPrimary
				onClick={() =>
					onConfirm(
						id,
						selectedLink.type === 'external'
							? injectHttps(selectedLink.value)
							: selectedLink.value,
						selectedLink.type,
						displayText,
					)
				}>
				Insert Link
			</ButtonPrimary>
		</Modal>
	)
}


export default KPLinkInput;
