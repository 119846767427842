export const extractRepeaterImages = (repeaterInstances) => {
    return repeaterInstances.reduce((acc, instance) => {
        
      const images = instance.main.imagesRepeater.map((d) => ({
        ...d,
        id: instance._id,
        contentType: instance.meta.kp_content_type,
        country: instance.tags?.country,
        districts: instance.tags?.districts,
        states: instance.tags?.states,
        stakeholder: instance.tags?.stakeholder,
        subject: instance.tags?.subject,
        facilityType: instance.tags?.facilityType,
        teams: instance.tags?.teams,
        kp_date_published: instance?.kp_date_published
      }));
      
      const imagesWithMeta = images.reduce((acc, b) => {
        const metaData = {
          stakeholder: b.stakeholder,
          facilityType: b.facilityType,
          subject: b.subject,
          contentType: b.contentType,
          country: b.country,
          states: b.states,
          districts: b.districts,
          teams: b.teams
      };

        const imagesArray = b.image?.map((i) => ({
          ...i,
          id: b.id,
          metaData
        }));
        return [...acc, ...(imagesArray || [])];
      }, []);
      
      return [...acc, ...imagesWithMeta];
    }, []);
  };