import {
    colors
} from "oolib";
import React from "react";

/**
 * currently only used in these 2 GELABS custom comp plugins, but this could potentially
 * streamline our layout shell for listing pages... maybe..
 */

export const FilterPlusBodyShellForCollectionPages = ({
  leftComp,
  filterStickyTop,
  children
}) => {
  const sidebarWidth = 300;

  return (
    <div
      style={{ display: "grid", gridTemplateColumns: `${sidebarWidth}px 1fr` }}
    >
      <div
        style={{
          borderRight: `1px solid ${colors.greyColor10}`,
          height: `calc(100dvh - ${filterStickyTop}px)`,
          position: "sticky",
          top: filterStickyTop + "px",
        }}
      >
        {leftComp}
      </div>
      <div>{children}</div>
    </div>
  );
};
