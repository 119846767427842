import styled, {css} from "styled-components";
import { mediaQuery } from "oolib";

export const ButtonsStyled = styled.div`
  display: ${({ showButton }) => (showButton ? "flex" : "none")};
  gap: 0.4rem;
  height: fit-content;
`;

// Then use it in SearchBarStyled
export const SearchBarStyled = styled.div`
  width: 100%;
  padding: 1.2rem;
  background: #ffffff;
  border-radius: 1rem;
  min-height: 9rem;
  border: 1px solid #0000001a;
  box-shadow: 0px 4px 10px 0px #0000000f;

  :focus-within {
    border: 1px solid #184469;
    ${ButtonsStyled} {
      display: flex;
    }
  }
`;

export const LoadingButtonStyled = styled.div`
  svg {
    ${({ loading }) =>
      loading &&
      css`
        animation: rotate 1s linear infinite;
      `}
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
// export const SearchBarStyled = styled.div`
//   width: 100%;
//   padding: 1.2rem;
//   background: #ffffff;
//   border-radius: 1rem;
//   min-height: 9rem;
//   border: 1px solid #0000001a;
//   box-shadow: 0px 4px 10px 0px #0000000f;

//   :focus-within {
//     border: 1px solid #184469;
//     ${ButtonsStyled} {
//       display: flex;
//     }
//   }
//   /* background: linear-gradient(180deg, #FFFFFF 62.09%, rgba(255, 255, 255, 0.5) 100%); */

//   /* background: linear-gradient(180deg, #FFFFFF 62.09%, rgba(255, 255, 255, 0.5) 100%); */
// `;

export const NewLineStyled = styled.div`
  display: none;

  ${mediaQuery("sm")} {
    display: block;

    color: #00000080;

    p {
      display: inline;
    }
  }
`;

// export const ButtonsStyled = styled.div`
//   display: none;
//   gap: 0.4rem;
//   height: fit-content;
// `;
