import { useContext } from "react";
import { queryClient } from "../../index";
import { AppSettingsContext } from "../../contexts/appSettingsContext";
import { genLocaleKeyForTextString } from "./utils/genLocaleKeyForTextString";

/**
 * A function that translates text based on the active language and locale data.
 *
 * @param {string} text - The text to be translated.
 * - Method 1: _Locale("This is a sentence which has simple text without any dyanmic word")
 * - Mehtod 2: _Locale(`This is a another sentence, which has <word1 : ${dynamicword}> inside a sentence as a template literal`)
 * @return {string} The translated text.
 */
// example of dynamice word in this file `client/src/pageTpls/PublishedPage/utils/useGenUnderEditBanners.js`
export const _Locale = (text) => {
  
  const {_LocaleData } = queryClient.getQueryData('platformConfigs') // STATIC
  
/**
 * get locale data from the active tpl configs
 * and merge it into the static _LocaleData
 */
 
  let allTpls = queryClient.getQueryData('allTpls')


  const _TotalLocaleData = (() => {
    let allTplsObj = allTpls.filter(d => !!d.tplLocales === true).reduce((con, next ) => {
      return ({ ...con, ...next.tplLocales})
    },{})
    let total = { ..._LocaleData, ...allTplsObj }
    return total;
  })()

  // ----> dynamic locale data coming from templates ----> getQueriesData(tpl) /// <----- optmize?

 
  //getQueriesData('tpl', { inactive : false})

  // if not on:
  // -- contribute flow
  // -- published page
  // -- questionaire page
  // -- userprofile 
  // -- org page
  // -- groups page
  const { APP_SETTINGS } = useContext(AppSettingsContext);

  if(typeof text !== 'string') return text;

  // Capture leading and trailing whitespace. useful in some cases where the input text is some sort of prefix that NEEDS the trailing space.
  const leadingSpace = text.match(/^\s*/)[0];
  const trailingSpace = text.match(/\s*$/)[0];
  
  let textTrim = text.trim();

  let activeLang = APP_SETTINGS.lang;

  // Find dynamic words in the input text ff
  // Regular expression to match patterns like <word1 : value>, <word2:value>, etc.
  const dynamicWordsRegex = /<word\d+\s*:\s*(.+?)>/g;
  const dynamicWords = {};

  //  Replace dynamic words with placeholders and store their values
  let processedText = textTrim.replace(dynamicWordsRegex, (match, value) => {
    // Extract the word index from the match (e.g., 1 for <word1>, 2 for <word2>, etc.)
    const wordIndex = match.match(/\d+/)[0];
    // Store the dynamic word value in the dynamicWords object, using <word\d+> as the key
    dynamicWords[`<word${wordIndex}>`] = value;
    // Replace the matched pattern with the placeholder <word\d+>
    return `<word${wordIndex}>`;
  });

  // Convert the processed text to lowercase for lookup
  let textTrimLs = processedText.toLowerCase();
  let textTrimLsNoSpace = genLocaleKeyForTextString(processedText);

  //    we dont translate english. We leave that text as is.
  //    /**
  //    * ALL OUR LOCALE DATA KEYS ARE ALWAYS WRITTEN IN LOWERCASE
  //    * Why?
  //    * Because, e.g.
  //    * if the word 'publish' is used at 2 different places on the platform:
  //    * once as 'Publish' & another time as 'PUBLISH', then,
  //    * 
  //    * if our locale data key is 'Publish', 
  //    * then translation for 'PUBLISH' won't work.
  //    * 
  //    * OUR LOCALE DATA LOOKUP IS CASE INSENSITIVE
  //    *  
  //    */

  //   /**
  //    * OUR LOCALE LOOKUP DOESNT CONTAIN ENGLISH TEXT
  //    * Why?
  //    * 
  //    * Take the same example of 'Publish' and 'PUBLISH'
  //    * If we have 1 lookup for both those words, then our case will be lost to whatever is
  //    * defined in the locale lookup 
  //    */

  let result;
  if (activeLang.value === 'en') {
    result = replaceDynamicWords(processedText, dynamicWords);
  } else {
    let localeEntry = _TotalLocaleData[textTrimLsNoSpace] || _TotalLocaleData[textTrimLs];
    if (localeEntry && localeEntry[activeLang.value]) {
      result = replaceDynamicWords(localeEntry[activeLang.value], dynamicWords);
    } else{
      // If no translation found, return the original text with dynamic words replaced
      result = replaceDynamicWords(processedText, dynamicWords);  
    }
    
  }

  return leadingSpace + result + trailingSpace;

}


const replaceDynamicWords = (text, dynamicWords) => {
  let result = text;
  Object.entries(dynamicWords).forEach(([key, value]) => {
    result = result.replace(key, value);
  });
  return result;
};