import { colors2 } from 'oolib';
import React, { useState } from 'react';
import styled from 'styled-components';

const { grey5 } = colors2

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  background-color: white;
  border-radius: 8px;
  width: 100%;
  max-width: 1000px;
`;

const HeaderRow = styled.div`
  display: flex;
`;

const HeaderSpacer = styled.div`
  width: 144px;
`;

const CategoryHeader = styled.div`
  width: 64px;
  height: 70px;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 500;
  color: #6b7280;
  transform: rotate(270deg) translateX(16px);
  transform-origin: center;
`;

const StateRow = styled.div`
  display: flex;
  ${props => props.selected && `
    background-color: ${grey5};
    outline: 1px solid black;
    border-radius: 8px;
    outline: 2px solid #1f2937;
    outline-offset: 2px;
  `}
`;

const StateLabel = styled.div`
  width: 130px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #4b5563;
`;

const Cell = styled.div`
  width: 64px;
  height: 48px;
  margin: 4px;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.intensity > 0.5 ? 'white' : 'black'};
  font-size: 0.75rem;
  font-weight: 500;
  background-color: ${props => {
    // Color intensity mapping based on intensity (0-1)
    if (props.intensity === 0) return '#fce7f3';
    if (props.intensity < 0.2) return '#fbcfe8';
    if (props.intensity < 0.4) return '#f9a8d4';
    if (props.intensity < 0.6) return '#f472b6';
    if (props.intensity < 0.8) return '#ec4899';
    if (props.intensity < 0.95) return '#db2777';
    return '#be185d';
  }};
`;

const TooltipContainer = styled.div`
  position: absolute;
  background-color: white;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  padding: 8px 12px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  z-index: 10;
  display: ${props => props.visible ? 'block' : 'none'};
  top: ${props => props.top}px;
  left: ${props => props.left}px;
`;

const TooltipTitle = styled.div`
  font-weight: 600;
  margin-bottom: 4px;
`;

const TooltipContent = styled.div`
  font-size: 0.875rem;
  color: #4b5563;
`;

/**
 * Dumb Heatmap Visualization Component
 * 
 * @param {Object} props
 * @param {Object} props.data - The heatmap data 
 * @param {Array} props.data.xValues - Array of objects with value and display for x-axis
 * @param {Array} props.data.yValues - Array of objects with value and display for y-axis
 * @param {Array} props.data.grid - Array of cell data objects with x, y, count, and intensity
 * @param {Function} props.onCellClick - Optional callback for cell click (receives cell data)
 * @param {Function} props.onRowClick - Optional callback for row click (receives y value)
 */
const HeatmapVisualization = ({ 
  data, 
  onCellClick, 
  onRowClick
}) => {
  const [selectedYValue, setSelectedYValue] = useState(null);
  const [tooltip, setTooltip] = useState({
    visible: false,
    top: 0,
    left: 0,
    content: null
  });

  if (!data || !data.xValues?.length || !data.yValues?.length) {
    return <div>No data available for visualization</div>;
  }

  // Handler for row selection
  const handleRowSelect = (yValue) => {
    const newSelectedValue = yValue === selectedYValue ? null : yValue;
    setSelectedYValue(newSelectedValue);
    if (onRowClick) {
      onRowClick(newSelectedValue);
    }
  };

  // Handler for cell hover
  const handleCellMouseEnter = (event, cell) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setTooltip({
      visible: true,
      top: rect.bottom + window.scrollY + 10,
      left: rect.left + window.scrollX,
      content: cell
    });
  };

  // Handler for cell hover exit
  const handleCellMouseLeave = () => {
    setTooltip({
      ...tooltip,
      visible: false
    });
  };
  
  // Handler for cell click
  const handleCellClick = (cell) => {
    if (onCellClick) {
      onCellClick(cell);
    }
  };

  return (
    <Container>
      {/* Tooltip */}
      <TooltipContainer 
        visible={tooltip.visible} 
        top={tooltip.top} 
        left={tooltip.left}
      >
        {tooltip.content && (
          <>
            <TooltipTitle>{tooltip.content.xDisplay} + {tooltip.content.yDisplay}</TooltipTitle>
            <TooltipContent>Count: {tooltip.content.count}</TooltipContent>
            <TooltipContent>Percentage: {Math.round(tooltip.content.intensity * 100)}%</TooltipContent>
          </>
        )}
      </TooltipContainer>

      {/* Categories header row */}
      <HeaderRow>
        <HeaderSpacer />
        {data.xValues.map((x, index) => (
          <CategoryHeader className='UI_CAPTION' key={`category-${index}`}>
            {x.display}
          </CategoryHeader>
        ))}
      </HeaderRow>

      {/* States and cells */}
      {data.yValues.map((y, yIndex) => (
        <StateRow 
          key={`state-${yIndex}`}
          selected={selectedYValue === y.value}
          onClick={() => handleRowSelect(y.value)}
        >
          <StateLabel className='UI_CAPTION'>{y.display}</StateLabel>
          
          {data.xValues.map((x, xIndex) => {
            // Find the corresponding cell data
            const cellData = data.grid.find(
              item => item.x === x.value && item.y === y.value
            );
            
            return (
              <Cell 
                key={`cell-${yIndex}-${xIndex}`}
                intensity={cellData ? cellData.intensity : 0}
                onMouseEnter={(e) => handleCellMouseEnter(e, cellData)}
                onMouseLeave={handleCellMouseLeave}
                onClick={() => handleCellClick(cellData)}
              >
                {cellData ? cellData.count : 0}
              </Cell>
            );
          })}
        </StateRow>
      ))}

      {/* Legend */}
      <div style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}>
        <span style={{ marginRight: '8px', fontSize: '0.75rem' }}>Intensity:</span>
        {[0, 0.2, 0.4, 0.6, 0.8, 1].map((intensity) => (
          <div
            key={`legend-${intensity}`}
            style={{
              width: '20px',
              height: '20px',
              marginRight: '2px',
              backgroundColor: intensity === 0 ? '#fce7f3' :
                             intensity < 0.2 ? '#fbcfe8' :
                             intensity < 0.4 ? '#f9a8d4' :
                             intensity < 0.6 ? '#f472b6' :
                             intensity < 0.8 ? '#ec4899' :
                             intensity < 0.95 ? '#db2777' : '#be185d'
            }}
          />
        ))}
        <span style={{ marginLeft: '8px', fontSize: '0.75rem' }}>Low</span>
        <span style={{ marginLeft: 'auto', fontSize: '0.75rem' }}>High</span>
      </div>
    </Container>
  );
};

export default HeatmapVisualization;