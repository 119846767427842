import { boxShadow1, colors2 as colors, transition } from "oolib";
import styled, { css } from "styled-components";
import { getGradientStyle } from "./utils/gradients";


export const StyledCardShell = styled.div`
  border: 1px solid rgba(0,0,0,0);
  border-radius: 0.5rem;
  background-color: white;
  cursor: pointer;
  /* background: ${colors.white}; */
  ${transition("background-color")}
  &:hover {
    /* ${boxShadow1}; */
    border-color: ${colors.grey20};
  }
  width: 100%;
`;

export const StyledInfoSection = styled.div`
  ${props => getGradientStyle(props.colors)}
  padding: 1.6rem;
  padding-top: ${({ colors }) => colors?.length > 0 ? '1.8rem' : '1.6rem'}; // To make room for the gradients
  padding-bottom: 0;
  z-index: 0;
  overflow: hidden;
  /* ${transition("padding-top")} */
`;

export const StyledButton = styled.button`
  display: inline-flex;
  justify-content : space-between;
  border: none;
  cursor: pointer;
  background-color: transparent;
  gap: 0.4rem; 
  align-items: center;
  overflow: hidden;
  padding: 0.8rem 1.4rem;
  width: 100%;
  ${transition('background-color')}
  :hover{
    background-color: ${colors.grey5};
  }
  border-radius: 0.5rem;
`

export const StyledIconWrapper = styled.div`
  ${transition('transform')};
  ${({show}) => !show && css`
    transform: rotate(180deg);
  `}
  display: flex;
`