import React, { useEffect, useState } from "react";
import { DropdownSingle, ModalSmall, useBannerContext } from "oolib";
import { useBulkUpdateSelfServeSurvey, useUpdateSelfServeSurvey } from "../../../../../../../utils/react-query-hooks/users/useUpdateSelfServeSurvey";
import { useGetQueryData } from "../../../../../../../utils/react-query-hooks/general";

export const useActivateSelfServeSurveyModal = ({
   contentType,
   onConfirmFunction,
}) => {
   const [showModal, setShowModal] = useState(undefined);
   const [selectedSurvey, setSelectedSurvey] = useState(undefined);
   const { _ProfileTypes } = useGetQueryData("platformConfigs");
   const { mutate: updateSelfServeSurvey, status: updateSelfServeSurveyStatus } = useUpdateSelfServeSurvey();
   const { mutate: bulkUpdateSelfServeSurvey, status: bulkUpdateSelfServeSurveyStatus } = useBulkUpdateSelfServeSurvey();
   const { SET_INFO_BANNER } = useBannerContext();

   const activateSelfServeSurvey = (e, data) => {
      setShowModal({ type: "activate", data });
   };

   // Get all self-serve content types except current one
   const activateSelfServeSurveyContentTypes = Array.from(
      new Set(
         _ProfileTypes
            .filter((p) => p.selfServeSurveyConfig?.surveys?.length > 0)
            .flatMap((p) => p.selfServeSurveyConfig?.surveys)
      )
   );

   const selfServeContentTypesExceptCurrent = activateSelfServeSurveyContentTypes.filter(
      (ct) => ct.contentType !== contentType
   );

   const modalOnConfirmFunctions = {
      singleActivateSelfServeSurvey: () => {
         if(updateSelfServeSurveyStatus === "loading") return;
         
         updateSelfServeSurvey(
            {
               contentType: selectedSurvey,
               userId: showModal.data.meta.kp_contributed_by?._id,
               active: true,
               contentTypeToInvalidate: contentType,
            },
            {
               onSuccess: () => {
                  setShowModal(undefined);
                  SET_INFO_BANNER(
                     "Survey activated successfully",
                     "green",
                     3000
                  );
               },
            }
         );
      },
      bulkActivateSelfServeSurvey: () => {
         if(bulkUpdateSelfServeSurveyStatus === "loading") return;
         const { userIds, bulkActions_setSelectedRowIds } = showModal.data;

         bulkUpdateSelfServeSurvey(
            {
               contentType: selectedSurvey,
               userIds,
               active: true,
               contentTypeToInvalidate: contentType,
            },
            {
               onSuccess: (res) => {
                  const { successful, failed } = res.data;
                  setShowModal(undefined);
                  SET_INFO_BANNER(
                     `${successful ? `Survey activated successfully for ${successful} users.` : ""} ${failed ? ` Failed for ${failed} users`: ""}`,
                     successful && failed ? "yellow" : successful ? "green" : "red",
                     3000
                  );
                  bulkActions_setSelectedRowIds([]);
               },
            }
         );
      },
   }

   const ActivateSelfServeSurveyModal = showModal ? (
      <ModalSmall
         title="Activate Self-Serve Survey"
         onClose={() => {
            setShowModal(undefined);
            setSelectedSurvey(undefined);
         }}
         onConfirmText="Activate Survey"
         showActionPanel
         onConfirm={selectedSurvey ? modalOnConfirmFunctions[onConfirmFunction] : undefined}
      >
         <div style={{ width: "100%" }}>
            <DropdownSingle
               options={selfServeContentTypesExceptCurrent.map((ct) => ({
                  value: ct.contentType,
                  display: ct.contentType,
               }))}
               saveValueAsString
               value={selectedSurvey}
               onChange={(k, v) => setSelectedSurvey(v)}
               placeholder="Select a survey to activate"
            />
         </div>
      </ModalSmall>
   ) : null;

   return {
      ActivateSelfServeSurveyModal,
      activateSelfServeSurvey,
      activateSelfServeSurveyContentTypes,
   };
};
