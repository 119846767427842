import { UIContent } from "../../../UIContent";

export const getAccessControlEnabledPLBModules = () =>
  [...menuOps, ...oldMenuOps]
    .reduce((a, b) => [...a, ...(b.subMenu || [b])], [])
    .filter((d) => d.addToPlatformBuilderAccessControl === true);

export const menuOps = [
  {
    display: "General",
    comp: "General",
    value: "general",
    icon: "Cube",
  },
  {
    display: UIContent.UserSettings.title,
    comp: "UserSettings",
    value: "userSettings",
    icon: "UserList",
    infoTooltip: {
      text: UIContent.UserSettings.infoTooltip,
      position: "bottom",
    },
    addToPlatformBuilderAccessControl: true, //this property enables access control against this module. do a global search of 'addToPlatformBuilderAccessControl' to see how
    addToGeneral: true,
  },
  {
    display: "Content Templates",
    comp: "ContentTypesManager",
    value: "contentTypesManager",
    icon: "Table",
    addToPlatformBuilderAccessControl: true,
    addToGeneral: true,
  },
  {
    display: "Tags Manager",
    value: "tagsManager",
    comp: "TagsManager",
    icon: 'Tag',
    addToGeneral: true,
    addToPlatformBuilderAccessControl: true
  },
  {
    display: "System",
    value: "system",
    icon: "Browsers",
    subMenu: [
      {
        display: "Themes Settings",
        value: "themesSettings",
        comp: "ThemesSettings",
        infoTooltip: {
          text: `Themes allow you to set colors for the platform according to your brand guidelines`,
          position: "bottom",
        },
        addToPlatformBuilderAccessControl: true,
        addToGeneral: true,
      },

      {
        display: "Navigation",
        value: "navigation",
        comp: "GlobalNavBuilder",
        infoTooltip: {text: `In the navigation section, you have 3 sections. 
        Logo Section is to upload logos for both header and footer.
        Header Section is to add pages to the header.
        Footer Section is to hide/show links from the header and add any other required links`, position: 'bottom'},
        addToPlatformBuilderAccessControl: true,
        addToGeneral: true,
        
      },
      {
        display: "Language Settings",
        value: "adminLocaleData",
        comp: "AdminLocaleData",
      },
      {
        display: "Access Control",
        comp: "AccessControlBuilder",
        value: "accessControl",
        infoTooltip: {text:UIContent.AccessControlBuilder.SubModuleHeader_tooltip,position:"bottom"},
        addToPlatformBuilderAccessControl: true,
        addToGeneral: true,
        
      },
    ],
  },
  {
    display: "Activity Analytics",
    value: "adminActivityAnalyticsList",
    comp: "AdminActivityAnalyticsList",
    icon: "Activity",
    addToPlatformBuilderAccessControl: true,
    addToGeneral: true,
  },
  {
    display: "User Analytics",
    value: "userAnalytics",
    comp: "UserAnalytics",
    icon: "Activity",
   addToPlatformBuilderAccessControl: true,
   // addToGeneral: true,
  },
  {
    display: "Search Manager",
    value: "searchManager",
    comp: "SearchManager",
    icon: "Binoculars",
  },
  {
    display: "Static Pages",
    value: "staticPages",
    comp: "AdminStaticPagesEdit",
    addToPlatformBuilderAccessControl: true,
    icon: "File",
  },
  {
    display: "Data Architecture (beta)",
    value: "dataArchitecture",
    comp: "AdminDataArchitectureViz",
    icon: "File",
  },
  {
    display: "AI Control Panel (beta)",
    value: "aiControlPanel",
    comp: "AIControlPanel",
    addToPlatformBuilderAccessControl: true,
    icon: "File",
  },
];

export const oldMenuOps = [
  {
    display: "Global Configurations",
    value: "globals",
    comp: "ConfigGlobals",
    props: { type: "deployment" },
    addToGeneral: true,
  },
  {
    display: "❗️ Page Builder",
    value: "pageBuilder",
    comp: "PageBuilder",
  },
];


 

