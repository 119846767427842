import schools_ss  from '../../staticData/schools_snapshot.json'
import anganwadis_ss from '../../staticData/anganwadis_snapshot.json'
import households_ss from '../../staticData/households_snapshot.json'
import {
	districtsSurveyed,
} from '../../staticData/general.json'

//the indices of the correspondings Keys and vals need to match in the arrayOfVals, and arrayOfKeys arrays as well.
//eg. [a_v,b_v,c_v] & [a_k, b_k, c_k] = correct | [a_v,b_v,c_v] & [a_k,c_k,b_k] = wrong
export const getHighestValAndKey = (arrayOfVals, arrayOfKeys) => {
	var highestVal = 0
	var highestValIndex = -1
	arrayOfVals.map((val, idx) => {
		if (val[0] > highestVal) {
			highestVal = val[0]
			highestValIndex = idx
		}
	})
	return [arrayOfVals[highestValIndex], arrayOfKeys[highestValIndex]]
}

export const getSelectedDistrictData = (selectedDistrict) => {
	const selectedDistrictData = {
		district: { value: selectedDistrict },
		schoolsData: {},
		anganwadisData: {},
		householdsData: {},
	}

	districtsSurveyed.map((district) => {
		if (district.value === selectedDistrict) {
			selectedDistrictData.district.name = district.name
		}
	})

	schools_ss.map((dataPoint) => {
		const { variable, district, percentage, category } = dataPoint
		if (
			variable === 'individuals_dental_fluorosis_symptoms' &&
			district === selectedDistrict
		) {
			selectedDistrictData.schoolsData.fluorosisSymptoms = {
				fluorosisType: 'Dental Fluorosis',
				value: [percentage, 100],
			}
		}
		if (variable === 'less_than_4_days_week' && district === selectedDistrict) {
			selectedDistrictData.schoolsData.lessThan4DaysNutrition = {
				who: 'schools',
				value: [percentage, 100],
			}
		}
		if (
			category === 'water_quality_fieldtestkit' &&
			district === selectedDistrict
		) {
			if (!selectedDistrictData.schoolsData.waterQualityFieldtestkit) {
				selectedDistrictData.schoolsData.waterQualityFieldtestkit = {
					who: 'schools',
				} //initialize
			}
			selectedDistrictData.schoolsData.waterQualityFieldtestkit[
				variable + '_value'
			] = [percentage, 100]
		}
	})

	anganwadis_ss.map((dataPoint) => {
		const { category, variable, district, percentage,
			// count, total
		} = dataPoint
		if (
			variable === 'anganwadis_aware_of_fluorosis' &&
			district === selectedDistrict
		) {
			selectedDistrictData.anganwadisData.unawareOfFluorosis = {
				who: 'Anganwadi workers',
				value: [100 - percentage, 100],
			}
		}
		if (variable === 'less_than_4_days_week' && district === selectedDistrict) {
			selectedDistrictData.anganwadisData.lessThan4DaysNutrition = {
				who: 'Anganwadis',
				value: [percentage, 100],
			}
		}
		if (
			category === 'water_quality_fieldtestkit' &&
			district === selectedDistrict
		) {
			if (!selectedDistrictData.anganwadisData.waterQualityFieldtestkit) {
				selectedDistrictData.anganwadisData.waterQualityFieldtestkit = {
					who: 'anganwadis',
				} //initialize
			}
			selectedDistrictData.anganwadisData.waterQualityFieldtestkit[
				variable + '_value'
			] = [percentage, 100]
		}
		if (variable === 'muac_critical' && district === selectedDistrict) {
			selectedDistrictData.anganwadisData.muacCritical = [percentage, 100]
		}
	})

	households_ss.map((dataPoint) => {
		const { variable, district, percentage, category } = dataPoint
		if (
			variable === 'households_aware_fluorosis' &&
			district === selectedDistrict
		) {
			selectedDistrictData.householdsData.unawareOfFluorosis = {
				who: 'households',
				value: [100 - percentage, 100],
			}
		}
		if (variable === 'less_than_4_days_week' && district === selectedDistrict) {
			selectedDistrictData.householdsData.lessThan4DaysNutrition = {
				who: 'households',
				value: [percentage, 100],
			}
		}
		if (
			category === 'water_quality_fieldtestkit' &&
			district === selectedDistrict
		) {
			if (!selectedDistrictData.householdsData.waterQualityFieldtestkit) {
				selectedDistrictData.householdsData.waterQualityFieldtestkit = {
					who: 'households',
				} //initialize
			}
			selectedDistrictData.householdsData.waterQualityFieldtestkit[
				variable + '_value'
			] = [percentage, 100]
		}
	})

	return selectedDistrictData
}
