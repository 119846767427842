export const infoData = {
  gelabs: {
    allText:
      "The chatbot answers questions based on Immersion z, y, and x templates.\n\nIt cannot respond to questions outside the defined topic areas.\n\nResponses are generated using simple vector search, making it unsuitable for broad queries requiring context from extensive or all data.\n\nIt may not provide accurate or complete answers for highly specific data aggregation requests, such as participant lists based on complex criteria.\n\nFor vague or overly complex queries, results may not be accurate. Users are encouraged to provide feedback and describe the issue so improvements can be made.\n\nThe chatbot has been instructed not to infer information such as gender or names to minimize incorrect presumptions. As a result, some queries within the context and training data may remain unanswered. This will be improved over time.",
    isLexical: true,
    editorState: {
      root: {
        children: [
          {
            children: [
              {
                children: [
                  {
                    detail: 0,
                    format: 0,
                    mode: "normal",
                    style: "",
                    text: "The chatbot answers questions based on",
                    type: "text",
                    version: 1,
                  },
                  {
                    detail: 0,
                    format: 1,
                    mode: "normal",
                    style: "",
                    text: " Immersion 1A IDI Nigeria",
                    type: "text",
                    version: 1,
                  },
                  {
                    detail: 0,
                    format: 0,
                    mode: "normal",
                    style: "",
                    text: ", ",
                    type: "text",
                    version: 1,
                  },
                  {
                    detail: 0,
                    format: 1,
                    mode: "normal",
                    style: "",
                    text: "Immersion 1A FGD Nigeria",
                    type: "text",
                    version: 1,
                  },
                  {
                    detail: 0,
                    format: 0,
                    mode: "normal",
                    style: "",
                    text: ",",
                    type: "text",
                    version: 1,
                  },
                  {
                    detail: 0,
                    format: 1,
                    mode: "normal",
                    style: "",
                    text: " Immersion 1B IDI Nigeria",
                    type: "text",
                    version: 1,
                  },
                  {
                    detail: 0,
                    format: 0,
                    mode: "normal",
                    style: "",
                    text: " and ",
                    type: "text",
                    version: 1,
                  },
                  {
                    detail: 0,
                    format: 1,
                    mode: "normal",
                    style: "",
                    text: "Immersion 1B FGD Nigeria",
                    type: "text",
                    version: 1,
                  },
                  {
                    detail: 0,
                    format: 0,
                    mode: "normal",
                    style: "",
                    text: ".",
                    type: "text",
                    version: 1,
                  },
                ],
                direction: "ltr",
                format: "",
                indent: 0,
                type: "listitem",
                version: 1,
                value: 1,
              },
              {
                children: [
                  {
                    detail: 0,
                    format: 0,
                    mode: "normal",
                    style: "",
                    text: "The chatbot can only respond to questions using relevant data that is available in the text fields (such as transcripts and researcher notes) in the above mentioned research notes.",
                    type: "text",
                    version: 1,
                  },
                ],
                direction: "ltr",
                format: "",
                indent: 0,
                type: "listitem",
                version: 1,
                value: 2,
              },
              {
                children: [
                  {
                    detail: 0,
                    format: 0,
                    mode: "normal",
                    style: "",
                    text: "Responses are generated using simple vector search, making it unsuitable for broad queries requiring context from extensive or all data.",
                    type: "text",
                    version: 1,
                  },
                ],
                direction: "ltr",
                format: "",
                indent: 0,
                type: "listitem",
                version: 1,
                value: 3,
              },
              {
                children: [
                  {
                    detail: 0,
                    format: 0,
                    mode: "normal",
                    style: "",
                    text: "It may not provide accurate or complete answers for highly specific data aggregation requests, such as participant lists based on complex criteria.",
                    type: "text",
                    version: 1,
                  },
                ],
                direction: "ltr",
                format: "",
                indent: 0,
                type: "listitem",
                version: 1,
                value: 4,
              },
              {
                children: [
                  {
                    detail: 0,
                    format: 0,
                    mode: "normal",
                    style: "",
                    text: "For vague or overly complex queries, results may not be accurate. Users are encouraged to provide feedback and describe the issue so improvements can be made.",
                    type: "text",
                    version: 1,
                  },
                ],
                direction: "ltr",
                format: "",
                indent: 0,
                type: "listitem",
                version: 1,
                value: 5,
              },
              {
                children: [
                  {
                    detail: 0,
                    format: 0,
                    mode: "normal",
                    style: "",
                    text: "The chatbot has been instructed not to infer information such as gender or names to minimize incorrect presumptions. As a result, some queries within the context and training data may remain unanswered. This will be improved over time",
                    type: "text",
                    version: 1,
                  },
                ],
                direction: "ltr",
                format: "",
                indent: 0,
                type: "listitem",
                version: 1,
                value: 6,
              },
            ],
            direction: "ltr",
            format: "",
            indent: 0,
            type: "list",
            version: 1,
            listType: "bullet",
            start: 1,
            tag: "ul",
          },
        ],
        direction: "ltr",
        format: "",
        indent: 0,
        type: "root",
        version: 1,
      },
    },
  },
};

export const defaultContent = {
    allText:
      "Welcome to our AI-powered chatbot. This chatbot can answer questions about relevant topics in our database.\n\nIt provides informative responses based on available data.\n\nFor best results, ask clear and specific questions related to the supported topics.\n\nThe chatbot may not be able to answer queries outside its knowledge base.\n\nIf you receive an incomplete answer, try rephrasing your question.\n\nYour feedback helps us improve the system over time.",
    isLexical: true,
    editorState: {
      root: {
        children: [
          {
            children: [
              {
                children: [
                  {
                    detail: 0,
                    format: 0,
                    mode: "normal",
                    style: "",
                    text: "Welcome to our ",
                    type: "text",
                    version: 1,
                  },
                  {
                    detail: 0,
                    format: 1,
                    mode: "normal",
                    style: "",
                    text: "AI-powered chatbot",
                    type: "text",
                    version: 1,
                  },
                  {
                    detail: 0,
                    format: 0,
                    mode: "normal",
                    style: "",
                    text: ".",
                    type: "text",
                    version: 1,
                  },
                ],
                direction: "ltr",
                format: "",
                indent: 0,
                type: "listitem",
                version: 1,
                value: 1,
              },
              {
                children: [
                  {
                    detail: 0,
                    format: 0,
                    mode: "normal",
                    style: "",
                    text: "This chatbot can answer questions about relevant topics in our database.",
                    type: "text",
                    version: 1,
                  },
                ],
                direction: "ltr",
                format: "",
                indent: 0,
                type: "listitem",
                version: 1,
                value: 2,
              },
              {
                children: [
                  {
                    detail: 0,
                    format: 0,
                    mode: "normal",
                    style: "",
                    text: "For best results, ask clear and specific questions related to the supported topics.",
                    type: "text",
                    version: 1,
                  },
                ],
                direction: "ltr",
                format: "",
                indent: 0,
                type: "listitem",
                version: 1,
                value: 3,
              },
              {
                children: [
                  {
                    detail: 0,
                    format: 0,
                    mode: "normal",
                    style: "",
                    text: "The chatbot may not be able to answer queries outside its knowledge base.",
                    type: "text",
                    version: 1,
                  },
                ],
                direction: "ltr",
                format: "",
                indent: 0,
                type: "listitem",
                version: 1,
                value: 4,
              },
              {
                children: [
                  {
                    detail: 0,
                    format: 0,
                    mode: "normal",
                    style: "",
                    text: "If you receive an incomplete answer, try rephrasing your question.",
                    type: "text",
                    version: 1,
                  },
                ],
                direction: "ltr",
                format: "",
                indent: 0,
                type: "listitem",
                version: 1,
                value: 5,
              },
              {
                children: [
                  {
                    detail: 0,
                    format: 0,
                    mode: "normal",
                    style: "",
                    text: "Your feedback helps us improve the system over time.",
                    type: "text",
                    version: 1,
                  },
                ],
                direction: "ltr",
                format: "",
                indent: 0,
                type: "listitem",
                version: 1,
                value: 6,
              },
            ],
            direction: "ltr",
            format: "",
            indent: 0,
            type: "list",
            version: 1,
            listType: "bullet",
            start: 1,
            tag: "ul",
          },
        ],
        direction: "ltr",
        format: "",
        indent: 0,
        type: "root",
        version: 1,
      },
    },
};
