import React from "react";
import { getTenant } from "../../TENANT_CONFIGS";
import styled from "styled-components";
import { colors, SANS_3, transition } from "oolib";
import platformCommonsLogo from './platformCommonsLogo.png'

const StyledButton = styled.button`
background: none;
border: none;
display: flex;
gap: 1rem;
align-items: center;
justify-content: center;
border: 2px solid ${colors.greyColor100};
width: 100%;
padding: 0.7rem 1rem;
cursor: pointer;
${transition('background-color')}
:hover {
  background-color: ${colors.greyColor10};
}
margin-top: 2rem;
`

export const OAuthLoginButton = () => {
  const serverUrl =
    process.env.NODE_ENV === "production"
      ? import.meta.env.VITE_SERVER_URL
      : "http://localhost:8080";
  const endpoint = `${serverUrl}/api/pluginRoutes/mad/platformCommonsLogin?state=${JSON.stringify(
    { tenant: getTenant() }
  )}`;
  return (
    <StyledButton onClick={() => window.open(endpoint)}>
      <img src={platformCommonsLogo} style={{width: '3rem', height: 'auto'}} />
      <SANS_3 style={{paddingTop: '0.2rem'}}>Login with Campus Cloud</SANS_3>
    </StyledButton>
  );
};
