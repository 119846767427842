import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useGenErrorMsgBanners } from "../../../utils/useGenErrorMsgBanners";

export const useBulkQuickUpdateContent = () => {
   const { genErrorMsgBanners } = useGenErrorMsgBanners();
   const queryClient = useQueryClient();
   
   return useMutation(
      ({
         contentType,
         body,
      }) => {
         return axios.post(
            `/api/content/bulkQuickUpdateContent/${contentType}`,
            body,
            { headers: { "Content-Type": "application/json" } }
         );
      },
      {
         onError: (err) => {
            genErrorMsgBanners({ err });
         },
         onSuccess: (
            res,
            { contentType }
         ) => {
            queryClient.invalidateQueries(["contentListing", "lazy", [contentType]]);
         },
      }
   );
};
