import axios from "axios";
import { useQuery } from "react-query";
import { extractAllBlocksFromTpl } from "../../../utils/templating/extractAllBlocksFromTpl";

export const extractValuePathAndDisplayPath = ({ blockValuePath, tpl }) => {
  if (!tpl) return {};
  const allBlocks = extractAllBlocksFromTpl({ tpl });
  const thisBlock = allBlocks.find((d) => d.valuePath === blockValuePath);
  switch (thisBlock.comp) {
    case "DropdownSingle":
    case "DropdownMulti":
    case "RadioList":
    case "CheckboxList":
      return {
        valuePath: blockValuePath + ".value",
        displayPath: blockValuePath + ".display",
        comp:thisBlock.comp,
        blockValuePath
      };
      case "TagsInputSingle":
        case "TagsInputMulti":
          return {
            valuePath: blockValuePath + ".data._id",
            displayPath: blockValuePath + ".data.display",
            isTagDataShape: true,
        comp:thisBlock.comp,
        blockValuePath
          }
    default:
      return {};
  }
};

export const useGetCountForQuantField = ({
  contentType,
  tpl,
  activeFilters = [],
  blockValuePath,
  blockValuePath2,
  subBlockValuePath,
  queryOptions = {},
  vizCardId,
  activeBlock
}) => {
  
  const getConfigs = () => blockValuePath2 ? 
   ({
    contentTypes: [contentType],
    activeFilters,
    xAxis: blockValuePath?extractValuePathAndDisplayPath({ blockValuePath, tpl }):undefined,
    yAxis: blockValuePath2?extractValuePathAndDisplayPath({ blockValuePath: blockValuePath2, tpl }):undefined,
   })
  : ({
    contentTypes: [contentType],
    activeFilters,
    groupBy:blockValuePath?extractValuePathAndDisplayPath({ blockValuePath, tpl }):undefined,
    subGroupBy:subBlockValuePath?extractValuePathAndDisplayPath({blockValuePath:subBlockValuePath,tpl}):undefined,
  })

  const endpoint = blockValuePath2 
  ? `/api/insightMatrix/getCorrelationCount`
  : `/api/insightMatrix/getCountForQuantField`

  return useQuery({
    queryKey: ["useGetCountForQuantField", vizCardId, getConfigs()],
    queryFn: () =>
      axios
        .get(endpoint, {
          params: {
            configs: JSON.stringify(getConfigs()),
          },
        })
        .then((data) => data.data),
    ...queryOptions,
  });
};