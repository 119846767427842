import React from 'react'
import { DESKTOP_SIDEBAR_WIDTH, MOBILE_SIDEBAR_WIDTH } from '../..'
import {
  StyledContentSection,
  StyledSection,
} from './styled'




export const ContentSection = ({ showSideBarLeft, contentConfig, isMobile }) => {
  

  return (
    <StyledContentSection
      style={{padding: contentConfig.layout === 'fullWidth' ? '2rem 0' : '2rem'}}
      sidebar={showSideBarLeft}
      isMobile={isMobile}
      sidebarWidth={isMobile ? MOBILE_SIDEBAR_WIDTH : DESKTOP_SIDEBAR_WIDTH}
    >
      <StyledSection sidebar={showSideBarLeft} id={"StyledSection"}>
      <div style={{ 
            width: '100%', // important for some reason. else horizontal scroll goes for a whack
          }}>
        {contentConfig?.content || contentConfig}
        </div>

      </StyledSection>
    </StyledContentSection>
  )
}