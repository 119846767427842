import { motion } from "framer-motion";
import { colors2 as colors } from "oolib";
import styled from "styled-components";

const { grey10 } = colors;

export const SidebarContainerStyled = styled(motion.div)`
  width: 35rem;
  padding: 1.6rem;
  padding-top: 6.5rem;
  background-color: white;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  z-index: 100;
  border-left: 1px solid ${grey10}; 
`;

export const SidebarWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overscroll-behavior: contain;
  overflow-y: scroll;
  height: 100%;
  scrollbar-width: none;
  margin-bottom: 6.5rem;
`;


export const CardWrapperStyled = styled.div`
  background: #ffffff;
  border: 1px solid #ebebeb;
  display: flex;
  gap: 1rem;
  padding: 1rem;
  height: fit-content;
  z-index: 1000;
`;
