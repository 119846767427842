import { cloneDeep } from "lodash";
import { AccordionV2, ButtonPrimary, DataVizAccordion, DropdownSingle, colors } from "oolib";
import React, { useState } from "react";
import { useGetQueryData } from "../../../../../utils/react-query-hooks/general";
import { extractAllBlocksFromTpl } from "../../../../../utils/templating/extractAllBlocksFromTpl";
import { useGetCountForQuantField } from "../../queries";
import { boxShadow } from "../../styles";
import { getValidFieldsForQuantViz } from "../../utils/getValidFieldsForQuantViz";
import { getShortLabelOfThisBlockFromTpl } from "../../utils/getShortLabelOfThisBlockFromTpl";
import HeatmapVisualization from "@/pageTpls/Playground/pages/Common/HeatmapVisualization";


export const DataVizWidgetGenerator = ({ vizData, setVizData, id, index, activeFilters = [] }) => {
  const initLocalVizConfig = {
    contentType: undefined,
    blockValuePath: undefined,
    blockValuePath2: undefined,
  };
  const [localVizConfig, setLocalVizConfig] = useState(initLocalVizConfig);
  const allTpls = useGetQueryData(["allTpls"]);
  
  

  const dataFetchEnabled =
    !!vizData.contentType === true && !!vizData.blockValuePath === true;
  const { data, status } = useGetCountForQuantField({
    activeFilters,
    vizCardId: id,
    contentType: vizData.contentType,
    tpl: allTpls.find((d) => d.kp_content_type === vizData.contentType),
    blockValuePath: vizData.blockValuePath,
    blockValuePath2: vizData.blockValuePath2,
    queryOptions: { enabled: dataFetchEnabled },
  });

  const genPercentBar= ()=> {
    if(data.xValues && data.yValues ){

      return (
        <AccordionV2>
          <HeatmapVisualization 
            data={data}
            /> 
        </AccordionV2>
      )
    }

    //else

    return (
      <>
   

        <DataVizAccordion
        key={JSON.stringify(activeFilters)}
        title={getShortLabelOfThisBlockFromTpl({vizData, allTpls})}
        chartOptions={[{
          display: 'Bar Chart',
          comp: 'BarChart',
        }]}
        data={data?.countsArray}
        valuePath="count"
        labelPath="display"
        colorIdx={index % 4}
          />
          </>
   
  )}

  return (
    <>
      {!dataFetchEnabled ? (
        <div
        style={{
          background: colors.white,
          padding: "2rem",
          borderRadius: "4px",
          boxShadow,
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <DropdownSingle
            S
            saveValueAsString
            label={"Choose a Content Type"}
            options={allTpls
              .filter((d) => d.general?.segment === "publishing")
              .map((d) => ({
                display: d.general?.content?.title || d.kp_content_type,
                value: d.kp_content_type,
              }))}
            onChange={(k, v) =>
              setLocalVizConfig((prev) => ({ ...prev, contentType: v }))
            }
            value={localVizConfig.contentType}
          />
          {localVizConfig.contentType && (
            <DropdownSingle
              S
              saveValueAsString
              label={"Choose Field 1"}
              options={getValidFieldsForQuantViz(extractAllBlocksFromTpl({
                tpl: allTpls.find(
                  (d) => d.kp_content_type === localVizConfig.contentType
                ),
              })).map((d) => ({
                  display: d.props?.label || d.valuePath,
                  value: d.valuePath,
                }))}
              onChange={(k, v) =>
                setLocalVizConfig((prev) => ({ ...prev, blockValuePath: v }))
              }
              value={localVizConfig.blockValuePath}
            />
          )}
           {localVizConfig.contentType && (
            <DropdownSingle
              S
              saveValueAsString
              label={"Choose Field 2"}
              options={getValidFieldsForQuantViz(extractAllBlocksFromTpl({
                tpl: allTpls.find(
                  (d) => d.kp_content_type === localVizConfig.contentType
                ),
              })).map((d) => ({
                  display: d.props?.label || d.valuePath,
                  value: d.valuePath,
                }))}
              onChange={(k, v) =>
                setLocalVizConfig((prev) => ({ ...prev, blockValuePath2: v }))
              }
              value={localVizConfig.blockValuePath2}
            />
          )}
          <ButtonPrimary
            S
            disabled={
              !localVizConfig.blockValuePath || !localVizConfig.contentType
            }
            onClick={() => {
              setVizData((prev) => {
                let newVizData = cloneDeep(prev);
                let newVizDatumIdx = newVizData.findIndex((d) => d.id === id);
                newVizData[newVizDatumIdx] = {
                  ...newVizData[newVizDatumIdx],
                  ...localVizConfig,
                };
                return newVizData;
              });
              setLocalVizConfig(initLocalVizConfig);
            }}
          >
            Visualize
          </ButtonPrimary>
        </div>
        </div>
      ) : status === "loading" ? (
        <div>Loading...</div>
      ) : status === "error" ? (
        <div>Error</div>
      ) : (
        status === "success" && genPercentBar() 
      )}
    </>
  );
};
