import { useMemo } from "react";


const extractAnnoTagStats = (tagData) => {
  const collections = {};
  
   tagData && tagData.forEach(item => {
    if (!item.annoTags) return;
    
    Object.entries(item.annoTags).forEach(([key, collection]) => {
      if (!collections[collection.collectionId]) {
        collections[collection.collectionId] = {
          tagCategory: collection.collectionId,
          totalCountOfAllTagsWithin: 0,
          tagSpecificCount: {}
        };
      }
      
      // Increment total count for this collection
      collections[collection.collectionId].totalCountOfAllTagsWithin++;
      
      // Process each tag in the collection's data array
      collection.data.forEach(tag => {
        if (!collections[collection.collectionId].tagSpecificCount[tag.tagId]) {
          collections[collection.collectionId].tagSpecificCount[tag.tagId] = {
            tagId: tag.tagId,
            display: tag.display,
            _id: tag._id,
            count: 0
          };
        }
        collections[collection.collectionId].tagSpecificCount[tag.tagId].count++;
      });
    });
  });
  
    //   return Object.values(collections).map(collection => ({
    //     ...collection,
    //     tagSpecificCount: Object.values(collection.tagSpecificCount)
    //   }));

    return Object.values(collections)
        .sort((a, b) => b.totalCountOfAllTagsWithin - a.totalCountOfAllTagsWithin)
        .map(collection => ({
        ...collection,
        tagSpecificCount: Object.values(collection.tagSpecificCount)
            .sort((a, b) => b.count - a.count)
    }));
};


export const useExtractAnnoTagStats = (tagData) => {
    return useMemo(() => extractAnnoTagStats(tagData), [tagData]);
  };