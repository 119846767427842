import React from 'react';
import {SANS_2, SANS_3, UI_BODY_SM_DF} from 'oolib'

import {createContentCardDate } from '../../../../../../utils/momentManipulate';

const DateDisplay = ({ value, textComp = 'UI_BODY_SM_DF', ...textCompProps }) => {

  const TextComps = {
    SANS_3,
    SANS_2,
    UI_BODY_SM_DF
  }
  
  const TextComp = TextComps[textComp]

  const fnOptions = {
    format: 'DD/MM/YYYY',
    switchDisplayFormatAfter: 0 
  }
  
  const date = createContentCardDate(value, null, null, fnOptions);

  return (
    <TextComp {...textCompProps}>{date || ' '}</TextComp>
  )

}



export default DateDisplay;
