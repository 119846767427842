import WQMCourse from "./WQMCourse";
import MediaLibrary from "./MediaLibrary";
import AiChat from "../../../components/AiChat";
import CreateContentQuickLinkParser from "./CreateContentQuickLinkParser";

export default { 
    WQMCourse,
    MediaLibrary,
    AiChat,
    CreateContentQuickLinkParser,
};
