import React, { useState } from "react";
import * as Comps from "../../../../CONFIG_PARSER/TableCellBlocks";
import { getVal, LoaderOverlay } from 'oolib';
import { blockIsPopulatedConditions } from '../../../../../../../utils/validation/blockIsPopulatedConditions';
import { StyledRowCellContentWrapper, StyledTableRowCell } from "../../../../styled";
import { useTheme } from "styled-components";
import { dynamicPropsFnLookup, generateDynamicProps } from "./utils/generateDynamicProps";
import { __CheckAccessViaConfig } from "../../../../../../../utils/accessControl";
import { useGetQueryData } from "../../../../../../../utils/react-query-hooks/general";


const TableCell = ({
    tableConfig,
    colConfig,
    data : tableRowData,
    rowId,
    colWidthConfig,
    handleCellChange,
    cellIdx,
    rowIdx
}) => {

  const userData = useGetQueryData('userData')
  const { tableElem_handleCellChangeFns } = useTheme() || {}
  /**
   * in the future, this might be controlled by a state
   * if we bring in row editing functionalities.
   */
  const readOnly = true;

  const [showHandleCellChangeLoader, setShowHandleCellChangeLoader] = useState(undefined)

  const genCell = (colConfig) => {

    const highlight = colConfig.highlightFn
          ? dynamicPropsFnLookup[colConfig.highlightFn]({colConfig, tableRowData})
          : false

    const genCellComp = (colConfig) => {
        let compName = colConfig.comp || "TableText";

        const dynamicProps = colConfig?.dynamicPropsFn
          ? generateDynamicProps({dynamicPropsFn: colConfig.dynamicPropsFn, colConfig, tableRowData})
          : {}

        let props = {
          readOnly,
          tableRowData,
          ...(colConfig.props || {}),
          value: colConfig.valuePath
            ? getVal(tableRowData, colConfig.valuePath) &&
            getVal(getVal(tableRowData, colConfig.valuePath), colConfig.propertyPath || null)
            : // we do this cuz we need to find the final property that has a 'displayable' string to show in the dom
            colConfig.value
            ? colConfig.value
            : undefined,
          ...dynamicProps
        };
  

        //basically all input blocks are not required to be populated
        const okToNotBePopulatedBlocks = [
          'TableTextInput'
        ]

        const allowAccessToThisCell = colConfig.accessConfig
          ? __CheckAccessViaConfig({
            user: userData?.user,
            action: colConfig.accessConfig.action,
            checkAccessTo: colConfig.accessConfig.checkAccessTo,
            resourceType: getVal(tableRowData, colConfig.accessConfig.resourceTypePath)
          })
          : true



        if(
          (!okToNotBePopulatedBlocks.includes(compName) && !blockIsPopulatedConditions({comp: compName, props}, props.value))
          || !allowAccessToThisCell
        ){
          compName = 'TableText'
          props = { value: '-' }
        }

        let Comp = Comps[compName];
        
        
  
        return (
          
          <Comp
            {...props}
            id={rowId+colConfig.colId}
            /**
             * handleCellChange can directly be passed as a function prop to
             * TableElem or, it can be passed as tableConfig.handleCellChangeFn into the 
             * tableConfig, and this will work only if the same named function is made available
             * in OolibThemeProvider
             */
            { ...(
              handleCellChange
              ? {onChange: (k,v) => {
                handleCellChange(tableRowData, colConfig, v, {rowIdx})
              }}
              : (tableConfig.handleCellChangeFn &&
                tableElem_handleCellChangeFns &&
                tableElem_handleCellChangeFns[tableConfig.handleCellChangeFn] )
                ? {onChange: (k,v) => {
                  tableElem_handleCellChangeFns[tableConfig.handleCellChangeFn](tableRowData, colConfig, v, setShowHandleCellChangeLoader)
                }}
                : {}
            )}
            // onChange={(k,v) => {
            //   handleCellChange(tableRowData, colConfig, v)
            // }}
          />
          
        );
      };
      return (
        <>
        {showHandleCellChangeLoader && <LoaderOverlay {...showHandleCellChangeLoader}/>}
        <StyledTableRowCell
          freezeFirstCol={tableConfig.freezeFirstCol}
          highlight={highlight}
          cellIdx={cellIdx}
          rowIdx={rowIdx}
          style={{
            width: colWidthConfig[colConfig.colId],
          }}
          >
            {colConfig.subColsConfig ? (
              <div style={{display: 'flex', height: '100%'}}>
                {colConfig.subColsConfig.map((dd) => (
                  <StyledRowCellContentWrapper
                    cellComp={dd.comp}
                    style={{width: `${100 / colConfig.subColsConfig.length}%`,}}
                    >
                    {genCellComp(dd)}
                  </StyledRowCellContentWrapper>
                ))}
              </div>
            ) : (
              <StyledRowCellContentWrapper cellComp={colConfig.comp}>
                {genCellComp(colConfig)}
              </StyledRowCellContentWrapper>
              
            )}
          
        </StyledTableRowCell>
        </>
      );
  }

  return genCell(colConfig);
};

export default TableCell;
