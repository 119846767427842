import { globalHoverOnWhiteBG, ActionMenu, ButtonGhost, ButtonSecondary, ButtonPrimary, toArray, InlineAlert, formatTextToCamelCase, icons, colors, boxShadow0, OKELink, multilineUnderline, transition } from "oolib";
import React, { Fragment, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

import {
  StyledContainer,
  StyledDragHandleWrapper,
  StyledContentWrapper,
  StyledIconAndTitleWrapper,
  StyledTitleWrapper,
  StyledTitle,
  StyledRightActionsWrapper,
  StyledTitleInput,
  StyledMoreContentWrapper,
  StyledSubtitle,
} from "./styled";
const { File, FolderSimple, FolderSimpleStar, LinkSimple, DotsSixVertical, PlusMinus, SelectionAll } = icons

const StyledBorderWrapper = styled.div`
  border: 1px solid ${colors.greyColor15};
  ${({onClick}) => !!onClick && css`
    cursor: pointer;
    ${globalHoverOnWhiteBG};
  `}
  &:hover{
    .StyledTitle{
      &::after{
        width: 0;
      }
    }

  }

  ${({isActive}) => isActive && css`
  background-color: ${({invert}) => invert ? colors.greyColor80 : colors.greyColor5};
  .StyledTitle{
      &::after{
        width: 0;
      }
    }
  `}

`

export const DraggableBlock = ({
  id,
  title,
  titleWeight,
  icon,
  iconProps,
  iconAfter,
  iconAfterProps,
  subtitle,
  actionMenu, //multi -> renders actionMenu and is a object with actionMenu props
  action, //single -> renders a button and is a object with button props
  editable,
  placeholder,
  onTitleInputChange,
  maxLength,
  tempCustomComp, //remove once we figure how to deal with repeater sidebar blocks
  disableDrag = false,
  customJSXBlock,
  customJSXInline,
  onClick,
  isActive
}) => {
  const Icons = {
    FolderSimple,
    FolderSimpleStar,
    File,
    LinkSimple,
    PlusMinus,
    SelectionAll
  };

  const genIcon = ({icon, iconProps = {}}) => {
    let Comp = Icons[icon];
    return (
      <div style={{ flexShrink: 0, marginTop: '-0.1rem' }}>
        <Comp
          weight={"bold"}
          size={16}
          {...(iconProps)} //if iconProps are passed they will override the above defaults
          />
      </div>
    );
  };

  const textInputRef = useRef(null)

  useEffect(() => {
    if(editable){
      textInputRef.current.focus()
    }
},[editable])

  const [inlineAlertMsg, setInlineAlertMsg] = useState('')

  const applyTitleWeight = (titleWeight) => {
    if(!titleWeight) return {};
    //else
    return ({[titleWeight]: true})
  }



  // for customJSXInline there is an order property available with the flexbox, to control where this flexItem should appear.

  return (
    <Fragment>{inlineAlertMsg && <InlineAlert text={inlineAlertMsg} type='danger'/>}
    <StyledBorderWrapper isActive={isActive} onClick={onClick}>
    <StyledContainer editable={editable}>
      {disableDrag ? <div style={{paddingLeft: '1rem'}}/> :
      <StyledDragHandleWrapper>
        <DotsSixVertical size={16} />
      </StyledDragHandleWrapper>}
      <StyledContentWrapper>
        <div>
          <StyledIconAndTitleWrapper>
            {icon && genIcon({icon, iconProps})}
            <StyledTitleWrapper>
              {editable && typeof title === 'string' ? ( //cuz title could be jsx also, and this string check is just a safeguard from breaking the platfrom
                <StyledTitleInput
                  ref={textInputRef}
                  type="text"
                  placeholder={placeholder}
                  className="SANS_3"
                  maxLength={maxLength}
                  onKeyUp={(e) => {
                    if(title.length === maxLength){
                      setInlineAlertMsg('Max Character Length reached!')
                    }else{
                      inlineAlertMsg && setInlineAlertMsg(undefined)
                    }
                  }}
                  value={title}
                  onChange={(e) => onTitleInputChange(id, e.target.value)}
                />
              ) : (
                <StyledTitle
                onClickExists={!!onClick}
                className='StyledTitle'
                  {...applyTitleWeight(titleWeight)}
                  >
                    {title}</StyledTitle>
              )}
            </StyledTitleWrapper>
            {iconAfter && //can pass multiple as an array
              <div style={{display: 'flex', gap: '0.5rem', alignItems: 'center'}}>
                {toArray(iconAfter).map((d, idx) => {
                  const thisIconAfterProps = toArray(iconAfterProps || [])[idx]
                  return genIcon({icon: iconAfter, iconProps: thisIconAfterProps})
                })
                }
              </div> }
          </StyledIconAndTitleWrapper>
          {subtitle &&
          <StyledMoreContentWrapper>
            <StyledSubtitle>{subtitle}</StyledSubtitle>
          </StyledMoreContentWrapper>
          }
          {tempCustomComp}
        </div>
      </StyledContentWrapper>

      {customJSXInline?customJSXInline:null}
      {(actionMenu || action) && (
        <StyledRightActionsWrapper>
          { action && toArray(action).map((a ,i)=> {
            const buttonComps = {
              ButtonGhost,
              ButtonSecondary,
              ButtonPrimary
            }
            const ButtonComp = buttonComps[a.comp] || ButtonGhost;

            const onClick = (event) => {
              // purpose of this onClick function is to handle button clicks by calling the provided onClick function (if any), and resetting the inline alert message state.
              // this function ensures that the existing onClick function is only called if it is a function
              // Call the onClick function provided in the action prop, if it exists and is a "function".
              // This is done using a type check to ensure that the function is actually a function before calling it.
              // If the function exists, it is called with the event object passed in as an argument.
              if (typeof a.onClick === 'function') {
                  a.onClick(event);
              }
              inlineAlertMsg !== undefined && setInlineAlertMsg(undefined)
            };

            return <ButtonComp stopPropagation key={i} {...{ ...a}} onClick={onClick} />
          }) }

          { actionMenu && toArray(actionMenu).map((a,i) =>
            <ActionMenu key={a.display ? formatTextToCamelCase(a.display) : i } {...a} />) }
        </StyledRightActionsWrapper>
      )}





    </StyledContainer>
    {customJSXBlock?customJSXBlock:null}

    </StyledBorderWrapper>

    </Fragment>
  );
};
