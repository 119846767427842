import React from "react";
import { UI_CAPTION_SEMIBOLD, UI_TITLE } from "oolib";
import { BetaStyledWrapper, LandingCompContainerStyled } from "./styled";
import { ChatbotInfo } from "../ChatbotInfo";

export const LandingComp = () => {
  return (
    <LandingCompContainerStyled>
      {" "}
      <div style={{ display: "flex", flexDirection: "column", gap: "1.4rem" }}>
        <div style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
          <UI_TITLE>Ask AI</UI_TITLE>{" "}
          <BetaStyledWrapper>
            <UI_CAPTION_SEMIBOLD>Beta</UI_CAPTION_SEMIBOLD>
          </BetaStyledWrapper>
        </div>
        <ChatbotInfo />
      </div>
    </LandingCompContainerStyled>
  );
};
