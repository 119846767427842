import { motion } from "framer-motion";
import { colors2, icons, mediaQuery } from "oolib";
import styled from "styled-components";
import { LEFT_RIGHT_PADDING } from "../Shell";


const ACTION_BAR_HEIGHT = '4rem';

export const StyledActionBar = styled.div`
  max-width: 100svw;
  display: flex;
  height: ${ACTION_BAR_HEIGHT};
  background-color: white;
  -ms-overflow-style: none;  
  scrollbar-width: none; 
  padding: 0 20px;
  gap: 2rem;
  &::-webkit-scrollbar {
    display: none; 
  }
`;

export const StyledActionBlock = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-right: 2rem; */
  flex-grow:1 ;
`


export const StyledActionsPanelWrapper = styled.div`
  position: sticky;
  z-index: 31;
  
  /* background-color: white; */
  top: ${({ tabsPanelHeight, stickyTitleHeight }) =>  tabsPanelHeight || stickyTitleHeight  ? `${tabsPanelHeight + stickyTitleHeight}px` : '0px'};
  ${mediaQuery('md')}{
    margin-top: 0;
    border-bottom: 1px solid ${colors2.grey10};
    /* border-top: 1.5px solid #E9E9E9; */
  }
`;

export const StyledSearchInputDesktop = styled.input`
color: ${colors2.grey80};
border: none;
height: 100%;

flex-grow: 1;

::placeholder{
  color: ${colors2.grey40};
  opacity: 1;
}

`

export const StyledSortButton = styled.button`
        height: ${ACTION_BAR_HEIGHT}; 
        padding-left: ${LEFT_RIGHT_PADDING}px;
        cursor: pointer;
        outline: none;
        border: none;
        background: none;
        display: flex;
        align-items: center;
        gap: 0.4rem;
        flex-grow: 1;
`



export const StyledMobileActions = styled.div`
  display: flex;
  justify-content: space-between;
  

  /* background-color: white; */
  z-index: 1000;
  
`

export const StyledActionsWrapper = styled.div`
  /* background-color: white; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 6px;
  overflow: hidden;
`