import React, { Fragment, Suspense, useEffect, useState } from "react";
//react query
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import * as Sentry from "@sentry/react";
import "./App.scss";

import { DataPivot } from "./components/analysisModules/DataPivot";
import WarningScreen from "./components/generalUI/WarningScreen";
import { Footer } from "./components/nav/Footer";
import { PrivateRoute } from "./components/routing/PrivateRoute";
import RouteAccessModule from "./components/routing/RouteAccessModule";
import RouteAccessOneContent from "./components/routing/RouteAccessOneContent";
import RouteAccessOneProfile from "./components/routing/RouteAccessOneProfile";
import RouteAccessSomeContent from "./components/routing/RouteAccessSomeContent";
import RouteSuperAdmin from "./components/routing/RouteSuperAdmin";
import RouteSomeAccessToContentType from "./components/routing/RouteSomeAccessToContentType"
import { AppSettingsProvider } from "./contexts/appSettingsContext";
import ChooseTemplate from "./pageTpls/ChooseTemplate";

import {
  BannerAlert,
  BannerInfo,
  GlobalStyles,
  Loader,
  useBannerContext,
} from "oolib";
import OolibThemeProvider from "./OolibThemeProvider";
import { useOnlineStatusChange } from "./utils/environmentConfig";
// import { __GetProfileTypeConfig } from './utils/getters/gettersV2'
import { useGetPlatformConfigs } from "./utils/react-query-hooks/useGetPlatformConfigs";
import { useUserLoad } from "./utils/react-query-hooks/auth";
import { useRegisterSw } from "./utils/swUtils";
import { Coachmark } from "./components/CoachmarkV2";

import { hotjar } from "react-hotjar";

import { Header } from "./components/nav/Header";

import { useGetTplsAndCacheSeparately } from "./utils/react-query-hooks/tpls";
import { LocalhostTenantSwitcher } from "./components/LocalhostTenantSwitcher";
import { useSetAppropriateMetaTitle } from "./utils/customHooks/useSetAppropriateMetaTitle";
import { useActivateGoogleAnalytics } from "./trackers/GA/useActivateGoogleAnalytics";
import PluginRoutes from "./Plugins/routes";
import { PreAccessChecklistContextProvider } from "./contexts/preAccessChecklistContext";
import RouteSomeAccessSomePlatformBuilderModule from "./components/routing/RouteSomeAccessSomePlatformBuilderModule";
import { getAccessControlEnabledPLBModules } from "./pageTpls/PlatformBuilder/config";
import { useQueryClient } from "react-query";
import MomentLocale from "./components/locale/MomentLocale";
import { LangSelectModal } from "./LangSelectModal";
import { WorldEngine } from "./pageTpls/WorldEngine";
import { InsightMatrix } from "./components/analysisModules/InsightMatrix";
import { AnnotationExplorer } from "./components/analysisModules/AnnotationExplorer";
import BroadcastNavigator from "./components/BroadcastNavigator";
import SelfServeSurvey from "./pageTpls/SelfServeSurvey";

console.log("REACT VERSION: ", React.version);
const PlatformBuilder = React.lazy(() => import("./pageTpls/PlatformBuilder"));
// const ContentTypeLanding = React.lazy(() => import('./pageTpls/PlatformBuilder/subModules/ContentTypesManager/comps/ContentTypeLanding'))
const Synthesis = React.lazy(() => import("./components/Synthesis"));
const TCIFetch = React.lazy(() =>
  import("./pageTpls/PlatformBuilder/subModules/ContentTypesManager/TCIFetch")
);

const Home = React.lazy(() => import("./pageTpls/Home"));
const AiChat = React.lazy(() => import("./components/AiChat"));
const GoatDashboard = React.lazy(() => import("./components/analysisModules/dashboard"));
const Playground = React.lazy(() => import("./pageTpls/Playground"));

const ContributeConfigure = React.lazy(() =>
  import("./pageTpls/ContributeConfigure")
);

const ContributeTpl = React.lazy(() => import("./pageTpls/ContributeTpl"));
const ViewProfileUser = React.lazy(() =>
  import("./pageTpls/ViewProfile/ViewProfileUser")
);

const UserProfilesListing = React.lazy(() =>
  import("./pageTpls/ProfilesListing")
);

const PublishedListing = React.lazy(() =>
  import("./pageTpls/PublishedListing")
);
const PublishedPage = React.lazy(() => import("./pageTpls/PublishedPage"));

const ModQueue = React.lazy(() => import("./pageTpls/ModQueue"));

const OKE404 = React.lazy(() => import("./pageTpls/OKE404"));
const OKFPublishSuccessful = React.lazy(() =>
  import("./pageTpls/OKFPublishSuccessful")
);
const Onboarding = React.lazy(() => import("./pageTpls/Onboarding"));
const UserAgreement = React.lazy(() => import("./pageTpls/UserAgreement"));
const GaAgreement = React.lazy(() => import("./pageTpls/GaAgreement"));

//----my dashboard -------//
const MyDashboard = React.lazy(() => import("./pageTpls/MyDashboard"));

const MyProfileEdit = React.lazy(() => import("./pageTpls/MyProfileEdit"));

/**
 * ---- auth routes ------- |
 */
const Signup = React.lazy(() => import("./pageTpls/Signup"));
const Login = React.lazy(() => import("./pageTpls/Login"));
const ForgotPassword = React.lazy(() =>
  import("./pageTpls/auth/ForgotPassword")
);
const ResetPassword = React.lazy(() => import("./pageTpls/auth/ResetPassword"));
const AccountVerification = React.lazy(() =>
  import("./pageTpls/AccountVerification")
);
const GoogleAuth = React.lazy(() => import("./pageTpls/GoogleAuth"));
/**
 * ---- END auth routes ---- |
 */

/**
 * ---- data routes ------- |
 */
const Data = React.lazy(() => import("./pageTpls/Data"));
// const DataPATHDrilldown1 = React.lazy(() =>
// 	import('./pageTpls/DataPATHDrilldown1'),
// )
// const DataPATHDrilldown2 = React.lazy(() =>
// 	import('./pageTpls/DataPATHDrilldown2'),
// )
/**
 * ---- END data routes ---- |
 */

const App = () => {
  //init hooks
  //phase this out, once we shift listings to react query
  // const { envConfigLoading } = useSelector((s) => s.environment)
  const { data: platformConfigs, status: platformConfigsStatus } =
    useGetPlatformConfigs();

  // fetch all tpls, and cache them separately as well
  const queryClient = useQueryClient();
  const { data: getTplsData, status: getTplsStatus } =
    useGetTplsAndCacheSeparately();
  /**
   * why are we doing this complicated stuff below rather than simply checking getTplsStatus and
   * load app accordingly?
   * Answer: we are being super safe here. if we rely on getTplsStatus, that will be 'success'
   * once the combined 'all tpls' cache is created. But, what we really care about is the
   * 'separately cached' tpls, and 'success' status doesnt tell us when that is done, since that
   * will be completed a fractional few milliseconds later (since those caches are set in the
   * onSuccess callback). Hence we use this function below, instead of relying on getTplsStatus
   */
  const atLeastOneSeparateTplCacheExists = (getTplsData) => {
    if (!getTplsData) return false;
    if (getTplsData.length === 0) return true; //basically, if no templates exist yet on the platform, we still want the app to load
    if (!!queryClient.getQueryData(["tpl", getTplsData[0].kp_content_type]))
      return true;
    // (above) : basically if at least one tpl cache is set, its a good enough proxy to know if all tpl caches are set.
    //else
    return false;
  };

  // banner state
  const { BANNER_STATE, REMOVE_ALERT_BANNER, REMOVE_INFO_BANNER } =
    useBannerContext();

  /**
   * fire this only after useGetTplsAndCacheSeparately cuz, it has a PWA related
   * function (__precachePrivateApis) which has a dependency on 'allTpls' cache
   */
  const userData = useUserLoad({
    platformConfigsLoading: platformConfigsStatus === "loading",
    platformConfigs,
    enabled: getTplsStatus === "success",
  });
  const enableCoachmark =
    platformConfigs?.deployment?._EnableCoachmarks?.enable;
  /**
   * fire this only after useGetTplsAndCacheSeparately cuz, it has a PWA related
   * function (__precachePrivateApis) which has a dependency on 'allTpls' cache
   */
  useRegisterSw({
    platformConfigs,
    enabled: platformConfigsStatus === "success" && getTplsStatus === "success",
  });

  useOnlineStatusChange();

  /**
   * PWA enabled apps, serve static files like index.html from serviceworker cache
   * Hence, we dont get the opportunity to dynamically edit the meta tags as per tenant, in index.html
   * on the server side.
   *
   * So we have to run this fix so that '__META_TITLE__' doesnt show up in the browser tab
   */
  useSetAppropriateMetaTitle();

  useEffect(() => {
    if (platformConfigsStatus === "success") {
      // const {_Branch} = platformConfigs;

      const { _Hotjar } = platformConfigs.deployment;
      // only if its the live production version of the platform
      if (
        import.meta.env.VITE_ENV === "prod" &&
        _Hotjar?.enable &&
        process.env.NODE_ENV === "production"
      ) {
        hotjar.initialize(_Hotjar.id, _Hotjar.sv);
      }
    }

    if (userData.status === "success") {
      Sentry.setUser({
        email: userData.data.user.email,
        username: userData.data.user.name,
        ip_address: "{{auto}}",
      });
    }
  }, [userData.status, platformConfigsStatus]);

  useActivateGoogleAnalytics({
    platformConfigs,
    platformConfigsStatus,
    userData: userData.status === "success" ? userData.data : undefined,
  });

  const [showWarningScreen, setShowWarningScreen] = useState(true);
  // useEffect(() => {
  // 	function findRects(){
  // 		const rects = coachMarkConfig[1]?.elements?.map((element) => {
  // 			const htmlElement = document.getElementById(element?.id);
  // 			return { rect: htmlElement?.getBoundingClientRect(), element: element?.element }
  // 		})

  // 		console.log({ rectsOnHome: rects })
  // 	}

  // 	window.addEventListener('scroll', findRects);
  //     window.addEventListener('resize', findRects);

  //     return () => {
  //       window.removeEventListener('scroll', findRects);
  //       window.removeEventListener('resize', findRects);
  //     };

  // }, [])
  const warningScreen =
    import.meta.env.VITE_ENV === "staging" && showWarningScreen ? true : false;

  return (
    <BrowserRouter>
      <PreAccessChecklistContextProvider>
        <ReactQueryDevtools
          initialIsOpen={false}
          toggleButtonProps={{ style: { right: 0, left: "unset" } }}
        />
        {platformConfigsStatus === "loading" ||
        !atLeastOneSeparateTplCacheExists(getTplsData) ||
        userData.status === "loading" ? (
          <div>
            <Loader blockHeight="100vh" debug="app config loading" />
          </div>
        ) : (
          <Fragment>
            <AppSettingsProvider>
              <MomentLocale />
              <OolibThemeProvider>
                {(process.env.NODE_ENV !== "production" ||
                  window.location.origin.includes(
                    "-dot-okf-fe-dev-dot-ok-framework.el.r.appspot.com"
                  )) && (
                  //|| import.meta.env.VITE_ENV === 'dev' activate when making dev a single url
                  <LocalhostTenantSwitcher />
                )}
                {/** has to be here, and not in index, because it uses platformConfigs API */}
                {import.meta.env.VITE_ENV === "staging" &&
                  showWarningScreen && (
                    <WarningScreen
                      platformConfigs={platformConfigs}
                      onClose={() => setShowWarningScreen(false)}
                    />
                  )}

                {platformConfigs.deployment._BASE_PLATFORM_IS_SETUP === false &&
                userData.status === "success" ? (
                  userData?.data?.user?.role === "superAdmin" ? (
                    <>
                      <GlobalStyles />
                      <WorldEngine updateBasePlatformIsSetupFlag />
                    </>
                  ) : (
                    <div>show platform not ready yet</div>
                  )
                ) : (
                  <Fragment>
                    <GlobalStyles />
                    <div id="optionsLightbox-root"></div>
                    <div id="modal-root"></div>{" "}
                    {/* we use createPortal to render modals, this div is used as refernce node*/}
                    <div id="actionmenu-lightbox-root" />{" "}
                    {/** we use createProtal to render the actionmenu lightbox here IF its prop 'popOutOfOverflowHiddenParent' is set to true */}
                    <div id="loader-root" />
                    <BannerInfo
                      BANNER_STATE={BANNER_STATE}
                      REMOVE_INFO_BANNER={REMOVE_INFO_BANNER}
                    />
                    {platformConfigs?.deployment?._EnableHeaderOnlyIfLogged
                      ?.enable ? (
                      userData?.data ? (
                        <Header />
                      ) : (
                        <></>
                      )
                    ) : (
                      <Header />
                    )}
                    <LangSelectModal />
                    <BroadcastNavigator />
                    <BannerAlert
                      BANNER_STATE={BANNER_STATE}
                      REMOVE_ALERT_BANNER={REMOVE_ALERT_BANNER}
                    />
                    <div className="kp-content">
                      <Suspense
                        fallback={
                          <Loader
                            blockHeight="calc(100vh - 6rem)"
                            debug={"react lazy"}
                          />
                        }
                      >
                        <Routes>
                          {/* Plugin Routes */}
                          <Route path="/plugins/*" element={<PluginRoutes />} />

                          {/* Home Route */}
                          <Route
                            path="/"
                            element={
                              <RouteAccessModule ACTION="READ" moduleId="home">
                                <Home />
                              </RouteAccessModule>
                            }
                          />

                          {/* Private Routes */}
                          <Route
                            path="/playground"
                            element={
                              <PrivateRoute>
                                <Playground />
                              </PrivateRoute>
                            }
                          />

                           <Route
                            path="/goatDashboard"
                            element={
                              <PrivateRoute>
                                <GoatDashboard />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path="/aiChat"
                            element={
                              <PrivateRoute>
                                <AiChat />
                              </PrivateRoute>
                            }
                          />

                          <Route
                            path="/my-dashboard"
                            element={
                              <PrivateRoute>
                                <MyDashboard />
                              </PrivateRoute>
                            }
                          />

                          <Route
                            path="/my-dashboard/:SectionId"
                            element={
                              <PrivateRoute>
                                <MyDashboard />
                              </PrivateRoute>
                            }
                          />

                          <Route
                            path="/contribute-config/:contentType"
                            element={
                              <PrivateRoute>
                                <ContributeConfigure />
                              </PrivateRoute>
                            }
                          />

                          <Route
                            path="/choose-template"
                            element={
                              <PrivateRoute>
                                <ChooseTemplate />
                              </PrivateRoute>
                            }
                          />

                          {/* Auth Routes */}
                          <Route path="/login" element={<Login />} />
                          <Route path="/signup" element={<Signup />} />
                          <Route
                            path="/verifyEmail/:token"
                            element={<AccountVerification />}
                          />
                          <Route
                            path="/reset-password"
                            element={
                              <PrivateRoute>
                                <ResetPassword />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path="/googleAuth/:token"
                            element={<GoogleAuth />}
                          />
                          <Route
                            path="/verifyResetPasswordMail"
                            element={<ForgotPassword />}
                          />
                          <Route
                            path="/verifyResetPasswordMail/:token"
                            element={<ForgotPassword />}
                          />

                          {/* Data Routes */}
                          <Route
                            path="/data"
                            element={
                              <RouteAccessModule ACTION="READ" moduleId="data">
                                <Data />
                              </RouteAccessModule>
                            }
                          />

                          {/* Content Routes */}
                          <Route
                            path="/published-listing/:contentType"
                            element={
                              <RouteAccessOneContent
                                ACTION="READ_IF_MEMBER"
                                ignoreExtendedIsMemberCheck={true}
                              >
                                <PublishedListing />
                              </RouteAccessOneContent>
                            }
                          />

                          <Route
                            path="/published-page/:content_type"
                            element={<PublishedPage />}
                          />

                          <Route
                            path="/edit/:content_type"
                            element={
                              <RouteSomeAccessToContentType ACTION={["UPDATE","PUBLISH","SUBMIT"]}>
                                <ContributeTpl />
                              </RouteSomeAccessToContentType>
                            }
                          />

                          {/* Moderation Routes */}
                          <Route
                            path="/moderate/:content_type"
                            element={
                              <RouteAccessOneContent ACTION="MODERATE">
                                <ContributeTpl />
                              </RouteAccessOneContent>
                            }
                          />

                          <Route
                            path="/mod-queue"
                            element={
                              <RouteAccessSomeContent ACTION="MODERATE">
                                <ModQueue />
                              </RouteAccessSomeContent>
                            }
                          />

                          {/* Analysis Routes */}
                          <Route
                            path="/synthesisV2"
                            element={
                              <RouteAccessModule
                                moduleId="analysisDashboard"
                                ACTION="READ"
                              >
                                <Synthesis />
                              </RouteAccessModule>
                            }
                          />

                          <Route
                            path="/insightMatrix"
                            element={
                              <RouteAccessSomeContent ACTION="READ">
                                <InsightMatrix />
                              </RouteAccessSomeContent>
                            }
                          />

                          <Route
                            path="/annotation-explorer"
                            element={
                              <RouteAccessSomeContent ACTION="READ">
                                <AnnotationExplorer />
                              </RouteAccessSomeContent>
                            }
                          />

                          <Route
                            path="/dataPivot"
                            element={
                              <RouteAccessSomeContent ACTION="READ">
                                <DataPivot />
                              </RouteAccessSomeContent>
                            }
                          />

                          {/* Profile Routes */}
                          <Route
                            path="/profile/userProfiles/:profileType/:id"
                            element={<ViewProfileUser />}
                          />

                          <Route
                            path="/community-listing/:profileType"
                            element={
                              <RouteAccessOneProfile ACTION="READ">
                                <UserProfilesListing />
                              </RouteAccessOneProfile>
                            }
                          />

                          <Route
                            path="/contribute-config"
                            element={
                              <PrivateRoute>
                                <ContributeConfigure />
                              </PrivateRoute>
                            }
                          />

                          <Route
                            path="/my-edit/:profileType/:id"
                            element={
                              <PrivateRoute>
                                <MyProfileEdit />
                              </PrivateRoute>
                            }
                          />

                          <Route
                            path="/onboarding"
                            element={
                              <PrivateRoute>
                                <Onboarding />
                              </PrivateRoute>
                            }
                          />

                          <Route
                            path="/self-serve-survey"
                            element={
                              <PrivateRoute>
                                <SelfServeSurvey />
                              </PrivateRoute>
                            }
                          />

                          <Route
                            path="/userAgreement"
                            element={
                              <PrivateRoute>
                                <UserAgreement />
                              </PrivateRoute>
                            }
                          />

                          <Route
                            path="/gaAgreement"
                            element={
                              <PrivateRoute>
                                <GaAgreement />
                              </PrivateRoute>
                            }
                          />

                          {/* TCI Routes */}
                          <Route
                            path="/tci/:contentType"
                            element={
                              <RouteSuperAdmin>
                                <TCIFetch />
                              </RouteSuperAdmin>
                            }
                          />

                          {/* Platform Builder Routes */}
                          <Route
                            path="/platformBuilder"
                            element={
                              <RouteSomeAccessSomePlatformBuilderModule
                                ACTIONS={["READ", "UPDATE"]}
                                modules={getAccessControlEnabledPLBModules().map(
                                  (d) => d.value
                                )}
                              >
                                <PlatformBuilder />
                              </RouteSomeAccessSomePlatformBuilderModule>
                            }
                          />

                          <Route
                            path="/platformBuilder/:view"
                            element={
                              <RouteSomeAccessSomePlatformBuilderModule
                                ACTIONS={["READ", "UPDATE"]}
                                modules={getAccessControlEnabledPLBModules().map(
                                  (d) => d.value
                                )}
                              >
                                <PlatformBuilder />
                              </RouteSomeAccessSomePlatformBuilderModule>
                            }
                          />

                          <Route
                            path="/platformBuilder/:view/:contentType"
                            element={
                              <RouteSomeAccessSomePlatformBuilderModule
                                ACTIONS={["READ", "UPDATE"]}
                                modules={getAccessControlEnabledPLBModules().map(
                                  (d) => d.value
                                )}
                              >
                                <PlatformBuilder />
                              </RouteSomeAccessSomePlatformBuilderModule>
                            }
                          />

                          <Route
                            path="/OKFPublishSuccessful"
                            element={<OKFPublishSuccessful />}
                          />

                          {/* 404 Route */}
                          <Route path="*" element={
                            <PrivateRoute>
                              <OKE404 />
                            </PrivateRoute>
                            } />
                        </Routes>
                      </Suspense>
                    </div>
                    {platformConfigs?.deployment?._EnableFooterOnlyIfLogged
                      ?.enable ? (
                      userData?.data ? (
                        <Footer />
                      ) : (
                        <></>
                      )
                    ) : (
                      <Footer />
                    )}
                  </Fragment>
                )}
                {enableCoachmark && userData?.data?.user && !warningScreen && (
                  <Coachmark />
                )}
              </OolibThemeProvider>
            </AppSettingsProvider>
          </Fragment>
        )}
      </PreAccessChecklistContextProvider>
    </BrowserRouter>
  );
};

export default Sentry.withProfiler(App);
