import styled, { css } from "styled-components";
import { ButtonSecondaryCompact, colors2 as colors } from "oolib";

const { grey30, grey10, red, redBG } = colors;

export const CardWrapperStyled = styled.div`
  background: #ffffff;
  border: 1px solid ${grey10};
  display: flex;
  padding: 1.6rem;
  height: fit-content;
  border-radius: 0.8rem;
  z-index: 1000;
  cursor: pointer;
  &:hover{
    border: 1px solid ${grey30};
  }

`;


export const StyledButtonSecondaryCompact = styled(ButtonSecondaryCompact)`

  ${({ remove }) => remove && css`
    background-color: ${redBG} !important;
    color: ${red} !important;
  `}


` 