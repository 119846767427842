import {
  Tooltip,
  UI_BODY_BOLD_SM,
  UI_BODY_SEMIBOLD_SM,
  UI_BODY_SM,
  colors2,
  TagClearV2
} from "oolib";
import React from "react";
import { CaretRight, LinkSimple } from "phosphor-react";
import {
  StyledFilterAccordionHeader,
  StyledFilterAccordionTitleWrapper,
  StyledSelectedValuesWrapper,
} from "../styled";

export const AccordionHeader = ({
  title,
  value,
  expand,
  onToggle,
  onChange,
  id,
  filterConfig,
  lightboxConfig
}) => {
  const TITLE_TYPO = value?.length ? UI_BODY_SEMIBOLD_SM : UI_BODY_SM;
  
  const readableDate = date => new Date(date).toLocaleDateString('en-IN', { year: 'numeric', month: 'short', day: 'numeric' })

  return (
    <StyledFilterAccordionHeader
      expand={expand}
      value={value}
      onClick={onToggle}
      style={{  }}
    >
      <StyledFilterAccordionTitleWrapper>
        <div style={{display: 'flex', alignItems: 'center', gap: '0.4rem'}}>
          {["nestedRollupTagType", "rollupValuePathType"].includes(
            filterConfig.target.filterType
          ) && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: '0.4rem',
                backgroundColor: colors2.grey10,
                borderRadius: "0.4rem",
              }}
            >
              {filterConfig.tooltip ? <Tooltip text={filterConfig.tooltip} position="right">
                <LinkSimple size={14} weight="bold"/>
              </Tooltip> : <LinkSimple size={14} weight="bold"/>}
            </div>
          )}
          <TITLE_TYPO style={{ color: colors2.grey80 }} semibold>{title}</TITLE_TYPO>
        </div>
        <div style={{ display: "flex", gap: "0.6rem", alignItems: "center" }}>
          {value?.length ? (
            <UI_BODY_BOLD_SM>{lightboxConfig.comp === "DateRangeType" ? "1" : value.length}</UI_BODY_BOLD_SM>
          ) : null}
          <CaretRight size={16} color={colors2.grey40} />
        </div>
      </StyledFilterAccordionTitleWrapper>
      {(lightboxConfig.comp === "DateRangeType" && value.length > 0) ? 
        <StyledSelectedValuesWrapper>
          <TagClearV2
            grey
            id={value}
            display={`${readableDate(value[0])} - ${readableDate(value[1])}`}
            size="XS"
            onClick={() =>
              onChange(
                id,
                value.filter((d) => value !== value)
              )
            }
          />
        </StyledSelectedValuesWrapper>
      :
      value?.length > 0 && (
        <StyledSelectedValuesWrapper>
          {value.map((v) => (
            <TagClearV2
              key={v.value}
              grey
              id={v.value}
              display={v.display}
              size="XS"
              fill="outline"
              onClick={() =>
                onChange(
                  id,
                  value.filter((d) => d.value !== v.value)
                )
              }
            />
          ))}
        </StyledSelectedValuesWrapper>
      )}
    </StyledFilterAccordionHeader>
  );
};
