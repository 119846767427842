import { injectHttps, isExternalUrl } from "oolib";
import React from "react";
import { Link } from "react-router-dom";

const TableRow = ({ link, parentRef, className, style, onClick, children }) => {
  const commonProps = {
    parentRef,
    className,
    style,
    children,
  };
  if (link) {
    if (isExternalUrl(link)) {
      return (
        <a href={injectHttps(link)} target="_blank" {...commonProps}>
          {children}
        </a>
      );
    } else {
      return (
        <Link to={link} {...commonProps}>
          {children}
        </Link>
      );
    }
  } else {
    return (
      <div {...commonProps} onClick={onClick}>
        {children}
      </div>
    );
  }
};

export default TableRow;
