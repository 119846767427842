import styled from "styled-components";

export const TopFadeOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 4rem;
  background: linear-gradient(white 0%, transparent 100%);
  pointer-events: none;
  z-index: 10;
`;

// radial gradient
export const BottomOverlay = styled.div`
  position: fixed;
  z-index: -1;

  height: 100vh;
  width: 100%;

  background-image: radial-gradient(circle at bottom, #e3efff 5%, #ffffff 70%);
`;


export const SearchAreaOverlay = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 12rem; // Adjust this height based on your search box and padding
  background-image: radial-gradient(circle at bottom, #e3efff 5%, #ffffff 70%);
  z-index: 99; // Higher than content but lower than your search box
  pointer-events: none; // Allows clicking through to elements below
`;