import { useEffect } from "react";

import { useLocation } from "react-router-dom";

/**
 * @description tracks the hash value in the url and scrolls to the
 * DOM element that has this hash value as its id
 */
export const useScrollHashLinkIntoView = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      /**location.hash.substring(1) has to be used since location.hash
       * returns the entire hash with the hash in it
       */
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.hash]);
};
