import styled, { css } from "styled-components";

export const StyledEllipsisRemoveWrapper = styled.div`
width: 100%;
${({readOnly}) => !readOnly && css` //cuz in the read only case we want '...'
p,h1,h2,h3,h4,h5,h6{
        white-space: normal;
        overflow: unset;
        text-overflow: unset;
    }
`}  
`