export const getGradientStyle = (colors) => {
  // Single color case
  if (colors.length === 1) {
    return {
      position: 'relative',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: '6px',
        "border-radius": "4px 4px 0px 0px",
        "z-index": 0,
        background: colors[0],
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        top: '-90px',
        left: 0,
        right: 0,
        height: '150px',
        "z-index": 0,
        background: `linear-gradient(to bottom, ${colors[0]}, white)`,
        "mix-blend-mode": "multiply",
        filter: 'blur(25px)'
      }
    };
  }

  // Multiple colors case - create individual elements instead of using background-image
  const beforeGradient = createLinearGradient(colors);

  return {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: '6px',
      "border-radius": "4px 4px 0px 0px",
      "z-index": 0,
      background: beforeGradient
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '-90px',
      left: 0,
      right: 0,
      height: '150px',
      // Create a mask-image approach
      maskImage: 'linear-gradient(to bottom, black, transparent)',
      background: beforeGradient,
      "mix-blend-mode": "multiply",
      filter: 'blur(25px)'
    }
  };
};


export const createLinearGradient = (colors) => {
  if (colors.length === 1) return colors[0];
  
  const stops = [];
  const segmentSize = 100 / colors.length;
  const gapSize = 2; // Gap in pixels
  
  // Calculate the percentage equivalent of 2px based on assumed container width
  // We'll need to estimate a container width (e.g., 300px) to convert pixels to percentage
  const containerWidth = 300; // Estimated container width
  const gapPercentage = (gapSize / containerWidth) * 100;
  
  colors.forEach((color, index) => {
    const start = segmentSize * index;
    const end = segmentSize * (index + 1);
    
    if (index > 0) {
      // Add transparent gap before each color except the first
      stops.push(`transparent ${start - gapPercentage/2}%`);
      stops.push(`${color} ${start + gapPercentage/2}%`);
    } else {
      stops.push(`${color} 0%`);
    }
    
    if (index < colors.length - 1) {
      // Add gap after each color except the last
      stops.push(`${color} ${end - gapPercentage/2}%`);
      stops.push(`transparent ${end + gapPercentage/2}%`);
    } else {
      stops.push(`${color} 100%`);
    }
  });
  
  return `linear-gradient(to right, ${stops.join(', ')})`;
};