import { colors2, TagClearV2, UI_BODY_SEMIBOLD_SM } from "oolib"
import React, { useState } from "react"
import { annoStorageKey } from "../.."
import styled from "styled-components"

export const StyledStatusWrapper = styled.div`
  background-color: ${colors2.white};
  padding: 1.1rem 1.6rem;
  /* margin: 1rem 0; */
  border-radius: 0.6rem;
  display: flex;
  gap: 1.2rem;
  flex-wrap: wrap;
`

export const StyledWrapper = styled.div`
  background-color: #f9f9f9;
  padding: 1rem 0;
  position: sticky;
  top: ${({ isMobile }) => isMobile ? "4.5rem" : "4rem"};
  z-index: 1;
`

export const ColorCodeDisplay = ({
  breakDown, 
  setBreakDown,
  appliedColorConfig,
  totalCount,
  isMobile,
  activeColorFilter
}) => {
  
  const removeColor = (tag) => {
    
    const updatedBreakdown = {
      ...breakDown,
      activeColorConfig: {
        ...breakDown?.activeColorConfig,
        tags: breakDown?.activeColorConfig.tags.filter(
          (tagD) => tagD.value !== tag.value
        )
      }
    };
    
    setBreakDown(updatedBreakdown);
  };

  
  if (!breakDown?.activeColorConfig?.tags?.length) {
    return null;
  }

  return(
    <StyledWrapper isMobile={isMobile}>
      <StyledStatusWrapper>
        
        <div style={{ borderRight: `1px solid ${colors2.grey10}`, paddingRight: "1.4rem", display: "flex", alignItems: "center" }}>
          <UI_BODY_SEMIBOLD_SM>Annotations Showing: {totalCount}</UI_BODY_SEMIBOLD_SM>
        </div>

        <div>
          <div style={{ display: "flex", gap: "0.6rem", flexWrap: "wrap", alignItems: "center" }}>

          <UI_BODY_SEMIBOLD_SM>Color code: Tag - {activeColorFilter?.display}</UI_BODY_SEMIBOLD_SM>

                {appliedColorConfig?.tags?.map((tag) => {
                  return(
                    <>
                      <TagClearV2 fill={"outline"} display={`${tag.display} (${tag.count})`} tag={tag.value} badgeColor={tag.fill} onClick={() => removeColor(tag)}/>
                    </>
                  )
                })}
          </div>
        </div>
      </StyledStatusWrapper>
    </StyledWrapper>  
  )
}