import React from "react";
import { icons, colors2 } from "oolib";
import {
  StyledSearchInputDesktop,
} from "../styled";
import { ButtonGhost } from "oolib/dist/v2/components/Buttons";

const { MagnifyingGlass, X } = icons;

export const Search = ({
    onClose,
    isMobile,
    searchTermInputText,
    handleSearch,
    placeholder,
    style
  }) => {
    const defaultPlaceholderText = isMobile ? "Search cards or content" : "Search cards or content within it";
    
    return(
      <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
        justifyContent: "space-between",
        flex: "1 0 auto",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      > 
        {
          isMobile ? (
            <ButtonGhost
              icon="MagnifyingGlass"
              iconWeight="bold"
              iconSize={20}
              style={{ width: "45px", height: "45px" }}
            />
          ) : (
            <div style={{ display: 'flex', alignItems: 'center', paddingRight: '0.5rem', marginLeft: '-2px' }}>
              <MagnifyingGlass size={16} weight="bold"/>
            </div>
          )
        }
         <StyledSearchInputDesktop
          style={
            { ... !isMobile
              ? { borderRight: `1px solid ${colors2.grey10}` }
              : { background: 'none' },
               ...style 
            }
          }
          placeholder={placeholder || defaultPlaceholderText}
          className={isMobile ? "UI_BODY_SEMIBOLD_DF" : "UI_BODY_SEMIBOLD_SM"}
          type="text"
          value={searchTermInputText}
          onChange={(e) => handleSearch(null, e.target.value)}
        />
      </div>
      {onClose && (
        <ButtonGhost
          icon="X"
          iconSize={20}
          iconWeight="bold"
          onClick={onClose}
          style={{ width: "45px", height: "45px" }}
        />
      )}
    </div>
    )
  }
  