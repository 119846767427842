import {
  Container,
  Section,
  Wrapper1000,
  Wrapper700,
  PaddingTopBottom15,
  PaddingTopBottom45,
} from "oolib";
import React from "react";

import { GeneratorComp } from "../ContributeTpl/layouts/GroupsStyle1/comps/GeneratorComp";
import { blockMapper } from "../../utils/templating/blockMapper";
import { useFormValidationContext } from "../../contexts/formValidationContext";

export const FormRenderer = ({ configs, content, memo }) => {
  const { formValidation, setFormValidation } = useFormValidationContext();

  return (
    <Section style={{ padding: "0 2rem" }}>
      <Wrapper700>
        <PaddingTopBottom45>
          {blockMapper({
            content,
            activeSpace: { valuePath: "configs" },
            tplConfig: { configs },
            blockGenerator: ({ block }) => {
              return (
                <GeneratorComp
                  {...{
                    key: block.props?.id,
                    block,
                    blockWrapper: PaddingTopBottom15,
                    readOnly: true,
                    memo,
                    content,
                    formValidation,
                    setFormValidation,
                  }}
                />
              );
            },
          })}
        </PaddingTopBottom45>
      </Wrapper700>
    </Section>
  );
};
