import React, { useState } from "react";
import { ButtonGhostCompact } from "oolib";

export const Copy = ({ color, onClick }) => {
  const [confirm, setConfirm] = useState(false);

  const handleCopy = async () => {
    setConfirm(true);
    await onClick();
    setTimeout(() => {
      setConfirm(false);
    }, 1000);
  };

  return (
    <div>
      <ButtonGhostCompact
        disabled={confirm}
        onClick={handleCopy}
        icon={confirm ? "Check" : "CopySimple"}
        iconColor={color}
      />
    </div>
  );
};
