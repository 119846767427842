import React from "react";
import {
  getBreakPoint,
  makeArrayFromLength,
  SkeletonLoader,
  useScreenWidth,
} from "oolib";
import { Fragment } from "react";
import ErrorStates from "../../../../ErrorStates";

export const MasonryCardGridShell = ({
  dataFetchStatus,
  dataFetchError,
  data,
  renderCard,
  forceCol
}) => {
  const screenWidth = useScreenWidth();
  const cols = screenWidth < getBreakPoint("sm") ? 1 
       : screenWidth < getBreakPoint("lg") ? 2
       : screenWidth < getBreakPoint("xl") ? 3 : forceCol?forceCol:4

  return (
    <div
      style={{
        gap: "1rem",
        display: "grid",
        gridTemplateColumns: makeArrayFromLength(cols)
          .map((d) => "minmax(0, 100%)")
          // .map(d => '1fr')
          .join(" "),
      }}
    >
      {dataFetchStatus === "loading" ? (
        makeArrayFromLength(20).map((d, i) => (
          <SkeletonLoader key={i} style={{ height: "300px", borderRadius: "0.5rem" }} />
        ))
      ) : dataFetchStatus === "error" ? (
        <ErrorStates errorResponseObject={dataFetchError} />
      ) : (
        data && (
          <Fragment>
            {makeArrayFromLength(cols).map((col, colI) => (
              <div
                key={colI}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                {data
                  .filter((d, i) => i % cols === colI)
                  .map((d, i, ary) => renderCard(cols, colI, d, i, ary))}
              </div>
            ))}
          </Fragment>
        )
      )}
    </div>
  );
};
