import {
  ActionMenu,
  ModalConfirm,
  colors,
  colors2,
  CheckboxButton,
  useScreenWidth,
} from "oolib"; //"../../generalUI/ActionMenu";
import React, { useContext, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import * as ExpandComps from "../../CONFIG_PARSER/ExpandComps";
import {
  __CheckAccessToContentType,
  __IsAuthor,
} from "../../../../../utils/accessControl";
import { stitchLink } from "../../../../../utils/general";
import { TableCell, TableRow } from "./comps";
import { StyledExpandCompContainer, StyledExpandCompWrapper } from "./styled";
import { __GetContentTypeConfigNew } from "../../../../../utils/getters/gettersV2";
import { _Locale } from "../../../../locale/Locale";
import { useModal } from "../../../../../utils/customHooks";
import { AppSettingsContext } from "../../../../../contexts/appSettingsContext";
import { useDeleteContent } from "../../../../../pageTpls/ContributeTpl/queryHooks/useDeleteContent";
import { ROW_HEIGHT } from "../..";
import styled, { css } from "styled-components";
import { StyledCheckboxContainer } from "../../styled";

const StyledTableRowWrapper = styled(TableRow)`
  border-top: ${({ rowIdx }) =>
    rowIdx !== 0 ? `1px solid ${colors2.grey10}` : "none"};

  ${({ rowIsClickable }) =>
    rowIsClickable &&
    css`
      &:hover {
        outline: 1px solid ${colors2.grey30};
        z-index: 1;
        border-top: ${({ rowIdx }) =>
          rowIdx !== 0
            ? `1px solid rgba(0,0,0,0)`
            : "none"}; //make transparent rather than remove so that the row text doesnt jump
      }
    `}
`;

const TableRowElem = ({
  id: rowId,
  data,
  tableConfig,
  actions,
  bulkActions,
  actionsConfig: actionsConfigProp,
  conditionsConfig: conditionsConfigProp,
  colWidthConfig,
  handleRowClick: parentHandleRowClick,
  handleCellChange,
  rowIdx,
  bulkActions_selectedRowIds,
  bulkActions_setSelectedRowIds,
}) => {
  const screenWidth = useScreenWidth();
  let queryClient = useQueryClient();
  let { data: userData } = queryClient.getQueryState("userData");

  const tableElemRow_ref = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();

  const { link: linkConfig, expandComp: expandCompConfig } = tableConfig;
  const link = linkConfig ? stitchLink(data, linkConfig) : undefined;

  const ExpandComp = expandCompConfig && ExpandComps[expandCompConfig.comp];

  const ExpandCompWrapperRef = useRef(null);

  const [showExpandComp, setShowExpandComp] = useState(false);
  const { APP_SETTINGS } = useContext(AppSettingsContext);
  const { mutate, isSuccess: isDeleted } = useDeleteContent();
  const [showDeleteModal, setShowDeleteModal] = useModal(false);

  const genDeleteModal = () => (
    <ModalConfirm
      title={
        // if localizatio doesnt exist at all OR if it exists and lang === english then..
        // be more specific with this statment
        !APP_SETTINGS.lang || APP_SETTINGS.lang?.value === "en"
          ? `Are you sure you want to delete this ${
              __GetContentTypeConfigNew(data.meta.kp_content_type).general
                .content?.singular || "content"
            } ?`
          : _Locale(`Are you sure you want to delete this ? <word1: ${__GetContentTypeConfigNew(data.meta.kp_content_type).general
            .content?.singular}>`)
      }
      onClose={() => setShowDeleteModal(false)}
      onConfirm={() => handleDelete()}
    />
  );
  const handleDelete = (e) => {
    setShowDeleteModal(false);
    mutate(
      {
        contentType: data.meta.kp_content_type,
        id: data.kp_og_published_doc || data._id,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("contentListing", [
            data.meta.kp_content_type,
          ]);
          queryClient.invalidateQueries("myContent");
        },
      }
    );
  };
  const handleRowClick = (e, data) => {
    if (expandCompConfig) {
      setShowExpandComp((prev) => !prev);
    } else if (parentHandleRowClick) {
      parentHandleRowClick(data);
    }

    e.stopPropagation();
  };

  const conditionsConfig = {
    //functions that decide weather an action should be shown to current user
    allowEdit: (data) => {
      let contentTypeConfig = __GetContentTypeConfigNew(
        data.meta.kp_content_type
      );
      switch (contentTypeConfig.general.segment) {
        case "publishing":
        default:
          return data.meta.kp_contributed_by?._id === userData?.user._id;
        case "staticPages":
        case "collections":
          return (
            __CheckAccessToContentType(
              userData?.user,
              "UPDATE",
              data.meta.kp_content_type
            ) &&
            (data.kp_published_status === "published" ||
              (data.currentEditor?._id || data.currentEditor) ===
                userData?.user._id)
          );
      }
    },
    allowModerate: (data) => {
      return !__IsAuthor(
        data.meta.kp_contributed_by?._id,
        userData?.user?._id
      ) &&
        __CheckAccessToContentType(
          userData.user,
          "MODERATE",
          data.meta.kp_content_type
        )
        ? true
        : false;
    },
    allowDelete: (data) => {
      let contentTypeConfig = __GetContentTypeConfigNew(
        data.meta.kp_content_type
      );

      // if content is under edit then don't show the delete option, because the api doesn't delete the clone doc used for editing "

      if (data.currentEditor) return false;

      switch (contentTypeConfig.general.segment) {
        case "publishing":
        default:
          return (
            __IsAuthor(data.meta.kp_contributed_by?._id, userData?.user._id) ||
            __CheckAccessToContentType(
              userData.user,
              "MODERATE",
              data.meta.kp_content_type
            )
          );
        case "staticPages":
        case "collections":
          return (
            __CheckAccessToContentType(
              userData?.user,
              "DELETE",
              data.meta.kp_content_type
            ) &&
            (data.kp_published_status === "published" ||
              (data.currentEditor?._id || data.currentEditor) ===
                userData?.user._id)
          );
      }
    },
    ...(conditionsConfigProp ? conditionsConfigProp : {}),
  };

  const actionsConfig = {
    fireUpdateAction: () => {
      if (link) {
        /*
        '&' because data.link will be 'www.foo.bar?id=123'
        */
        let path = `${link}&createUpdate=create`;
        navigate(path, {
          state: { postPblRedirect: `${location.pathname}${location.search}` },
        });
      }
      //redirect to the case report page
    },
    editContent: () => {
      //redirect to the case report page
      navigate(
        `/edit/${data.meta.kp_content_type}?id=${
          data.kp_og_published_doc || data._id
        }`
      ); // ----> edit
    },
    viewPublishedContent: () => {
      //redirect to the case report page
      const docId =
        data.kp_published_status === "editPublished"
          ? data.kp_og_published_doc
          : data._id;
      navigate(`/published-page/${data.meta.kp_content_type}?id=${docId}`); // ----> edit
    },
    fireEditReport: () => {
      // deprecate after replaced in nazdeek table config
      //redirect to the case report page
      navigate(`/edit/${data.meta.kp_content_type}?id=${rowId}`); // ----> edit
    },
    fireModerateReport: () => {
      //redirect to the case report page
      navigate(`/moderate/${data.meta.kp_content_type}?id=${rowId}`); // ----> moderate
    },
    deleteContent: () => {
      setShowDeleteModal(true);
    },
    ...(actionsConfigProp ? actionsConfigProp : {}),
  };

  const rowIsClickable = expandCompConfig || link || parentHandleRowClick;

  return (
    <div>
      {showDeleteModal && genDeleteModal()}
      <StyledTableRowWrapper
        rowIsClickable={rowIsClickable}
        rowIdx={rowIdx}
        link={link}
        parentRef={tableElemRow_ref}
        style={{
          display: "flex",
          alignItems: "center",
          ...(rowIsClickable ? { cursor: "pointer" } : {}),
          position: "relative",
          height: ROW_HEIGHT + "px",
          backgroundColor: rowIdx % 2 === 1 ? colors2.white : colors2.grey2,
        }}
        onClick={(e) => handleRowClick(e, data)}
      >
        {bulkActions && (
          <StyledCheckboxContainer
            screenWidth={screenWidth}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <CheckboxButton
              value={bulkActions_selectedRowIds.includes(rowId)}
              onChange={(k, v) => {
                if (v === true) {
                  //lets add this rowId to bulkActions_selectedRowIds
                  bulkActions_setSelectedRowIds((prev) => [...prev, rowId]);
                } else {
                  //remove this rowId from bulkActions_selectedRowIds
                  bulkActions_setSelectedRowIds((prev) => {
                    return prev.filter((p) => p !== rowId);
                  });
                }
              }}
            />
          </StyledCheckboxContainer>
        )}
        {tableConfig.cellData.map((d, i) => (
          <TableCell
            tableConfig={tableConfig}
            rowIdx={rowIdx}
            cellIdx={i}
            key={d.colId}
            colWidthConfig={colWidthConfig}
            rowId={rowId}
            data={data}
            colConfig={d}
            handleCellChange={handleCellChange}
          />
        ))}
        {actions && (
          <ActionMenu
            M
            popOutOfOverflowHiddenParent
            align="right"
            actions={actions
              .map((d) =>
                d.condition
                  ? conditionsConfig[d.condition](data)
                    ? {
                        display: d.display,
                        onClick: (e) => {
                          e.stopPropagation();
                          actionsConfig[d.action](e, data);
                        },
                        confirmAction: d.confirmAction,
                      }
                    : undefined
                  : {
                      display: d.display,
                      onClick: (e) => {
                        e.stopPropagation();
                        actionsConfig[d.action](e, data);
                      },
                      confirmAction: d.confirmAction,
                    }
              )
              .filter(Boolean)}
          />
        )}
      </StyledTableRowWrapper>

      {expandCompConfig && ( //we need tat showExpandedComp stuff for the out-transition cuz it has a delay
        <StyledExpandCompContainer
          id="ExpandCompContainer"
          {...{
            showExpandComp,
            contentsHeight:
              ExpandCompWrapperRef.current?.getBoundingClientRect().height,
          }}
        >
          <StyledExpandCompWrapper>
            <div
              style={{ padding: "1rem", backgroundColor: colors.greyColor10 }}
              ref={ExpandCompWrapperRef}
            >
              <ExpandComp
                rowId={rowId}
                parentRowRawData={data}
                {...expandCompConfig.props}
              />
            </div>
          </StyledExpandCompWrapper>
        </StyledExpandCompContainer>
      )}
    </div>
  );
};

export default React.memo(TableRowElem);
