export const applyFilters = (data, filters) => {

    if (filters.length === 0) return data;
  
    let filteredData = [...data];
  
    const filterById = (filterId, field) => {
      const filter = filters.find(f => f.filterId === filterId && f.values?.length > 0);
      if (filter) {
        if(filterId === "dateFilter"){
          const startDate = new Date(filter.values[0]);
          const endDate = new Date(new Date(filter.values[1]).getTime()+86400000);
          filteredData = filteredData.filter(item => {
            const date = new Date(item.metaData?.[field]);
            if(date >= startDate && date <= endDate) return true;
            return false;
          });
        } else {
          filteredData = filteredData.filter(item =>
            item.metaData?.[field]?.data?.length > 0 &&
            filter.values.some(v =>
              item.metaData?.[field]?.data.some(dd => dd._id === v.value)
            )
          );
        }
      }
    };
    
    const contentTypeFilter = filters.find(
      (d) => d.filterId === "contentType" && d.values?.length > 0
    );
    if (contentTypeFilter) {
      filteredData = filteredData.filter((d) =>
        contentTypeFilter.values.some((v) => v.value === d.metaData?.contentType)
      );
    }

    filterById('stakeholder', 'stakeholder');
    filterById('facilityTypes', 'facilityTypes');
    filterById('conditionAreas', 'conditionAreas');
    filterById('teams', 'teams');
    filterById('subject', 'subject');
    filterById('country', 'country');
    filterById('states', 'states');
    filterById('districts', 'districts');
    filterById('dateFilter', 'kp_date_published');
  
    return filteredData;
  };