import { cloneDeep, isEqual } from "lodash";
import {
  ActionMenu,
  icons,
  colors2 as colors,
  getBreakPoint,
  Loader,
  LoaderOverlay,
  useScreenWidth,
  DropdownSingle,
  UI_BODY_BOLD_SM,
  UI_BODY_SEMIBOLD_DF,
  UI_BODY_SEMIBOLD_SM,
  UI_BODY_SEMIBOLD_SM_DF,
} from "oolib";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import _SaveStatus from "../../components/generalUI/SaveStatus";
import { _Locale } from "../../components/locale/Locale";
import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonTertiary,
} from "oolib/dist/v2/components/Buttons";

import { useFormValidationContext } from "../../contexts/formValidationContext";

import ConfirmAction from "../../components/generalUI/ConfirmAction";
import { UIContent } from "../../UIContent";
import { isDeviceOnline } from "../../utils/environmentConfig";
import { getQueryParam } from "../../utils/general";
import {
  useAutoSave,
  useContributionCloneForModeration,
  useGetContribution
} from "../../utils/react-query-hooks/contributions";
import EditLayoutRenderer from "./comps/EditLayoutRenderer";
import { HeaderContributeTpl } from "./comps/HeaderContributeTpl";
import {
  RawSynthToggleDesktop,
  RawSynthToggleMobile,
} from "./layouts/DEPR/RawSynthToggle";
import { SendBackModal } from "./utils/SendBackModal";
import { useGetPrimaryActionsConfig } from "./utils/useGetPrimaryActionsConfig";
import { handleInjectSerialNumbersBasedOnTplConfig } from "../../utils/addSerialNumbersToTemplate";
import styled from "styled-components";

const ButtonStyle = styled.h4`
  display: flex;
  align-items: center;
  gap: 3px;
  color: ${({ publishButtonDisabled }) =>
    publishButtonDisabled ? colors.grey40 : "white"};
`;


export default function ContributeTpl({
  onboardingProps,
  CustomHeader,
  publishOnClick
}) {
  
  const { pathname, search: queryString } = useLocation()
  const { content_type } = useParams()
  
  const edit_moderate =
    onboardingProps?.edit_moderate || pathname?.split("/")[1];
  const contributionId =
    onboardingProps?.contributionId || getQueryParam(queryString, "id");
  const urlParamContentType =
    onboardingProps?.contentType || content_type;

  const { resetFormValidation, formValidation, makeFormValidationVisible } =
    useFormValidationContext();

  useEffect(() => {
    return () => resetFormValidation();
  }, []);

  const {
    mutate: autosave,
    isLoading: isAutoSaving,
    isSuccess: isAutoSaved,
  } = useAutoSave();

  const { data: contributionQueryData, status: getContributionStatus } =
    useGetContribution({
      contentType: urlParamContentType,
      contentId: contributionId,
      mode: edit_moderate, // = edit
    });
  const { data: moderationQueryData, status: contributionCloneStatus } =
    useContributionCloneForModeration({
      contentType: urlParamContentType,
      contentId: contributionId,
      mode: edit_moderate, // = moderate
    });

  const { PaperPlaneTilt, CheckCircle, Info, WarningDiamond, DotsThree, CaretDown } = icons;
  const IconsMap = { PaperPlaneTilt, CheckCircle, Info, WarningDiamond, CaretDown };

  const getContentLoadStatus = () => {
    return getContributionStatus !== "idle"
      ? getContributionStatus
      : contributionCloneStatus;
  };

  const [content, setContent] = useState(null);

  useEffect(() => {
    if (getContentLoadStatus() === "success") {
      const { contribution } = contributionQueryData || moderationQueryData;
      setContent(cloneDeep(contribution));
    }
  }, [getContentLoadStatus()]);

  const screenWidth = useScreenWidth();
  const isMobile = screenWidth < getBreakPoint('md');
  const kp_published_status = (contributionQueryData || moderationQueryData)?.contribution.kp_published_status;
  const {
    loadingText: loadingTextPrimaryActions,
    isLoading: isLoadingPrimaryActions,
    
    setOpenSendBackModal,
    openSendBackModal,

    sendBackMutate,
    submitPublishContent,
    moderationApproveQuery,

    publishButtonDisabled,
    mutliPagePublishButtonDisabled,
    closeButtonConfig,
    publishButtonConfig,
    otherLeftsideButtonConfigs,
    otherRightsideButtonConfigs,
    disablePublishButtonVisually,

    headerLeftOptions,
    headerRightOptions,
    formStatusmessage,
    activePageIdx,
    setActivePageIdx,
    stepValidation,
    setStepValidation,
    totalPagesLength,
  } = useGetPrimaryActionsConfig({
    kp_published_status,
    contentType: urlParamContentType,
    contributionQueryData,
    moderationQueryData,
    isAutoSaving,
    edit_moderate,
    content,
    contributionId,
    publishOnClick,
  });

  useEffect(() => {
    const callback = (e) => {
      e.preventDefault();
      return true;
    };
    if (getContentLoadStatus() !== "success" || !content) return;
    const { contribution } = contributionQueryData || moderationQueryData;
   
    if (!isEqual(contribution, content)) {
      window.addEventListener("beforeunload", callback);
    } else {
      window.removeEventListener("beforeunload", callback);
    }
    return () => {
      window.removeEventListener("beforeunload", callback);
    }
  }, [getContentLoadStatus(), content]);

  const H_PrimaryCTA = () => {
    if (!publishButtonConfig) return null;

    return (
      // Publish / Approve / Submit
      <div onClick={(e) => publishButtonConfig.wrapperOnClick(e)}>
        <ConfirmAction {...publishButtonConfig.confirmAction}>
          <ButtonPrimary
            id={"h_PrimaryCTA"} /** Id is v. important for coachmark to work */
            style={{ pointerEvents: isAutoSaving ? "none" : "", width: isMobile ? "114px" : "102px"
             , padding: isMobile ? "12px 14px 12px 12px" : "9px 14px 9px 12px"
             }}
            onClick={() =>
              !publishButtonDisabled &&
              (publishOnClick || publishButtonConfig.onClick)?.()
            } //publishOnClick is an optional prop that can be passed through. only used is selfServeSurvey
            disabled={disablePublishButtonVisually()}
            responsive={true}
          >
            <ButtonStyle
              publishButtonDisabled={disablePublishButtonVisually()}
            >
              {kp_published_status === "underModeration" ? (
                <CheckCircle size={isMobile ? 20 : 16} weight="bold" style={{marginBottom: isMobile ? "0px" : "2px"}} />
              ) : (
                <PaperPlaneTilt size={isMobile ? 20 : 16} weight="bold" />
              )}
              <UI_BODY_SEMIBOLD_SM_DF>{publishButtonConfig.display
                ? publishButtonConfig.display
                : "No actions"}</UI_BODY_SEMIBOLD_SM_DF>
            </ButtonStyle>
          </ButtonPrimary>
        </ConfirmAction>
      </div>
    );
  };

  const GenStepIndicator = ({activePageIdx, totalPagesLength}) => (
    <div style={{ display: "flex" }}>
      {!isMobile && <UI_BODY_BOLD_SM>{`Step `}</UI_BODY_BOLD_SM>}
      &nbsp;
      {isMobile ? <UI_BODY_SEMIBOLD_DF>{` ${activePageIdx + 1}/${totalPagesLength}`}</UI_BODY_SEMIBOLD_DF>
       : <UI_BODY_BOLD_SM>{` ${activePageIdx + 1}/${totalPagesLength}`}</UI_BODY_BOLD_SM>}
    </div>
  );
  
  const FormPageStatus = ({formStatusmessage}) => {
    const Icon = IconsMap[formStatusmessage.icon]
    return (
      <div>
        <UI_BODY_SEMIBOLD_SM
          style={{
            color: formStatusmessage?.color,
            display: "flex",
            alignItems: "center",
            gap: "4px",
            fontWeight: "500",
          }}
        >
          <Icon size={16} weight="bold" style={{marginBottom: '2px'}} />
          {formStatusmessage?.text}
        </UI_BODY_SEMIBOLD_SM>
      </div>
    );
  };

  const H_SaveStatus = () => {
    const text = isAutoSaved
      ? !isDeviceOnline()
        ? "Saved Offline"
        : "Saved!"
      : isAutoSaving
      ? !isDeviceOnline()
        ? "Saving Offline..."
        : "Saving..."
      : "";
    if(!text) return null;
    return (
      <>
        {(!isMobile || totalPagesLength > 1) && <div style={{borderLeft: `2px solid ${colors.grey10}`, height: "18px"}}/>}
        <_SaveStatus
          status={_Locale(
            <UI_BODY_SEMIBOLD_SM_DF style={{ color: colors.grey40, fontWeight: "500" }}>
              {text}
            </UI_BODY_SEMIBOLD_SM_DF>
          )}
        />
      </>
    );
  };

  const BackButton = ({children, disabled, onClick}) => (
    <ButtonSecondary
      children={<UI_BODY_SEMIBOLD_SM_DF >{children}</UI_BODY_SEMIBOLD_SM_DF>}
      disabled={disabled}
      onClick={onClick}
      className={isMobile && "buttonStyle"}
      style={{width: isMobile ? "105px" : "102px", padding: isMobile ? "12px 14px 12px 12px" : "9px 14px 9px 12px"}}
    />
  );

  const NextButton = ({id, children, disabled, onClick, wrapperOnClick, icon }) => {
    let modifiedChildren = children;
    if(icon){
      const Icon = IconsMap[icon]
      modifiedChildren = <><Icon size={isMobile ? 20 : 16} weight="bold" style={{marginBottom: "1px"}} /> <UI_BODY_SEMIBOLD_SM_DF>{children}</UI_BODY_SEMIBOLD_SM_DF></>;
    }
    return (
    <div onClick={wrapperOnClick}>
      <ButtonPrimary
        id={id}
        children={
          <ButtonStyle publishButtonDisabled={formValidation.makeVisible}>
            {modifiedChildren}
          </ButtonStyle>
        }
        disabled={formValidation?.makeVisible}
        onClick={()=>{
          if(mutliPagePublishButtonDisabled) return;
          onClick();
        }}
        className="buttonStyle"
        style={{width: isMobile ? "105px" : "102px", padding: isMobile ? "12px 14px 12px 12px" : "9px 14px 9px 12px"}}
        responsive={true}
      />
    </div>
  )};

  const H_DEPRECATED_researchNotesInject = ({ desktop_mobile }) => {
    const { tpl } = contributionQueryData || moderationQueryData;

    return tpl.category !== "researchNotes"
      ? null
      : desktop_mobile === "desktop"
      ? screenWidth >= getBreakPoint("md") && <RawSynthToggleDesktop />
      : desktop_mobile === "mobile" &&
        screenWidth < getBreakPoint("md") && <RawSynthToggleMobile />;
  };

  const EditLaterButton = () => {
    if(!closeButtonConfig) return null;
    return (
      <ConfirmAction {...closeButtonConfig.confirmAction}>
        <ButtonTertiary
            id={"h_CloseButton"} /** Id is v. important for coachmark to work */
            disabled={isAutoSaving}
            onClick={closeButtonConfig.onClick}
            className="buttonStyle"
            responsive={true}
          >
            {closeButtonConfig.display}
        </ButtonTertiary>
      </ConfirmAction>
    );
  }

  const H_CloseButton = () => {
   
    return (
      <>
        {headerRightOptions?.map(({comp, props}, ind) => {
            const Comp = CompMap[comp];
            if(!Comp) return null;
            return (
            <Fragment key={ind}>
             <Comp {...props} />
            </Fragment>
          )})}
      </>
    )
  };

  const SendBackButton = () => {
    if(otherLeftsideButtonConfigs.length === 0) return null;

    return (
      <ConfirmAction
        title={UIContent.confirmActionSendBack.title}
        subtitle={UIContent.confirmActionSendBack.subtitle}
        onConfirmText={UIContent.confirmActionSendBack.onConfirmText}
      >
        <ButtonSecondary 
          disabled={isAutoSaving} 
          onClick={() => setOpenSendBackModal(true)}
          responsive={true}
        >
          {UIContent.sendBackBtn}
        </ButtonSecondary>
      </ConfirmAction>
    )
  }

  const CompMap = {
    ActionMenu,
    DropdownSingle,
    GenStepIndicator,
    FormPageStatus,
    EditLaterButton,
    SendBackButton,
    H_CloseButton,
    H_PrimaryCTA,
    BackButton,
    NextButton,
  };

  const PRIMARY_BUTTON = () => {
    console.log('PRIMARY_BUTTON headerLeftOptions:', headerLeftOptions);
    if(headerLeftOptions.length === 0) return null;
    return (
      <>
        <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
          {headerLeftOptions?.map(({comp, props}, ind) => {
            const Comp = CompMap[comp];
            if(!Comp) return null;
            return (
            <Fragment key={ind}>
             <Comp {...props} />
             {ind !== headerLeftOptions.length-1 && <div style={{borderLeft: `2px solid ${colors.grey10}`, height: "18px"}}/> }
            </Fragment>
          )})}
        </div>
      </>
    );
  };
  
  const CLOSE_BUTTON = () => {
    const Comps = otherRightsideButtonConfigs.map((rightComp, index) => {
      const Comp = CompMap[rightComp.comp];
      if (!Comp) {
        console.error(`Component not found: ${rightComp.comp}`);
        return null;
      }
      return (
        <Comp
          key={`${rightComp.comp}-${index}`}
          {...rightComp.props}
          CustomSelectComp={(props)=><ButtonTertiary {...props} responsive={true} style={{padding: "9px 10px 9px 12px"}}>
            <div style={{display: "flex", gap: "4px", alignItems: "center", lineHeight: "1"}}>
              <UI_BODY_SEMIBOLD_SM style={{lineHeight: "18px"}}>More Options </UI_BODY_SEMIBOLD_SM>
              <CaretDown weight="bold" style={{display: "flex"}} />
            </div>
          </ButtonTertiary>}
          genCustomSelectComp={() => (
            <ButtonTertiary
              style={{ display: "flex", width: "45px", height: "45px" }}
              children={<DotsThree size={20} style={{ display: "flex" }} />}
              responsive={true}
            />
          )}
        />
      );
    });
  
    return (
      <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
        {otherRightsideButtonConfigs.length > 0 ? Comps : null}
        <H_CloseButton />
      </div>
    );
  };

  const genComp = () => {
    let status = getContentLoadStatus();

    switch (status) {
      case "loading":
        return <Loader />;
      case "error":
        return <h1>Error</h1>;
      case "success":
        return (
          content && (
            <>

              {CustomHeader ? (
                <CustomHeader
                  onSave={(onboardingCb) => {
                    const content = contributionQueryData.contribution;
                    submitPublishContent.mutate(
                      {
                        contentType: content.meta.kp_content_type,
                        contentId: content._id,
                        userAction: "PUBLISH",
                        disableRedirectOnPublish: true,
                      },
                      {
                        onSuccess: (res) => {
                          onboardingCb &&
                            onboardingCb({
                              dynamicOnboardingProps: {
                                contentType: res.data.content.meta.kp_content_type,
                                contributionId: res.data.content._id,
                              },
                            });
                        },
                      }
                    );
                  }}
                  saveDisabled={publishButtonDisabled}
                  customRightComp={<H_SaveStatus />}
                />
              ) : (
                //eventually we want to move this header altogether into each Layout comp
                ["researchNotes2"].indexOf(
                  (contributionQueryData || moderationQueryData).tpl.category
                ) === -1 && (
                  <HeaderContributeTpl
                    PrimaryCTA={<PRIMARY_BUTTON />}
                    SaveStatus={<H_SaveStatus />}
                    CenterComp={
                      <H_DEPRECATED_researchNotesInject
                        desktop_mobile={"desktop"}
                      />
                    }
                    CloseButton={<CLOSE_BUTTON />}
                    AfterComp={
                      <H_DEPRECATED_researchNotesInject
                        desktop_mobile={"mobile"}
                      />
                    }
                  />
                )
              )}
              <EditLayoutRenderer
                tpl={handleInjectSerialNumbersBasedOnTplConfig({
                  tpl: (contributionQueryData || moderationQueryData).tpl
                })}
                autosave={autosave}
                isAutoSaving={isAutoSaving}
                isAutoSaved={isAutoSaved}
                isModerateMode={edit_moderate === "moderate"}
                contentState={[content, setContent]}
                activePageIdx={activePageIdx}
                setActivePageIdx={setActivePageIdx}
                stepValidation={stepValidation} 
                setStepValidation ={setStepValidation}
                publishBtnTextAndOnClickAndDisabled={{
                  text: publishButtonConfig.display
                    ? publishButtonConfig.display
                    : "No actions",
                  onClick: publishOnClick || publishButtonConfig.onClick,
                  disabled: publishButtonDisabled,
                  wrapperOnClick: publishButtonConfig.wrapperOnClick
                }}
                //header comps
                {...{
                  H_PrimaryCTA:PRIMARY_BUTTON,
                  H_SaveStatus,
                  H_CloseButton:CLOSE_BUTTON
                }}
              />
              {/* { genTemplateInterfaceByCategory() } */}
            </>
          )
        );
      default:
        return;
    }
  };

  return (
    <>
    {isLoadingPrimaryActions && <LoaderOverlay loaderText={loadingTextPrimaryActions}/>}
    <SendBackModal
      sendBackMutate={sendBackMutate}
      openSendBackModal={openSendBackModal}
      setOpenSendBackModal={setOpenSendBackModal}
      moderationQueryData={moderationQueryData}
      />
      {moderationApproveQuery.status === "loading" && (
        <LoaderOverlay loaderText={"Approving..."} />
      )}
      {submitPublishContent.status === "loading" && (
        <LoaderOverlay loaderText={"Publishing..."} />
      )}
      <div className="kp-contribute-flow">{genComp()}</div>
    </>
  );
}
