export const addFillColorsToEachAnno = ({ data, colorsConfig }) => {
  // Extract relevant configuration
  const { tagType, tagId, tags: colorTags } = colorsConfig;
  
  // Map color tags by their _id for easier lookup
  const colorMap = colorTags.reduce((acc, tag) => {
    acc[tag.value] = tag.fill;
    return acc;
  }, {});
  
  // Create a counter to track occurrences of each tag
  const tagCounts = colorTags.reduce((acc, tag) => {
    acc[tag.value] = 0;
    return acc;
  }, {});
  
  const coloredData = data?.map(item => {
    // Skip if item doesn't have the specified tagType
    if (!item[tagType]) {
      return { ...item, fills: [], tagLengths: [] };
    }
    
    // Handle tagId as either string or array
    const tagIds = Array.isArray(tagId) ? tagId : [tagId];
    
    // Collect matching colors across all specified tagIds
    let allMatchingColors = [];
    let allTagLengths = [];
    let foundTagIds = new Set();
    
    // Process each tagId
    tagIds.forEach(id => {
      // Skip if this specific tagId doesn't exist
      if (!item[tagType][id]) {
        return;
      }
      
      // Get the tags data array from the specified path
      const tagsData = item[tagType][id].data;
      
      // Skip if no tags data exists
      if (!Array.isArray(tagsData) || tagsData.length === 0) {
        return;
      }
      
      // Find matching colors for this tagId and update counts
      tagsData.forEach(tag => {
        const color = colorMap[tag._id];
        if (color !== undefined) {
          allMatchingColors.push(color);
          allTagLengths.push(tag.length || 0);
          
          // Increment the counter for this tag
          tagCounts[tag._id] = (tagCounts[tag._id] || 0) + 1;
          
          // Track that we found this tag
          foundTagIds.add(tag._id);
        }
      });
    });
    
    // Add all matching colors and tag lengths to the item
    return {
      ...item,
      fills: allMatchingColors.length > 0 ? allMatchingColors : [],
      tagLengths: allTagLengths.length > 0 ? allTagLengths : [],
      foundTagIds: Array.from(foundTagIds)
    };
  });
  
  // Update colorsConfig with the count information
  const updatedColorTags = colorTags.map(tag => ({
    ...tag,
    count: tagCounts[tag.value] || 0
  }));
  
  const updatedColorsConfig = {
    ...colorsConfig,
    tags: updatedColorTags
  };
  
  // Sort the data based on colors
  // Items with colors will come first, sorted by their first color
  // Items with no colors will come last
  const sortedData = coloredData?.sort((a, b) => {
    // Case 1: Both items have colors
    if (a.fills.length > 0 && b.fills.length > 0) {
      // Compare their first colors alphabetically
      return a.fills[0].localeCompare(b.fills[0]);
    }
    
    // Case 2: Only item a has colors
    if (a.fills.length > 0 && b.fills.length === 0) {
      return -1; // a comes before b
    }
    
    // Case 3: Only item b has colors
    if (a.fills.length === 0 && b.fills.length > 0) {
      return 1; // b comes before a
    }
    
    // Case 4: Neither has colors
    return 0; // maintain original order
  });
  
  return {
    data: sortedData,
    colorsConfig: updatedColorsConfig
  };
}