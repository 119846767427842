import { BAR_GAP, DEFAULT_SINGLE_BAR_WIDTH } from "..";
import { CONTAINER_PADDING } from "../styled";

export const useGetDimensions = (data, maxHeight, containerWidth) => {
    let singleBarWidth;

    const totalBars = data?.reduce((total, tagCategory ) => {
            return total += tagCategory.tagSpecificCount?.length
        }
    ,0)

    const totalGapSpace = (totalBars - 1) * Math.floor(BAR_GAP * 10); // BAR GAP, example 0.3 converted 3Px

    const availableWidthForBars = (containerWidth - totalGapSpace) - (CONTAINER_PADDING * 2); // CONTAINER_PADDING Left right 10 * 10  = 20

    // const defaultWidthForBars = (totalBars - 1) * DEFAULT_SINGLE_BAR_WIDTH - (CONTAINER_PADDING * 2);
    
    const dynamicBarWidth = availableWidthForBars / totalBars;

    singleBarWidth = Math.max(dynamicBarWidth, DEFAULT_SINGLE_BAR_WIDTH);

    return data?.map(category => {
        // total count for this category
        const total = category.tagSpecificCount.reduce((sum, tag) => sum + tag.count, 0);

        const barsInCategory = category.tagSpecificCount.length;
        const gapsInCategory = Math.max(0, barsInCategory - 1); // One less gap than bars

        const categoryTotalWidth = (barsInCategory * singleBarWidth) + (gapsInCategory * Math.floor(BAR_GAP * 10));
        // percentage to each tag
        return {
            ...category,
            width: categoryTotalWidth,
            tagSpecificCount: category.tagSpecificCount.map(tag => {
               let percentage =  Number((tag.count / total * 100).toFixed(1))
                return{
                   percentage: percentage,  // Rounds to 1 decimal place
                   height: Math.round((percentage / 100) * maxHeight),
                   width: singleBarWidth,
                   ...tag,
                }
              }
            )
        };
    });
}
