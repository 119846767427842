import styled, {css, keyframes} from 'styled-components';

import {colors, transition, mediaQuery, SANS_0, /*ButtonGhost, UploadButton*/} from 'oolib'; // use relative path below when moving to oolib
/*import { colors } from '../../../../../themes';
import { transition } from '../../../../../themes/mixins';
import { SANS_0 } from '../../../../Typo';
import { ButtonGhost } from '../../../../Buttons';*/


const { greyColor5, greyColor10, greyColor100, greyColor40, greyColor80, greyColor70 } = colors;


const slideLeft = keyframes`
  from {
    transform: translateX(1rem);
    opacity: 0.5;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const StyledToolbar = styled.div`
  background-color: ${greyColor5};
  border-radius: 0.3rem;
  display: ${({showToolbar, isMobile})=> showToolbar? (isMobile ? "grid" : "flex") : "none"};
  grid-template-columns: repeat(6, 1fr);
  justify-items: center;
  align-items: center;
  width: 100vw;
  z-index: 100;
  & > * {
    cursor: pointer;
  }
  ${mediaQuery("sm")} {
    position: relative;
    width: 100%;
  }
`;

export const StyledDropdown = styled.div`
  background-color: ${greyColor5};
  z-index: 50;
  border-radius: 0.2rem;
  display: flex;
  flex-flow: column wrap;
  position: absolute;
  bottom: 100%;
  overflow: hidden; // for clipping animation
  clip: rect(${({isActive})=> isActive ? 0 : '20rem'}, auto, auto, auto);
  ${transition('clip')}
`;

export const StyledTooltip = styled(SANS_0)`
  visibility: hidden;
  white-space: nowrap;
  z-index: 100;
  position: absolute;
  left: 50%;
  top: 100%; 
  transform: translateX(-50%);
  
`;


export const StyledOptionBtn = styled.button`
  height: 4rem;
  width: 4rem;
  padding: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({isDisabled})=> css`
    cursor: ${isDisabled ? 'not-allowed !important': 'pointer'};
  `}
  border: none;
  color: ${({ invert, isDisabled, theme: { colors } }) =>{
    return isDisabled ? 
    greyColor40:
    invert?
    colors.primaryColor40
    : greyColor100 
  }};
  background-color: ${({active, invert, theme: { colors } }) => {
    if (invert) return active ? greyColor80 : greyColor100;
    return active ? greyColor10: "inherit";
  }};
  &:hover {
    background-color: ${({ invert, active, theme: { colors } }) =>
      active
        ? ``
        : invert
        ? greyColor70
        : colors.primaryColor40
      };
  }
  ${transition('background-color')}
  animation: ${slideLeft}  0.3s ease-out;
  ${mediaQuery('md')}{
    &:hover ~ .buttName {// to display the tooltip
      visibility: visible;
    }
  }
`;


