import React from "react";
import { ButtonTertiaryCompact, UI_BODY_SEMIBOLD_SM } from "oolib";
import { ErrorMessageStyled } from "./styled";

export const ErrorMessage = ({
  message = {
    content: { value: `Sorry something went!, please try again.` },
  },
  onRetry
}) => {
  return (
    <ErrorMessageStyled key={message.id}>
      <div style={{ display: "flex", gap: "2rem", alignItems: "center" }}>
        <UI_BODY_SEMIBOLD_SM>{message.content.value}</UI_BODY_SEMIBOLD_SM>
        <ButtonTertiaryCompact onClick={onRetry} icon="ArrowClockwise" S>
          Retry
        </ButtonTertiaryCompact>
      </div>
    </ErrorMessageStyled>
  );
};
