import React, { useState, Fragment } from "react";

//custom components
import { KPDataSummaryCardTypePercentPlusViz, KPDataSummaryCardTypePercent, KPDataSummaryCardTypeHeadline } from '../../components/dataviz/KPDataSummaryCard';


//utils
import { getSelectedDistrictData } from "./datavizUtils"

//icons
import Tooth from "../../components/icons/Tooth"
import WaterDrop from "../../components/icons/WaterDrop"
import Unaware from "../../components/icons/Unaware"

import {  SANS_3, DropdownSingle } from 'oolib'

//data
import { districtsSurveyed } from "../../staticData/general.json";


const DataWQN = ({ value: valueFromParent, noContainerFluid, noHeaderDd, title }) => {

  const onChange = (id, val) => { setSelectedDistrictData(getSelectedDistrictData(val.value)); }

  // unused
  // const tooltipClick = (e) => { setTooltipInfo(e.target.id) }
  // const tooltipInfoClose = () => { setTooltipInfo("") }

  const [selectedDistrictData, setSelectedDistrictData] = useState(getSelectedDistrictData(valueFromParent || districtsSurveyed[0].value));


  // unused
  // const [tooltipInfo, setTooltipInfo] = useState("");

  const {
    district : { name : districtName },
    schoolsData : {
      fluorosisSymptoms : { fluorosisType, value : fluorosisAffectedVal},
      lessThan4DaysNutrition : { value : schoolsLessThan4DaysNutritionVal, who : schoolsLessThan4DaysNutritionWho },
      waterQualityFieldtestkit : waterQualitySchools
    },
    anganwadisData : {
      unawareOfFluorosis : { value : anganwadisUnawareOfFluorosisVal, who : anganwadisUnawareOfFluorosisWho  },
      lessThan4DaysNutrition : { value : anganwadisLessThan4DaysNutritionVal, who : anganwadisLessThan4DaysNutritionWho },
      waterQualityFieldtestkit : waterQualityAnganwadis,
      muacCritical
    },
    householdsData : {
      unawareOfFluorosis : { value : householdsUnawareOfFluorosis, who : whoInHouseholdsUnaware },
      lessThan4DaysNutrition : { value : householdsLessThan4DaysNutritionVal, who : householdsLessThan4DaysNutritionWho },
      waterQualityFieldtestkit : waterQualityHouseholds
    }
  } = selectedDistrictData;


  // unused
  // const highestUnawareOfFluorosis = getHighestValAndKey(
  //   [anganwadisUnawareOfFluorosisVal, householdsUnawareOfFluorosis],
  //   [anganwadisUnawareOfFluorosisWho, whoInHouseholdsUnaware]
  // );
  // const highestLessThan4DaysNutrition = getHighestValAndKey(
  //   [anganwadisLessThan4DaysNutritionVal, schoolsLessThan4DaysNutritionVal, householdsLessThan4DaysNutritionVal],
  //   [anganwadisLessThan4DaysNutritionWho, schoolsLessThan4DaysNutritionWho, householdsLessThan4DaysNutritionWho]
  // );

  return(
    <div>
      <div className="kp-secondary-header">
        <div className="kp-secondary-header__left-comp">
        { <SANS_3 semibold className="kp-secondary-header__title">{`${title ? title : ''} District Dashboard`}</SANS_3>}
          <SANS_3 className="kp-secondary-header__subtitle">Baseline Data<strong>2019</strong></SANS_3>
        </div>
        <div className="kp-secondary-header__center-comp"></div>
        <div className="kp-secondary-header__right-comp">
        { !noHeaderDd &&
          <Fragment>
            <SANS_3 className=" kp-secondary-header__text">District</SANS_3>
              <DropdownSingle
                value={{...selectedDistrictData.district, display: selectedDistrictData.district.name}}
                id="kp-secondary-header__dd"
                className="kp-secondary-header__dd"
                onChange={(id, val) => onChange(id, val)}
                options={districtsSurveyed.map(d => ({...d, display: d.name}))}
                />
          </Fragment> }
        </div>
      </div>
      <div className={`${!noContainerFluid ? 'container-fluid kp-container-fluid': ''} kp-data-summary-body`}>
        <div className="row kp-row">
          <KPDataSummaryCardTypeHeadline
            colWidth = "col-xl-3 col-md-6 col-sm-12"
            icon ={ <Tooth /> }
            title={fluorosisType}
            headlinePart1= { fluorosisAffectedVal[0] }
            headlinePart2= { `of every ${fluorosisAffectedVal[1]}` }
            subheadline={ <Fragment>adolscents suffer from varying levels of {fluorosisType}</Fragment> }
            />
          <KPDataSummaryCardTypeHeadline
            colWidth = "col-xl-3 col-md-6 col-sm-12"
            icon = { <Tooth /> }
            title = "Malnutrition"
            headlinePart1= { muacCritical[0] }
            headlinePart2= { `of every ${muacCritical[1]}` }
            subheadline={ <Fragment>infants are critically malnutritioned in {districtName} district</Fragment> }
            />
          <KPDataSummaryCardTypePercentPlusViz
            colWidth = "col-xl-6 col-lg-12"
            icon = { <WaterDrop /> }
            title = "Water Quality"
            dataDescrip = "Percentage of unsafe water sources in the district for each of the following"
            data = { [waterQualitySchools, waterQualityAnganwadis, waterQualityHouseholds] }
            />

          <KPDataSummaryCardTypePercent
            colWidth = "col-xl-6"
            icon = { <Tooth /> }
            title = "Less Access to Nutrition"
            dataDescrip = "Percentage of entities who have access to nutrition less that 4 days a week"
            data = {
              [
                { key : schoolsLessThan4DaysNutritionWho , val : schoolsLessThan4DaysNutritionVal[0]+"%" },
                { key : anganwadisLessThan4DaysNutritionWho , val : anganwadisLessThan4DaysNutritionVal[0]+"%" },
                { key : householdsLessThan4DaysNutritionWho , val : householdsLessThan4DaysNutritionVal[0]+"%" }
              ]
            }
            />

          <KPDataSummaryCardTypePercent
            colWidth = "col-xl-6"
            icon = { <Unaware /> }
            title = "Unaware of Fluorosis"
            dataDescrip = "Percentage of entities who lac awareness about Fluorosis and its symptoms"
            data = {
              [
                { key : whoInHouseholdsUnaware , val : householdsUnawareOfFluorosis[0]+"%" },
                { key : anganwadisUnawareOfFluorosisWho , val : anganwadisUnawareOfFluorosisVal[0]+"%" }
              ]
            }
            />
        </div>
      </div>
    </div>
  )
}

export default DataWQN;
