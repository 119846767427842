import { colors2, Tooltip, UI_BODY_SM_DF } from "oolib";
import React from "react";
import { StyledEllipsisRemoveWrapper } from "../utils/StyledEllipsisRemoveWrapper";

export const TableTextInput = ({
  value,
  onChange,
  readOnly,
  tooltip,
}) => {
  const halfGapBetweenInfoAndInput = '0.25rem'

  return (
    <StyledEllipsisRemoveWrapper readOnly={readOnly}>
      {readOnly ? (
        <Tooltip text={value} position="bottom">
        <UI_BODY_SM_DF>{value}</UI_BODY_SM_DF>
        </Tooltip>
      ) : (
        <div style={{ display: "flex", alignItems: "center" }}>
          {tooltip && (
            <div style={{paddingRight: halfGapBetweenInfoAndInput, borderRight: `1px solid ${colors2.grey10}`}}>
            <Tooltip
              presetTarget="infoIcon"
              popOutOfOverflowHiddenParent
              position="right"
              {...tooltip}
            />
            </div>
          )}
          <input
            style={{
              width: "100%",
             border: 'none',
              background: "none",
              textIndent: halfGapBetweenInfoAndInput,
            }}
            type="text"
            className="UI_BODY_SM_DF"
            value={value}
            onChange={(e) => onChange(null, e.target.value)}
          />
        </div>
      )}
    </StyledEllipsisRemoveWrapper>
  );
};
