import React, { useEffect, useRef, useState } from "react";
import { fixedGetDataConfig } from "../../../pageTpls/PublishedListing/config/fixedGetDataConfig";
import { __GetAllContentTypeConfig } from "../../../utils/getters/gettersV2";
import { useGetDataLazy } from "../../../utils/react-query-hooks/getData";
import { extractAllBlocksFromTpl } from "../../../utils/templating/extractAllBlocksFromTpl";

import { useGetCountForQuantField } from "../InsightMatrix/queries";
import { getValidFieldsForQuantViz } from "../InsightMatrix/utils/getValidFieldsForQuantViz";
import { CompRendererDataPivot } from "./CompRendererDataPivot";
import { getSessionStorageDataForListingPage, getSessionStorageKeyForListingPage } from "../../../pageTpls/ListingPage/newUtils/sessionStorageUtils";

export const DataFetcherDataPivot = ({contentType, coverConfig, usedIn = 'PublishedListing', filterConfig}) => {
  
  const allTpls = useRef(__GetAllContentTypeConfig())

  const getPivotableBlocks = (contentType) => {
    const allBlocks = extractAllBlocksFromTpl({ tpl: allTpls.current.find(d => d.kp_content_type === contentType)});
    return allBlocks.filter((bl) => bl.comp !== "StaticRichText" && bl.inputOnly !== true);
  };

  const [activeBlock, setActiveBlock] = useState(
    contentType ? getPivotableBlocks(contentType)[0] : undefined
  );
  const [subActiveBlock, setSubActiveBlock] = useState();

  const sessionStorageKey = getSessionStorageKeyForListingPage({contentTypes: [contentType]})
  const sessionStorageData = getSessionStorageDataForListingPage(sessionStorageKey);

  const [activeFilters, setActiveFilters] = useState(
    usedIn === 'PublishedListing' ? (sessionStorageData?.activeFilters || []) : []
  )

  useEffect(() => {

    if(usedIn !== 'PublishedListing') return; //cuz we wanna remember filters only in the PublishedListing context

    
    const existingData = sessionStorage.getItem(sessionStorageKey);
    
    if (existingData) {
      const parsedData = JSON.parse(existingData);
      const updatedData = {
        ...parsedData,
        activeFilters: activeFilters || []
      };
      sessionStorage.setItem(sessionStorageKey, JSON.stringify(updatedData));
    } else {
      const toSet = {
        searchTerm: '',
        activeFilters: activeFilters || []
      };
      sessionStorage.setItem(sessionStorageKey, JSON.stringify(toSet));
    }
  }, [activeFilters]);


  const {data: countData, status: countStatus, error: countError } = useGetCountForQuantField({
    vizCardId: activeBlock?.valuePath,
    contentType,
    activeFilters,
    tpl: allTpls.current.find(d => d.kp_content_type === contentType),
    blockValuePath: activeBlock?.valuePath,
    subBlockValuePath: subActiveBlock?.valuePath,
    activeBlock,
    queryOptions: {
      enabled: !!activeBlock === true && getValidFieldsForQuantViz([activeBlock]).some(d => d.valuePath === activeBlock.valuePath),
    }
  })

  const { data, status, error , fetchNextPage, hasNextPage, isFetchingNextPage } = useGetDataLazy({
    
    contentTypes: [contentType],
    ...fixedGetDataConfig,
    activeFilters,
    projection: {
      [activeBlock?.valuePath]: 1,
      [subActiveBlock?.valuePath]: 1,
      'meta.kp_contributed_by': 1,
      'kp_date_published': 1
    },
    queryOptions: {
      enabled: !!activeBlock === true,
    },
  });

  return <div>
      
      <CompRendererDataPivot
      pivotableBlocks={contentType && getPivotableBlocks(contentType)}
      setActiveBlock={setActiveBlock}
      activeBlock={activeBlock}
      setSubActiveBlock={setSubActiveBlock}
      subActiveBlock={subActiveBlock}
      data={data}
      dataFetchStatus={status}
      dataFetchError={error}
      dataVizAccordion={countData && { 
        title: 'Summary',
        data:countData?.countsArray,
        chartOptions: [{
          display: 'Bar Chart',
          comp: 'BarChart',
        }],
        colorIdx:0,
        valuePath:
        subActiveBlock && countData.maxBreakDownItems
          ? Array.from(
              { length: countData.maxBreakDownItems },
              (_, i) => `breakDown.${i}.count`
            )
          : "count",
          tooltipLabelsPath: subActiveBlock && countData.maxBreakDownItems
          ? Array.from(
              { length: countData.maxBreakDownItems },
              (_, i) => `breakDown.${i}.display`
            )
          : undefined,
        labelPath:"display"
      }}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      filterConfig={filterConfig}
      activeFilters={activeFilters}
      setActiveFilters={setActiveFilters}
      />
    </div>
};
