import React, { useEffect, useRef, useState } from "react"
import { colors2, SkeletonLoader, Tooltip, UI_BODY_SEMIBOLD_SM, UI_BODY_SM } from "oolib"
import { StyledBar, StyledBarGraphCollectionWrapper, StyledBarGraphContainer, StyledBarGraphWrapper, StyledLabelWrapper, StyledSingleBarWrapper } from "./styled";
import { useGetDimensions } from "./utils/useGetDimensions";
import { __GetContentTypeConfigNew } from "../../../../../utils/getters/gettersV2";
import CustomTooltip  from "oolib/dist/v2/components/BarChart/comps/CustomTooltip"
const { grey5 } = colors2

export const BAR_GRAPH_HEIGHT = 250;
export const DEFAULT_SINGLE_BAR_WIDTH = 24;
export const BAR_GAP = 0.3;

export const BarGraphCollection = ({ data: data_ }) => {

    const containerRef = useRef()

    const [ containerWidth, setContainerWidth ] = useState(0);

    const data = useGetDimensions(data_, BAR_GRAPH_HEIGHT, containerWidth);

    useEffect(() => {
        const handleResize = () => {
            setContainerWidth(containerRef?.current?.getBoundingClientRect()?.width)
        };

        handleResize();

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);
    
    if(data?.length === 0){
      return null
    }

  return (
      <StyledBarGraphContainer style={{ width: `auto` }} ref={containerRef}>
            <StyledBarGraphCollectionWrapper
                style={{
                    width: `auto`,
                    gap: `${BAR_GAP}rem`,
                    overflowX: "auto"
                }}>
                    {(data)?.map(( tagCategory , index1 ) => {

                      const categoryTitle = __GetContentTypeConfigNew(tagCategory.tagCategory)?.general?.content?.title || (tagCategory.tagCategory.charAt(0).toUpperCase() + tagCategory.tagCategory.slice(1))
                      const singleBarWidth_ = "2"

                        return(
                          <div key={`${index1}-${tagCategory.tagCategory}`} style={{ display: "flex", flexDirection: "column", gap: `${BAR_GAP}rem`, height: `${BAR_GRAPH_HEIGHT  + 50}`, marginBottom: "0.6rem" }}>
                            <BarGraph {...{ tagCategory, index1 }} />

                            <div title={categoryTitle} style={{ width: `${tagCategory?.width}px`, background: grey5, borderRadius: "2px", padding: "0.8rem", maxHeight: "50px", overflow:"hidden"}}>
                              <UI_BODY_SEMIBOLD_SM>
                                <p style={{ 
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: "100%" 
                                }}>
                                  {categoryTitle}
                                </p>
                              </UI_BODY_SEMIBOLD_SM>
                            </div>

                          </div>
                        )
                    })}
            </StyledBarGraphCollectionWrapper>
       </StyledBarGraphContainer>
    );
}


export const BarGraph = ({ tagCategory, index1 }) => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [activeTooltip, setActiveTooltip] = useState(null);
  
  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };
  
    window.addEventListener('mousemove', handleMouseMove);
  
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const truncateText = (text, maxLength = 30) => {
    if (!text) return '';
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + '...';
  };


  const handleBarMouseEnter = (index) => {
    setActiveTooltip(index);
  };

  const handleBarMouseLeave = () => {
    setActiveTooltip(null);
  };

  return (
    <StyledBarGraphWrapper
          style={{
              height: `${BAR_GRAPH_HEIGHT}px`,
              gap: `${BAR_GAP}rem`,
            }}>
        {tagCategory?.tagSpecificCount?.map(( tag, index ) => {
          
          const tooltipData = [{
            // color: tag.color,
            percentage: `Percentage ${tag.percentage}`,
            count: `Count ${tag.count}`,
            // tooltipLabel: tag.display || tag.name || `Item ${index + 1}`
          }];
          
          const position = index === tagCategory?.tagSpecificCount?.length - 1 ? "left" : "right";

            return(
              <div key={tag._id || `${index1}-${index}`}
                onMouseEnter={() => handleBarMouseEnter(index)}
                onMouseLeave={handleBarMouseLeave}>
              {/* // <CustomTooltip mousePosition={mousePosition} value={"hello"} key={index} text={`${tag?.percentage}%, ${tag?.count}`} position={index === tagCategory?.tagSpecificCount?.length - 1 ? "left" : "right"}> */}
                <StyledSingleBarWrapper >
                    <StyledBar
                      style={{
                        height: `${tag.height}px`,
                        width: `${tag.width}px`
                      }}
                    />
                    <StyledLabelWrapper>
                      <UI_BODY_SM>{truncateText(tag?.display, 30 )}</UI_BODY_SM>
                    </StyledLabelWrapper>

                {activeTooltip === index && (
                  <CustomTooltip
                    active={true}
                    payload={[tag]}
                    mousePosition={mousePosition}
                    value={tooltipData}
                    showPercent={true}
                  />
                )}
                </StyledSingleBarWrapper>
              </div>
                )
            })
        }
    </StyledBarGraphWrapper>
  )
}
