import {
  colors,
  colors2,
  Container,
  icons,
  PaddingTopBottom20,
  SkeletonLoader,
  sortData,
  UI_BODY_SEMIBOLD_SM,
  UI_TITLE_SM
} from "oolib";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { CardAnnoExplorer } from "../../../../../../../../components/analysisModules/AnnotationExplorer/comps/CardAnnoExplorer";
import { useGetAnnotationExplorerData } from "../../../../../../../../components/analysisModules/AnnotationExplorer/useGetAnnotationExplorerData";

import { getAnnoExplorerConfigsForThisTenant } from "../../../../../../../../components/analysisModules/AnnotationExplorer/utils/getAnnoExplorerConfigsForThisTenant";
import FilterModule from "../../../../../../../../components/discovery/FilterModule";
import { segrigateDocs } from "../../../../../../../../utils/general";
import { getRichTextAsPlainTextLex } from "../../../../../../../../utils/getters/gettersV2";
import { useGetDataQuery } from "../../../../../../../../utils/react-query-hooks/getData";
import { lexicalTextEditorHasValue } from "../../../../../../../../utils/validation/validatorFns";
import { parseSpecialConfigSyntax } from "../../../ListingConfigsRenderer/utils/parseSpecialConfigSyntax";
import { FilterPlusBodyShellForCollectionPages } from "../GELABS_findingsAnnoTab/FilterPlusBodyShellForCollectionPages";
import { applyTagsWhitelistConfig } from "@/components/analysisModules/AnnotationExplorer/utils/applyTagsWhitelistConfig";

const { ArrowLineUpRight } = icons;

const StyledTitleLink = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${colors2.black};
  }

  &:hover {
    &::after {
      height: 0;
    }
    /* border-bottom: 1px solid ${colors2.black}; */
  }
`;

const customConfigsForThisPlugin = {
  filterDefs: {
    whitelist: [
      "ROLLUP_tags_participantsRegistrationTemplates_segmentationNigeria",
      "ROLLUP_tags_participantsRegistrationTemplates_segmentKenya",
      "ROLLUP_tags_participantsRegistrationTemplates_segmentationSenegal",
      "ROLLUP_docTags_participantsRegistrationTemplates_segmentationNigeria",
      "ROLLUP_docTags_participantsRegistrationTemplates_segmentKenya",
      "ROLLUP_docTags_participantsRegistrationTemplates_segmentationSenegal"
    ],
  },
};

export const GELABS_findings = ({
  content,
  tabsPanelHeight,
  actionBarHeight,
}) => {
  const annoExplorerConfigs = getAnnoExplorerConfigsForThisTenant();

  

  const presetActiveFilters = useMemo(() => {
    const filters = [
      // Original hypothesis filter
      {
        target: {
          filterType: "nestedRollupTagType",
          /**
           * this plugin is used on the hypo collection page & the insights collection page
           * both of these are used as tags on findings. and findings is used an an annotation tag
           * on the content. so the below dynamic stuff on rolluppath works
           */
          rollupPath: ["findings", content.meta.kp_content_type],
        },
        source: {
          scope: "annoTags",
        },
        values: [{ value: "$valuePath(_id)" }],
      },
    ];

    return parseSpecialConfigSyntax({
      config: filters,
      content,
    });
  }, [content]);

  // Use the new hook with tenant-specific configuration
  const {
    tplOptions,
    tplOptionsDropdownData,
    data,
    totalData,
    totalCount,
    selectedTpls,
    setSelectedTpls,
    activeFilters,
    setActiveFilters,
    filterConfigs,
    sortOptions,
    activeSort,
    setActiveSort,
    keyword,
    setKeyword,
    observerRef,
    dataFetchStatus,
    error,
  } = useGetAnnotationExplorerData({
    presetActiveFilters,
    // Use whitelist from annoExplorerConfigs
    contentTypesWhitelist: annoExplorerConfigs?.contentTypes?.whitelist,
    initialCardsLimit: 20,
    filterDefs: customConfigsForThisPlugin.filterDefs,
  });

  const sidebarWidth = 300;

  const { data: allFindings, status: fetchAllFindingsStatus } = useGetDataQuery(
    {
      contentTypes: ["findings"],
      findQuery: {
        kp_published_status: "published",
      },
    }
  );

  const segrigateDataByFindingsTags = ({ allFindings, totalData }) => {
    let segrigated = segrigateDocs(totalData, "annoTags.findings.data._id");
    return Object.keys(segrigated)
      .map((key) => {
        return allFindings.data.find((d) => d._id === key);
      })
      .filter(Boolean)
      .map((d) => ({
        thisFindingTagDoc: d,
        thisFindingsData: sortData({
          data: segrigated[d._id],
          path: "kp_date_published",
          fn: "date",
          sortBy: "b",
        }), //this will give us newest to oldest
      }));
  };

  const segrigatedByFindings =
    allFindings &&
    totalData &&
    segrigateDataByFindingsTags({
      allFindings,
      totalData,
    });

  const filterStickyTop = (actionBarHeight || 0) + (tabsPanelHeight || 0);

  return (
    <FilterPlusBodyShellForCollectionPages
      leftComp={
        filterConfigs && (
          <FilterModule
            filterConfig={filterConfigs}
            activeFilters={activeFilters}
            setActiveFilters={setActiveFilters}
            presetActiveFilters={presetActiveFilters}
            orientation="sidebar"
            expandedWidth={sidebarWidth}
          />
        )
      }
      filterStickyTop={filterStickyTop}
    >
      <Container style={{ background: colors2.grey2 }}>
        <PaddingTopBottom20>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "2rem" }}
          >
            {fetchAllFindingsStatus === "loading" ||
            dataFetchStatus === "loading"
              ? [1, 2, 3].map((d) => (
                  <SkeletonLoader style={{ height: "300px" }} />
                ))
              : segrigatedByFindings &&
                allFindings.data.map((f) => {
                  //basically, dont show findings cards, if there are no annotations tagged with this finding under it
                  //now because for annotations tagged with findings, we only fetch those annotations that are tagged with
                  //findings THAT are tagged with THIS INSIGHT, therefore only those findings cards will show which are tagged with
                  //this insight
                  if(!segrigatedByFindings.find((d) => d.thisFindingTagDoc._id === f._id)) return null

                  return (
                    <div
                      style={{
                        background: colors2.grey2,
                        padding: "1.6rem",
                        cursor: "pointer",
                        borderRadius: "10px",
                        border: `1px solid ${colors.greyColor10}`,
                      }}
                      // onClick={() => setSelectedFinding(f)}
                    >
                      {/* http://localhost:3000/published-page/findings?id=679b44e512888e000b31da90&activeTab=annotations */}
                      <div onClick={() => {}}>
                        {/* <MetaPrimary
                    valuePath={"main.title"}
                    /> */}
                        <Link
                          style={{ display: "flex" }}
                          to={`/published-page/findings?id=${f._id}&activeTab=annotations`}
                        >
                          <StyledTitleLink>
                            <UI_TITLE_SM semibold>{f.main.title}</UI_TITLE_SM>
                            <ArrowLineUpRight size={16} weight="bold" />
                          </StyledTitleLink>
                        </Link>
                        {lexicalTextEditorHasValue(f.main.desc) && (
                          <div
                            style={{ maxWidth: "70rem", paddingTop: "0.4rem" }}
                          >
                            <UI_BODY_SEMIBOLD_SM>
                              {getRichTextAsPlainTextLex({
                                value: f.main.desc,
                              })}
                            </UI_BODY_SEMIBOLD_SM>
                          </div>
                        )}
                      </div>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                          gap: "0.8rem",
                          marginTop: "1.6rem",
                        }}
                      >
                        {segrigatedByFindings
                          .find((d) => d.thisFindingTagDoc._id === f._id)
                          ?.thisFindingsData.map(
                            (d, i) =>
                              i < 2 && (
                                <CardAnnoExplorer
                                  // cardColor={colors2.grey2}
                                  key={singleAnno.annoKey}
                                  cardConfigs={getAnnoExplorerConfigsForThisTenant()?.cardConfigs}
                                  singleAnno={d}
                                  lastCardObserverRef={null}
                                />
                              )
                          )}
                      </div>
                    </div>
                  );
                })}
          </div>
        </PaddingTopBottom20>
      </Container>
    </FilterPlusBodyShellForCollectionPages>
  );
};
