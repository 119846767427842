export const configs_nkms_annoBase = {
  contentTypes: {
    // whitelist: ["immersion1AFGDNigeria", "immersion1ATemplates", "immersion1BIDINigeria", "immersion1BFGDNigeria"]
  },
  filterDefs: {
    whitelist: [
      "ROLLUP_tags_states_country",
      {
        filterId: "tags_states",
        filterOptionsBy: { filterId: "ROLLUP_tags_states_country" }
      },
      {
        filterId: "tags_districts",
        filterOptionsBy: { filterId: "tags_states" }
      },
      "tags_stakeholder",
      "tags_subject",
      "tags_facilityTypes",
      "tags_conditionAreas",
      
      "annoTags_type",
      "annoTags_lens",
      "annoTags_stakeholder",
      "annoTags_subject",
    ],
  },
  // disableColorCode: false,
  cardConfigs: {
    // tags: {
    //     whitelist: [
    //         'documentStatus'
    //     ]
    // },
    // annoTags: {
    //     whitelist: []
    // }
  },
};

/**
 *
 *
 *
 */
