import React, { useEffect, useState } from "react";
import { ModalSmall, OKELink, UI_BODY_SM } from "oolib";
import LexicalTextEditor from "../../../inputs/TextEditor/LexicalTextEditor";
import { defaultContent, infoData } from "./data";
import { getTenant } from "../../../../TENANT_CONFIGS";

export const ChatbotInfo = ({ variant = "raw" }) => {
  const [infoModal, setInfoModal] = useState(false);

  const data = infoData[getTenant()] || defaultContent;



  useEffect(() => {
    if (!localStorage.getItem("infoText")) {
      setInfoModal(true);
    }
  }, []);

  return variant === "modal" ? (
    <>
      <div
        style={{
          width: "fit-content",
          margin: "auto",
          marginBottom: "1rem",
        }}
      >
        <UI_BODY_SM>
          <OKELink text={"Learn more"} onClick={() => setInfoModal(true)} /> about this Chatbot.
        </UI_BODY_SM>
      </div>

      {infoModal ? (
        <ModalSmall
          title="About the Q&A Chatbot"
          onClose={() => {
            localStorage.setItem("infoText", true);
            setInfoModal(false);
          }}
        >
          {" "}
          <div
            style={{
              borderRadius: "9px",
              padding: "1.6rem 1.1rem",
              background: "#00000005",
            }}
          >
            <LexicalTextEditor readOnly={true} value={data} />
          </div>
        </ModalSmall>
      ) : null}
    </>
  ) : (
    <div
      style={{
        borderRadius: "9px",
        padding: "1.6rem 1.1rem",
        background: "#00000005",
      }}
    >
      <LexicalTextEditor readOnly={true} value={data} />
    </div>
  );
};
