import { cloneDeep } from "lodash";
import { queryClient } from "../../../../../..";
import { defaultSortConfig } from "../../../../../PublishedListing/config/defaults";
import { fixedGetDataConfig } from "../../../../../PublishedListing/config/fixedGetDataConfig";

import { defaultListingConfigForCardOrList } from "../../../../../PublishedListing/config/defaultListingConfigForCardOrList";
import { parseSpecialConfigSyntax } from "./utils/parseSpecialConfigSyntax";
import { extractAllBlocksFromTpl } from "../../../../../../utils/templating/extractAllBlocksFromTpl";

/**
 * 
 * @abstract important to understand the logic with which this works: 
 * 
 * **contentTypes** are passed via configs prop. 
 * Note 1. that this is the only 'config' that is not expected by the <ListingPage/> via 
 * the configs prop, but rather as a direct prop called 'contentTypes'. This is a 
 * slight inconsistency, but whatever.
 * **If no contentTypes are 'passed' then, we throw an error**
 * 
 * **presetActiveFilters:** Is a way to pass 'filter configs' to <ListingPage/> at the 
 * time of first mount. More useful than sending a getDataConfig.findQuery because, filters 
 * can do more optimized data fetching such as getting the ids of 'taggedResources' for a 
 * given collection, and then querying only those documents.
 * 
 * **listingConfig, filterConfig, listingStyle, keywordSearchConfig:** This part is super critical 
 * to understand. Basically, if a single contentType is passed to the ListingConfigsRenderer, then,
 * it does not expect the above 4 at all. Because, it will automatically access the tpl config of THIS
 * contentType, and extract the above 4 configs from there, and use them. If however any of the above 
 * 4 are explicitly defined, then they will override the extracted ones. 
 * **THIS ABOVE LOGIC HOWEVER, IS NOT THE CASE, IF MULTIPLE CONTENT TYPES ARE PASSED. IN SUCH A CASE
 * AT LEAST listingStyle & listingConfig has to be passed, else this page will throw an error. Reason
 * being, if different content types have different configs for the above, then its too complex to 
 * figure which config to use.
 * 
 */
export const prepareListingRendererProps = ({ configs : _configs = {}, content, readOnly }) => {

  const configs = { ..._configs };
  
  let contentTypes = configs.contentTypes

  if (!contentTypes || contentTypes.length === 0){
    console.log("At least one content type needs to be passed, to render this page. (Err message from ListingConfigsRenderer)")
    return null;
    
  }
  

  let confsExtractedFromTpl;
  
  if(contentTypes.length === 1){
    confsExtractedFromTpl = queryClient.getQueryData(['tpl',contentTypes[0]])
  }else{ // contentTypes.length > 1

    if(!configs.listingStyle) configs.listingStyle = 'CardsStyle';
    if(!configs.listingConfig) configs.listingConfig = defaultListingConfigForCardOrList
  }

  /**
   * im not sure how necessary this is. 
   * because if no contentTypes are found above, then we are breaking the page anyway.
   * apart from that, no other config needs to exist in order for the page to render really..
   * 
   * hence commenting this out, if it breaks page, then we see.
   */
  // if (!configs) return null;


  /**
   * 2// PRESET FILTERS EXTRACTION
   */

  /**
    * Determines filter config for collection pages based on template relationships.
    * If contentType is directly tagged with collection's tagType -> default config
    * If contentType has single indirect relationship (via another template) -> rollup config
    * If multiple indirect relationships exist -> default config (TODO: handle multiple paths)
    * @param {Object} configs - Contains contentTypes array specifying displayable content
    */
  const autoGenPresetActiveFilters = (configs) => {
      const defaultConfig = [
        {
          filterId: "filterByThisCollectionId",
          values: [{ value: "$valuePath(_id)" }], // dont need to do this $valuepath special character business. could simply do content._id... but maybe do it when you have time to test
          target: {
            filterType: "tagType",
            tagType: content.meta.kp_content_type,
          }
        },
      ]
      try {
        if(configs.contentTypes.length > 1 || configs.contentTypes.length === 0){
          return defaultConfig
        }else{ //configs.contentTypes.length === 1
          const allTpls = queryClient.getQueryData('allTpls')
          const thisContentTypeTpl = allTpls.find(tpl => tpl.kp_content_type === configs.contentTypes[0]);
          const allBlocks = extractAllBlocksFromTpl({tpl: thisContentTypeTpl});
          
          const tagBlocks = allBlocks.filter(d => d.valuePath.startsWith('tags.'));
          
          // Check if contentType is directly tagged with collection's tagType
          if(tagBlocks.some(bl => bl.props.tagType === content.meta.kp_content_type)){
            return defaultConfig
          }else{
            const theseTagTpls = tagBlocks.map(bl => allTpls.find(tpl => tpl.kp_content_type === bl.props?.tagType));
  
            // Find templates that both tag our contentType and are tagged with collection's tagType
            const theseTagTplsTaggedWithThisContentMetaContentType = theseTagTpls.filter(tpl => {
              const allTagBlocksInThisTpl = extractAllBlocksFromTpl({tpl: tpl})?.filter(bl => bl.valuePath.startsWith('tags.'));
              if(allTagBlocksInThisTpl.some(bl => bl.props.tagType === content.meta.kp_content_type)){
                return true
              }else{
                return false
              }
            })
    
            //if only one of theseTagTpls has this content.meta.kp_content_type as their tagType then we can do the rollup
            if(theseTagTplsTaggedWithThisContentMetaContentType.length === 0 || theseTagTplsTaggedWithThisContentMetaContentType.length > 1){
              return defaultConfig
            }else{ //theseTagTplsTaggedWithThisContentMetaContentType.length === 1
              //return the rollupone here.
              
              return [
                {
                  "filterId": "filterByThisCollectionId",
                  "values": [{ "value": "$valuePath(_id)" }],
                  "target": {
                    "filterType": "nestedRollupTagType",
                    "rollupPath": [theseTagTplsTaggedWithThisContentMetaContentType[0].kp_content_type, content.meta.kp_content_type]
                  }
                }
              ]
            }
          }
          
        }  
      } catch (error) {
        console.log('Error in figureOutPresetActiveFilters:', error);
        return defaultConfig;
      }
      
  }
  
    // console.log({FIGUREDOUT: autoGenPresetActiveFilters(configs)})
  
  let presetActiveFilters = configs.presetActiveFilters || (configs.isAnnotation?[ {
    filterId: "filterByAnnotationTag",
    values: [{ value: content.tagId }],
    target: {
      filterType: "annotationTagType",
      tagType: content.meta.kp_content_type,
    }
  }]: autoGenPresetActiveFilters(configs))
 

  /**
   * we do this simply to keep the data clean.
   * since we are anyway pulling out presetActiveFilters and
   * passing it through as a separate prop
   */
  const rmPresetActiveFiltersFromConfigs = (configs) => {
    if (!configs.presetActiveFilters) return configs;
    //else
    let newConf = cloneDeep(configs);
    delete newConf.presetActiveFilters;
    return newConf;
  };


  // FINALLY, generate the configs prop to be passed the <ListingPage/>

  // for tagType filters tagged with this collection's tagType.
  // e.g if this is a country's collection page, and say on THIS tab is a listing of some stories
  // with a 'states' filter. then IF the states are generally being tagged with countries, then,
  // we wanna funnel down these states to show only those that belong to this country

  const autoInjectFunneling = ({filterConfig, content}) => {
    try {
      if(!filterConfig?.filters || filterConfig.filters.length === 0) return filterConfig;

      const allTpls = queryClient.getQueryData('allTpls')
      const thisCollectionTpl = allTpls.find(tpl => tpl.kp_content_type === content.meta.kp_content_type);
      if(!thisCollectionTpl) return filterConfig; //this is impossible, but still we check
      //else
      
      const updatedFiltersArray = filterConfig.filters.map(conf => {
        if(conf.source.filterType === 'tagType'){
          const thisFilterTagTpl = allTpls.find(tpl => tpl.kp_content_type === conf.source.tagType);
          const allBlocksOnIt = extractAllBlocksFromTpl({tpl: thisFilterTagTpl});
          const isTaggedWithThisCollectionTagType = allBlocksOnIt.some(bl => bl.props.tagType === content.meta.kp_content_type)
          if(isTaggedWithThisCollectionTagType){
            return ({
              ...conf,
              source: {
                ...conf.source,
                findQuery: {
                  ...(conf.source.findQuery || {}),
                  [`tags.${content.meta.kp_content_type}.data._id`] : content._id //basically this says, get only those states into the filter options, which are tagged with whatever country this collection page represents.
                }
              }
            })
          }else{
            return conf
          }
        }else{
          return conf
        }
      })
  
      return ({
        ...filterConfig,
        filters: updatedFiltersArray
      })  
    } catch (error) {
      return filterConfig
    }
    
  }

  const genListingPageConfigsProp = () => {
    const parsed = parseSpecialConfigSyntax({
      config: rmPresetActiveFiltersFromConfigs(configs),
      content,
    });
    let filterConfig = parsed.filterConfig || confsExtractedFromTpl?.listing.filterConfig;
    filterConfig = autoInjectFunneling({filterConfig, content})
    return {
      // confsExtractedFromTpl wont exist of contentTypes.length > 1 (since that is the logic decided upon. read @abstract above)
      filterConfig, 
      listingStyle: parsed.listingStyle || confsExtractedFromTpl?.listing.listingStyle,
      listingConfig: parsed.listingConfig || confsExtractedFromTpl?.listing.listingConfig,
      keywordSearchConfig: parsed.keywordSearchConfig || confsExtractedFromTpl?.listing.keywordSearchConfig,
      getDataConfig: {
        ...fixedGetDataConfig, // this ensures only published content is fetched, and kp_contributed_by is populated.
        ...(parsed.getDataConfig || {}), // unless this overrides the above..
      },
      sortConfig: parsed.sortConfig || defaultSortConfig,
   
            tagCategory: {
              display: content.meta.kp_content_type,
              value: content.meta.kp_content_type,
            },
            tag: { display: content.main?.title, value: content.tagId },
          
 
    };
  };

  
  
  

  const TCIIsActive = sessionStorage.getItem("TCIIsActive") === "true"

  return ({
    contentTypes,
    content,
    presetActiveFilters: !TCIIsActive ? parseSpecialConfigSyntax({
      config: presetActiveFilters,
      content,
    }) : undefined,
    configs: genListingPageConfigsProp(),
    listingExport: true
  });
};
