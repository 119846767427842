export const extractImages = (imageInstances) => {
    return imageInstances.reduce((acc, instance) => {
      const metaData = {
        stakeholder: instance.tags?.stakeholder,
        facilityType: instance.main?.facilityType,
        subject: instance.tags?.subject,
        contentType: instance.meta?.kp_content_type,
        country: instance.tags?.country,
        states: instance.tags?.states,
        districts: instance.tags?.districts,
        kp_date_published: instance?.kp_date_published
      };
      
      const imagesArray = instance.main.images.map((image) => ({
        ...image,
        id: instance._id,
        metaData
      }));
      
      return [...acc, ...imagesArray];
    }, []);
};