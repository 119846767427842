import { colors, CONTENT_H1, styledOKELinkCSS } from "oolib";
import styled, { css } from "styled-components";
import { codeThemeCSS } from "./themes/code";
import { tableThemeCSS } from "./themes/table";
import { annoThemeCSS } from "./themes/anno";

export const StyledEditorWrapperLex = styled.div`
  position: relative;

  color: ${({invert}) => invert ? colors.greyColor10 : colors.greyColor100 };

  /*  h1, h2, h3, and blockquote */
  .contentEditableLex h1,
  .contentEditableLex h2,
  .contentEditableLex h3,
  .contentEditableLex blockquote {
    margin: 28px 0;
  }

  /* for first child h1, h2, h3, and blockquote */
  .contentEditableLex > h1:first-child,
  .contentEditableLex > h2:first-child,
  .contentEditableLex > h3:first-child,
  .contentEditableLex > blockquote:first-child {
    margin-top: 0;
    margin-bottom: 28px;
  }

  /* for h1, h2, h3, and blockquote followed by a paragraph */
  .contentEditableLex h1 + p,
  .contentEditableLex h2 + p,
  .contentEditableLex h3 + p{
    margin-top: -20px; /* 28px - 20px = 8px */
  }

  /* paragraph  */
  .contentEditableLex p {
    margin-bottom: 8px;
  }

  .contentEditableLex {
    &:focus {
      outline: none;
    };
    ${({textAlignment}) => textAlignment && css` text-align: ${textAlignment}`};
  }
  .orderedListStyleLex,
  .unorderedListStyleLex {
    padding-left: 2rem;
  }

  .listItemStyleLex {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
  }

  .orderedListStyleLex .listItemStyleLex {
    list-style: decimal;
  }

  .unorderedListStyleLex .listItemStyleLex {
    list-style: disc;
  }

  .temp_bold {
    font-weight: 600; //600 is actually semibold
  }

  .temp_italic {
    font-style: italic;
  }

  .temp_underline {
    text-decoration: underline;
  }

  .OKELink {
    ${styledOKELinkCSS};
  }

  /** code styling */
  ${codeThemeCSS}

  ${tableThemeCSS}

  /** checkbox styling.
      ideally it should pull from the exact styling of CheckboxList on oolib
   */
  .temp_listItemCheckedStyleLex,
  .temp_listItemUncheckedStyleLex {
    position: relative;
    margin-left: 8px;
    margin-right: 8px;
    padding-left: 28px;
    padding-right: 24px;
    list-style-type: none !important;
    outline: none;
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
  }
  .temp_listItemCheckedStyleLex {
    text-decoration: line-through;
  }
  .temp_listItemUncheckedStyleLex:before,
  .temp_listItemCheckedStyleLex:before {
    content: "";
    width: 2rem;
    height: 2rem;
    top: 2px;
    left: 0;
    cursor: pointer;
    display: block;
    background-size: cover;
    position: absolute;
  }
  .temp_listItemUncheckedStyleLex[dir="rtl"]:before,
  .temp_listItemCheckedStyleLex[dir="rtl"]:before {
    left: auto;
    right: 0;
  }
  /* .temp_listItemUncheckedStyleLex:focus:before,
  .temp_listItemCheckedStyleLex:focus:before {
    box-shadow: inset 0 0 0 2px ${({ theme }) => theme?.colors.primaryColor40};
    border-radius: 2px;
  } */
  .temp_listItemUncheckedStyleLex:before {
    box-shadow: inset 0 0 0 2px ${({ theme }) => theme?.colors.primaryColor100};
    border-radius: 2px;
  }
  .temp_listItemCheckedStyleLex:before {
    border: 1px solid ${({ theme }) => theme?.colors.primaryColor100};
    border-radius: 2px;
    background-color: ${({ theme }) => theme?.colors.primaryColor100};
    background-repeat: no-repeat;
  }
  .temp_listItemCheckedStyleLex:after {
    content: "";
    cursor: pointer;
    border-color: ${colors.white};
    border-style: solid;
    position: absolute;
    display: block;
    top: 4.5px;
    width: 7px;
    left: 6.5px;
    height: 12px;
    transform: rotate(45deg);
    border-width: 0 1.8px 1.8px 0;
    border-radius: 1.1px;
  }

  .PlaygroundEditorTheme__table h1,
  .PlaygroundEditorTheme__table h2,
  .PlaygroundEditorTheme__table h3 {
    margin: 0;
  }
  //anno temp
  ${annoThemeCSS}
  
`;

export const StyledPlaceholderLex = styled.p`
  color: ${({ theme, placeholderColor, invert }) => placeholderColor || (invert ? theme?.colors.primaryColor40 : theme?.colors.primaryColor100)};
  position: absolute;
  top: 0;
  width: 100%;
  ${({textAlignment}) => textAlignment && css` text-align: ${textAlignment}`};
`;
