import { colors2, getVal, MetaBlockV2, UI_CAPTION, UI_CAPTION_SEMIBOLD, UI_TAG } from "oolib";
import React, { useRef } from "react";
import LexicalTextEditor from "../../../../inputs/TextEditor/LexicalTextEditor";
import { uiContent } from "../../uiContent";
import { StyledCardShell, StyledInfoSection } from "./styled";
import { useGetContribution } from "../../../../../utils/react-query-hooks/contributions";
import { TagSectionOnCard } from "./comps/TagSectionOnCard";
import { applyTagsWhitelistConfig } from "../../utils/applyTagsWhitelistConfig";

// const { IndiaFlag } = icons;

// const flagsMap = {
//   "india" : IndiaFlag
// }


export const CardAnnoExplorer = ({ singleAnno, lastCardObserverRef, showTagSection=true, cardConfigs }) => {
  // const FlagIcon = flagsMap[singleAnno.tags.country.data[0].tagId]  
  const {annoTags, tags } = applyTagsWhitelistConfig({annoDoc: singleAnno, cardConfigs})
  const colors = singleAnno?.fills?.length > 0 ? singleAnno?.fills : [];

  return (
    <a
      target="_blank"
      href={`/published-page/${singleAnno.meta.kp_content_type}?id=${singleAnno.meta.documentId}`}
    >
     
      <StyledCardShell
        key={singleAnno._id + singleAnno.annoKey}
        // className={singleAnnoI === thisAry.length - 1 && 'has ref'}
        ref={lastCardObserverRef}
        // style={{backgroundColor: singleAnno?.fills?.length > 0 ? singleAnno?.fills[0] : "white"  }}
      >
        <StyledInfoSection colors={colors}>
          
          {/* Content type title */}
          <div style={{ height: '1.8rem', display: 'flex', justifyContent: "space-between" }}> {/* Temp height as there is no icon */}
            <UI_TAG style={{ color: colors2.grey60 }}>{getVal(singleAnno, "meta.contentTypeTitle")}</UI_TAG>
            {/* {FlagIcon && <FlagIcon size={20} /> } */}
          </div>

          {/* Single anno lex */}
          <div style={{ paddingBottom: "0.4rem", color: colors2.grey80 }}>
            <LexicalTextEditor variant={"simple"} style={{ color: colors2.grey80 }} value={singleAnno.fragment} readOnly disableAnnoColors={true} annotation={false}/>
          </div>
          
          {/* title */}
          <div style={{ paddingBottom: "1.2rem" }}>
            <UI_CAPTION_SEMIBOLD style={{ color: colors2.grey60 }}>{singleAnno.main.title}</UI_CAPTION_SEMIBOLD>
          </div>

          {/* Meta Block */}
          {
            /**
             * what this means is, 
             * 
             * if metablock configs aren't defined at all, render it with default configs.
             * 
             * if however the configs explicitly define metablock configs as an empty array,
             * then that means we shouldnt render this at all.
             * 
             */
            (!cardConfigs?.metaBlock || cardConfigs?.metaBlock.length !== 0) &&
            <div style={{ paddingBottom: "1rem" }}>
            <MetaBlockV2 
              data={singleAnno}
              config={cardConfigs?.metaBlock || [
                { key: "date", valuePath: "kp_date_published"},
                { key: "name", valuePath: "meta.kp_contributed_by.name"}
              ]}
              />
          </div>
          }
            
          
        </StyledInfoSection>

        {showTagSection?
          <TagSectionOnCard
            {...{
              annoTags,
              annoTagsTitle: uiContent.annoTagsTitle,
              tags,
              tagsTitle: uiContent.tagsTitle,
              // cardWidth: annoCardRef?.current?.getBoundingClientRect().width,
            }}
            />
        :null}
      </StyledCardShell>
    </a>
  );
};
