export const RTEDefaultInternalEmbedConfig = {
    title: 'main.title',
    desc: ["main.desc.allText"],
    cardLabel: '$thisContentType',
    image: ['main.coverImage', 'main.images'],
    video: 'main.video',
    metaBlock: [
      { key: 'name', valuePath: 'meta.kp_contributed_by.name' },
      { key: 'date', valuePath: 'kp_date_published' }
    ]
  }