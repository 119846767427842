import { getBreakPoint, Section, useScreenWidth } from "oolib";
import React from "react";

export const HeaderContributeTpl = ({
  PrimaryCTA,
  SaveStatus,
  CenterComp,
  CloseButton,
  AfterComp,
}) => {
  const isMobile = useScreenWidth() < getBreakPoint('md');
  return (
    <Section
      borderBottom
      white
      style={{ position: "sticky", top: 0, zIndex: 999999, margin: 0 }}
    >
      <div>
        <div>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              columnGap: "2rem",
              // justifyContent: "space-between",
              minHeight: "6rem",
              padding: isMobile ? "12px 20px" : "14px 16px",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "1rem",
              }}
            >
              {PrimaryCTA}
              {SaveStatus}
            </div>
            {CenterComp}

            {CloseButton}
          </div>
        </div>
      </div>
      {AfterComp}
    </Section>
  );
};
