import { ButtonGhost, colors, SANS_2, SANS_3, icons, Tooltip, colors2, useScreenWidth, CheckboxButton } from "oolib";
import React, { useState } from "react";
import { useEffect } from "react";
import { useTheme } from "styled-components";
import { _Locale } from "../../../../locale/Locale";
import { StyledCheckboxContainer, StyledDataTableHeader, StyledDataTableHeaderCell, StyledDataTableHeaderCellText, StyledHeaderCellContentWrapper } from "../../styled";
import { checkIfSortPathsAreMatching } from "../../utils/tableSortUtils";
import { HeaderCellDropdown } from "./comps/HeaderCellDropdown";
import { useResizeDataTableColumns } from "./utils/useResizeDataTableColumns";
import { ROW_HEIGHT } from "../..";
const { ArrowDown, ArrowUp } = icons

const TableHeaderElem = ({
  tableConfig,
  handleTableSort,
  activeSorts,
  actions,
  bulkActions,
  colWidthConfig,
  setColWidthConfig,
  backendActiveSort,
  builderProps,
  tableRowIds,
  bulkActions_selectedRowIds,
  bulkActions_setSelectedRowIds,
}) => {
  const screenWidth = useScreenWidth();

  const isBackendSort = !!backendActiveSort

  const sortConfigExists = (d) =>
    isBackendSort ? !!d.backendSortConfig : !!d.sortConfig;

  const genArrows = (d) => {
    if (!sortConfigExists(d)) return null;
    //else

    if (isBackendSort) {
      return (
        <div className="TableElem__HeaderCellSortBtn">
          { checkIfSortPathsAreMatching(backendActiveSort.sort, d.backendSortConfig.sort)
            ? Object.values(backendActiveSort.sort)[0] === 1 
              ? <ArrowUp size={16} /> //ascending
              : <ArrowDown size={16} /> //descending
            : null }
        </div>
      );
    } else {
      return (
        <div className="TableElem__HeaderCellSortBtn">
          { activeSorts[d.colId] === "a" ? (
            <ArrowUp size={16} /> //ascending
          ) : activeSorts[d.colId] === "b" ? (
            <ArrowDown size={16} /> //descending
          ) : null }
        </div>
      );
    }
  };

  const theme = useTheme()


  const {
    resetColResizeState,
    hideShowDragZoneIndicator,
    initColResizeState,
    resetDragZoneIndicator,
    handleResizeColumn,
    colResizeState,
    dragZoneWidth
  } = useResizeDataTableColumns({
    setColWidthConfig,
    colWidthConfig,
    theme,
    builderProps
  })

  useEffect(() => {
    window.addEventListener('mouseup', resetColResizeState)
    window.addEventListener('mousemove', handleResizeColumn)
    return () => {
      window.removeEventListener('mousemove', handleResizeColumn)
      window.removeEventListener('mouseup', resetColResizeState)
    }
  },[colResizeState])

  const [showHeaderCellDropdown, setShowHeaderCellDropdown] = useState(undefined);

  const selectedRowsCount = bulkActions_selectedRowIds.length;
  const totalNumberOfRows = tableRowIds?.length;

  return (
    
    <StyledDataTableHeader
      style={{ 
        /* 
          cuz if no builderProps && table actions, 
          then no 'plus button in header OR ellipsis in table rows, 
          in which case the border right on the last cell of each row will work as the table's borderRight
        */
        borderRight: !builderProps && !actions && 'none' 
      }}
      // onMouseMove={handleResizeColumn}
    > 
      {bulkActions && (
        <StyledCheckboxContainer
          screenWidth={screenWidth}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <CheckboxButton
            /*
              Displays the minus icon when at least one row is selected but not all rows are selected:
              selectedRowsCount > 0 && selectedRowsCount !== totalNumberOfRows
            */
            isSelected={selectedRowsCount > 0}
            isMixture={selectedRowsCount !== totalNumberOfRows}
            onChange={(k, v) => {
              if (v === true) {
                // Select All - Add all rowIds to bulkActions_selectedRowIds array
                bulkActions_setSelectedRowIds(tableRowIds);
              } else {
                // Deselect All - Clicking on the check icon or minus icon deselects all rows
                bulkActions_setSelectedRowIds([]);
              }
            }}
          />
        </StyledCheckboxContainer>
      )}
      {tableConfig.cellData.map((d,i) => {
        return (
          <StyledDataTableHeaderCell
            freezeFirstCol={tableConfig.freezeFirstCol}
            cellIdx={i}
            id='TableHeaderCell' //v.imp. used in the col resize calculations
            key={d.colId}
            onMouseOut={resetDragZoneIndicator}
            onMouseDown={(e) => {
              initColResizeState(e, d)
            }}
            // onMouseUp={resetColResizeState}
            onMouseMove={hideShowDragZoneIndicator}
            style={{
              width: colWidthConfig[d.colId],
              height: tableConfig.cellData.some(d => d.subColsConfig) ? (ROW_HEIGHT*2)+'px' : ROW_HEIGHT+'px',
              borderRight: colResizeState?.colId === d.colId && `2px solid ${theme.colors.primaryColor100}`
            }}
            // onClick={(e) => !isInDragZone(e) && sortConfigExists(d) && handleSort(d)}
          >
            <StyledHeaderCellContentWrapper 
              style={{marginLeft: '1rem', marginRight: `${dragZoneWidth}px`}} //need this margin, else hideShowDragZoneIndicator doesnt fire on StyledDataTableHeaderCell, since in the hideShow function, we entertain events firing directly from StyledDataTableHeaderCell, and not its children
              onClick={() => {
                setShowHeaderCellDropdown(d)
              }}
              title={d.headerDisplay}>
              {d.headerDisplay && (
                <StyledDataTableHeaderCellText semibold>
                  {_Locale(d.headerDisplay)}
                </StyledDataTableHeaderCellText>
              )}
              {showHeaderCellDropdown?.colId === d.colId && 
                <HeaderCellDropdown
                  setShowHeaderCellDropdown={setShowHeaderCellDropdown} 
                  onSortClick={(asc_desc) => {
                    let value = isBackendSort 
                      ? asc_desc === 'ascending' ? 1 : -1
                      : asc_desc === 'ascending' ? 'a' : 'b'
                    sortConfigExists(d) && handleTableSort(d, value)
                    setShowHeaderCellDropdown(false)
                  }}
                  {...{isBackendSort, backendActiveSort, showHeaderCellDropdown, activeSorts}}
                  /> }
                  {d.infoTooltip? <Tooltip presetTarget={"infoIcon"} {...d.infoTooltip} />:null}
              { genArrows(d)}

            </StyledHeaderCellContentWrapper>
            {d.subColsConfig && (
              <div style={{display: 'flex'}}>
                {d.subColsConfig.map((s,i) => (
                  <StyledHeaderCellContentWrapper 
                    style={{
                      width: `${100 / d.subColsConfig.length}%`,
                      borderTop: `1px solid ${colors2.grey10}`,
                      borderRight: i !== d.subColsConfig.length - 1 && `1px solid ${colors2.grey10}`
                    }}
                    title={s.headerDisplay}
                    >
                    <StyledDataTableHeaderCellText bold>{s.headerDisplay}</StyledDataTableHeaderCellText>
                    {s.infoTooltip? <Tooltip presetTarget={"infoIcon"} { ...s.infoTooltip} />:null}
                  </StyledHeaderCellContentWrapper>
                ))}
              </div>
            )}
          </StyledDataTableHeaderCell>
        );
      })}
      {builderProps && <ButtonGhost iconSize='S'  M icon='Plus' onClick={() => builderProps.showColumnConfigureTools()}/>}  
    </StyledDataTableHeader>
    
    
  );
};

export default TableHeaderElem;
