import { toArray } from "oolib";



export const getSessionStorageKeyForListingPage = ({contentTypes = [], collectionId}) => {
    return `ListingPage__${
    collectionId ? collectionId + "__" : ""
  }${toArray(contentTypes).join("_")}`
}

export const getSessionStorageDataForListingPage = sessionStorageKey => JSON.parse(sessionStorage.getItem(sessionStorageKey))